import { useMemo } from 'react'

import type { AppProps } from 'next/app'

import { initializeApollo } from './apollo'
import { APOLLO_STATE_PROP_NAME } from './utils'

export function useApollo(pageProps: AppProps['pageProps']) {
  const state = pageProps[APOLLO_STATE_PROP_NAME]
  const store = useMemo(
    () => initializeApollo({ initialState: state }),
    [state]
  )
  return store
}
