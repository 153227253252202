import { Fragment } from 'react'

import { CgChevronDown } from 'react-icons/cg'

import { Box, HStack, Text } from '@chakra-ui/react'
import { Listbox, Transition } from '@headlessui/react'

interface ListBoxIProps {
  selectedValuePrefix?: string
  options: { name: string; value: number }[]
  selected: { name: string; value: number }
  setSelected: (value: { name: string; value: number }) => void
  onButtonHover?: () => void
  onButtonClick?: () => void
}

export const ListBox = ({
  selectedValuePrefix,
  options,
  selected,
  setSelected,
  onButtonHover,
  onButtonClick,
}: ListBoxIProps) => {
  return (
    <Listbox onChange={setSelected} value={selected}>
      <Box mt={1} pos='relative' w='auto'>
        <Listbox.Button
          _hover={{
            color: '#091848',
            bg: '#F2F7FF',
          }}
          as={HStack}
          color='#1F2023'
          cursor='pointer'
          data-testid='Listbox:Button'
          isTruncated
          justifyContent='space-between'
          onClick={onButtonClick}
          onMouseEnter={onButtonHover}
          p='8px'
          rounded='full'
          transition='all ease 300ms'
        >
          <Text fontSize='10px' isTruncated w='full'>
            {selectedValuePrefix
              ? `${selectedValuePrefix} ${selected?.name}`
              : selected?.name}
          </Text>
          <Box>
            <CgChevronDown size={14} />
          </Box>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <Listbox.Options
            _focus={{
              outline: 'none',
            }}
            as={Box}
            bg='#fff'
            boxShadow='0px 0px 4px rgba(0, 0, 0, 0.25)'
            left='50%'
            p='8px'
            pos='absolute'
            rounded='8px'
            top='calc(100% + 10px)'
            transform='translateX(-50%)'
            w='150px'
            zIndex='1'
          >
            {options.map((option, personIdx) => (
              <Listbox.Option
                _hover={{
                  bg: '#F2F7FF',
                }}
                as={Box}
                cursor='pointer'
                key={personIdx}
                p='8px'
                rounded='4px'
                value={option}
              >
                {({ selected }) => (
                  <Text
                    color={selected ? '#091848' : '#898989'}
                    fontSize='12px'
                    fontWeight={selected ? 'bold' : 'normal'}
                  >
                    {option.name}
                  </Text>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </Box>
    </Listbox>
  )
}
