import { Circle, Flex } from '@chakra-ui/react'

import { DayOptionI } from '@/features/incident-rules'

interface DayOfTheWeekPickerIProps {
  onClick: (value: string, index: number) => void
  selected?: boolean
  days: DayOptionI[]
}

export const DayOfTheWeekPicker = ({
  days,
  onClick,
}: DayOfTheWeekPickerIProps) => {
  return (
    <Flex flexDirection='row' gap='6px'>
      {days.map((day, index) => (
        <Circle
          _hover={{ opacity: 0.5 }}
          bg={day.selected ? '#3279C7' : '#9FB8E5'}
          color='white'
          cursor='pointer'
          fontSize='14px'
          fontWeight='bold'
          key={day.value}
          onClick={() => onClick(day.value, index)}
          size='30px'
        >
          {day?.label}
        </Circle>
      ))}
    </Flex>
  )
}
