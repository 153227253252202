import { usePermissions } from '@/hooks/usePermissions'
import { useToasts } from '@/hooks/useToasts'
import { SubmitHandler, useForm } from 'react-hook-form'
import { HiOutlineCheckCircle } from 'react-icons/hi'

import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import * as EmailValidator from 'email-validator'

import {
  Button,
  FormInputControl,
  FormInputSelectControl,
} from '@/components/ui'
import { RoleExtended } from '@/features/managed-accounts'
import {
  ManagedAccountsDocument,
  useCreateUserMutation,
  useFacilityNamesQuery,
} from '@/graphql/generated/hooks'
import { getFacilityOptions } from '@/utils/forms/optionBuilders'

import { RoleIcon, getRoleDisplayName } from './UserRoles'

const IconWrapperStyled = styled(Box)`
  .icon {
    width: 30px;
  }
`
interface FormInputsIProps {
  firstName: string
  lastName: string
  email: string
  designatedFacility: { value: string; label: string }
}

interface IProps {
  isOpen: boolean
  onClose: () => void
  role: RoleExtended
}

export const CreateUserModal = ({ isOpen, onClose, role }: IProps) => {
  const { shouldEnableCreateUser } = usePermissions()
  const [createUser, { loading: isLoadingCreateUser }] = useCreateUserMutation()
  const { handleSubmit, register, control, errors } = useForm<FormInputsIProps>(
    {
      mode: 'onChange',
    }
  )
  const { data: facilitiesData } = useFacilityNamesQuery({
    fetchPolicy: 'network-only',
  })

  const facilityOptions = getFacilityOptions(facilitiesData)

  const { showSuccess, showError } = useToasts()

  const onSubmit: SubmitHandler<FormInputsIProps> = async (values) => {
    const data = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      roleName: role === RoleExtended.EMPLOYEE ? '' : role,
      designatedFacilityId: values.designatedFacility.value,
    }

    try {
      await createUser({
        variables: {
          input: data,
        },
        refetchQueries: [
          {
            query: ManagedAccountsDocument,
          },
        ],
      })
      onClose()
      showSuccess(`${getRoleDisplayName(role)} created.`)
    } catch {
      showError('Something went wrong.')
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      size='xl'
    >
      <ModalOverlay />
      <ModalContent bg='#fff'>
        <ModalHeader>
          <Box alignItems='center' d='flex'>
            <IconWrapperStyled>
              <RoleIcon role={role} />
            </IconWrapperStyled>
            <Box ml={3}>
              <Box
                color='#353849'
                fontSize='26px'
                fontWeight='extrabold'
                letterSpacing='-0.4px'
                lineHeight='1'
              >
                {`Create ${getRoleDisplayName(role)}`}
              </Box>
            </Box>
          </Box>
        </ModalHeader>
        <ModalCloseButton color='#000' opacity={0.4} />
        <ModalBody p={0}>
          <Box px={6} py={2}>
            <form data-testid='CreateUserModal:form'>
              <Box mb={3}>
                <FormInputControl
                  data-testid='CreateUserModal:firstName'
                  errorMessage={errors.firstName && errors.firstName.message}
                  id='firstName'
                  inputRef={register({
                    required: 'Please add first name',
                  })}
                  isInvalid={!!errors.firstName}
                  label={`${getRoleDisplayName(role)} First Name`}
                  placeholder={`Enter ${getRoleDisplayName(role)} First Name`}
                />
              </Box>
              <Box mb={3}>
                <FormInputControl
                  data-testid='CreateUserModal:lastName'
                  errorMessage={errors.lastName && errors.lastName.message}
                  id='lastName'
                  inputRef={register({
                    required: 'Please add last name',
                  })}
                  isInvalid={!!errors.lastName}
                  label={`${getRoleDisplayName(role)} Last Name`}
                  placeholder={`Enter ${getRoleDisplayName(role)} Last Name`}
                />
              </Box>
              <Box mb={3}>
                <FormInputControl
                  data-testid='CreateUserModal:email'
                  errorMessage={errors.email && errors.email.message}
                  id='email'
                  inputRef={register({
                    required: 'You must specify an email',
                    validate: {
                      isEmail: (v) =>
                        EmailValidator.validate(v) || 'Invalid email address',
                    },
                  })}
                  isInvalid={!!errors.email}
                  label={`${getRoleDisplayName(role)} Email`}
                  placeholder={`Enter ${getRoleDisplayName(role)} Email`}
                  type='email'
                />
              </Box>
              <Box mb={3}>
                <FormInputSelectControl
                  control={control}
                  errorMessage={
                    !!errors?.designatedFacility && 'You must select a facility'
                  }
                  id='designatedFacility'
                  isInvalid={!!errors.designatedFacility}
                  label={`${getRoleDisplayName(role)} Facility`}
                  options={facilityOptions}
                  placeholder={`Enter ${getRoleDisplayName(role)} Facility`}
                  rules={{ required: true }}
                />
              </Box>
            </form>
          </Box>
        </ModalBody>
        <ModalFooter py={4}>
          <Button
            data-testid='CreateUserModal:cancel'
            mr={3}
            onClick={onClose}
            variant='secondary'
          >
            Cancel
          </Button>
          <Button
            data-testid='CreateUserModal:submit'
            isDisabled={!shouldEnableCreateUser}
            isLoading={isLoadingCreateUser}
            leftIcon={<HiOutlineCheckCircle size='24px' />}
            loadingText={`Creating ${getRoleDisplayName(role)}`}
            onClick={handleSubmit(onSubmit)}
            type='submit'
          >
            {`Create ${getRoleDisplayName(role)}`}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
