import { DragEvent } from 'react'

interface OffsetI {
  x: number
  y: number
}

export interface DragEventI {
  data: unknown
  grabOffset: OffsetI
}

interface DraggableIProps {
  children: React.ReactNode
  dragData: any
  dragEnabled?: boolean
  onDragStart?: (event: DragEventI) => void
  onDragEnded?: () => void
}

export const Draggable = ({
  children,
  dragData,
  onDragStart,
  onDragEnded,
  dragEnabled = true,
}: DraggableIProps) => {
  const onDragStarting = (e: DragEvent<HTMLDivElement>) => {
    const currentTargetRect = e.currentTarget.getBoundingClientRect()

    // Find the offset of the mouse from those coordinates.
    const offset = {
      x: e.clientX - currentTargetRect.left,
      y: e.clientY - currentTargetRect.top,
    }

    // Pass the drag data
    onDragStart({ data: dragData, grabOffset: offset })
  }

  return (
    <div
      className='draggable'
      draggable={dragEnabled}
      onDragEnd={onDragEnded}
      onDragStart={onDragStarting}
    >
      {children}
    </div>
  )
}
