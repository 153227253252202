import { OptionI } from './optionBuilders'

export const sortOptions = <T>(options: OptionI<T>[]): OptionI<T>[] => {
  return [...options].sort((a, b) => a.label.localeCompare(b.label))
}

export const getDefaultOption = <T>(
  option: OptionI<T> | OptionI<T>[]
): OptionI<T> | OptionI<T>[] | null => {
  if (Array.isArray(option)) {
    return option?.length ? option : null
  } else {
    return option || null
  }
}

export const mapOptions = <T>(arr: T[]): OptionI<T>[] =>
  arr.map((optionValue) => ({
    label: optionValue as string,
    value: optionValue,
  }))

export const getOptionsArray = <T>(options: OptionI<T>[] | OptionI<T>) =>
  Array.isArray(options) ? options : [options]
