export const Empty = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      fill='none'
      height='138'
      viewBox='0 0 139 138'
      width='139'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_109_2541)'>
        <path
          d='M109.75 25.875H29.25C21.3121 25.875 14.875 32.3121 14.875 40.25V106.375C14.875 114.313 21.3121 120.75 29.25 120.75H109.75C117.688 120.75 124.125 114.313 124.125 106.375V40.25C124.125 32.3121 117.688 25.875 109.75 25.875Z'
          fill='#C7D1DB'
        />
        <path
          d='M46.5 31.625C44.9101 31.625 43.625 30.337 43.625 28.75V17.25H35V28.75C35 30.337 33.7149 31.625 32.125 31.625C30.5351 31.625 29.25 30.337 29.25 28.75V17.25C29.25 14.0789 31.8289 11.5 35 11.5H43.625C46.7961 11.5 49.375 14.0789 49.375 17.25V28.75C49.375 30.337 48.0899 31.625 46.5 31.625Z'
          fill='#9FADBC'
        />
        <path
          d='M106.875 31.625C105.285 31.625 104 30.337 104 28.75V17.25H95.375V28.75C95.375 30.337 94.0899 31.625 92.5 31.625C90.9101 31.625 89.625 30.337 89.625 28.75V17.25C89.625 14.0789 92.2039 11.5 95.375 11.5H104C107.171 11.5 109.75 14.0789 109.75 17.25V28.75C109.75 30.337 108.465 31.625 106.875 31.625Z'
          fill='#9FADBC'
        />
        <path
          d='M55.125 97.7586C54.3603 97.7586 53.5984 97.4567 53.032 96.8587C51.9424 95.703 51.997 93.8831 53.1499 92.7935L72.2859 74.75L53.1528 56.7094C51.9999 55.6197 51.9453 53.7999 53.0349 52.6441C54.1217 51.4941 55.9444 51.4366 57.1002 52.5262L76.4891 70.8084C77.5673 71.8089 78.1998 73.2521 78.1998 74.75C78.1998 76.2479 77.5673 77.6911 76.4662 78.7117L57.1002 96.9766C56.5424 97.4999 55.8323 97.7586 55.125 97.7586Z'
          fill='#9FADBC'
        />
        <path
          d='M72.375 77.625H3.375C1.78513 77.625 0.5 76.3399 0.5 74.75C0.5 73.1601 1.78513 71.875 3.375 71.875H72.375C73.9649 71.875 75.25 73.1601 75.25 74.75C75.25 76.3399 73.9649 77.625 72.375 77.625Z'
          fill='#9FADBC'
        />
      </g>
      <defs>
        <clipPath id='clip0_109_2541'>
          <rect
            fill='white'
            height='138'
            transform='translate(0.5)'
            width='138'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
