export const Settings = ({
  className,
  strokeColor = '#fff',
}: {
  className?: string
  strokeColor?: string
}) => {
  return (
    <svg
      className={className}
      height='48'
      viewBox='0 0 48 48'
      width='48'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <path d='M0 0h48v48H0V0z' fill='none' />
        <path
          d='M20.65 8.634c.852-3.512 5.848-3.512 6.7 0 .25928608 1.0701184 1.01406748 1.95204848 2.03130312 2.37349162 1.01723564.42144314 2.17457904.3317109 3.11469688-.24149162 3.086-1.88 6.62 1.652 4.74 4.74-.57237506.9397078-.66189078 2.09616214-.24094407 3.11275833C37.41600265 19.63535453 38.29685758 20.39000297 39.366 20.65c3.512.852 3.512 5.848 0 6.7-1.0701184.25928608-1.95204848 1.01406748-2.37349162 2.03130312-.42144314 1.01723564-.3317109 2.17457904.24149162 3.11469688 1.88 3.086-1.652 6.62-4.74 4.74-.9397078-.57237506-2.09616214-.66189078-3.11275833-.24094407C28.36464547 37.41600265 27.60999703 38.29685758 27.35 39.366c-.852 3.512-5.848 3.512-6.7 0-.25928608-1.0701184-1.01406748-1.95204848-2.03130312-2.37349162-1.01723564-.42144314-2.17457904-.3317109-3.11469688.24149162-3.086 1.88-6.62-1.652-4.74-4.74.57237506-.9397078.66189078-2.09616214.24094407-3.11275833C10.58399735 28.36464547 9.70314242 27.60999703 8.634 27.35c-3.512-.852-3.512-5.848 0-6.7 1.0701184-.25928608 1.95204848-1.01406748 2.37349162-2.03130312.42144314-1.01723564.3317109-2.17457904-.24149162-3.11469688-1.88-3.086 1.652-6.62 4.74-4.74 1.992 1.216 4.592.14 5.144-2.13z'
          fill='none'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
        <ellipse
          cx='24'
          cy='24'
          fill='none'
          rx='6'
          ry='6'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
      </g>
    </svg>
  )
}
