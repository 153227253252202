import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from './operations'

const defaultOptions = {} as const

export const ActivateSavedFilterDocument = gql`
  mutation ActivateSavedFilter($id: ID!) {
    updateSavedFilter(input: { context: INCIDENT, id: $id, isActive: true }) {
      savedFilter {
        id
        isActive
      }
    }
  }
`
export type ActivateSavedFilterMutationFn = Apollo.MutationFunction<
  Types.ActivateSavedFilterMutation,
  Types.ActivateSavedFilterMutationVariables
>

/**
 * __useActivateSavedFilterMutation__
 *
 * To run a mutation, you first call `useActivateSavedFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateSavedFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateSavedFilterMutation, { data, loading, error }] = useActivateSavedFilterMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateSavedFilterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ActivateSavedFilterMutation,
    Types.ActivateSavedFilterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.ActivateSavedFilterMutation,
    Types.ActivateSavedFilterMutationVariables
  >(ActivateSavedFilterDocument, options)
}
export type ActivateSavedFilterMutationHookResult = ReturnType<
  typeof useActivateSavedFilterMutation
>
export type ActivateSavedFilterMutationResult =
  Apollo.MutationResult<Types.ActivateSavedFilterMutation>
export type ActivateSavedFilterMutationOptions = Apollo.BaseMutationOptions<
  Types.ActivateSavedFilterMutation,
  Types.ActivateSavedFilterMutationVariables
>
export const AssociateSingleIncidentToMultipleCasesDocument = gql`
  mutation AssociateSingleIncidentToMultipleCases(
    $input: AssociateIncidentToCasesInput!
  ) {
    associateSingleIncidentToMultipleCases(input: $input) {
      incident {
        id
      }
    }
  }
`
export type AssociateSingleIncidentToMultipleCasesMutationFn =
  Apollo.MutationFunction<
    Types.AssociateSingleIncidentToMultipleCasesMutation,
    Types.AssociateSingleIncidentToMultipleCasesMutationVariables
  >

/**
 * __useAssociateSingleIncidentToMultipleCasesMutation__
 *
 * To run a mutation, you first call `useAssociateSingleIncidentToMultipleCasesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssociateSingleIncidentToMultipleCasesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [associateSingleIncidentToMultipleCasesMutation, { data, loading, error }] = useAssociateSingleIncidentToMultipleCasesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssociateSingleIncidentToMultipleCasesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.AssociateSingleIncidentToMultipleCasesMutation,
    Types.AssociateSingleIncidentToMultipleCasesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.AssociateSingleIncidentToMultipleCasesMutation,
    Types.AssociateSingleIncidentToMultipleCasesMutationVariables
  >(AssociateSingleIncidentToMultipleCasesDocument, options)
}
export type AssociateSingleIncidentToMultipleCasesMutationHookResult =
  ReturnType<typeof useAssociateSingleIncidentToMultipleCasesMutation>
export type AssociateSingleIncidentToMultipleCasesMutationResult =
  Apollo.MutationResult<Types.AssociateSingleIncidentToMultipleCasesMutation>
export type AssociateSingleIncidentToMultipleCasesMutationOptions =
  Apollo.BaseMutationOptions<
    Types.AssociateSingleIncidentToMultipleCasesMutation,
    Types.AssociateSingleIncidentToMultipleCasesMutationVariables
  >
export const AssociateMultipleIncidentsToSingleCaseDocument = gql`
  mutation AssociateMultipleIncidentsToSingleCase(
    $input: AssociateIncidentsToCaseInput!
  ) {
    associateMultipleIncidentsToSingleCase(input: $input) {
      case {
        id
        name
      }
    }
  }
`
export type AssociateMultipleIncidentsToSingleCaseMutationFn =
  Apollo.MutationFunction<
    Types.AssociateMultipleIncidentsToSingleCaseMutation,
    Types.AssociateMultipleIncidentsToSingleCaseMutationVariables
  >

/**
 * __useAssociateMultipleIncidentsToSingleCaseMutation__
 *
 * To run a mutation, you first call `useAssociateMultipleIncidentsToSingleCaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssociateMultipleIncidentsToSingleCaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [associateMultipleIncidentsToSingleCaseMutation, { data, loading, error }] = useAssociateMultipleIncidentsToSingleCaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssociateMultipleIncidentsToSingleCaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.AssociateMultipleIncidentsToSingleCaseMutation,
    Types.AssociateMultipleIncidentsToSingleCaseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.AssociateMultipleIncidentsToSingleCaseMutation,
    Types.AssociateMultipleIncidentsToSingleCaseMutationVariables
  >(AssociateMultipleIncidentsToSingleCaseDocument, options)
}
export type AssociateMultipleIncidentsToSingleCaseMutationHookResult =
  ReturnType<typeof useAssociateMultipleIncidentsToSingleCaseMutation>
export type AssociateMultipleIncidentsToSingleCaseMutationResult =
  Apollo.MutationResult<Types.AssociateMultipleIncidentsToSingleCaseMutation>
export type AssociateMultipleIncidentsToSingleCaseMutationOptions =
  Apollo.BaseMutationOptions<
    Types.AssociateMultipleIncidentsToSingleCaseMutation,
    Types.AssociateMultipleIncidentsToSingleCaseMutationVariables
  >
export const CreateAudioClipDocument = gql`
  mutation CreateAudioClip($input: CreateAudioClipInput!) {
    createAudioClip(input: $input) {
      audioClip {
        id
        creator {
          id
          firstName
          lastName
          roleName
        }
        createdAt
        endTime
        incident {
          id
          name
        }
        incidentId
        startTime
        updatedAt
        url
      }
    }
  }
`
export type CreateAudioClipMutationFn = Apollo.MutationFunction<
  Types.CreateAudioClipMutation,
  Types.CreateAudioClipMutationVariables
>

/**
 * __useCreateAudioClipMutation__
 *
 * To run a mutation, you first call `useCreateAudioClipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAudioClipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAudioClipMutation, { data, loading, error }] = useCreateAudioClipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAudioClipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateAudioClipMutation,
    Types.CreateAudioClipMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateAudioClipMutation,
    Types.CreateAudioClipMutationVariables
  >(CreateAudioClipDocument, options)
}
export type CreateAudioClipMutationHookResult = ReturnType<
  typeof useCreateAudioClipMutation
>
export type CreateAudioClipMutationResult =
  Apollo.MutationResult<Types.CreateAudioClipMutation>
export type CreateAudioClipMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateAudioClipMutation,
  Types.CreateAudioClipMutationVariables
>
export const CreateAudioMessageDocument = gql`
  mutation CreateAudioMessage($input: CreateAudioMessageInput!) {
    createAudioMessage(input: $input) {
      audioMessage {
        id
        createdAt
        creator {
          id
          firstName
          lastName
          roleName
        }
        audioClip {
          id
          url
        }
      }
    }
  }
`
export type CreateAudioMessageMutationFn = Apollo.MutationFunction<
  Types.CreateAudioMessageMutation,
  Types.CreateAudioMessageMutationVariables
>

/**
 * __useCreateAudioMessageMutation__
 *
 * To run a mutation, you first call `useCreateAudioMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAudioMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAudioMessageMutation, { data, loading, error }] = useCreateAudioMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAudioMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateAudioMessageMutation,
    Types.CreateAudioMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateAudioMessageMutation,
    Types.CreateAudioMessageMutationVariables
  >(CreateAudioMessageDocument, options)
}
export type CreateAudioMessageMutationHookResult = ReturnType<
  typeof useCreateAudioMessageMutation
>
export type CreateAudioMessageMutationResult =
  Apollo.MutationResult<Types.CreateAudioMessageMutation>
export type CreateAudioMessageMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateAudioMessageMutation,
  Types.CreateAudioMessageMutationVariables
>
export const CreateCaseDocument = gql`
  mutation CreateCase($input: CreateCaseInput!) {
    createCase(input: $input) {
      case {
        createdAt
        createdBy
        description
        detailedReport
        displayId
        id
        name
        ownerId
        status
        updatedAt
        updatedBy
        facilities {
          address
          archived
          createdAt
          defaultFloorId
          guardCount
          id
          latitude
          longitude
          name
        }
      }
    }
  }
`
export type CreateCaseMutationFn = Apollo.MutationFunction<
  Types.CreateCaseMutation,
  Types.CreateCaseMutationVariables
>

/**
 * __useCreateCaseMutation__
 *
 * To run a mutation, you first call `useCreateCaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCaseMutation, { data, loading, error }] = useCreateCaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateCaseMutation,
    Types.CreateCaseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateCaseMutation,
    Types.CreateCaseMutationVariables
  >(CreateCaseDocument, options)
}
export type CreateCaseMutationHookResult = ReturnType<
  typeof useCreateCaseMutation
>
export type CreateCaseMutationResult =
  Apollo.MutationResult<Types.CreateCaseMutation>
export type CreateCaseMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateCaseMutation,
  Types.CreateCaseMutationVariables
>
export const CreateCaseCommentDocument = gql`
  mutation CreateCaseComment($input: CreateCaseCommentInput!) {
    createCaseComment(input: $input) {
      caseComment {
        caseId
        createdAt
        createdBy
        id
        message
        updatedAt
        updatedBy
      }
    }
  }
`
export type CreateCaseCommentMutationFn = Apollo.MutationFunction<
  Types.CreateCaseCommentMutation,
  Types.CreateCaseCommentMutationVariables
>

/**
 * __useCreateCaseCommentMutation__
 *
 * To run a mutation, you first call `useCreateCaseCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCaseCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCaseCommentMutation, { data, loading, error }] = useCreateCaseCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCaseCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateCaseCommentMutation,
    Types.CreateCaseCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateCaseCommentMutation,
    Types.CreateCaseCommentMutationVariables
  >(CreateCaseCommentDocument, options)
}
export type CreateCaseCommentMutationHookResult = ReturnType<
  typeof useCreateCaseCommentMutation
>
export type CreateCaseCommentMutationResult =
  Apollo.MutationResult<Types.CreateCaseCommentMutation>
export type CreateCaseCommentMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateCaseCommentMutation,
  Types.CreateCaseCommentMutationVariables
>
export const CreateCheckpointDocument = gql`
  mutation CreateCheckpoint($input: CreateDeviceInput!) {
    createCheckpoint(input: $input) {
      device {
        id
      }
    }
  }
`
export type CreateCheckpointMutationFn = Apollo.MutationFunction<
  Types.CreateCheckpointMutation,
  Types.CreateCheckpointMutationVariables
>

/**
 * __useCreateCheckpointMutation__
 *
 * To run a mutation, you first call `useCreateCheckpointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCheckpointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCheckpointMutation, { data, loading, error }] = useCreateCheckpointMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCheckpointMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateCheckpointMutation,
    Types.CreateCheckpointMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateCheckpointMutation,
    Types.CreateCheckpointMutationVariables
  >(CreateCheckpointDocument, options)
}
export type CreateCheckpointMutationHookResult = ReturnType<
  typeof useCreateCheckpointMutation
>
export type CreateCheckpointMutationResult =
  Apollo.MutationResult<Types.CreateCheckpointMutation>
export type CreateCheckpointMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateCheckpointMutation,
  Types.CreateCheckpointMutationVariables
>
export const CreateDatabaseResetDocument = gql`
  mutation CreateDatabaseReset {
    createDatabaseReset {
      createdAt
      createdBy
    }
  }
`
export type CreateDatabaseResetMutationFn = Apollo.MutationFunction<
  Types.CreateDatabaseResetMutation,
  Types.CreateDatabaseResetMutationVariables
>

/**
 * __useCreateDatabaseResetMutation__
 *
 * To run a mutation, you first call `useCreateDatabaseResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDatabaseResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDatabaseResetMutation, { data, loading, error }] = useCreateDatabaseResetMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateDatabaseResetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateDatabaseResetMutation,
    Types.CreateDatabaseResetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateDatabaseResetMutation,
    Types.CreateDatabaseResetMutationVariables
  >(CreateDatabaseResetDocument, options)
}
export type CreateDatabaseResetMutationHookResult = ReturnType<
  typeof useCreateDatabaseResetMutation
>
export type CreateDatabaseResetMutationResult =
  Apollo.MutationResult<Types.CreateDatabaseResetMutation>
export type CreateDatabaseResetMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateDatabaseResetMutation,
  Types.CreateDatabaseResetMutationVariables
>
export const CreateDevicesDocument = gql`
  mutation CreateDevices($input: CreateDevicesInput!) {
    createDevices(input: $input) {
      device {
        id
      }
    }
  }
`
export type CreateDevicesMutationFn = Apollo.MutationFunction<
  Types.CreateDevicesMutation,
  Types.CreateDevicesMutationVariables
>

/**
 * __useCreateDevicesMutation__
 *
 * To run a mutation, you first call `useCreateDevicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDevicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDevicesMutation, { data, loading, error }] = useCreateDevicesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDevicesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateDevicesMutation,
    Types.CreateDevicesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateDevicesMutation,
    Types.CreateDevicesMutationVariables
  >(CreateDevicesDocument, options)
}
export type CreateDevicesMutationHookResult = ReturnType<
  typeof useCreateDevicesMutation
>
export type CreateDevicesMutationResult =
  Apollo.MutationResult<Types.CreateDevicesMutation>
export type CreateDevicesMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateDevicesMutation,
  Types.CreateDevicesMutationVariables
>
export const CreateEdgeDeviceDocument = gql`
  mutation CreateEdgeDevice($input: CreateEdgeDeviceInput!) {
    createEdgeDevice(input: $input) {
      edgeDevice {
        id
        name
      }
    }
  }
`
export type CreateEdgeDeviceMutationFn = Apollo.MutationFunction<
  Types.CreateEdgeDeviceMutation,
  Types.CreateEdgeDeviceMutationVariables
>

/**
 * __useCreateEdgeDeviceMutation__
 *
 * To run a mutation, you first call `useCreateEdgeDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEdgeDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEdgeDeviceMutation, { data, loading, error }] = useCreateEdgeDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEdgeDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateEdgeDeviceMutation,
    Types.CreateEdgeDeviceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateEdgeDeviceMutation,
    Types.CreateEdgeDeviceMutationVariables
  >(CreateEdgeDeviceDocument, options)
}
export type CreateEdgeDeviceMutationHookResult = ReturnType<
  typeof useCreateEdgeDeviceMutation
>
export type CreateEdgeDeviceMutationResult =
  Apollo.MutationResult<Types.CreateEdgeDeviceMutation>
export type CreateEdgeDeviceMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateEdgeDeviceMutation,
  Types.CreateEdgeDeviceMutationVariables
>
export const CreateEmergencyCallDocument = gql`
  mutation CreateEmergencyCall($input: CreateEmergencyCallInput!) {
    createEmergencyCall(input: $input) {
      emergencyCall {
        id
      }
    }
  }
`
export type CreateEmergencyCallMutationFn = Apollo.MutationFunction<
  Types.CreateEmergencyCallMutation,
  Types.CreateEmergencyCallMutationVariables
>

/**
 * __useCreateEmergencyCallMutation__
 *
 * To run a mutation, you first call `useCreateEmergencyCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmergencyCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmergencyCallMutation, { data, loading, error }] = useCreateEmergencyCallMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEmergencyCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateEmergencyCallMutation,
    Types.CreateEmergencyCallMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateEmergencyCallMutation,
    Types.CreateEmergencyCallMutationVariables
  >(CreateEmergencyCallDocument, options)
}
export type CreateEmergencyCallMutationHookResult = ReturnType<
  typeof useCreateEmergencyCallMutation
>
export type CreateEmergencyCallMutationResult =
  Apollo.MutationResult<Types.CreateEmergencyCallMutation>
export type CreateEmergencyCallMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateEmergencyCallMutation,
  Types.CreateEmergencyCallMutationVariables
>
export const CreateExternalAccountDocument = gql`
  mutation CreateExternalAccount($input: CreateExternalAccountInput!) {
    createExternalAccount(input: $input) {
      externalAccount {
        accountId
        accountName
        defaultAccount
        externalSystemType
        id
      }
    }
  }
`
export type CreateExternalAccountMutationFn = Apollo.MutationFunction<
  Types.CreateExternalAccountMutation,
  Types.CreateExternalAccountMutationVariables
>

/**
 * __useCreateExternalAccountMutation__
 *
 * To run a mutation, you first call `useCreateExternalAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExternalAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExternalAccountMutation, { data, loading, error }] = useCreateExternalAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExternalAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateExternalAccountMutation,
    Types.CreateExternalAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateExternalAccountMutation,
    Types.CreateExternalAccountMutationVariables
  >(CreateExternalAccountDocument, options)
}
export type CreateExternalAccountMutationHookResult = ReturnType<
  typeof useCreateExternalAccountMutation
>
export type CreateExternalAccountMutationResult =
  Apollo.MutationResult<Types.CreateExternalAccountMutation>
export type CreateExternalAccountMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateExternalAccountMutation,
  Types.CreateExternalAccountMutationVariables
>
export const CreateExternalAccountApiKeyDocument = gql`
  mutation CreateExternalAccountApiKey(
    $input: CreateExternalAccountApiKeyInput!
  ) {
    createExternalAccountApiKey(input: $input) {
      externalAccountApiKey {
        id
        secretName
      }
    }
  }
`
export type CreateExternalAccountApiKeyMutationFn = Apollo.MutationFunction<
  Types.CreateExternalAccountApiKeyMutation,
  Types.CreateExternalAccountApiKeyMutationVariables
>

/**
 * __useCreateExternalAccountApiKeyMutation__
 *
 * To run a mutation, you first call `useCreateExternalAccountApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExternalAccountApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExternalAccountApiKeyMutation, { data, loading, error }] = useCreateExternalAccountApiKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExternalAccountApiKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateExternalAccountApiKeyMutation,
    Types.CreateExternalAccountApiKeyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateExternalAccountApiKeyMutation,
    Types.CreateExternalAccountApiKeyMutationVariables
  >(CreateExternalAccountApiKeyDocument, options)
}
export type CreateExternalAccountApiKeyMutationHookResult = ReturnType<
  typeof useCreateExternalAccountApiKeyMutation
>
export type CreateExternalAccountApiKeyMutationResult =
  Apollo.MutationResult<Types.CreateExternalAccountApiKeyMutation>
export type CreateExternalAccountApiKeyMutationOptions =
  Apollo.BaseMutationOptions<
    Types.CreateExternalAccountApiKeyMutation,
    Types.CreateExternalAccountApiKeyMutationVariables
  >
export const CreateFacilityDocument = gql`
  mutation CreateFacility($input: CreateFacilityInput!) {
    createFacility(input: $input) {
      facility {
        id
      }
    }
  }
`
export type CreateFacilityMutationFn = Apollo.MutationFunction<
  Types.CreateFacilityMutation,
  Types.CreateFacilityMutationVariables
>

/**
 * __useCreateFacilityMutation__
 *
 * To run a mutation, you first call `useCreateFacilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFacilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFacilityMutation, { data, loading, error }] = useCreateFacilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFacilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateFacilityMutation,
    Types.CreateFacilityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateFacilityMutation,
    Types.CreateFacilityMutationVariables
  >(CreateFacilityDocument, options)
}
export type CreateFacilityMutationHookResult = ReturnType<
  typeof useCreateFacilityMutation
>
export type CreateFacilityMutationResult =
  Apollo.MutationResult<Types.CreateFacilityMutation>
export type CreateFacilityMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateFacilityMutation,
  Types.CreateFacilityMutationVariables
>
export const CreateFloorDocument = gql`
  mutation CreateFloor($input: CreateFloorInput!) {
    createFloor(input: $input) {
      floor {
        id
      }
    }
  }
`
export type CreateFloorMutationFn = Apollo.MutationFunction<
  Types.CreateFloorMutation,
  Types.CreateFloorMutationVariables
>

/**
 * __useCreateFloorMutation__
 *
 * To run a mutation, you first call `useCreateFloorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFloorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFloorMutation, { data, loading, error }] = useCreateFloorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFloorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateFloorMutation,
    Types.CreateFloorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateFloorMutation,
    Types.CreateFloorMutationVariables
  >(CreateFloorDocument, options)
}
export type CreateFloorMutationHookResult = ReturnType<
  typeof useCreateFloorMutation
>
export type CreateFloorMutationResult =
  Apollo.MutationResult<Types.CreateFloorMutation>
export type CreateFloorMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateFloorMutation,
  Types.CreateFloorMutationVariables
>
export const CreateFloorPlanDocument = gql`
  mutation CreateFloorPlan($input: CreateFloorPlanInput!) {
    createFloorPlan(input: $input) {
      floorPlan {
        id
      }
    }
  }
`
export type CreateFloorPlanMutationFn = Apollo.MutationFunction<
  Types.CreateFloorPlanMutation,
  Types.CreateFloorPlanMutationVariables
>

/**
 * __useCreateFloorPlanMutation__
 *
 * To run a mutation, you first call `useCreateFloorPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFloorPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFloorPlanMutation, { data, loading, error }] = useCreateFloorPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFloorPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateFloorPlanMutation,
    Types.CreateFloorPlanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateFloorPlanMutation,
    Types.CreateFloorPlanMutationVariables
  >(CreateFloorPlanDocument, options)
}
export type CreateFloorPlanMutationHookResult = ReturnType<
  typeof useCreateFloorPlanMutation
>
export type CreateFloorPlanMutationResult =
  Apollo.MutationResult<Types.CreateFloorPlanMutation>
export type CreateFloorPlanMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateFloorPlanMutation,
  Types.CreateFloorPlanMutationVariables
>
export const CreateIncidentDocument = gql`
  mutation CreateIncident($input: CreateIncidentInput!) {
    createIncident(input: $input) {
      incident {
        id
      }
    }
  }
`
export type CreateIncidentMutationFn = Apollo.MutationFunction<
  Types.CreateIncidentMutation,
  Types.CreateIncidentMutationVariables
>

/**
 * __useCreateIncidentMutation__
 *
 * To run a mutation, you first call `useCreateIncidentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIncidentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIncidentMutation, { data, loading, error }] = useCreateIncidentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIncidentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateIncidentMutation,
    Types.CreateIncidentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateIncidentMutation,
    Types.CreateIncidentMutationVariables
  >(CreateIncidentDocument, options)
}
export type CreateIncidentMutationHookResult = ReturnType<
  typeof useCreateIncidentMutation
>
export type CreateIncidentMutationResult =
  Apollo.MutationResult<Types.CreateIncidentMutation>
export type CreateIncidentMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateIncidentMutation,
  Types.CreateIncidentMutationVariables
>
export const CreateIncidentActionDocument = gql`
  mutation CreateIncidentAction($input: CreateIncidentActionInput!) {
    createIncidentAction(input: $input) {
      incidentAction {
        id
        audioMessageId
      }
    }
  }
`
export type CreateIncidentActionMutationFn = Apollo.MutationFunction<
  Types.CreateIncidentActionMutation,
  Types.CreateIncidentActionMutationVariables
>

/**
 * __useCreateIncidentActionMutation__
 *
 * To run a mutation, you first call `useCreateIncidentActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIncidentActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIncidentActionMutation, { data, loading, error }] = useCreateIncidentActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIncidentActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateIncidentActionMutation,
    Types.CreateIncidentActionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateIncidentActionMutation,
    Types.CreateIncidentActionMutationVariables
  >(CreateIncidentActionDocument, options)
}
export type CreateIncidentActionMutationHookResult = ReturnType<
  typeof useCreateIncidentActionMutation
>
export type CreateIncidentActionMutationResult =
  Apollo.MutationResult<Types.CreateIncidentActionMutation>
export type CreateIncidentActionMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateIncidentActionMutation,
  Types.CreateIncidentActionMutationVariables
>
export const CreateIncidentNoteDocument = gql`
  mutation CreateIncidentNote($input: CreateIncidentNoteInput!) {
    createIncidentNote(input: $input) {
      incidentNote {
        id
        message
      }
    }
  }
`
export type CreateIncidentNoteMutationFn = Apollo.MutationFunction<
  Types.CreateIncidentNoteMutation,
  Types.CreateIncidentNoteMutationVariables
>

/**
 * __useCreateIncidentNoteMutation__
 *
 * To run a mutation, you first call `useCreateIncidentNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIncidentNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIncidentNoteMutation, { data, loading, error }] = useCreateIncidentNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIncidentNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateIncidentNoteMutation,
    Types.CreateIncidentNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateIncidentNoteMutation,
    Types.CreateIncidentNoteMutationVariables
  >(CreateIncidentNoteDocument, options)
}
export type CreateIncidentNoteMutationHookResult = ReturnType<
  typeof useCreateIncidentNoteMutation
>
export type CreateIncidentNoteMutationResult =
  Apollo.MutationResult<Types.CreateIncidentNoteMutation>
export type CreateIncidentNoteMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateIncidentNoteMutation,
  Types.CreateIncidentNoteMutationVariables
>
export const CreateIncidentNoteMediaDocument = gql`
  mutation CreateIncidentNoteMedia($input: CreateIncidentNoteMediaInput!) {
    createIncidentNoteMedia(input: $input) {
      incidentNoteId
      media {
        id
        url
        name
        mimeType
      }
    }
  }
`
export type CreateIncidentNoteMediaMutationFn = Apollo.MutationFunction<
  Types.CreateIncidentNoteMediaMutation,
  Types.CreateIncidentNoteMediaMutationVariables
>

/**
 * __useCreateIncidentNoteMediaMutation__
 *
 * To run a mutation, you first call `useCreateIncidentNoteMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIncidentNoteMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIncidentNoteMediaMutation, { data, loading, error }] = useCreateIncidentNoteMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIncidentNoteMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateIncidentNoteMediaMutation,
    Types.CreateIncidentNoteMediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateIncidentNoteMediaMutation,
    Types.CreateIncidentNoteMediaMutationVariables
  >(CreateIncidentNoteMediaDocument, options)
}
export type CreateIncidentNoteMediaMutationHookResult = ReturnType<
  typeof useCreateIncidentNoteMediaMutation
>
export type CreateIncidentNoteMediaMutationResult =
  Apollo.MutationResult<Types.CreateIncidentNoteMediaMutation>
export type CreateIncidentNoteMediaMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateIncidentNoteMediaMutation,
  Types.CreateIncidentNoteMediaMutationVariables
>
export const CreateLlmIncidentsDocument = gql`
  mutation CreateLlmIncidents {
    createLlmIncidents(input: {}) {
      incidents {
        id
      }
    }
  }
`
export type CreateLlmIncidentsMutationFn = Apollo.MutationFunction<
  Types.CreateLlmIncidentsMutation,
  Types.CreateLlmIncidentsMutationVariables
>

/**
 * __useCreateLlmIncidentsMutation__
 *
 * To run a mutation, you first call `useCreateLlmIncidentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLlmIncidentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLlmIncidentsMutation, { data, loading, error }] = useCreateLlmIncidentsMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateLlmIncidentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateLlmIncidentsMutation,
    Types.CreateLlmIncidentsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateLlmIncidentsMutation,
    Types.CreateLlmIncidentsMutationVariables
  >(CreateLlmIncidentsDocument, options)
}
export type CreateLlmIncidentsMutationHookResult = ReturnType<
  typeof useCreateLlmIncidentsMutation
>
export type CreateLlmIncidentsMutationResult =
  Apollo.MutationResult<Types.CreateLlmIncidentsMutation>
export type CreateLlmIncidentsMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateLlmIncidentsMutation,
  Types.CreateLlmIncidentsMutationVariables
>
export const CreateManualIncidentCategoryDocument = gql`
  mutation CreateManualIncidentCategory(
    $input: CreateManualIncidentCategoryInput!
  ) {
    createManualIncidentCategory(input: $input) {
      manualIncidentCategory {
        id
        name
      }
    }
  }
`
export type CreateManualIncidentCategoryMutationFn = Apollo.MutationFunction<
  Types.CreateManualIncidentCategoryMutation,
  Types.CreateManualIncidentCategoryMutationVariables
>

/**
 * __useCreateManualIncidentCategoryMutation__
 *
 * To run a mutation, you first call `useCreateManualIncidentCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManualIncidentCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManualIncidentCategoryMutation, { data, loading, error }] = useCreateManualIncidentCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateManualIncidentCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateManualIncidentCategoryMutation,
    Types.CreateManualIncidentCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateManualIncidentCategoryMutation,
    Types.CreateManualIncidentCategoryMutationVariables
  >(CreateManualIncidentCategoryDocument, options)
}
export type CreateManualIncidentCategoryMutationHookResult = ReturnType<
  typeof useCreateManualIncidentCategoryMutation
>
export type CreateManualIncidentCategoryMutationResult =
  Apollo.MutationResult<Types.CreateManualIncidentCategoryMutation>
export type CreateManualIncidentCategoryMutationOptions =
  Apollo.BaseMutationOptions<
    Types.CreateManualIncidentCategoryMutation,
    Types.CreateManualIncidentCategoryMutationVariables
  >
export const CreatePacsSourceInstanceDocument = gql`
  mutation createPacsSourceInstance($input: CreatePacsSourceInstanceInput!) {
    createPacsSourceInstance(input: $input) {
      pacsSourceInstance {
        id
      }
    }
  }
`
export type CreatePacsSourceInstanceMutationFn = Apollo.MutationFunction<
  Types.CreatePacsSourceInstanceMutation,
  Types.CreatePacsSourceInstanceMutationVariables
>

/**
 * __useCreatePacsSourceInstanceMutation__
 *
 * To run a mutation, you first call `useCreatePacsSourceInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePacsSourceInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPacsSourceInstanceMutation, { data, loading, error }] = useCreatePacsSourceInstanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePacsSourceInstanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreatePacsSourceInstanceMutation,
    Types.CreatePacsSourceInstanceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreatePacsSourceInstanceMutation,
    Types.CreatePacsSourceInstanceMutationVariables
  >(CreatePacsSourceInstanceDocument, options)
}
export type CreatePacsSourceInstanceMutationHookResult = ReturnType<
  typeof useCreatePacsSourceInstanceMutation
>
export type CreatePacsSourceInstanceMutationResult =
  Apollo.MutationResult<Types.CreatePacsSourceInstanceMutation>
export type CreatePacsSourceInstanceMutationOptions =
  Apollo.BaseMutationOptions<
    Types.CreatePacsSourceInstanceMutation,
    Types.CreatePacsSourceInstanceMutationVariables
  >
export const CreateRuleDocument = gql`
  mutation CreateRule($input: CreateRuleInput!) {
    createRule(input: $input) {
      rule {
        id
      }
    }
  }
`
export type CreateRuleMutationFn = Apollo.MutationFunction<
  Types.CreateRuleMutation,
  Types.CreateRuleMutationVariables
>

/**
 * __useCreateRuleMutation__
 *
 * To run a mutation, you first call `useCreateRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRuleMutation, { data, loading, error }] = useCreateRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateRuleMutation,
    Types.CreateRuleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateRuleMutation,
    Types.CreateRuleMutationVariables
  >(CreateRuleDocument, options)
}
export type CreateRuleMutationHookResult = ReturnType<
  typeof useCreateRuleMutation
>
export type CreateRuleMutationResult =
  Apollo.MutationResult<Types.CreateRuleMutation>
export type CreateRuleMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateRuleMutation,
  Types.CreateRuleMutationVariables
>
export const CreateSavedFilterDocument = gql`
  mutation CreateSavedFilter($input: CreateSavedFilterInput!) {
    createSavedFilter(input: $input) {
      savedFilter {
        id
      }
    }
  }
`
export type CreateSavedFilterMutationFn = Apollo.MutationFunction<
  Types.CreateSavedFilterMutation,
  Types.CreateSavedFilterMutationVariables
>

/**
 * __useCreateSavedFilterMutation__
 *
 * To run a mutation, you first call `useCreateSavedFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSavedFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSavedFilterMutation, { data, loading, error }] = useCreateSavedFilterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSavedFilterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateSavedFilterMutation,
    Types.CreateSavedFilterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateSavedFilterMutation,
    Types.CreateSavedFilterMutationVariables
  >(CreateSavedFilterDocument, options)
}
export type CreateSavedFilterMutationHookResult = ReturnType<
  typeof useCreateSavedFilterMutation
>
export type CreateSavedFilterMutationResult =
  Apollo.MutationResult<Types.CreateSavedFilterMutation>
export type CreateSavedFilterMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateSavedFilterMutation,
  Types.CreateSavedFilterMutationVariables
>
export const CreateStandardOperatingProcedureDocument = gql`
  mutation CreateStandardOperatingProcedure(
    $input: CreateStandardOperatingProcedureInput!
  ) {
    createStandardOperatingProcedure(input: $input) {
      standardOperatingProcedure {
        id
        name
        externalId
        url
      }
    }
  }
`
export type CreateStandardOperatingProcedureMutationFn =
  Apollo.MutationFunction<
    Types.CreateStandardOperatingProcedureMutation,
    Types.CreateStandardOperatingProcedureMutationVariables
  >

/**
 * __useCreateStandardOperatingProcedureMutation__
 *
 * To run a mutation, you first call `useCreateStandardOperatingProcedureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStandardOperatingProcedureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStandardOperatingProcedureMutation, { data, loading, error }] = useCreateStandardOperatingProcedureMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStandardOperatingProcedureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateStandardOperatingProcedureMutation,
    Types.CreateStandardOperatingProcedureMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateStandardOperatingProcedureMutation,
    Types.CreateStandardOperatingProcedureMutationVariables
  >(CreateStandardOperatingProcedureDocument, options)
}
export type CreateStandardOperatingProcedureMutationHookResult = ReturnType<
  typeof useCreateStandardOperatingProcedureMutation
>
export type CreateStandardOperatingProcedureMutationResult =
  Apollo.MutationResult<Types.CreateStandardOperatingProcedureMutation>
export type CreateStandardOperatingProcedureMutationOptions =
  Apollo.BaseMutationOptions<
    Types.CreateStandardOperatingProcedureMutation,
    Types.CreateStandardOperatingProcedureMutationVariables
  >
export const CreateTailgateConnDocument = gql`
  mutation CreateTailgateConn($input: CreateTailgateConnInput!) {
    createTailgateConn(input: $input) {
      tailgateConn {
        id
        cameras {
          edges {
            node {
              id
            }
          }
        }
        door {
          id
        }
      }
    }
  }
`
export type CreateTailgateConnMutationFn = Apollo.MutationFunction<
  Types.CreateTailgateConnMutation,
  Types.CreateTailgateConnMutationVariables
>

/**
 * __useCreateTailgateConnMutation__
 *
 * To run a mutation, you first call `useCreateTailgateConnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTailgateConnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTailgateConnMutation, { data, loading, error }] = useCreateTailgateConnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTailgateConnMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateTailgateConnMutation,
    Types.CreateTailgateConnMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateTailgateConnMutation,
    Types.CreateTailgateConnMutationVariables
  >(CreateTailgateConnDocument, options)
}
export type CreateTailgateConnMutationHookResult = ReturnType<
  typeof useCreateTailgateConnMutation
>
export type CreateTailgateConnMutationResult =
  Apollo.MutationResult<Types.CreateTailgateConnMutation>
export type CreateTailgateConnMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateTailgateConnMutation,
  Types.CreateTailgateConnMutationVariables
>
export const CreateTechnicianDocument = gql`
  mutation CreateTechnician($input: CreateTechnicianInput!) {
    createTechnician(input: $input) {
      technician {
        id
        email
        firstName
        lastName
        facilities {
          name
        }
        deviceTypes
      }
    }
  }
`
export type CreateTechnicianMutationFn = Apollo.MutationFunction<
  Types.CreateTechnicianMutation,
  Types.CreateTechnicianMutationVariables
>

/**
 * __useCreateTechnicianMutation__
 *
 * To run a mutation, you first call `useCreateTechnicianMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTechnicianMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTechnicianMutation, { data, loading, error }] = useCreateTechnicianMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTechnicianMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateTechnicianMutation,
    Types.CreateTechnicianMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateTechnicianMutation,
    Types.CreateTechnicianMutationVariables
  >(CreateTechnicianDocument, options)
}
export type CreateTechnicianMutationHookResult = ReturnType<
  typeof useCreateTechnicianMutation
>
export type CreateTechnicianMutationResult =
  Apollo.MutationResult<Types.CreateTechnicianMutation>
export type CreateTechnicianMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateTechnicianMutation,
  Types.CreateTechnicianMutationVariables
>
export const CreateTourPlanDocument = gql`
  mutation CreateTourPlan($input: CreateTourPlanInput!) {
    createTourPlan(input: $input) {
      tourPlan {
        id
      }
    }
  }
`
export type CreateTourPlanMutationFn = Apollo.MutationFunction<
  Types.CreateTourPlanMutation,
  Types.CreateTourPlanMutationVariables
>

/**
 * __useCreateTourPlanMutation__
 *
 * To run a mutation, you first call `useCreateTourPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTourPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTourPlanMutation, { data, loading, error }] = useCreateTourPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTourPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateTourPlanMutation,
    Types.CreateTourPlanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateTourPlanMutation,
    Types.CreateTourPlanMutationVariables
  >(CreateTourPlanDocument, options)
}
export type CreateTourPlanMutationHookResult = ReturnType<
  typeof useCreateTourPlanMutation
>
export type CreateTourPlanMutationResult =
  Apollo.MutationResult<Types.CreateTourPlanMutation>
export type CreateTourPlanMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateTourPlanMutation,
  Types.CreateTourPlanMutationVariables
>
export const CreateUserDocument = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        designatedFacilityId
        email
        firstName
        lastName
        roleName
      }
    }
  }
`
export type CreateUserMutationFn = Apollo.MutationFunction<
  Types.CreateUserMutation,
  Types.CreateUserMutationVariables
>

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateUserMutation,
    Types.CreateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateUserMutation,
    Types.CreateUserMutationVariables
  >(CreateUserDocument, options)
}
export type CreateUserMutationHookResult = ReturnType<
  typeof useCreateUserMutation
>
export type CreateUserMutationResult =
  Apollo.MutationResult<Types.CreateUserMutation>
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateUserMutation,
  Types.CreateUserMutationVariables
>
export const DeactivateSavedFilterDocument = gql`
  mutation DeactivateSavedFilter($id: ID!) {
    updateSavedFilter(input: { context: INCIDENT, id: $id, isActive: false }) {
      savedFilter {
        id
        isActive
      }
    }
  }
`
export type DeactivateSavedFilterMutationFn = Apollo.MutationFunction<
  Types.DeactivateSavedFilterMutation,
  Types.DeactivateSavedFilterMutationVariables
>

/**
 * __useDeactivateSavedFilterMutation__
 *
 * To run a mutation, you first call `useDeactivateSavedFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateSavedFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateSavedFilterMutation, { data, loading, error }] = useDeactivateSavedFilterMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateSavedFilterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeactivateSavedFilterMutation,
    Types.DeactivateSavedFilterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.DeactivateSavedFilterMutation,
    Types.DeactivateSavedFilterMutationVariables
  >(DeactivateSavedFilterDocument, options)
}
export type DeactivateSavedFilterMutationHookResult = ReturnType<
  typeof useDeactivateSavedFilterMutation
>
export type DeactivateSavedFilterMutationResult =
  Apollo.MutationResult<Types.DeactivateSavedFilterMutation>
export type DeactivateSavedFilterMutationOptions = Apollo.BaseMutationOptions<
  Types.DeactivateSavedFilterMutation,
  Types.DeactivateSavedFilterMutationVariables
>
export const DeleteDeviceDocument = gql`
  mutation DeleteDevice($input: DeleteDeviceInput!) {
    deleteDevice(input: $input) {
      id
    }
  }
`
export type DeleteDeviceMutationFn = Apollo.MutationFunction<
  Types.DeleteDeviceMutation,
  Types.DeleteDeviceMutationVariables
>

/**
 * __useDeleteDeviceMutation__
 *
 * To run a mutation, you first call `useDeleteDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeviceMutation, { data, loading, error }] = useDeleteDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteDeviceMutation,
    Types.DeleteDeviceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.DeleteDeviceMutation,
    Types.DeleteDeviceMutationVariables
  >(DeleteDeviceDocument, options)
}
export type DeleteDeviceMutationHookResult = ReturnType<
  typeof useDeleteDeviceMutation
>
export type DeleteDeviceMutationResult =
  Apollo.MutationResult<Types.DeleteDeviceMutation>
export type DeleteDeviceMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteDeviceMutation,
  Types.DeleteDeviceMutationVariables
>
export const DeleteFacilityDocument = gql`
  mutation DeleteFacility($input: DeleteFacilityInput!) {
    deleteFacility(input: $input) {
      id
    }
  }
`
export type DeleteFacilityMutationFn = Apollo.MutationFunction<
  Types.DeleteFacilityMutation,
  Types.DeleteFacilityMutationVariables
>

/**
 * __useDeleteFacilityMutation__
 *
 * To run a mutation, you first call `useDeleteFacilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFacilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFacilityMutation, { data, loading, error }] = useDeleteFacilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFacilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteFacilityMutation,
    Types.DeleteFacilityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.DeleteFacilityMutation,
    Types.DeleteFacilityMutationVariables
  >(DeleteFacilityDocument, options)
}
export type DeleteFacilityMutationHookResult = ReturnType<
  typeof useDeleteFacilityMutation
>
export type DeleteFacilityMutationResult =
  Apollo.MutationResult<Types.DeleteFacilityMutation>
export type DeleteFacilityMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteFacilityMutation,
  Types.DeleteFacilityMutationVariables
>
export const DeleteFloorPlanDocument = gql`
  mutation DeleteFloorPlan($input: DeleteFloorPlanInput!) {
    deleteFloorPlan(input: $input) {
      id
    }
  }
`
export type DeleteFloorPlanMutationFn = Apollo.MutationFunction<
  Types.DeleteFloorPlanMutation,
  Types.DeleteFloorPlanMutationVariables
>

/**
 * __useDeleteFloorPlanMutation__
 *
 * To run a mutation, you first call `useDeleteFloorPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFloorPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFloorPlanMutation, { data, loading, error }] = useDeleteFloorPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFloorPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteFloorPlanMutation,
    Types.DeleteFloorPlanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.DeleteFloorPlanMutation,
    Types.DeleteFloorPlanMutationVariables
  >(DeleteFloorPlanDocument, options)
}
export type DeleteFloorPlanMutationHookResult = ReturnType<
  typeof useDeleteFloorPlanMutation
>
export type DeleteFloorPlanMutationResult =
  Apollo.MutationResult<Types.DeleteFloorPlanMutation>
export type DeleteFloorPlanMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteFloorPlanMutation,
  Types.DeleteFloorPlanMutationVariables
>
export const DeleteManualIncidentCategoryDocument = gql`
  mutation deleteManualIncidentCategory(
    $input: DeleteManualIncidentCategoryInput!
  ) {
    deleteManualIncidentCategory(input: $input) {
      id
    }
  }
`
export type DeleteManualIncidentCategoryMutationFn = Apollo.MutationFunction<
  Types.DeleteManualIncidentCategoryMutation,
  Types.DeleteManualIncidentCategoryMutationVariables
>

/**
 * __useDeleteManualIncidentCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteManualIncidentCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManualIncidentCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManualIncidentCategoryMutation, { data, loading, error }] = useDeleteManualIncidentCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteManualIncidentCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteManualIncidentCategoryMutation,
    Types.DeleteManualIncidentCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.DeleteManualIncidentCategoryMutation,
    Types.DeleteManualIncidentCategoryMutationVariables
  >(DeleteManualIncidentCategoryDocument, options)
}
export type DeleteManualIncidentCategoryMutationHookResult = ReturnType<
  typeof useDeleteManualIncidentCategoryMutation
>
export type DeleteManualIncidentCategoryMutationResult =
  Apollo.MutationResult<Types.DeleteManualIncidentCategoryMutation>
export type DeleteManualIncidentCategoryMutationOptions =
  Apollo.BaseMutationOptions<
    Types.DeleteManualIncidentCategoryMutation,
    Types.DeleteManualIncidentCategoryMutationVariables
  >
export const DeleteSavedFilterDocument = gql`
  mutation DeleteSavedFilter($input: DeleteSavedFilterInput!) {
    deleteSavedFilter(input: $input) {
      id
    }
  }
`
export type DeleteSavedFilterMutationFn = Apollo.MutationFunction<
  Types.DeleteSavedFilterMutation,
  Types.DeleteSavedFilterMutationVariables
>

/**
 * __useDeleteSavedFilterMutation__
 *
 * To run a mutation, you first call `useDeleteSavedFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSavedFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSavedFilterMutation, { data, loading, error }] = useDeleteSavedFilterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSavedFilterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteSavedFilterMutation,
    Types.DeleteSavedFilterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.DeleteSavedFilterMutation,
    Types.DeleteSavedFilterMutationVariables
  >(DeleteSavedFilterDocument, options)
}
export type DeleteSavedFilterMutationHookResult = ReturnType<
  typeof useDeleteSavedFilterMutation
>
export type DeleteSavedFilterMutationResult =
  Apollo.MutationResult<Types.DeleteSavedFilterMutation>
export type DeleteSavedFilterMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteSavedFilterMutation,
  Types.DeleteSavedFilterMutationVariables
>
export const DeleteStandardOperatingProcedureDocument = gql`
  mutation DeleteStandardOperatingProcedure(
    $input: DeleteStandardOperatingProcedureInput!
  ) {
    deleteStandardOperatingProcedure(input: $input) {
      id
    }
  }
`
export type DeleteStandardOperatingProcedureMutationFn =
  Apollo.MutationFunction<
    Types.DeleteStandardOperatingProcedureMutation,
    Types.DeleteStandardOperatingProcedureMutationVariables
  >

/**
 * __useDeleteStandardOperatingProcedureMutation__
 *
 * To run a mutation, you first call `useDeleteStandardOperatingProcedureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStandardOperatingProcedureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStandardOperatingProcedureMutation, { data, loading, error }] = useDeleteStandardOperatingProcedureMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteStandardOperatingProcedureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteStandardOperatingProcedureMutation,
    Types.DeleteStandardOperatingProcedureMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.DeleteStandardOperatingProcedureMutation,
    Types.DeleteStandardOperatingProcedureMutationVariables
  >(DeleteStandardOperatingProcedureDocument, options)
}
export type DeleteStandardOperatingProcedureMutationHookResult = ReturnType<
  typeof useDeleteStandardOperatingProcedureMutation
>
export type DeleteStandardOperatingProcedureMutationResult =
  Apollo.MutationResult<Types.DeleteStandardOperatingProcedureMutation>
export type DeleteStandardOperatingProcedureMutationOptions =
  Apollo.BaseMutationOptions<
    Types.DeleteStandardOperatingProcedureMutation,
    Types.DeleteStandardOperatingProcedureMutationVariables
  >
export const DeleteTailgateGridDocument = gql`
  mutation DeleteTailgateGrid($input: DeleteTailgateGridInput!) {
    deleteTailgateGrid(input: $input) {
      camera {
        id
      }
      door {
        id
      }
    }
  }
`
export type DeleteTailgateGridMutationFn = Apollo.MutationFunction<
  Types.DeleteTailgateGridMutation,
  Types.DeleteTailgateGridMutationVariables
>

/**
 * __useDeleteTailgateGridMutation__
 *
 * To run a mutation, you first call `useDeleteTailgateGridMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTailgateGridMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTailgateGridMutation, { data, loading, error }] = useDeleteTailgateGridMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTailgateGridMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteTailgateGridMutation,
    Types.DeleteTailgateGridMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.DeleteTailgateGridMutation,
    Types.DeleteTailgateGridMutationVariables
  >(DeleteTailgateGridDocument, options)
}
export type DeleteTailgateGridMutationHookResult = ReturnType<
  typeof useDeleteTailgateGridMutation
>
export type DeleteTailgateGridMutationResult =
  Apollo.MutationResult<Types.DeleteTailgateGridMutation>
export type DeleteTailgateGridMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteTailgateGridMutation,
  Types.DeleteTailgateGridMutationVariables
>
export const DeleteTechnicianDocument = gql`
  mutation DeleteTechnician($input: DeleteTechnicianInput!) {
    deleteTechnician(input: $input) {
      id
    }
  }
`
export type DeleteTechnicianMutationFn = Apollo.MutationFunction<
  Types.DeleteTechnicianMutation,
  Types.DeleteTechnicianMutationVariables
>

/**
 * __useDeleteTechnicianMutation__
 *
 * To run a mutation, you first call `useDeleteTechnicianMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTechnicianMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTechnicianMutation, { data, loading, error }] = useDeleteTechnicianMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTechnicianMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteTechnicianMutation,
    Types.DeleteTechnicianMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.DeleteTechnicianMutation,
    Types.DeleteTechnicianMutationVariables
  >(DeleteTechnicianDocument, options)
}
export type DeleteTechnicianMutationHookResult = ReturnType<
  typeof useDeleteTechnicianMutation
>
export type DeleteTechnicianMutationResult =
  Apollo.MutationResult<Types.DeleteTechnicianMutation>
export type DeleteTechnicianMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteTechnicianMutation,
  Types.DeleteTechnicianMutationVariables
>
export const DeleteTourPlanDocument = gql`
  mutation DeleteTourPlan($input: DeleteTourPlanInput!) {
    deleteTourPlan(input: $input) {
      id
    }
  }
`
export type DeleteTourPlanMutationFn = Apollo.MutationFunction<
  Types.DeleteTourPlanMutation,
  Types.DeleteTourPlanMutationVariables
>

/**
 * __useDeleteTourPlanMutation__
 *
 * To run a mutation, you first call `useDeleteTourPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTourPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTourPlanMutation, { data, loading, error }] = useDeleteTourPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTourPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteTourPlanMutation,
    Types.DeleteTourPlanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.DeleteTourPlanMutation,
    Types.DeleteTourPlanMutationVariables
  >(DeleteTourPlanDocument, options)
}
export type DeleteTourPlanMutationHookResult = ReturnType<
  typeof useDeleteTourPlanMutation
>
export type DeleteTourPlanMutationResult =
  Apollo.MutationResult<Types.DeleteTourPlanMutation>
export type DeleteTourPlanMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteTourPlanMutation,
  Types.DeleteTourPlanMutationVariables
>
export const DeleteUserDocument = gql`
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      id
    }
  }
`
export type DeleteUserMutationFn = Apollo.MutationFunction<
  Types.DeleteUserMutation,
  Types.DeleteUserMutationVariables
>

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteUserMutation,
    Types.DeleteUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.DeleteUserMutation,
    Types.DeleteUserMutationVariables
  >(DeleteUserDocument, options)
}
export type DeleteUserMutationHookResult = ReturnType<
  typeof useDeleteUserMutation
>
export type DeleteUserMutationResult =
  Apollo.MutationResult<Types.DeleteUserMutation>
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteUserMutation,
  Types.DeleteUserMutationVariables
>
export const DisassociateMultipleIncidentsFromSingleCaseDocument = gql`
  mutation DisassociateMultipleIncidentsFromSingleCase(
    $input: DisassociateIncidentsFromCaseInput!
  ) {
    disassociateMultipleIncidentsFromSingleCase(input: $input) {
      case {
        id
        name
      }
    }
  }
`
export type DisassociateMultipleIncidentsFromSingleCaseMutationFn =
  Apollo.MutationFunction<
    Types.DisassociateMultipleIncidentsFromSingleCaseMutation,
    Types.DisassociateMultipleIncidentsFromSingleCaseMutationVariables
  >

/**
 * __useDisassociateMultipleIncidentsFromSingleCaseMutation__
 *
 * To run a mutation, you first call `useDisassociateMultipleIncidentsFromSingleCaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisassociateMultipleIncidentsFromSingleCaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disassociateMultipleIncidentsFromSingleCaseMutation, { data, loading, error }] = useDisassociateMultipleIncidentsFromSingleCaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisassociateMultipleIncidentsFromSingleCaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DisassociateMultipleIncidentsFromSingleCaseMutation,
    Types.DisassociateMultipleIncidentsFromSingleCaseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.DisassociateMultipleIncidentsFromSingleCaseMutation,
    Types.DisassociateMultipleIncidentsFromSingleCaseMutationVariables
  >(DisassociateMultipleIncidentsFromSingleCaseDocument, options)
}
export type DisassociateMultipleIncidentsFromSingleCaseMutationHookResult =
  ReturnType<typeof useDisassociateMultipleIncidentsFromSingleCaseMutation>
export type DisassociateMultipleIncidentsFromSingleCaseMutationResult =
  Apollo.MutationResult<Types.DisassociateMultipleIncidentsFromSingleCaseMutation>
export type DisassociateMultipleIncidentsFromSingleCaseMutationOptions =
  Apollo.BaseMutationOptions<
    Types.DisassociateMultipleIncidentsFromSingleCaseMutation,
    Types.DisassociateMultipleIncidentsFromSingleCaseMutationVariables
  >
export const GenerateFileUploadUrlDocument = gql`
  mutation GenerateFileUploadUrl($input: GenerateFileUploadUrlInput!) {
    generateFileUploadUrl(input: $input) {
      fileId
      url
      __typename
    }
  }
`
export type GenerateFileUploadUrlMutationFn = Apollo.MutationFunction<
  Types.GenerateFileUploadUrlMutation,
  Types.GenerateFileUploadUrlMutationVariables
>

/**
 * __useGenerateFileUploadUrlMutation__
 *
 * To run a mutation, you first call `useGenerateFileUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateFileUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateFileUploadUrlMutation, { data, loading, error }] = useGenerateFileUploadUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateFileUploadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.GenerateFileUploadUrlMutation,
    Types.GenerateFileUploadUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.GenerateFileUploadUrlMutation,
    Types.GenerateFileUploadUrlMutationVariables
  >(GenerateFileUploadUrlDocument, options)
}
export type GenerateFileUploadUrlMutationHookResult = ReturnType<
  typeof useGenerateFileUploadUrlMutation
>
export type GenerateFileUploadUrlMutationResult =
  Apollo.MutationResult<Types.GenerateFileUploadUrlMutation>
export type GenerateFileUploadUrlMutationOptions = Apollo.BaseMutationOptions<
  Types.GenerateFileUploadUrlMutation,
  Types.GenerateFileUploadUrlMutationVariables
>
export const ModifyTailgateConnDocument = gql`
  mutation ModifyTailgateConn($input: ModifyTailgateConnInput!) {
    modifyTailgateConn(input: $input) {
      tailgateConn {
        id
        cameras {
          edges {
            node {
              id
            }
          }
        }
        door {
          id
        }
      }
    }
  }
`
export type ModifyTailgateConnMutationFn = Apollo.MutationFunction<
  Types.ModifyTailgateConnMutation,
  Types.ModifyTailgateConnMutationVariables
>

/**
 * __useModifyTailgateConnMutation__
 *
 * To run a mutation, you first call `useModifyTailgateConnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyTailgateConnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyTailgateConnMutation, { data, loading, error }] = useModifyTailgateConnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyTailgateConnMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ModifyTailgateConnMutation,
    Types.ModifyTailgateConnMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.ModifyTailgateConnMutation,
    Types.ModifyTailgateConnMutationVariables
  >(ModifyTailgateConnDocument, options)
}
export type ModifyTailgateConnMutationHookResult = ReturnType<
  typeof useModifyTailgateConnMutation
>
export type ModifyTailgateConnMutationResult =
  Apollo.MutationResult<Types.ModifyTailgateConnMutation>
export type ModifyTailgateConnMutationOptions = Apollo.BaseMutationOptions<
  Types.ModifyTailgateConnMutation,
  Types.ModifyTailgateConnMutationVariables
>
export const RestartEdgeDeviceDocument = gql`
  mutation RestartEdgeDevice($input: RestartEdgeDeviceInput!) {
    restartEdgeDevice(input: $input) {
      isRestartRequested
    }
  }
`
export type RestartEdgeDeviceMutationFn = Apollo.MutationFunction<
  Types.RestartEdgeDeviceMutation,
  Types.RestartEdgeDeviceMutationVariables
>

/**
 * __useRestartEdgeDeviceMutation__
 *
 * To run a mutation, you first call `useRestartEdgeDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestartEdgeDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restartEdgeDeviceMutation, { data, loading, error }] = useRestartEdgeDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestartEdgeDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.RestartEdgeDeviceMutation,
    Types.RestartEdgeDeviceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.RestartEdgeDeviceMutation,
    Types.RestartEdgeDeviceMutationVariables
  >(RestartEdgeDeviceDocument, options)
}
export type RestartEdgeDeviceMutationHookResult = ReturnType<
  typeof useRestartEdgeDeviceMutation
>
export type RestartEdgeDeviceMutationResult =
  Apollo.MutationResult<Types.RestartEdgeDeviceMutation>
export type RestartEdgeDeviceMutationOptions = Apollo.BaseMutationOptions<
  Types.RestartEdgeDeviceMutation,
  Types.RestartEdgeDeviceMutationVariables
>
export const UpdateCameraDocument = gql`
  mutation UpdateCamera($input: UpdateCameraInput!) {
    updateCamera(input: $input) {
      camera {
        id
        externalId
      }
    }
  }
`
export type UpdateCameraMutationFn = Apollo.MutationFunction<
  Types.UpdateCameraMutation,
  Types.UpdateCameraMutationVariables
>

/**
 * __useUpdateCameraMutation__
 *
 * To run a mutation, you first call `useUpdateCameraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCameraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCameraMutation, { data, loading, error }] = useUpdateCameraMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCameraMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateCameraMutation,
    Types.UpdateCameraMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateCameraMutation,
    Types.UpdateCameraMutationVariables
  >(UpdateCameraDocument, options)
}
export type UpdateCameraMutationHookResult = ReturnType<
  typeof useUpdateCameraMutation
>
export type UpdateCameraMutationResult =
  Apollo.MutationResult<Types.UpdateCameraMutation>
export type UpdateCameraMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateCameraMutation,
  Types.UpdateCameraMutationVariables
>
export const UpdateCaseDocument = gql`
  mutation UpdateCase($input: UpdateCaseInput!) {
    updateCase(input: $input) {
      case {
        description
        detailedReport
        id
        status
      }
    }
  }
`
export type UpdateCaseMutationFn = Apollo.MutationFunction<
  Types.UpdateCaseMutation,
  Types.UpdateCaseMutationVariables
>

/**
 * __useUpdateCaseMutation__
 *
 * To run a mutation, you first call `useUpdateCaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCaseMutation, { data, loading, error }] = useUpdateCaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateCaseMutation,
    Types.UpdateCaseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateCaseMutation,
    Types.UpdateCaseMutationVariables
  >(UpdateCaseDocument, options)
}
export type UpdateCaseMutationHookResult = ReturnType<
  typeof useUpdateCaseMutation
>
export type UpdateCaseMutationResult =
  Apollo.MutationResult<Types.UpdateCaseMutation>
export type UpdateCaseMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateCaseMutation,
  Types.UpdateCaseMutationVariables
>
export const UpdateCaseDetailsDocument = gql`
  mutation UpdateCaseDetails(
    $caseId: ID!
    $caseName: String!
    $caseFacilityIds: [ID!]!
    $caseOwnerId: ID!
    $status: CaseStatus!
  ) {
    updateCase(
      input: { id: $caseId, name: $caseName, facilityIds: $caseFacilityIds }
    ) {
      case {
        id
        name
        ownerId
      }
    }
    updateCaseOwner(input: { caseId: $caseId, ownerId: $caseOwnerId }) {
      case {
        id
        ownerId
      }
    }
    updateCaseStatus(input: { caseId: $caseId, newStatus: $status }) {
      case {
        id
        status
      }
    }
  }
`
export type UpdateCaseDetailsMutationFn = Apollo.MutationFunction<
  Types.UpdateCaseDetailsMutation,
  Types.UpdateCaseDetailsMutationVariables
>

/**
 * __useUpdateCaseDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateCaseDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCaseDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCaseDetailsMutation, { data, loading, error }] = useUpdateCaseDetailsMutation({
 *   variables: {
 *      caseId: // value for 'caseId'
 *      caseName: // value for 'caseName'
 *      caseFacilityIds: // value for 'caseFacilityIds'
 *      caseOwnerId: // value for 'caseOwnerId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateCaseDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateCaseDetailsMutation,
    Types.UpdateCaseDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateCaseDetailsMutation,
    Types.UpdateCaseDetailsMutationVariables
  >(UpdateCaseDetailsDocument, options)
}
export type UpdateCaseDetailsMutationHookResult = ReturnType<
  typeof useUpdateCaseDetailsMutation
>
export type UpdateCaseDetailsMutationResult =
  Apollo.MutationResult<Types.UpdateCaseDetailsMutation>
export type UpdateCaseDetailsMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateCaseDetailsMutation,
  Types.UpdateCaseDetailsMutationVariables
>
export const UpdateDeviceDocument = gql`
  mutation UpdateDevice($input: UpdateDeviceInput!) {
    updateDevice(input: $input) {
      device {
        id
      }
    }
  }
`
export type UpdateDeviceMutationFn = Apollo.MutationFunction<
  Types.UpdateDeviceMutation,
  Types.UpdateDeviceMutationVariables
>

/**
 * __useUpdateDeviceMutation__
 *
 * To run a mutation, you first call `useUpdateDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeviceMutation, { data, loading, error }] = useUpdateDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateDeviceMutation,
    Types.UpdateDeviceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateDeviceMutation,
    Types.UpdateDeviceMutationVariables
  >(UpdateDeviceDocument, options)
}
export type UpdateDeviceMutationHookResult = ReturnType<
  typeof useUpdateDeviceMutation
>
export type UpdateDeviceMutationResult =
  Apollo.MutationResult<Types.UpdateDeviceMutation>
export type UpdateDeviceMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateDeviceMutation,
  Types.UpdateDeviceMutationVariables
>
export const UpdateDoorDocument = gql`
  mutation UpdateDoor($input: UpdateDoorInput!) {
    updateDoor(input: $input) {
      door {
        id
        externalId
      }
    }
  }
`
export type UpdateDoorMutationFn = Apollo.MutationFunction<
  Types.UpdateDoorMutation,
  Types.UpdateDoorMutationVariables
>

/**
 * __useUpdateDoorMutation__
 *
 * To run a mutation, you first call `useUpdateDoorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDoorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDoorMutation, { data, loading, error }] = useUpdateDoorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDoorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateDoorMutation,
    Types.UpdateDoorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateDoorMutation,
    Types.UpdateDoorMutationVariables
  >(UpdateDoorDocument, options)
}
export type UpdateDoorMutationHookResult = ReturnType<
  typeof useUpdateDoorMutation
>
export type UpdateDoorMutationResult =
  Apollo.MutationResult<Types.UpdateDoorMutation>
export type UpdateDoorMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateDoorMutation,
  Types.UpdateDoorMutationVariables
>
export const UpdateEdgeDeviceDocument = gql`
  mutation UpdateEdgeDevice($input: UpdateEdgeDeviceInput!) {
    updateEdgeDevice(input: $input) {
      edgeDevice {
        id
        name
      }
    }
  }
`
export type UpdateEdgeDeviceMutationFn = Apollo.MutationFunction<
  Types.UpdateEdgeDeviceMutation,
  Types.UpdateEdgeDeviceMutationVariables
>

/**
 * __useUpdateEdgeDeviceMutation__
 *
 * To run a mutation, you first call `useUpdateEdgeDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEdgeDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEdgeDeviceMutation, { data, loading, error }] = useUpdateEdgeDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEdgeDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateEdgeDeviceMutation,
    Types.UpdateEdgeDeviceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateEdgeDeviceMutation,
    Types.UpdateEdgeDeviceMutationVariables
  >(UpdateEdgeDeviceDocument, options)
}
export type UpdateEdgeDeviceMutationHookResult = ReturnType<
  typeof useUpdateEdgeDeviceMutation
>
export type UpdateEdgeDeviceMutationResult =
  Apollo.MutationResult<Types.UpdateEdgeDeviceMutation>
export type UpdateEdgeDeviceMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateEdgeDeviceMutation,
  Types.UpdateEdgeDeviceMutationVariables
>
export const UpdateEmergencyCallDocument = gql`
  mutation UpdateEmergencyCall($input: UpdateEmergencyCallInput!) {
    updateEmergencyCall(input: $input) {
      emergencyCall {
        id
      }
    }
  }
`
export type UpdateEmergencyCallMutationFn = Apollo.MutationFunction<
  Types.UpdateEmergencyCallMutation,
  Types.UpdateEmergencyCallMutationVariables
>

/**
 * __useUpdateEmergencyCallMutation__
 *
 * To run a mutation, you first call `useUpdateEmergencyCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmergencyCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmergencyCallMutation, { data, loading, error }] = useUpdateEmergencyCallMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmergencyCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateEmergencyCallMutation,
    Types.UpdateEmergencyCallMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateEmergencyCallMutation,
    Types.UpdateEmergencyCallMutationVariables
  >(UpdateEmergencyCallDocument, options)
}
export type UpdateEmergencyCallMutationHookResult = ReturnType<
  typeof useUpdateEmergencyCallMutation
>
export type UpdateEmergencyCallMutationResult =
  Apollo.MutationResult<Types.UpdateEmergencyCallMutation>
export type UpdateEmergencyCallMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateEmergencyCallMutation,
  Types.UpdateEmergencyCallMutationVariables
>
export const UpdateExternalAccountDocument = gql`
  mutation updateExternalAccount($input: UpdateExternalAccountInput!) {
    updateExternalAccount(input: $input) {
      externalAccount {
        accountId
        accountName
        defaultAccount
        externalSystemType
        id
      }
    }
  }
`
export type UpdateExternalAccountMutationFn = Apollo.MutationFunction<
  Types.UpdateExternalAccountMutation,
  Types.UpdateExternalAccountMutationVariables
>

/**
 * __useUpdateExternalAccountMutation__
 *
 * To run a mutation, you first call `useUpdateExternalAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExternalAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExternalAccountMutation, { data, loading, error }] = useUpdateExternalAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExternalAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateExternalAccountMutation,
    Types.UpdateExternalAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateExternalAccountMutation,
    Types.UpdateExternalAccountMutationVariables
  >(UpdateExternalAccountDocument, options)
}
export type UpdateExternalAccountMutationHookResult = ReturnType<
  typeof useUpdateExternalAccountMutation
>
export type UpdateExternalAccountMutationResult =
  Apollo.MutationResult<Types.UpdateExternalAccountMutation>
export type UpdateExternalAccountMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateExternalAccountMutation,
  Types.UpdateExternalAccountMutationVariables
>
export const UpdateExternalAccountApiKeyDocument = gql`
  mutation UpdateExternalAccountApiKey(
    $input: UpdateExternalAccountApiKeyInput!
  ) {
    updateExternalAccountApiKey(input: $input) {
      externalAccountApiKey {
        id
        secretName
        permissionName
      }
    }
  }
`
export type UpdateExternalAccountApiKeyMutationFn = Apollo.MutationFunction<
  Types.UpdateExternalAccountApiKeyMutation,
  Types.UpdateExternalAccountApiKeyMutationVariables
>

/**
 * __useUpdateExternalAccountApiKeyMutation__
 *
 * To run a mutation, you first call `useUpdateExternalAccountApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExternalAccountApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExternalAccountApiKeyMutation, { data, loading, error }] = useUpdateExternalAccountApiKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExternalAccountApiKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateExternalAccountApiKeyMutation,
    Types.UpdateExternalAccountApiKeyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateExternalAccountApiKeyMutation,
    Types.UpdateExternalAccountApiKeyMutationVariables
  >(UpdateExternalAccountApiKeyDocument, options)
}
export type UpdateExternalAccountApiKeyMutationHookResult = ReturnType<
  typeof useUpdateExternalAccountApiKeyMutation
>
export type UpdateExternalAccountApiKeyMutationResult =
  Apollo.MutationResult<Types.UpdateExternalAccountApiKeyMutation>
export type UpdateExternalAccountApiKeyMutationOptions =
  Apollo.BaseMutationOptions<
    Types.UpdateExternalAccountApiKeyMutation,
    Types.UpdateExternalAccountApiKeyMutationVariables
  >
export const UpdateFacilityDocument = gql`
  mutation UpdateFacility($input: UpdateFacilityInput!) {
    updateFacility(input: $input) {
      facility {
        id
        name
        shortName
        address
        longitude
        latitude
        emergencyNumber
      }
    }
  }
`
export type UpdateFacilityMutationFn = Apollo.MutationFunction<
  Types.UpdateFacilityMutation,
  Types.UpdateFacilityMutationVariables
>

/**
 * __useUpdateFacilityMutation__
 *
 * To run a mutation, you first call `useUpdateFacilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFacilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFacilityMutation, { data, loading, error }] = useUpdateFacilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFacilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateFacilityMutation,
    Types.UpdateFacilityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateFacilityMutation,
    Types.UpdateFacilityMutationVariables
  >(UpdateFacilityDocument, options)
}
export type UpdateFacilityMutationHookResult = ReturnType<
  typeof useUpdateFacilityMutation
>
export type UpdateFacilityMutationResult =
  Apollo.MutationResult<Types.UpdateFacilityMutation>
export type UpdateFacilityMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateFacilityMutation,
  Types.UpdateFacilityMutationVariables
>
export const UpdateFloorDocument = gql`
  mutation UpdateFloor($input: UpdateFloorInput!) {
    updateFloor(input: $input) {
      floor {
        id
      }
    }
  }
`
export type UpdateFloorMutationFn = Apollo.MutationFunction<
  Types.UpdateFloorMutation,
  Types.UpdateFloorMutationVariables
>

/**
 * __useUpdateFloorMutation__
 *
 * To run a mutation, you first call `useUpdateFloorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFloorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFloorMutation, { data, loading, error }] = useUpdateFloorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFloorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateFloorMutation,
    Types.UpdateFloorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateFloorMutation,
    Types.UpdateFloorMutationVariables
  >(UpdateFloorDocument, options)
}
export type UpdateFloorMutationHookResult = ReturnType<
  typeof useUpdateFloorMutation
>
export type UpdateFloorMutationResult =
  Apollo.MutationResult<Types.UpdateFloorMutation>
export type UpdateFloorMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateFloorMutation,
  Types.UpdateFloorMutationVariables
>
export const UpdateManualIncidentCategoryDocument = gql`
  mutation updateManualIncidentCategory(
    $input: UpdateManualIncidentCategoryInput!
  ) {
    updateManualIncidentCategory(input: $input) {
      manualIncidentCategory {
        id
      }
    }
  }
`
export type UpdateManualIncidentCategoryMutationFn = Apollo.MutationFunction<
  Types.UpdateManualIncidentCategoryMutation,
  Types.UpdateManualIncidentCategoryMutationVariables
>

/**
 * __useUpdateManualIncidentCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateManualIncidentCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManualIncidentCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManualIncidentCategoryMutation, { data, loading, error }] = useUpdateManualIncidentCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateManualIncidentCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateManualIncidentCategoryMutation,
    Types.UpdateManualIncidentCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateManualIncidentCategoryMutation,
    Types.UpdateManualIncidentCategoryMutationVariables
  >(UpdateManualIncidentCategoryDocument, options)
}
export type UpdateManualIncidentCategoryMutationHookResult = ReturnType<
  typeof useUpdateManualIncidentCategoryMutation
>
export type UpdateManualIncidentCategoryMutationResult =
  Apollo.MutationResult<Types.UpdateManualIncidentCategoryMutation>
export type UpdateManualIncidentCategoryMutationOptions =
  Apollo.BaseMutationOptions<
    Types.UpdateManualIncidentCategoryMutation,
    Types.UpdateManualIncidentCategoryMutationVariables
  >
export const UpdateOrganizationSettingsDocument = gql`
  mutation UpdateOrganizationSettings(
    $input: UpdateOrganizationSettingsInput!
  ) {
    updateOrganizationSettings(input: $input) {
      organizationSettings {
        id
        minimumCameraOfflineDuration
        cameraOfflineDurationUnit
      }
    }
  }
`
export type UpdateOrganizationSettingsMutationFn = Apollo.MutationFunction<
  Types.UpdateOrganizationSettingsMutation,
  Types.UpdateOrganizationSettingsMutationVariables
>

/**
 * __useUpdateOrganizationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationSettingsMutation, { data, loading, error }] = useUpdateOrganizationSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateOrganizationSettingsMutation,
    Types.UpdateOrganizationSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateOrganizationSettingsMutation,
    Types.UpdateOrganizationSettingsMutationVariables
  >(UpdateOrganizationSettingsDocument, options)
}
export type UpdateOrganizationSettingsMutationHookResult = ReturnType<
  typeof useUpdateOrganizationSettingsMutation
>
export type UpdateOrganizationSettingsMutationResult =
  Apollo.MutationResult<Types.UpdateOrganizationSettingsMutation>
export type UpdateOrganizationSettingsMutationOptions =
  Apollo.BaseMutationOptions<
    Types.UpdateOrganizationSettingsMutation,
    Types.UpdateOrganizationSettingsMutationVariables
  >
export const UpdatePacsSourceInstanceDocument = gql`
  mutation UpdatePacsSourceInstance($input: UpdatePacsSourceInstanceInput!) {
    updatePacsSourceInstance(input: $input) {
      pacsSourceInstance {
        id
      }
    }
  }
`
export type UpdatePacsSourceInstanceMutationFn = Apollo.MutationFunction<
  Types.UpdatePacsSourceInstanceMutation,
  Types.UpdatePacsSourceInstanceMutationVariables
>

/**
 * __useUpdatePacsSourceInstanceMutation__
 *
 * To run a mutation, you first call `useUpdatePacsSourceInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePacsSourceInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePacsSourceInstanceMutation, { data, loading, error }] = useUpdatePacsSourceInstanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePacsSourceInstanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdatePacsSourceInstanceMutation,
    Types.UpdatePacsSourceInstanceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdatePacsSourceInstanceMutation,
    Types.UpdatePacsSourceInstanceMutationVariables
  >(UpdatePacsSourceInstanceDocument, options)
}
export type UpdatePacsSourceInstanceMutationHookResult = ReturnType<
  typeof useUpdatePacsSourceInstanceMutation
>
export type UpdatePacsSourceInstanceMutationResult =
  Apollo.MutationResult<Types.UpdatePacsSourceInstanceMutation>
export type UpdatePacsSourceInstanceMutationOptions =
  Apollo.BaseMutationOptions<
    Types.UpdatePacsSourceInstanceMutation,
    Types.UpdatePacsSourceInstanceMutationVariables
  >
export const UpdateRuleDocument = gql`
  mutation UpdateRule($input: UpdateRuleInput!) {
    updateRule(input: $input) {
      rule {
        id
        status
      }
    }
  }
`
export type UpdateRuleMutationFn = Apollo.MutationFunction<
  Types.UpdateRuleMutation,
  Types.UpdateRuleMutationVariables
>

/**
 * __useUpdateRuleMutation__
 *
 * To run a mutation, you first call `useUpdateRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRuleMutation, { data, loading, error }] = useUpdateRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateRuleMutation,
    Types.UpdateRuleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateRuleMutation,
    Types.UpdateRuleMutationVariables
  >(UpdateRuleDocument, options)
}
export type UpdateRuleMutationHookResult = ReturnType<
  typeof useUpdateRuleMutation
>
export type UpdateRuleMutationResult =
  Apollo.MutationResult<Types.UpdateRuleMutation>
export type UpdateRuleMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateRuleMutation,
  Types.UpdateRuleMutationVariables
>
export const UpdateSavedFilterDocument = gql`
  mutation UpdateSavedFilter($input: UpdateSavedFilterInput!) {
    updateSavedFilter(input: $input) {
      savedFilter {
        id
      }
    }
  }
`
export type UpdateSavedFilterMutationFn = Apollo.MutationFunction<
  Types.UpdateSavedFilterMutation,
  Types.UpdateSavedFilterMutationVariables
>

/**
 * __useUpdateSavedFilterMutation__
 *
 * To run a mutation, you first call `useUpdateSavedFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSavedFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSavedFilterMutation, { data, loading, error }] = useUpdateSavedFilterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSavedFilterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateSavedFilterMutation,
    Types.UpdateSavedFilterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateSavedFilterMutation,
    Types.UpdateSavedFilterMutationVariables
  >(UpdateSavedFilterDocument, options)
}
export type UpdateSavedFilterMutationHookResult = ReturnType<
  typeof useUpdateSavedFilterMutation
>
export type UpdateSavedFilterMutationResult =
  Apollo.MutationResult<Types.UpdateSavedFilterMutation>
export type UpdateSavedFilterMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateSavedFilterMutation,
  Types.UpdateSavedFilterMutationVariables
>
export const UpdateStandardOperatingProcedureDocument = gql`
  mutation UpdateStandardOperatingProcedure(
    $input: UpdateStandardOperatingProcedureInput!
  ) {
    updateStandardOperatingProcedure(input: $input) {
      standardOperatingProcedure {
        id
      }
    }
  }
`
export type UpdateStandardOperatingProcedureMutationFn =
  Apollo.MutationFunction<
    Types.UpdateStandardOperatingProcedureMutation,
    Types.UpdateStandardOperatingProcedureMutationVariables
  >

/**
 * __useUpdateStandardOperatingProcedureMutation__
 *
 * To run a mutation, you first call `useUpdateStandardOperatingProcedureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStandardOperatingProcedureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStandardOperatingProcedureMutation, { data, loading, error }] = useUpdateStandardOperatingProcedureMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStandardOperatingProcedureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateStandardOperatingProcedureMutation,
    Types.UpdateStandardOperatingProcedureMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateStandardOperatingProcedureMutation,
    Types.UpdateStandardOperatingProcedureMutationVariables
  >(UpdateStandardOperatingProcedureDocument, options)
}
export type UpdateStandardOperatingProcedureMutationHookResult = ReturnType<
  typeof useUpdateStandardOperatingProcedureMutation
>
export type UpdateStandardOperatingProcedureMutationResult =
  Apollo.MutationResult<Types.UpdateStandardOperatingProcedureMutation>
export type UpdateStandardOperatingProcedureMutationOptions =
  Apollo.BaseMutationOptions<
    Types.UpdateStandardOperatingProcedureMutation,
    Types.UpdateStandardOperatingProcedureMutationVariables
  >
export const UpdateTailgateGridDocument = gql`
  mutation UpdateTailgateGrid(
    $updateTailgateGridInput: UpdateTailgateGridInput!
    $updateCameraInput: UpdateCameraInput!
  ) {
    updateTailgateGrid(input: $updateTailgateGridInput) {
      camera {
        id
      }
      door {
        id
      }
    }
    updateCamera(input: $updateCameraInput) {
      camera {
        id
      }
    }
  }
`
export type UpdateTailgateGridMutationFn = Apollo.MutationFunction<
  Types.UpdateTailgateGridMutation,
  Types.UpdateTailgateGridMutationVariables
>

/**
 * __useUpdateTailgateGridMutation__
 *
 * To run a mutation, you first call `useUpdateTailgateGridMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTailgateGridMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTailgateGridMutation, { data, loading, error }] = useUpdateTailgateGridMutation({
 *   variables: {
 *      updateTailgateGridInput: // value for 'updateTailgateGridInput'
 *      updateCameraInput: // value for 'updateCameraInput'
 *   },
 * });
 */
export function useUpdateTailgateGridMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateTailgateGridMutation,
    Types.UpdateTailgateGridMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateTailgateGridMutation,
    Types.UpdateTailgateGridMutationVariables
  >(UpdateTailgateGridDocument, options)
}
export type UpdateTailgateGridMutationHookResult = ReturnType<
  typeof useUpdateTailgateGridMutation
>
export type UpdateTailgateGridMutationResult =
  Apollo.MutationResult<Types.UpdateTailgateGridMutation>
export type UpdateTailgateGridMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateTailgateGridMutation,
  Types.UpdateTailgateGridMutationVariables
>
export const UpdateTechnicianDocument = gql`
  mutation UpdateTechnician($input: UpdateTechnicianInput!) {
    updateTechnician(input: $input) {
      technician {
        id
        email
        firstName
        lastName
        facilities {
          id
          name
        }
        deviceTypes
      }
    }
  }
`
export type UpdateTechnicianMutationFn = Apollo.MutationFunction<
  Types.UpdateTechnicianMutation,
  Types.UpdateTechnicianMutationVariables
>

/**
 * __useUpdateTechnicianMutation__
 *
 * To run a mutation, you first call `useUpdateTechnicianMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTechnicianMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTechnicianMutation, { data, loading, error }] = useUpdateTechnicianMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTechnicianMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateTechnicianMutation,
    Types.UpdateTechnicianMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateTechnicianMutation,
    Types.UpdateTechnicianMutationVariables
  >(UpdateTechnicianDocument, options)
}
export type UpdateTechnicianMutationHookResult = ReturnType<
  typeof useUpdateTechnicianMutation
>
export type UpdateTechnicianMutationResult =
  Apollo.MutationResult<Types.UpdateTechnicianMutation>
export type UpdateTechnicianMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateTechnicianMutation,
  Types.UpdateTechnicianMutationVariables
>
export const UpdateTourPlanDocument = gql`
  mutation UpdateTourPlan($input: UpdateTourPlanInput!) {
    updateTourPlan(input: $input) {
      tourPlan {
        id
      }
    }
  }
`
export type UpdateTourPlanMutationFn = Apollo.MutationFunction<
  Types.UpdateTourPlanMutation,
  Types.UpdateTourPlanMutationVariables
>

/**
 * __useUpdateTourPlanMutation__
 *
 * To run a mutation, you first call `useUpdateTourPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTourPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTourPlanMutation, { data, loading, error }] = useUpdateTourPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTourPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateTourPlanMutation,
    Types.UpdateTourPlanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateTourPlanMutation,
    Types.UpdateTourPlanMutationVariables
  >(UpdateTourPlanDocument, options)
}
export type UpdateTourPlanMutationHookResult = ReturnType<
  typeof useUpdateTourPlanMutation
>
export type UpdateTourPlanMutationResult =
  Apollo.MutationResult<Types.UpdateTourPlanMutation>
export type UpdateTourPlanMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateTourPlanMutation,
  Types.UpdateTourPlanMutationVariables
>
export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        designatedFacilityId
        email
        firstName
        lastName
        roleName
      }
    }
  }
`
export type UpdateUserMutationFn = Apollo.MutationFunction<
  Types.UpdateUserMutation,
  Types.UpdateUserMutationVariables
>

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateUserMutation,
    Types.UpdateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateUserMutation,
    Types.UpdateUserMutationVariables
  >(UpdateUserDocument, options)
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>
export type UpdateUserMutationResult =
  Apollo.MutationResult<Types.UpdateUserMutation>
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateUserMutation,
  Types.UpdateUserMutationVariables
>
export const ManualIncidentCategoriesDocument = gql`
  query ManualIncidentCategories($input: ManualIncidentCategoryFilterInput) {
    manualIncidentCategories(input: $input) {
      totalCount
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

/**
 * __useManualIncidentCategoriesQuery__
 *
 * To run a query within a React component, call `useManualIncidentCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useManualIncidentCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManualIncidentCategoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useManualIncidentCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.ManualIncidentCategoriesQuery,
    Types.ManualIncidentCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.ManualIncidentCategoriesQuery,
    Types.ManualIncidentCategoriesQueryVariables
  >(ManualIncidentCategoriesDocument, options)
}
export function useManualIncidentCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ManualIncidentCategoriesQuery,
    Types.ManualIncidentCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.ManualIncidentCategoriesQuery,
    Types.ManualIncidentCategoriesQueryVariables
  >(ManualIncidentCategoriesDocument, options)
}
export type ManualIncidentCategoriesQueryHookResult = ReturnType<
  typeof useManualIncidentCategoriesQuery
>
export type ManualIncidentCategoriesLazyQueryHookResult = ReturnType<
  typeof useManualIncidentCategoriesLazyQuery
>
export type ManualIncidentCategoriesQueryResult = Apollo.QueryResult<
  Types.ManualIncidentCategoriesQuery,
  Types.ManualIncidentCategoriesQueryVariables
>
export const ActiveIncidentsFilterDocument = gql`
  query ActiveIncidentsFilter {
    activeFilter(context: INCIDENT) {
      id
      name
      filterCriteria {
        incident {
          createdAtGteInterval
          deviceIdIn
          facilityIdIn
          floorIdIn
          mutedStatus
          priorityIn
          statusIn
          OR {
            sourceIn
            deviceTypeIn
          }
          AND {
            OR {
              typeIn
              manualIncidentCategoryIdIn
            }
          }
        }
      }
    }
  }
`

/**
 * __useActiveIncidentsFilterQuery__
 *
 * To run a query within a React component, call `useActiveIncidentsFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveIncidentsFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveIncidentsFilterQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveIncidentsFilterQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.ActiveIncidentsFilterQuery,
    Types.ActiveIncidentsFilterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.ActiveIncidentsFilterQuery,
    Types.ActiveIncidentsFilterQueryVariables
  >(ActiveIncidentsFilterDocument, options)
}
export function useActiveIncidentsFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ActiveIncidentsFilterQuery,
    Types.ActiveIncidentsFilterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.ActiveIncidentsFilterQuery,
    Types.ActiveIncidentsFilterQueryVariables
  >(ActiveIncidentsFilterDocument, options)
}
export type ActiveIncidentsFilterQueryHookResult = ReturnType<
  typeof useActiveIncidentsFilterQuery
>
export type ActiveIncidentsFilterLazyQueryHookResult = ReturnType<
  typeof useActiveIncidentsFilterLazyQuery
>
export type ActiveIncidentsFilterQueryResult = Apollo.QueryResult<
  Types.ActiveIncidentsFilterQuery,
  Types.ActiveIncidentsFilterQueryVariables
>
export const CameraFeedDocument = gql`
  query CameraFeed($deviceId: ID!) {
    streamingUrlWrapper(deviceId: $deviceId) {
      url
      expiryTime
    }
  }
`

/**
 * __useCameraFeedQuery__
 *
 * To run a query within a React component, call `useCameraFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCameraFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCameraFeedQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useCameraFeedQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.CameraFeedQuery,
    Types.CameraFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.CameraFeedQuery, Types.CameraFeedQueryVariables>(
    CameraFeedDocument,
    options
  )
}
export function useCameraFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.CameraFeedQuery,
    Types.CameraFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.CameraFeedQuery,
    Types.CameraFeedQueryVariables
  >(CameraFeedDocument, options)
}
export type CameraFeedQueryHookResult = ReturnType<typeof useCameraFeedQuery>
export type CameraFeedLazyQueryHookResult = ReturnType<
  typeof useCameraFeedLazyQuery
>
export type CameraFeedQueryResult = Apollo.QueryResult<
  Types.CameraFeedQuery,
  Types.CameraFeedQueryVariables
>
export const CaseDocument = gql`
  query Case($id: ID!) {
    case(id: $id) {
      createdAt
      description
      detailedReport
      displayId
      id
      name
      status
      facilities {
        id
        name
        shortName
        archived
      }
      owner {
        firstName
        lastName
        roleName
        id
      }
      incidents {
        edges {
          node {
            id
            displayId
            createdAt
            name
            status
            facility {
              id
              name
            }
            floor {
              id
              name
            }
          }
        }
      }
      actions {
        edges {
          node {
            id
            type
            comment {
              message
            }
            createdAt
            creator {
              id
              firstName
              lastName
              roleName
            }
            referenceId
          }
        }
      }
    }
  }
`

/**
 * __useCaseQuery__
 *
 * To run a query within a React component, call `useCaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCaseQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.CaseQuery,
    Types.CaseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.CaseQuery, Types.CaseQueryVariables>(
    CaseDocument,
    options
  )
}
export function useCaseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.CaseQuery,
    Types.CaseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.CaseQuery, Types.CaseQueryVariables>(
    CaseDocument,
    options
  )
}
export type CaseQueryHookResult = ReturnType<typeof useCaseQuery>
export type CaseLazyQueryHookResult = ReturnType<typeof useCaseLazyQuery>
export type CaseQueryResult = Apollo.QueryResult<
  Types.CaseQuery,
  Types.CaseQueryVariables
>
export const CaseIncidentsDocument = gql`
  query CaseIncidents($id: ID!, $pageIndex: Int, $pageSize: Int) {
    case(id: $id) {
      id
      incidents(first: $pageIndex, last: $pageSize) {
        edges {
          node {
            id
            displayId
            createdAt
            name
            status
            facility {
              id
              name
            }
            floor {
              id
              name
            }
          }
        }
        totalCount
      }
    }
  }
`

/**
 * __useCaseIncidentsQuery__
 *
 * To run a query within a React component, call `useCaseIncidentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseIncidentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseIncidentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      pageIndex: // value for 'pageIndex'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useCaseIncidentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.CaseIncidentsQuery,
    Types.CaseIncidentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.CaseIncidentsQuery,
    Types.CaseIncidentsQueryVariables
  >(CaseIncidentsDocument, options)
}
export function useCaseIncidentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.CaseIncidentsQuery,
    Types.CaseIncidentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.CaseIncidentsQuery,
    Types.CaseIncidentsQueryVariables
  >(CaseIncidentsDocument, options)
}
export type CaseIncidentsQueryHookResult = ReturnType<
  typeof useCaseIncidentsQuery
>
export type CaseIncidentsLazyQueryHookResult = ReturnType<
  typeof useCaseIncidentsLazyQuery
>
export type CaseIncidentsQueryResult = Apollo.QueryResult<
  Types.CaseIncidentsQuery,
  Types.CaseIncidentsQueryVariables
>
export const CaseIncidentsVideosDocument = gql`
  query CaseIncidentsVideos($id: ID!) {
    case(id: $id) {
      id
      incidents {
        edges {
          node {
            id
            displayId
            createdAt
            type
            video {
              expiryTime
              url
            }
          }
        }
      }
    }
  }
`

/**
 * __useCaseIncidentsVideosQuery__
 *
 * To run a query within a React component, call `useCaseIncidentsVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseIncidentsVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseIncidentsVideosQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCaseIncidentsVideosQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.CaseIncidentsVideosQuery,
    Types.CaseIncidentsVideosQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.CaseIncidentsVideosQuery,
    Types.CaseIncidentsVideosQueryVariables
  >(CaseIncidentsVideosDocument, options)
}
export function useCaseIncidentsVideosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.CaseIncidentsVideosQuery,
    Types.CaseIncidentsVideosQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.CaseIncidentsVideosQuery,
    Types.CaseIncidentsVideosQueryVariables
  >(CaseIncidentsVideosDocument, options)
}
export type CaseIncidentsVideosQueryHookResult = ReturnType<
  typeof useCaseIncidentsVideosQuery
>
export type CaseIncidentsVideosLazyQueryHookResult = ReturnType<
  typeof useCaseIncidentsVideosLazyQuery
>
export type CaseIncidentsVideosQueryResult = Apollo.QueryResult<
  Types.CaseIncidentsVideosQuery,
  Types.CaseIncidentsVideosQueryVariables
>
export const CaseReportEnrichmentDocument = gql`
  query CaseReportEnrichment($incidentIds: [ID!]) {
    incidents(filter: { idIn: $incidentIds }) {
      edges {
        node {
          id
          displayId
        }
      }
    }
    users {
      edges {
        node {
          id
          firstName
          lastName
          roleName
        }
      }
    }
  }
`

/**
 * __useCaseReportEnrichmentQuery__
 *
 * To run a query within a React component, call `useCaseReportEnrichmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseReportEnrichmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseReportEnrichmentQuery({
 *   variables: {
 *      incidentIds: // value for 'incidentIds'
 *   },
 * });
 */
export function useCaseReportEnrichmentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.CaseReportEnrichmentQuery,
    Types.CaseReportEnrichmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.CaseReportEnrichmentQuery,
    Types.CaseReportEnrichmentQueryVariables
  >(CaseReportEnrichmentDocument, options)
}
export function useCaseReportEnrichmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.CaseReportEnrichmentQuery,
    Types.CaseReportEnrichmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.CaseReportEnrichmentQuery,
    Types.CaseReportEnrichmentQueryVariables
  >(CaseReportEnrichmentDocument, options)
}
export type CaseReportEnrichmentQueryHookResult = ReturnType<
  typeof useCaseReportEnrichmentQuery
>
export type CaseReportEnrichmentLazyQueryHookResult = ReturnType<
  typeof useCaseReportEnrichmentLazyQuery
>
export type CaseReportEnrichmentQueryResult = Apollo.QueryResult<
  Types.CaseReportEnrichmentQuery,
  Types.CaseReportEnrichmentQueryVariables
>
export const CasesDocument = gql`
  query Cases($filter: CaseFilterInput, $first: Int, $last: Int) {
    cases(filter: $filter, first: $first, last: $last) {
      totalCount
      edges {
        cursor
        node {
          displayId
          id
          name
          status
          createdAt
          owner {
            firstName
            id
            lastName
          }
        }
      }
    }
  }
`

/**
 * __useCasesQuery__
 *
 * To run a query within a React component, call `useCasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCasesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useCasesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.CasesQuery,
    Types.CasesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.CasesQuery, Types.CasesQueryVariables>(
    CasesDocument,
    options
  )
}
export function useCasesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.CasesQuery,
    Types.CasesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.CasesQuery, Types.CasesQueryVariables>(
    CasesDocument,
    options
  )
}
export type CasesQueryHookResult = ReturnType<typeof useCasesQuery>
export type CasesLazyQueryHookResult = ReturnType<typeof useCasesLazyQuery>
export type CasesQueryResult = Apollo.QueryResult<
  Types.CasesQuery,
  Types.CasesQueryVariables
>
export const AverageGuardAcknowledgmentTimeKpiDocument = gql`
  query AverageGuardAcknowledgmentTimeKPI($guards: [ID!], $hours: Int!) {
    kpi {
      averageGuardAcknowledgmentTime(guards: $guards, hours: $hours) {
        current
        past
      }
    }
  }
`

/**
 * __useAverageGuardAcknowledgmentTimeKpiQuery__
 *
 * To run a query within a React component, call `useAverageGuardAcknowledgmentTimeKpiQuery` and pass it any options that fit your needs.
 * When your component renders, `useAverageGuardAcknowledgmentTimeKpiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAverageGuardAcknowledgmentTimeKpiQuery({
 *   variables: {
 *      guards: // value for 'guards'
 *      hours: // value for 'hours'
 *   },
 * });
 */
export function useAverageGuardAcknowledgmentTimeKpiQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.AverageGuardAcknowledgmentTimeKpiQuery,
    Types.AverageGuardAcknowledgmentTimeKpiQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.AverageGuardAcknowledgmentTimeKpiQuery,
    Types.AverageGuardAcknowledgmentTimeKpiQueryVariables
  >(AverageGuardAcknowledgmentTimeKpiDocument, options)
}
export function useAverageGuardAcknowledgmentTimeKpiLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.AverageGuardAcknowledgmentTimeKpiQuery,
    Types.AverageGuardAcknowledgmentTimeKpiQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.AverageGuardAcknowledgmentTimeKpiQuery,
    Types.AverageGuardAcknowledgmentTimeKpiQueryVariables
  >(AverageGuardAcknowledgmentTimeKpiDocument, options)
}
export type AverageGuardAcknowledgmentTimeKpiQueryHookResult = ReturnType<
  typeof useAverageGuardAcknowledgmentTimeKpiQuery
>
export type AverageGuardAcknowledgmentTimeKpiLazyQueryHookResult = ReturnType<
  typeof useAverageGuardAcknowledgmentTimeKpiLazyQuery
>
export type AverageGuardAcknowledgmentTimeKpiQueryResult = Apollo.QueryResult<
  Types.AverageGuardAcknowledgmentTimeKpiQuery,
  Types.AverageGuardAcknowledgmentTimeKpiQueryVariables
>
export const AverageGuardTimeOnSceneKpiDocument = gql`
  query AverageGuardTimeOnSceneKPI($guards: [ID!], $hours: Int!) {
    kpi {
      averageGuardOnScene(guards: $guards, hours: $hours) {
        current
        past
      }
    }
  }
`

/**
 * __useAverageGuardTimeOnSceneKpiQuery__
 *
 * To run a query within a React component, call `useAverageGuardTimeOnSceneKpiQuery` and pass it any options that fit your needs.
 * When your component renders, `useAverageGuardTimeOnSceneKpiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAverageGuardTimeOnSceneKpiQuery({
 *   variables: {
 *      guards: // value for 'guards'
 *      hours: // value for 'hours'
 *   },
 * });
 */
export function useAverageGuardTimeOnSceneKpiQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.AverageGuardTimeOnSceneKpiQuery,
    Types.AverageGuardTimeOnSceneKpiQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.AverageGuardTimeOnSceneKpiQuery,
    Types.AverageGuardTimeOnSceneKpiQueryVariables
  >(AverageGuardTimeOnSceneKpiDocument, options)
}
export function useAverageGuardTimeOnSceneKpiLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.AverageGuardTimeOnSceneKpiQuery,
    Types.AverageGuardTimeOnSceneKpiQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.AverageGuardTimeOnSceneKpiQuery,
    Types.AverageGuardTimeOnSceneKpiQueryVariables
  >(AverageGuardTimeOnSceneKpiDocument, options)
}
export type AverageGuardTimeOnSceneKpiQueryHookResult = ReturnType<
  typeof useAverageGuardTimeOnSceneKpiQuery
>
export type AverageGuardTimeOnSceneKpiLazyQueryHookResult = ReturnType<
  typeof useAverageGuardTimeOnSceneKpiLazyQuery
>
export type AverageGuardTimeOnSceneKpiQueryResult = Apollo.QueryResult<
  Types.AverageGuardTimeOnSceneKpiQuery,
  Types.AverageGuardTimeOnSceneKpiQueryVariables
>
export const AverageGuardTimeToClearKpiDocument = gql`
  query AverageGuardTimeToClearKPI($guards: [ID!], $hours: Int!) {
    kpi {
      averageGuardToClear(guards: $guards, hours: $hours) {
        current
        past
      }
    }
  }
`

/**
 * __useAverageGuardTimeToClearKpiQuery__
 *
 * To run a query within a React component, call `useAverageGuardTimeToClearKpiQuery` and pass it any options that fit your needs.
 * When your component renders, `useAverageGuardTimeToClearKpiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAverageGuardTimeToClearKpiQuery({
 *   variables: {
 *      guards: // value for 'guards'
 *      hours: // value for 'hours'
 *   },
 * });
 */
export function useAverageGuardTimeToClearKpiQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.AverageGuardTimeToClearKpiQuery,
    Types.AverageGuardTimeToClearKpiQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.AverageGuardTimeToClearKpiQuery,
    Types.AverageGuardTimeToClearKpiQueryVariables
  >(AverageGuardTimeToClearKpiDocument, options)
}
export function useAverageGuardTimeToClearKpiLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.AverageGuardTimeToClearKpiQuery,
    Types.AverageGuardTimeToClearKpiQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.AverageGuardTimeToClearKpiQuery,
    Types.AverageGuardTimeToClearKpiQueryVariables
  >(AverageGuardTimeToClearKpiDocument, options)
}
export type AverageGuardTimeToClearKpiQueryHookResult = ReturnType<
  typeof useAverageGuardTimeToClearKpiQuery
>
export type AverageGuardTimeToClearKpiLazyQueryHookResult = ReturnType<
  typeof useAverageGuardTimeToClearKpiLazyQuery
>
export type AverageGuardTimeToClearKpiQueryResult = Apollo.QueryResult<
  Types.AverageGuardTimeToClearKpiQuery,
  Types.AverageGuardTimeToClearKpiQueryVariables
>
export const AverageOperatorTimeToAcknowledgeKpiDocument = gql`
  query AverageOperatorTimeToAcknowledgeKPI($owners: [ID!], $hours: Int!) {
    kpi {
      averageOperatorTimeToAcknowledge(owners: $owners, hours: $hours) {
        current
        past
      }
    }
  }
`

/**
 * __useAverageOperatorTimeToAcknowledgeKpiQuery__
 *
 * To run a query within a React component, call `useAverageOperatorTimeToAcknowledgeKpiQuery` and pass it any options that fit your needs.
 * When your component renders, `useAverageOperatorTimeToAcknowledgeKpiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAverageOperatorTimeToAcknowledgeKpiQuery({
 *   variables: {
 *      owners: // value for 'owners'
 *      hours: // value for 'hours'
 *   },
 * });
 */
export function useAverageOperatorTimeToAcknowledgeKpiQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.AverageOperatorTimeToAcknowledgeKpiQuery,
    Types.AverageOperatorTimeToAcknowledgeKpiQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.AverageOperatorTimeToAcknowledgeKpiQuery,
    Types.AverageOperatorTimeToAcknowledgeKpiQueryVariables
  >(AverageOperatorTimeToAcknowledgeKpiDocument, options)
}
export function useAverageOperatorTimeToAcknowledgeKpiLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.AverageOperatorTimeToAcknowledgeKpiQuery,
    Types.AverageOperatorTimeToAcknowledgeKpiQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.AverageOperatorTimeToAcknowledgeKpiQuery,
    Types.AverageOperatorTimeToAcknowledgeKpiQueryVariables
  >(AverageOperatorTimeToAcknowledgeKpiDocument, options)
}
export type AverageOperatorTimeToAcknowledgeKpiQueryHookResult = ReturnType<
  typeof useAverageOperatorTimeToAcknowledgeKpiQuery
>
export type AverageOperatorTimeToAcknowledgeKpiLazyQueryHookResult = ReturnType<
  typeof useAverageOperatorTimeToAcknowledgeKpiLazyQuery
>
export type AverageOperatorTimeToAcknowledgeKpiQueryResult = Apollo.QueryResult<
  Types.AverageOperatorTimeToAcknowledgeKpiQuery,
  Types.AverageOperatorTimeToAcknowledgeKpiQueryVariables
>
export const AverageOperatorTimeToResolveKpiDocument = gql`
  query AverageOperatorTimeToResolveKPI($owners: [ID!], $hours: Int!) {
    kpi {
      averageOperatorTimeToResolve(owners: $owners, hours: $hours) {
        current
        past
      }
    }
  }
`

/**
 * __useAverageOperatorTimeToResolveKpiQuery__
 *
 * To run a query within a React component, call `useAverageOperatorTimeToResolveKpiQuery` and pass it any options that fit your needs.
 * When your component renders, `useAverageOperatorTimeToResolveKpiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAverageOperatorTimeToResolveKpiQuery({
 *   variables: {
 *      owners: // value for 'owners'
 *      hours: // value for 'hours'
 *   },
 * });
 */
export function useAverageOperatorTimeToResolveKpiQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.AverageOperatorTimeToResolveKpiQuery,
    Types.AverageOperatorTimeToResolveKpiQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.AverageOperatorTimeToResolveKpiQuery,
    Types.AverageOperatorTimeToResolveKpiQueryVariables
  >(AverageOperatorTimeToResolveKpiDocument, options)
}
export function useAverageOperatorTimeToResolveKpiLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.AverageOperatorTimeToResolveKpiQuery,
    Types.AverageOperatorTimeToResolveKpiQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.AverageOperatorTimeToResolveKpiQuery,
    Types.AverageOperatorTimeToResolveKpiQueryVariables
  >(AverageOperatorTimeToResolveKpiDocument, options)
}
export type AverageOperatorTimeToResolveKpiQueryHookResult = ReturnType<
  typeof useAverageOperatorTimeToResolveKpiQuery
>
export type AverageOperatorTimeToResolveKpiLazyQueryHookResult = ReturnType<
  typeof useAverageOperatorTimeToResolveKpiLazyQuery
>
export type AverageOperatorTimeToResolveKpiQueryResult = Apollo.QueryResult<
  Types.AverageOperatorTimeToResolveKpiQuery,
  Types.AverageOperatorTimeToResolveKpiQueryVariables
>
export const EnrolledDevicesKpiDocument = gql`
  query EnrolledDevicesKPI($deviceType: DeviceType, $hours: Int!) {
    kpi {
      totalEnrolledDevices(type: $deviceType, hours: $hours)
    }
  }
`

/**
 * __useEnrolledDevicesKpiQuery__
 *
 * To run a query within a React component, call `useEnrolledDevicesKpiQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrolledDevicesKpiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrolledDevicesKpiQuery({
 *   variables: {
 *      deviceType: // value for 'deviceType'
 *      hours: // value for 'hours'
 *   },
 * });
 */
export function useEnrolledDevicesKpiQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.EnrolledDevicesKpiQuery,
    Types.EnrolledDevicesKpiQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.EnrolledDevicesKpiQuery,
    Types.EnrolledDevicesKpiQueryVariables
  >(EnrolledDevicesKpiDocument, options)
}
export function useEnrolledDevicesKpiLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.EnrolledDevicesKpiQuery,
    Types.EnrolledDevicesKpiQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.EnrolledDevicesKpiQuery,
    Types.EnrolledDevicesKpiQueryVariables
  >(EnrolledDevicesKpiDocument, options)
}
export type EnrolledDevicesKpiQueryHookResult = ReturnType<
  typeof useEnrolledDevicesKpiQuery
>
export type EnrolledDevicesKpiLazyQueryHookResult = ReturnType<
  typeof useEnrolledDevicesKpiLazyQuery
>
export type EnrolledDevicesKpiQueryResult = Apollo.QueryResult<
  Types.EnrolledDevicesKpiQuery,
  Types.EnrolledDevicesKpiQueryVariables
>
export const IncidentResolutionRateKpiDocument = gql`
  query IncidentResolutionRateKPI($hours: Int!) {
    kpi {
      incidentResolutionRate(hours: $hours)
    }
  }
`

/**
 * __useIncidentResolutionRateKpiQuery__
 *
 * To run a query within a React component, call `useIncidentResolutionRateKpiQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncidentResolutionRateKpiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncidentResolutionRateKpiQuery({
 *   variables: {
 *      hours: // value for 'hours'
 *   },
 * });
 */
export function useIncidentResolutionRateKpiQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.IncidentResolutionRateKpiQuery,
    Types.IncidentResolutionRateKpiQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.IncidentResolutionRateKpiQuery,
    Types.IncidentResolutionRateKpiQueryVariables
  >(IncidentResolutionRateKpiDocument, options)
}
export function useIncidentResolutionRateKpiLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.IncidentResolutionRateKpiQuery,
    Types.IncidentResolutionRateKpiQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.IncidentResolutionRateKpiQuery,
    Types.IncidentResolutionRateKpiQueryVariables
  >(IncidentResolutionRateKpiDocument, options)
}
export type IncidentResolutionRateKpiQueryHookResult = ReturnType<
  typeof useIncidentResolutionRateKpiQuery
>
export type IncidentResolutionRateKpiLazyQueryHookResult = ReturnType<
  typeof useIncidentResolutionRateKpiLazyQuery
>
export type IncidentResolutionRateKpiQueryResult = Apollo.QueryResult<
  Types.IncidentResolutionRateKpiQuery,
  Types.IncidentResolutionRateKpiQueryVariables
>
export const MedianOperatorTimeToAcknowledgeDocument = gql`
  query MedianOperatorTimeToAcknowledge($owners: [ID!], $hours: Int!) {
    kpi {
      medianOperatorTimeToAcknowledge(owners: $owners, hours: $hours) {
        current
        past
      }
    }
  }
`

/**
 * __useMedianOperatorTimeToAcknowledgeQuery__
 *
 * To run a query within a React component, call `useMedianOperatorTimeToAcknowledgeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedianOperatorTimeToAcknowledgeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedianOperatorTimeToAcknowledgeQuery({
 *   variables: {
 *      owners: // value for 'owners'
 *      hours: // value for 'hours'
 *   },
 * });
 */
export function useMedianOperatorTimeToAcknowledgeQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.MedianOperatorTimeToAcknowledgeQuery,
    Types.MedianOperatorTimeToAcknowledgeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.MedianOperatorTimeToAcknowledgeQuery,
    Types.MedianOperatorTimeToAcknowledgeQueryVariables
  >(MedianOperatorTimeToAcknowledgeDocument, options)
}
export function useMedianOperatorTimeToAcknowledgeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.MedianOperatorTimeToAcknowledgeQuery,
    Types.MedianOperatorTimeToAcknowledgeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.MedianOperatorTimeToAcknowledgeQuery,
    Types.MedianOperatorTimeToAcknowledgeQueryVariables
  >(MedianOperatorTimeToAcknowledgeDocument, options)
}
export type MedianOperatorTimeToAcknowledgeQueryHookResult = ReturnType<
  typeof useMedianOperatorTimeToAcknowledgeQuery
>
export type MedianOperatorTimeToAcknowledgeLazyQueryHookResult = ReturnType<
  typeof useMedianOperatorTimeToAcknowledgeLazyQuery
>
export type MedianOperatorTimeToAcknowledgeQueryResult = Apollo.QueryResult<
  Types.MedianOperatorTimeToAcknowledgeQuery,
  Types.MedianOperatorTimeToAcknowledgeQueryVariables
>
export const MedianOperatorTimeToResolveDocument = gql`
  query MedianOperatorTimeToResolve($owners: [ID!], $hours: Int!) {
    kpi {
      medianOperatorTimeToResolve(owners: $owners, hours: $hours) {
        current
        past
      }
    }
  }
`

/**
 * __useMedianOperatorTimeToResolveQuery__
 *
 * To run a query within a React component, call `useMedianOperatorTimeToResolveQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedianOperatorTimeToResolveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedianOperatorTimeToResolveQuery({
 *   variables: {
 *      owners: // value for 'owners'
 *      hours: // value for 'hours'
 *   },
 * });
 */
export function useMedianOperatorTimeToResolveQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.MedianOperatorTimeToResolveQuery,
    Types.MedianOperatorTimeToResolveQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.MedianOperatorTimeToResolveQuery,
    Types.MedianOperatorTimeToResolveQueryVariables
  >(MedianOperatorTimeToResolveDocument, options)
}
export function useMedianOperatorTimeToResolveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.MedianOperatorTimeToResolveQuery,
    Types.MedianOperatorTimeToResolveQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.MedianOperatorTimeToResolveQuery,
    Types.MedianOperatorTimeToResolveQueryVariables
  >(MedianOperatorTimeToResolveDocument, options)
}
export type MedianOperatorTimeToResolveQueryHookResult = ReturnType<
  typeof useMedianOperatorTimeToResolveQuery
>
export type MedianOperatorTimeToResolveLazyQueryHookResult = ReturnType<
  typeof useMedianOperatorTimeToResolveLazyQuery
>
export type MedianOperatorTimeToResolveQueryResult = Apollo.QueryResult<
  Types.MedianOperatorTimeToResolveQuery,
  Types.MedianOperatorTimeToResolveQueryVariables
>
export const OutstandingIncidentsDocument = gql`
  query OutstandingIncidents(
    $pastHour: String!
    $first: Int
    $last: Int
    $facilityIdIn: [ID!]
    $typeIn: [IncidentType!]
    $guardIdIn: [ID!]
    $ownerIdIn: [ID!]
    $deviceTypesContain: DeviceType
    $orderBy: [IncidentOrderInput]
  ) {
    outstandingIncidents(
      filter: {
        statusNotIn: RESOLVED
        actionTypeIn: [ACKNOWLEDGEMENT_BY_GUARD, ACKNOWLEDGEMENT_BY_OPERATOR]
        actionUpdatedAtLte: $pastHour
        facilityIdIn: $facilityIdIn
        typeIn: $typeIn
        guardIdIn: $guardIdIn
        ownerIdIn: $ownerIdIn
        deviceTypesContain: $deviceTypesContain
      }
      first: $first
      last: $last
      orderBy: $orderBy
    ) {
      totalCount
      edges {
        node {
          id
          createdAt
          updatedAt
          name
          status
          real
          description
          type
          createdAt
          displayId
          guard {
            id
            firstName
            lastName
            avatar {
              ... on UrlContent {
                minExpiryTime
                url
              }
            }
          }
          owner {
            id
            firstName
            lastName
            avatar {
              ... on UrlContent {
                minExpiryTime
                url
              }
            }
          }
          devices {
            id
            name
            type
            facility {
              id
              name
              shortName
            }
            floor {
              id
              name
            }
          }
          facility {
            id
            name
            shortName
          }
          floor {
            id
            name
          }
          actions {
            type
            createdAt
          }
        }
      }
    }
  }
`

/**
 * __useOutstandingIncidentsQuery__
 *
 * To run a query within a React component, call `useOutstandingIncidentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutstandingIncidentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutstandingIncidentsQuery({
 *   variables: {
 *      pastHour: // value for 'pastHour'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      facilityIdIn: // value for 'facilityIdIn'
 *      typeIn: // value for 'typeIn'
 *      guardIdIn: // value for 'guardIdIn'
 *      ownerIdIn: // value for 'ownerIdIn'
 *      deviceTypesContain: // value for 'deviceTypesContain'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useOutstandingIncidentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.OutstandingIncidentsQuery,
    Types.OutstandingIncidentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.OutstandingIncidentsQuery,
    Types.OutstandingIncidentsQueryVariables
  >(OutstandingIncidentsDocument, options)
}
export function useOutstandingIncidentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.OutstandingIncidentsQuery,
    Types.OutstandingIncidentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.OutstandingIncidentsQuery,
    Types.OutstandingIncidentsQueryVariables
  >(OutstandingIncidentsDocument, options)
}
export type OutstandingIncidentsQueryHookResult = ReturnType<
  typeof useOutstandingIncidentsQuery
>
export type OutstandingIncidentsLazyQueryHookResult = ReturnType<
  typeof useOutstandingIncidentsLazyQuery
>
export type OutstandingIncidentsQueryResult = Apollo.QueryResult<
  Types.OutstandingIncidentsQuery,
  Types.OutstandingIncidentsQueryVariables
>
export const OutstandingIncidentsGuardsDocument = gql`
  query OutstandingIncidentsGuards {
    guards: users(filter: { roleName: "GUARD" }) {
      edges {
        node {
          id
          firstName
          lastName
        }
      }
    }
  }
`

/**
 * __useOutstandingIncidentsGuardsQuery__
 *
 * To run a query within a React component, call `useOutstandingIncidentsGuardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutstandingIncidentsGuardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutstandingIncidentsGuardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOutstandingIncidentsGuardsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.OutstandingIncidentsGuardsQuery,
    Types.OutstandingIncidentsGuardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.OutstandingIncidentsGuardsQuery,
    Types.OutstandingIncidentsGuardsQueryVariables
  >(OutstandingIncidentsGuardsDocument, options)
}
export function useOutstandingIncidentsGuardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.OutstandingIncidentsGuardsQuery,
    Types.OutstandingIncidentsGuardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.OutstandingIncidentsGuardsQuery,
    Types.OutstandingIncidentsGuardsQueryVariables
  >(OutstandingIncidentsGuardsDocument, options)
}
export type OutstandingIncidentsGuardsQueryHookResult = ReturnType<
  typeof useOutstandingIncidentsGuardsQuery
>
export type OutstandingIncidentsGuardsLazyQueryHookResult = ReturnType<
  typeof useOutstandingIncidentsGuardsLazyQuery
>
export type OutstandingIncidentsGuardsQueryResult = Apollo.QueryResult<
  Types.OutstandingIncidentsGuardsQuery,
  Types.OutstandingIncidentsGuardsQueryVariables
>
export const OutstandingIncidentsOperatorsDocument = gql`
  query OutstandingIncidentsOperators {
    operators: users(filter: { roleName: "OPERATOR" }) {
      edges {
        node {
          id
          firstName
          lastName
        }
      }
    }
  }
`

/**
 * __useOutstandingIncidentsOperatorsQuery__
 *
 * To run a query within a React component, call `useOutstandingIncidentsOperatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutstandingIncidentsOperatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutstandingIncidentsOperatorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOutstandingIncidentsOperatorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.OutstandingIncidentsOperatorsQuery,
    Types.OutstandingIncidentsOperatorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.OutstandingIncidentsOperatorsQuery,
    Types.OutstandingIncidentsOperatorsQueryVariables
  >(OutstandingIncidentsOperatorsDocument, options)
}
export function useOutstandingIncidentsOperatorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.OutstandingIncidentsOperatorsQuery,
    Types.OutstandingIncidentsOperatorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.OutstandingIncidentsOperatorsQuery,
    Types.OutstandingIncidentsOperatorsQueryVariables
  >(OutstandingIncidentsOperatorsDocument, options)
}
export type OutstandingIncidentsOperatorsQueryHookResult = ReturnType<
  typeof useOutstandingIncidentsOperatorsQuery
>
export type OutstandingIncidentsOperatorsLazyQueryHookResult = ReturnType<
  typeof useOutstandingIncidentsOperatorsLazyQuery
>
export type OutstandingIncidentsOperatorsQueryResult = Apollo.QueryResult<
  Types.OutstandingIncidentsOperatorsQuery,
  Types.OutstandingIncidentsOperatorsQueryVariables
>
export const OutstandingIncidentsTotalCountDocument = gql`
  query OutstandingIncidentsTotalCount($pastHour: String!) {
    outstandingIncidents(
      filter: {
        statusNotIn: RESOLVED
        actionTypeIn: [ACKNOWLEDGEMENT_BY_GUARD, ACKNOWLEDGEMENT_BY_OPERATOR]
        actionUpdatedAtLte: $pastHour
      }
    ) {
      totalCount
    }
  }
`

/**
 * __useOutstandingIncidentsTotalCountQuery__
 *
 * To run a query within a React component, call `useOutstandingIncidentsTotalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutstandingIncidentsTotalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutstandingIncidentsTotalCountQuery({
 *   variables: {
 *      pastHour: // value for 'pastHour'
 *   },
 * });
 */
export function useOutstandingIncidentsTotalCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.OutstandingIncidentsTotalCountQuery,
    Types.OutstandingIncidentsTotalCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.OutstandingIncidentsTotalCountQuery,
    Types.OutstandingIncidentsTotalCountQueryVariables
  >(OutstandingIncidentsTotalCountDocument, options)
}
export function useOutstandingIncidentsTotalCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.OutstandingIncidentsTotalCountQuery,
    Types.OutstandingIncidentsTotalCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.OutstandingIncidentsTotalCountQuery,
    Types.OutstandingIncidentsTotalCountQueryVariables
  >(OutstandingIncidentsTotalCountDocument, options)
}
export type OutstandingIncidentsTotalCountQueryHookResult = ReturnType<
  typeof useOutstandingIncidentsTotalCountQuery
>
export type OutstandingIncidentsTotalCountLazyQueryHookResult = ReturnType<
  typeof useOutstandingIncidentsTotalCountLazyQuery
>
export type OutstandingIncidentsTotalCountQueryResult = Apollo.QueryResult<
  Types.OutstandingIncidentsTotalCountQuery,
  Types.OutstandingIncidentsTotalCountQueryVariables
>
export const TotalActiveIncidentsKpiDocument = gql`
  query TotalActiveIncidentsKPI($hours: Int!) {
    kpi {
      totalActiveIncidents(hours: $hours) {
        past
        current
      }
    }
  }
`

/**
 * __useTotalActiveIncidentsKpiQuery__
 *
 * To run a query within a React component, call `useTotalActiveIncidentsKpiQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalActiveIncidentsKpiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalActiveIncidentsKpiQuery({
 *   variables: {
 *      hours: // value for 'hours'
 *   },
 * });
 */
export function useTotalActiveIncidentsKpiQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.TotalActiveIncidentsKpiQuery,
    Types.TotalActiveIncidentsKpiQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.TotalActiveIncidentsKpiQuery,
    Types.TotalActiveIncidentsKpiQueryVariables
  >(TotalActiveIncidentsKpiDocument, options)
}
export function useTotalActiveIncidentsKpiLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.TotalActiveIncidentsKpiQuery,
    Types.TotalActiveIncidentsKpiQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.TotalActiveIncidentsKpiQuery,
    Types.TotalActiveIncidentsKpiQueryVariables
  >(TotalActiveIncidentsKpiDocument, options)
}
export type TotalActiveIncidentsKpiQueryHookResult = ReturnType<
  typeof useTotalActiveIncidentsKpiQuery
>
export type TotalActiveIncidentsKpiLazyQueryHookResult = ReturnType<
  typeof useTotalActiveIncidentsKpiLazyQuery
>
export type TotalActiveIncidentsKpiQueryResult = Apollo.QueryResult<
  Types.TotalActiveIncidentsKpiQuery,
  Types.TotalActiveIncidentsKpiQueryVariables
>
export const TotalIncidentsKpiDocument = gql`
  query TotalIncidentsKPI($hours: Int!) {
    kpi {
      totalIncidents(hours: $hours) {
        current
        past
      }
    }
  }
`

/**
 * __useTotalIncidentsKpiQuery__
 *
 * To run a query within a React component, call `useTotalIncidentsKpiQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalIncidentsKpiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalIncidentsKpiQuery({
 *   variables: {
 *      hours: // value for 'hours'
 *   },
 * });
 */
export function useTotalIncidentsKpiQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.TotalIncidentsKpiQuery,
    Types.TotalIncidentsKpiQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.TotalIncidentsKpiQuery,
    Types.TotalIncidentsKpiQueryVariables
  >(TotalIncidentsKpiDocument, options)
}
export function useTotalIncidentsKpiLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.TotalIncidentsKpiQuery,
    Types.TotalIncidentsKpiQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.TotalIncidentsKpiQuery,
    Types.TotalIncidentsKpiQueryVariables
  >(TotalIncidentsKpiDocument, options)
}
export type TotalIncidentsKpiQueryHookResult = ReturnType<
  typeof useTotalIncidentsKpiQuery
>
export type TotalIncidentsKpiLazyQueryHookResult = ReturnType<
  typeof useTotalIncidentsKpiLazyQuery
>
export type TotalIncidentsKpiQueryResult = Apollo.QueryResult<
  Types.TotalIncidentsKpiQuery,
  Types.TotalIncidentsKpiQueryVariables
>
export const TotalIncidentsCountByFacilityDocument = gql`
  query TotalIncidentsCountByFacility($hours: Int!) {
    kpi {
      countIncidentsByFacility(hours: $hours) {
        count
        label
      }
    }
  }
`

/**
 * __useTotalIncidentsCountByFacilityQuery__
 *
 * To run a query within a React component, call `useTotalIncidentsCountByFacilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalIncidentsCountByFacilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalIncidentsCountByFacilityQuery({
 *   variables: {
 *      hours: // value for 'hours'
 *   },
 * });
 */
export function useTotalIncidentsCountByFacilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.TotalIncidentsCountByFacilityQuery,
    Types.TotalIncidentsCountByFacilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.TotalIncidentsCountByFacilityQuery,
    Types.TotalIncidentsCountByFacilityQueryVariables
  >(TotalIncidentsCountByFacilityDocument, options)
}
export function useTotalIncidentsCountByFacilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.TotalIncidentsCountByFacilityQuery,
    Types.TotalIncidentsCountByFacilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.TotalIncidentsCountByFacilityQuery,
    Types.TotalIncidentsCountByFacilityQueryVariables
  >(TotalIncidentsCountByFacilityDocument, options)
}
export type TotalIncidentsCountByFacilityQueryHookResult = ReturnType<
  typeof useTotalIncidentsCountByFacilityQuery
>
export type TotalIncidentsCountByFacilityLazyQueryHookResult = ReturnType<
  typeof useTotalIncidentsCountByFacilityLazyQuery
>
export type TotalIncidentsCountByFacilityQueryResult = Apollo.QueryResult<
  Types.TotalIncidentsCountByFacilityQuery,
  Types.TotalIncidentsCountByFacilityQueryVariables
>
export const DeviceDocument = gql`
  query Device($id: ID!) {
    device(id: $id) {
      id
      name
      status
      type
      modelName
      manufacturerName
      macAddress
      floor {
        id
        name
      }
      incidents {
        edges {
          node {
            id
            createdAt
            status
            name
            description
            priority
          }
        }
      }
      facility {
        id
        name
        shortName
        longitude
        latitude
        address
      }
      thumbnail {
        ... on UrlContent {
          url
        }
      }
      tailgateConns {
        edges {
          node {
            id
            cameras {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useDeviceQuery__
 *
 * To run a query within a React component, call `useDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeviceQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.DeviceQuery,
    Types.DeviceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.DeviceQuery, Types.DeviceQueryVariables>(
    DeviceDocument,
    options
  )
}
export function useDeviceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.DeviceQuery,
    Types.DeviceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.DeviceQuery, Types.DeviceQueryVariables>(
    DeviceDocument,
    options
  )
}
export type DeviceQueryHookResult = ReturnType<typeof useDeviceQuery>
export type DeviceLazyQueryHookResult = ReturnType<typeof useDeviceLazyQuery>
export type DeviceQueryResult = Apollo.QueryResult<
  Types.DeviceQuery,
  Types.DeviceQueryVariables
>
export const DeviceCameraEditDocument = gql`
  query DeviceCameraEdit($id: ID!) {
    device(id: $id) {
      id
      type
      name
      macAddress
      modelName
      manufacturerName
      x
      y
      floor {
        id
        name
      }
      camera {
        id
        externalId
        rtspIp
        rtspPort
        rtspPath
        rtspUsername
        rtspPassword
        streamingProtocol
        streamingType
        externalAccount {
          id
          accountId
        }
      }
    }
  }
`

/**
 * __useDeviceCameraEditQuery__
 *
 * To run a query within a React component, call `useDeviceCameraEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceCameraEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceCameraEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeviceCameraEditQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.DeviceCameraEditQuery,
    Types.DeviceCameraEditQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.DeviceCameraEditQuery,
    Types.DeviceCameraEditQueryVariables
  >(DeviceCameraEditDocument, options)
}
export function useDeviceCameraEditLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.DeviceCameraEditQuery,
    Types.DeviceCameraEditQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.DeviceCameraEditQuery,
    Types.DeviceCameraEditQueryVariables
  >(DeviceCameraEditDocument, options)
}
export type DeviceCameraEditQueryHookResult = ReturnType<
  typeof useDeviceCameraEditQuery
>
export type DeviceCameraEditLazyQueryHookResult = ReturnType<
  typeof useDeviceCameraEditLazyQuery
>
export type DeviceCameraEditQueryResult = Apollo.QueryResult<
  Types.DeviceCameraEditQuery,
  Types.DeviceCameraEditQueryVariables
>
export const DeviceEditDocument = gql`
  query DeviceEdit($id: ID!) {
    device(id: $id) {
      id
      type
      name
      macAddress
      modelName
      manufacturerName
      x
      y
      floor {
        id
        name
      }
      door {
        id
        externalId
      }
      camera {
        id
        externalId
      }
    }
  }
`

/**
 * __useDeviceEditQuery__
 *
 * To run a query within a React component, call `useDeviceEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeviceEditQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.DeviceEditQuery,
    Types.DeviceEditQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.DeviceEditQuery, Types.DeviceEditQueryVariables>(
    DeviceEditDocument,
    options
  )
}
export function useDeviceEditLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.DeviceEditQuery,
    Types.DeviceEditQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.DeviceEditQuery,
    Types.DeviceEditQueryVariables
  >(DeviceEditDocument, options)
}
export type DeviceEditQueryHookResult = ReturnType<typeof useDeviceEditQuery>
export type DeviceEditLazyQueryHookResult = ReturnType<
  typeof useDeviceEditLazyQuery
>
export type DeviceEditQueryResult = Apollo.QueryResult<
  Types.DeviceEditQuery,
  Types.DeviceEditQueryVariables
>
export const DeviceEventsDocument = gql`
  query DeviceEvents($id: ID!) {
    deviceEvents: events(filter: { deviceIdsContain: $id }) {
      edges {
        node {
          id
          createdAt
          updatedAt
          type
        }
      }
    }
  }
`

/**
 * __useDeviceEventsQuery__
 *
 * To run a query within a React component, call `useDeviceEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceEventsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeviceEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.DeviceEventsQuery,
    Types.DeviceEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.DeviceEventsQuery,
    Types.DeviceEventsQueryVariables
  >(DeviceEventsDocument, options)
}
export function useDeviceEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.DeviceEventsQuery,
    Types.DeviceEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.DeviceEventsQuery,
    Types.DeviceEventsQueryVariables
  >(DeviceEventsDocument, options)
}
export type DeviceEventsQueryHookResult = ReturnType<
  typeof useDeviceEventsQuery
>
export type DeviceEventsLazyQueryHookResult = ReturnType<
  typeof useDeviceEventsLazyQuery
>
export type DeviceEventsQueryResult = Apollo.QueryResult<
  Types.DeviceEventsQuery,
  Types.DeviceEventsQueryVariables
>
export const DeviceFeedDetailsDocument = gql`
  query DeviceFeedDetails($id: ID!) {
    device(id: $id) {
      id
      type
      tailgateConns {
        edges {
          node {
            id
            cameras {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useDeviceFeedDetailsQuery__
 *
 * To run a query within a React component, call `useDeviceFeedDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceFeedDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceFeedDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeviceFeedDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.DeviceFeedDetailsQuery,
    Types.DeviceFeedDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.DeviceFeedDetailsQuery,
    Types.DeviceFeedDetailsQueryVariables
  >(DeviceFeedDetailsDocument, options)
}
export function useDeviceFeedDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.DeviceFeedDetailsQuery,
    Types.DeviceFeedDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.DeviceFeedDetailsQuery,
    Types.DeviceFeedDetailsQueryVariables
  >(DeviceFeedDetailsDocument, options)
}
export type DeviceFeedDetailsQueryHookResult = ReturnType<
  typeof useDeviceFeedDetailsQuery
>
export type DeviceFeedDetailsLazyQueryHookResult = ReturnType<
  typeof useDeviceFeedDetailsLazyQuery
>
export type DeviceFeedDetailsQueryResult = Apollo.QueryResult<
  Types.DeviceFeedDetailsQuery,
  Types.DeviceFeedDetailsQueryVariables
>
export const DevicePopoverDetailsDocument = gql`
  query DevicePopoverDetails($id: ID!) {
    device(id: $id) {
      id
      status
      floor {
        id
        name
      }
      facility {
        id
        address
      }
    }
  }
`

/**
 * __useDevicePopoverDetailsQuery__
 *
 * To run a query within a React component, call `useDevicePopoverDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevicePopoverDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevicePopoverDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDevicePopoverDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.DevicePopoverDetailsQuery,
    Types.DevicePopoverDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.DevicePopoverDetailsQuery,
    Types.DevicePopoverDetailsQueryVariables
  >(DevicePopoverDetailsDocument, options)
}
export function useDevicePopoverDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.DevicePopoverDetailsQuery,
    Types.DevicePopoverDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.DevicePopoverDetailsQuery,
    Types.DevicePopoverDetailsQueryVariables
  >(DevicePopoverDetailsDocument, options)
}
export type DevicePopoverDetailsQueryHookResult = ReturnType<
  typeof useDevicePopoverDetailsQuery
>
export type DevicePopoverDetailsLazyQueryHookResult = ReturnType<
  typeof useDevicePopoverDetailsLazyQuery
>
export type DevicePopoverDetailsQueryResult = Apollo.QueryResult<
  Types.DevicePopoverDetailsQuery,
  Types.DevicePopoverDetailsQueryVariables
>
export const DevicesDocument = gql`
  query Devices($filter: DeviceFilterInput) {
    devices(filter: $filter) {
      totalCount
      edges {
        node {
          id
          name
          status
          type
          incidents(filter: { statusIn: [IN_PROGRESS, ACTIVE] }) {
            totalCount
          }
          floor {
            id
            name
          }
          facility {
            id
            name
            shortName
          }
        }
      }
    }
  }
`

/**
 * __useDevicesQuery__
 *
 * To run a query within a React component, call `useDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevicesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDevicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.DevicesQuery,
    Types.DevicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.DevicesQuery, Types.DevicesQueryVariables>(
    DevicesDocument,
    options
  )
}
export function useDevicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.DevicesQuery,
    Types.DevicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.DevicesQuery, Types.DevicesQueryVariables>(
    DevicesDocument,
    options
  )
}
export type DevicesQueryHookResult = ReturnType<typeof useDevicesQuery>
export type DevicesLazyQueryHookResult = ReturnType<typeof useDevicesLazyQuery>
export type DevicesQueryResult = Apollo.QueryResult<
  Types.DevicesQuery,
  Types.DevicesQueryVariables
>
export const DevicesDoorDocument = gql`
  query DevicesDoor($filter: DeviceFilterInput) {
    devices(filter: $filter) {
      edges {
        node {
          id
          name
          facility {
            id
            name
          }
        }
      }
    }
  }
`

/**
 * __useDevicesDoorQuery__
 *
 * To run a query within a React component, call `useDevicesDoorQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevicesDoorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevicesDoorQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDevicesDoorQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.DevicesDoorQuery,
    Types.DevicesDoorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.DevicesDoorQuery,
    Types.DevicesDoorQueryVariables
  >(DevicesDoorDocument, options)
}
export function useDevicesDoorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.DevicesDoorQuery,
    Types.DevicesDoorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.DevicesDoorQuery,
    Types.DevicesDoorQueryVariables
  >(DevicesDoorDocument, options)
}
export type DevicesDoorQueryHookResult = ReturnType<typeof useDevicesDoorQuery>
export type DevicesDoorLazyQueryHookResult = ReturnType<
  typeof useDevicesDoorLazyQuery
>
export type DevicesDoorQueryResult = Apollo.QueryResult<
  Types.DevicesDoorQuery,
  Types.DevicesDoorQueryVariables
>
export const DevicesUnpairedDocument = gql`
  query DevicesUnpaired {
    devices(filter: { typeNot: CHECKPOINT, hasEdgeDevice: false }) {
      edges {
        node {
          id
          name
          facility {
            id
            name
            shortName
          }
          floor {
            id
            name
          }
        }
      }
    }
  }
`

/**
 * __useDevicesUnpairedQuery__
 *
 * To run a query within a React component, call `useDevicesUnpairedQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevicesUnpairedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevicesUnpairedQuery({
 *   variables: {
 *   },
 * });
 */
export function useDevicesUnpairedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.DevicesUnpairedQuery,
    Types.DevicesUnpairedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.DevicesUnpairedQuery,
    Types.DevicesUnpairedQueryVariables
  >(DevicesUnpairedDocument, options)
}
export function useDevicesUnpairedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.DevicesUnpairedQuery,
    Types.DevicesUnpairedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.DevicesUnpairedQuery,
    Types.DevicesUnpairedQueryVariables
  >(DevicesUnpairedDocument, options)
}
export type DevicesUnpairedQueryHookResult = ReturnType<
  typeof useDevicesUnpairedQuery
>
export type DevicesUnpairedLazyQueryHookResult = ReturnType<
  typeof useDevicesUnpairedLazyQuery
>
export type DevicesUnpairedQueryResult = Apollo.QueryResult<
  Types.DevicesUnpairedQuery,
  Types.DevicesUnpairedQueryVariables
>
export const EdgeDeviceDocument = gql`
  query EdgeDevice($id: ID!) {
    edgeDevice(id: $id) {
      id
      name
      devices {
        edges {
          node {
            id
            name
            archived
            facility {
              id
              name
            }
            floor {
              id
              name
            }
          }
        }
      }
    }
  }
`

/**
 * __useEdgeDeviceQuery__
 *
 * To run a query within a React component, call `useEdgeDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useEdgeDeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEdgeDeviceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEdgeDeviceQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.EdgeDeviceQuery,
    Types.EdgeDeviceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.EdgeDeviceQuery, Types.EdgeDeviceQueryVariables>(
    EdgeDeviceDocument,
    options
  )
}
export function useEdgeDeviceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.EdgeDeviceQuery,
    Types.EdgeDeviceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.EdgeDeviceQuery,
    Types.EdgeDeviceQueryVariables
  >(EdgeDeviceDocument, options)
}
export type EdgeDeviceQueryHookResult = ReturnType<typeof useEdgeDeviceQuery>
export type EdgeDeviceLazyQueryHookResult = ReturnType<
  typeof useEdgeDeviceLazyQuery
>
export type EdgeDeviceQueryResult = Apollo.QueryResult<
  Types.EdgeDeviceQuery,
  Types.EdgeDeviceQueryVariables
>
export const EmergencyCallSetupDocument = gql`
  query EmergencyCallSetup($incidentId: ID!) {
    emergencyCallingAccessToken {
      token
    }
    incident(id: $incidentId) {
      facility {
        emergencyCalling {
          outboundPhoneNumber
        }
      }
    }
  }
`

/**
 * __useEmergencyCallSetupQuery__
 *
 * To run a query within a React component, call `useEmergencyCallSetupQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmergencyCallSetupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmergencyCallSetupQuery({
 *   variables: {
 *      incidentId: // value for 'incidentId'
 *   },
 * });
 */
export function useEmergencyCallSetupQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.EmergencyCallSetupQuery,
    Types.EmergencyCallSetupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.EmergencyCallSetupQuery,
    Types.EmergencyCallSetupQueryVariables
  >(EmergencyCallSetupDocument, options)
}
export function useEmergencyCallSetupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.EmergencyCallSetupQuery,
    Types.EmergencyCallSetupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.EmergencyCallSetupQuery,
    Types.EmergencyCallSetupQueryVariables
  >(EmergencyCallSetupDocument, options)
}
export type EmergencyCallSetupQueryHookResult = ReturnType<
  typeof useEmergencyCallSetupQuery
>
export type EmergencyCallSetupLazyQueryHookResult = ReturnType<
  typeof useEmergencyCallSetupLazyQuery
>
export type EmergencyCallSetupQueryResult = Apollo.QueryResult<
  Types.EmergencyCallSetupQuery,
  Types.EmergencyCallSetupQueryVariables
>
export const EmergencyCallsDocument = gql`
  query EmergencyCalls(
    $first: Int
    $last: Int
    $filter: EmergencyCallFilterInput
    $orderBy: [EmergencyCallOrderInput]
  ) {
    emergencyCalls(
      first: $first
      last: $last
      filter: $filter
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        page
        pageSize
      }
      edges {
        node {
          id
          createdAt
          callDuration
          callStartTime
          callEndTime
          incidentId
          incident {
            displayId
            facility {
              id
              name
              archived
            }
          }
          user {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`

/**
 * __useEmergencyCallsQuery__
 *
 * To run a query within a React component, call `useEmergencyCallsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmergencyCallsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmergencyCallsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useEmergencyCallsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.EmergencyCallsQuery,
    Types.EmergencyCallsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.EmergencyCallsQuery,
    Types.EmergencyCallsQueryVariables
  >(EmergencyCallsDocument, options)
}
export function useEmergencyCallsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.EmergencyCallsQuery,
    Types.EmergencyCallsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.EmergencyCallsQuery,
    Types.EmergencyCallsQueryVariables
  >(EmergencyCallsDocument, options)
}
export type EmergencyCallsQueryHookResult = ReturnType<
  typeof useEmergencyCallsQuery
>
export type EmergencyCallsLazyQueryHookResult = ReturnType<
  typeof useEmergencyCallsLazyQuery
>
export type EmergencyCallsQueryResult = Apollo.QueryResult<
  Types.EmergencyCallsQuery,
  Types.EmergencyCallsQueryVariables
>
export const ExternalAccountDocument = gql`
  query ExternalAccount($id: ID!) {
    externalAccount(id: $id) {
      accountId
      accountName
      defaultAccount
      externalSystemType
      id
      apiKeys {
        id
        permissionName
        secretName
      }
    }
  }
`

/**
 * __useExternalAccountQuery__
 *
 * To run a query within a React component, call `useExternalAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExternalAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ExternalAccountQuery,
    Types.ExternalAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.ExternalAccountQuery,
    Types.ExternalAccountQueryVariables
  >(ExternalAccountDocument, options)
}
export function useExternalAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ExternalAccountQuery,
    Types.ExternalAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.ExternalAccountQuery,
    Types.ExternalAccountQueryVariables
  >(ExternalAccountDocument, options)
}
export type ExternalAccountQueryHookResult = ReturnType<
  typeof useExternalAccountQuery
>
export type ExternalAccountLazyQueryHookResult = ReturnType<
  typeof useExternalAccountLazyQuery
>
export type ExternalAccountQueryResult = Apollo.QueryResult<
  Types.ExternalAccountQuery,
  Types.ExternalAccountQueryVariables
>
export const ExternalAccountsDocument = gql`
  query ExternalAccounts {
    externalAccounts {
      edges {
        node {
          accountId
          accountName
          createdAt
          defaultAccount
          externalSystemType
          id
          apiKeys {
            id
            permissionName
            secretName
          }
        }
      }
    }
  }
`

/**
 * __useExternalAccountsQuery__
 *
 * To run a query within a React component, call `useExternalAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useExternalAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.ExternalAccountsQuery,
    Types.ExternalAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.ExternalAccountsQuery,
    Types.ExternalAccountsQueryVariables
  >(ExternalAccountsDocument, options)
}
export function useExternalAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ExternalAccountsQuery,
    Types.ExternalAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.ExternalAccountsQuery,
    Types.ExternalAccountsQueryVariables
  >(ExternalAccountsDocument, options)
}
export type ExternalAccountsQueryHookResult = ReturnType<
  typeof useExternalAccountsQuery
>
export type ExternalAccountsLazyQueryHookResult = ReturnType<
  typeof useExternalAccountsLazyQuery
>
export type ExternalAccountsQueryResult = Apollo.QueryResult<
  Types.ExternalAccountsQuery,
  Types.ExternalAccountsQueryVariables
>
export const FacilitiesDocument = gql`
  query Facilities($filter: FacilityFilterInput) {
    facilities(filter: $filter) {
      totalCount
      edges {
        node {
          id
          type
          name
          address
          defaultFloorId
          shortName
          latitude
          longitude
          guardCount
          devices(filter: { typeIn: [CAMERA, DOOR] }) {
            totalCount
          }
          floors {
            totalCount
            edges {
              node {
                id
              }
            }
          }
          incidents(filter: { statusIn: [IN_PROGRESS, ACTIVE] }) {
            totalCount
          }
          cameras: devices(filter: { type: CAMERA }) {
            totalCount
          }
          doors: devices(filter: { type: DOOR }) {
            totalCount
          }
          healthyDevices: devices(
            filter: { status: HEALTHY, typeIn: [CAMERA, DOOR] }
          ) {
            totalCount
          }
        }
      }
    }
  }
`

/**
 * __useFacilitiesQuery__
 *
 * To run a query within a React component, call `useFacilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacilitiesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFacilitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.FacilitiesQuery,
    Types.FacilitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.FacilitiesQuery, Types.FacilitiesQueryVariables>(
    FacilitiesDocument,
    options
  )
}
export function useFacilitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FacilitiesQuery,
    Types.FacilitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.FacilitiesQuery,
    Types.FacilitiesQueryVariables
  >(FacilitiesDocument, options)
}
export type FacilitiesQueryHookResult = ReturnType<typeof useFacilitiesQuery>
export type FacilitiesLazyQueryHookResult = ReturnType<
  typeof useFacilitiesLazyQuery
>
export type FacilitiesQueryResult = Apollo.QueryResult<
  Types.FacilitiesQuery,
  Types.FacilitiesQueryVariables
>
export const FacilitiesAndUsersDocument = gql`
  query FacilitiesAndUsers {
    facilityNames: facilities {
      edges {
        node {
          id
          name
          shortName
          archived
        }
      }
    }
    users(filter: { roleNameIn: ["OPERATOR", "PROGRAM_MANAGER"] }) {
      edges {
        node {
          id
          firstName
          lastName
          roleName
        }
      }
    }
  }
`

/**
 * __useFacilitiesAndUsersQuery__
 *
 * To run a query within a React component, call `useFacilitiesAndUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilitiesAndUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacilitiesAndUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useFacilitiesAndUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.FacilitiesAndUsersQuery,
    Types.FacilitiesAndUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.FacilitiesAndUsersQuery,
    Types.FacilitiesAndUsersQueryVariables
  >(FacilitiesAndUsersDocument, options)
}
export function useFacilitiesAndUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FacilitiesAndUsersQuery,
    Types.FacilitiesAndUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.FacilitiesAndUsersQuery,
    Types.FacilitiesAndUsersQueryVariables
  >(FacilitiesAndUsersDocument, options)
}
export type FacilitiesAndUsersQueryHookResult = ReturnType<
  typeof useFacilitiesAndUsersQuery
>
export type FacilitiesAndUsersLazyQueryHookResult = ReturnType<
  typeof useFacilitiesAndUsersLazyQuery
>
export type FacilitiesAndUsersQueryResult = Apollo.QueryResult<
  Types.FacilitiesAndUsersQuery,
  Types.FacilitiesAndUsersQueryVariables
>
export const FacilitiesWithFloorsDocument = gql`
  query FacilitiesWithFloors {
    facilities {
      edges {
        node {
          id
          name
          floors {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useFacilitiesWithFloorsQuery__
 *
 * To run a query within a React component, call `useFacilitiesWithFloorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilitiesWithFloorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacilitiesWithFloorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFacilitiesWithFloorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.FacilitiesWithFloorsQuery,
    Types.FacilitiesWithFloorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.FacilitiesWithFloorsQuery,
    Types.FacilitiesWithFloorsQueryVariables
  >(FacilitiesWithFloorsDocument, options)
}
export function useFacilitiesWithFloorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FacilitiesWithFloorsQuery,
    Types.FacilitiesWithFloorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.FacilitiesWithFloorsQuery,
    Types.FacilitiesWithFloorsQueryVariables
  >(FacilitiesWithFloorsDocument, options)
}
export type FacilitiesWithFloorsQueryHookResult = ReturnType<
  typeof useFacilitiesWithFloorsQuery
>
export type FacilitiesWithFloorsLazyQueryHookResult = ReturnType<
  typeof useFacilitiesWithFloorsLazyQuery
>
export type FacilitiesWithFloorsQueryResult = Apollo.QueryResult<
  Types.FacilitiesWithFloorsQuery,
  Types.FacilitiesWithFloorsQueryVariables
>
export const FacilityDocument = gql`
  query Facility($id: ID!) {
    facility(id: $id) {
      id
      address
      latitude
      longitude
      shortName
      name
      timeZone
      type
      defaultFloorId
      emergencyCalling {
        outboundPhoneNumber
      }
      emergencyNumber
      floors {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`

/**
 * __useFacilityQuery__
 *
 * To run a query within a React component, call `useFacilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacilityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFacilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.FacilityQuery,
    Types.FacilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.FacilityQuery, Types.FacilityQueryVariables>(
    FacilityDocument,
    options
  )
}
export function useFacilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FacilityQuery,
    Types.FacilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.FacilityQuery, Types.FacilityQueryVariables>(
    FacilityDocument,
    options
  )
}
export type FacilityQueryHookResult = ReturnType<typeof useFacilityQuery>
export type FacilityLazyQueryHookResult = ReturnType<
  typeof useFacilityLazyQuery
>
export type FacilityQueryResult = Apollo.QueryResult<
  Types.FacilityQuery,
  Types.FacilityQueryVariables
>
export const FacilityACsDocument = gql`
  query FacilityACs($id: ID!) {
    facilityACs: devices(filter: { type: DOOR, facilityId: $id }) {
      edges {
        node {
          id
          name
          incidents {
            totalCount
          }
          status
          floor {
            name
          }
        }
      }
    }
  }
`

/**
 * __useFacilityACsQuery__
 *
 * To run a query within a React component, call `useFacilityACsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilityACsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacilityACsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFacilityACsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.FacilityACsQuery,
    Types.FacilityACsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.FacilityACsQuery,
    Types.FacilityACsQueryVariables
  >(FacilityACsDocument, options)
}
export function useFacilityACsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FacilityACsQuery,
    Types.FacilityACsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.FacilityACsQuery,
    Types.FacilityACsQueryVariables
  >(FacilityACsDocument, options)
}
export type FacilityACsQueryHookResult = ReturnType<typeof useFacilityACsQuery>
export type FacilityACsLazyQueryHookResult = ReturnType<
  typeof useFacilityACsLazyQuery
>
export type FacilityACsQueryResult = Apollo.QueryResult<
  Types.FacilityACsQuery,
  Types.FacilityACsQueryVariables
>
export const FacilityAndDeviceNamesDocument = gql`
  query FacilityAndDeviceNames($filter: FacilityFilterInput) {
    facilityNames: facilities(filter: $filter) {
      edges {
        node {
          id
          name
          shortName
          archived
        }
      }
    }
    devices {
      edges {
        node {
          id
          name
          facility {
            id
            name
          }
          floor {
            id
            name
          }
        }
      }
    }
    manualIncidentCategories {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

/**
 * __useFacilityAndDeviceNamesQuery__
 *
 * To run a query within a React component, call `useFacilityAndDeviceNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilityAndDeviceNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacilityAndDeviceNamesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFacilityAndDeviceNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.FacilityAndDeviceNamesQuery,
    Types.FacilityAndDeviceNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.FacilityAndDeviceNamesQuery,
    Types.FacilityAndDeviceNamesQueryVariables
  >(FacilityAndDeviceNamesDocument, options)
}
export function useFacilityAndDeviceNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FacilityAndDeviceNamesQuery,
    Types.FacilityAndDeviceNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.FacilityAndDeviceNamesQuery,
    Types.FacilityAndDeviceNamesQueryVariables
  >(FacilityAndDeviceNamesDocument, options)
}
export type FacilityAndDeviceNamesQueryHookResult = ReturnType<
  typeof useFacilityAndDeviceNamesQuery
>
export type FacilityAndDeviceNamesLazyQueryHookResult = ReturnType<
  typeof useFacilityAndDeviceNamesLazyQuery
>
export type FacilityAndDeviceNamesQueryResult = Apollo.QueryResult<
  Types.FacilityAndDeviceNamesQuery,
  Types.FacilityAndDeviceNamesQueryVariables
>
export const FacilityDevicesByFloorsDocument = gql`
  query FacilityDevicesByFloors(
    $facilityId: ID!
    $floorId: ID!
    $types: [DeviceType!]
    $floorIds: [ID!]
  ) {
    facility(id: $facilityId) {
      id
      name
      floors(filter: { id: $floorId }) {
        edges {
          node {
            id
            name
            floorPlan {
              id
              image {
                ... on UrlContent {
                  url
                }
              }
            }
          }
        }
      }
      devices(filter: { typeIn: $types, floorIdIn: $floorIds }) {
        edges {
          node {
            id
            name
            createdAt
            macAddress
            type
            x
            y
            status
            fieldOfView
            bearing
            visibilityRange
            floorPlanId
            incidents {
              edges {
                node {
                  status
                }
              }
            }
            camera {
              tailgateGrids {
                camera {
                  id
                }
                door {
                  id
                }
                inBox {
                  topLeft {
                    x
                    y
                  }
                }
              }
            }
            tailgateConns {
              edges {
                node {
                  id
                  door {
                    id
                  }
                  cameras {
                    edges {
                      node {
                        id
                      }
                    }
                  }
                }
              }
            }
            checkpoint {
              id
              displayId
            }
            floor {
              id
              name
            }
          }
        }
      }
    }
  }
`

/**
 * __useFacilityDevicesByFloorsQuery__
 *
 * To run a query within a React component, call `useFacilityDevicesByFloorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilityDevicesByFloorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacilityDevicesByFloorsQuery({
 *   variables: {
 *      facilityId: // value for 'facilityId'
 *      floorId: // value for 'floorId'
 *      types: // value for 'types'
 *      floorIds: // value for 'floorIds'
 *   },
 * });
 */
export function useFacilityDevicesByFloorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.FacilityDevicesByFloorsQuery,
    Types.FacilityDevicesByFloorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.FacilityDevicesByFloorsQuery,
    Types.FacilityDevicesByFloorsQueryVariables
  >(FacilityDevicesByFloorsDocument, options)
}
export function useFacilityDevicesByFloorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FacilityDevicesByFloorsQuery,
    Types.FacilityDevicesByFloorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.FacilityDevicesByFloorsQuery,
    Types.FacilityDevicesByFloorsQueryVariables
  >(FacilityDevicesByFloorsDocument, options)
}
export type FacilityDevicesByFloorsQueryHookResult = ReturnType<
  typeof useFacilityDevicesByFloorsQuery
>
export type FacilityDevicesByFloorsLazyQueryHookResult = ReturnType<
  typeof useFacilityDevicesByFloorsLazyQuery
>
export type FacilityDevicesByFloorsQueryResult = Apollo.QueryResult<
  Types.FacilityDevicesByFloorsQuery,
  Types.FacilityDevicesByFloorsQueryVariables
>
export const FacilityCamerasDocument = gql`
  query FacilityCameras($id: ID!) {
    facilityCameras: devices(filter: { type: CAMERA, facilityId: $id }) {
      edges {
        node {
          id
          name
          incidents {
            totalCount
          }
          status
          floor {
            name
          }
        }
      }
    }
  }
`

/**
 * __useFacilityCamerasQuery__
 *
 * To run a query within a React component, call `useFacilityCamerasQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilityCamerasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacilityCamerasQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFacilityCamerasQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.FacilityCamerasQuery,
    Types.FacilityCamerasQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.FacilityCamerasQuery,
    Types.FacilityCamerasQueryVariables
  >(FacilityCamerasDocument, options)
}
export function useFacilityCamerasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FacilityCamerasQuery,
    Types.FacilityCamerasQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.FacilityCamerasQuery,
    Types.FacilityCamerasQueryVariables
  >(FacilityCamerasDocument, options)
}
export type FacilityCamerasQueryHookResult = ReturnType<
  typeof useFacilityCamerasQuery
>
export type FacilityCamerasLazyQueryHookResult = ReturnType<
  typeof useFacilityCamerasLazyQuery
>
export type FacilityCamerasQueryResult = Apollo.QueryResult<
  Types.FacilityCamerasQuery,
  Types.FacilityCamerasQueryVariables
>
export const FacilityDevicesDocument = gql`
  query FacilityDevices($id: ID!) {
    facilityDevices: devices(
      filter: { facilityId: $id, typeIn: [CAMERA, DOOR] }
    ) {
      edges {
        node {
          id
          name
          incidents {
            totalCount
          }
          type
          status
          floor {
            name
          }
        }
      }
    }
  }
`

/**
 * __useFacilityDevicesQuery__
 *
 * To run a query within a React component, call `useFacilityDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilityDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacilityDevicesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFacilityDevicesQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.FacilityDevicesQuery,
    Types.FacilityDevicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.FacilityDevicesQuery,
    Types.FacilityDevicesQueryVariables
  >(FacilityDevicesDocument, options)
}
export function useFacilityDevicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FacilityDevicesQuery,
    Types.FacilityDevicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.FacilityDevicesQuery,
    Types.FacilityDevicesQueryVariables
  >(FacilityDevicesDocument, options)
}
export type FacilityDevicesQueryHookResult = ReturnType<
  typeof useFacilityDevicesQuery
>
export type FacilityDevicesLazyQueryHookResult = ReturnType<
  typeof useFacilityDevicesLazyQuery
>
export type FacilityDevicesQueryResult = Apollo.QueryResult<
  Types.FacilityDevicesQuery,
  Types.FacilityDevicesQueryVariables
>
export const FacilityFloorsDocument = gql`
  query FacilityFloors($id: ID, $name: String) {
    floors(filter: { facilityId: $id, name: $name }) {
      edges {
        node {
          id
          name
          facility {
            id
            name
          }
          incidents(filter: { statusIn: [IN_PROGRESS, ACTIVE] }) {
            totalCount
          }
          cameras: devices(filter: { type: CAMERA }) {
            totalCount
          }
          doors: devices(filter: { type: DOOR }) {
            totalCount
          }
        }
      }
    }
  }
`

/**
 * __useFacilityFloorsQuery__
 *
 * To run a query within a React component, call `useFacilityFloorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilityFloorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacilityFloorsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useFacilityFloorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.FacilityFloorsQuery,
    Types.FacilityFloorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.FacilityFloorsQuery,
    Types.FacilityFloorsQueryVariables
  >(FacilityFloorsDocument, options)
}
export function useFacilityFloorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FacilityFloorsQuery,
    Types.FacilityFloorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.FacilityFloorsQuery,
    Types.FacilityFloorsQueryVariables
  >(FacilityFloorsDocument, options)
}
export type FacilityFloorsQueryHookResult = ReturnType<
  typeof useFacilityFloorsQuery
>
export type FacilityFloorsLazyQueryHookResult = ReturnType<
  typeof useFacilityFloorsLazyQuery
>
export type FacilityFloorsQueryResult = Apollo.QueryResult<
  Types.FacilityFloorsQuery,
  Types.FacilityFloorsQueryVariables
>
export const FacilityFloorsDrawerDocument = gql`
  query facilityFloorsDrawer($facilityId: ID!) {
    facility(id: $facilityId) {
      id
      name
      address
      defaultFloorId
      floors {
        edges {
          node {
            id
            name
            createdAt
            incidents(filter: { statusIn: [IN_PROGRESS, ACTIVE] }) {
              totalCount
            }
          }
        }
      }
    }
  }
`

/**
 * __useFacilityFloorsDrawerQuery__
 *
 * To run a query within a React component, call `useFacilityFloorsDrawerQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilityFloorsDrawerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacilityFloorsDrawerQuery({
 *   variables: {
 *      facilityId: // value for 'facilityId'
 *   },
 * });
 */
export function useFacilityFloorsDrawerQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.FacilityFloorsDrawerQuery,
    Types.FacilityFloorsDrawerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.FacilityFloorsDrawerQuery,
    Types.FacilityFloorsDrawerQueryVariables
  >(FacilityFloorsDrawerDocument, options)
}
export function useFacilityFloorsDrawerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FacilityFloorsDrawerQuery,
    Types.FacilityFloorsDrawerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.FacilityFloorsDrawerQuery,
    Types.FacilityFloorsDrawerQueryVariables
  >(FacilityFloorsDrawerDocument, options)
}
export type FacilityFloorsDrawerQueryHookResult = ReturnType<
  typeof useFacilityFloorsDrawerQuery
>
export type FacilityFloorsDrawerLazyQueryHookResult = ReturnType<
  typeof useFacilityFloorsDrawerLazyQuery
>
export type FacilityFloorsDrawerQueryResult = Apollo.QueryResult<
  Types.FacilityFloorsDrawerQuery,
  Types.FacilityFloorsDrawerQueryVariables
>
export const FacilityGuardsDocument = gql`
  query FacilityGuards($facilityId: ID!) {
    checkedInUsers(facilityId: $facilityId) {
      edges {
        node {
          id
          firstName
          lastName
          avatar {
            ... on UrlContent {
              url
            }
          }
        }
      }
    }
  }
`

/**
 * __useFacilityGuardsQuery__
 *
 * To run a query within a React component, call `useFacilityGuardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilityGuardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacilityGuardsQuery({
 *   variables: {
 *      facilityId: // value for 'facilityId'
 *   },
 * });
 */
export function useFacilityGuardsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.FacilityGuardsQuery,
    Types.FacilityGuardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.FacilityGuardsQuery,
    Types.FacilityGuardsQueryVariables
  >(FacilityGuardsDocument, options)
}
export function useFacilityGuardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FacilityGuardsQuery,
    Types.FacilityGuardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.FacilityGuardsQuery,
    Types.FacilityGuardsQueryVariables
  >(FacilityGuardsDocument, options)
}
export type FacilityGuardsQueryHookResult = ReturnType<
  typeof useFacilityGuardsQuery
>
export type FacilityGuardsLazyQueryHookResult = ReturnType<
  typeof useFacilityGuardsLazyQuery
>
export type FacilityGuardsQueryResult = Apollo.QueryResult<
  Types.FacilityGuardsQuery,
  Types.FacilityGuardsQueryVariables
>
export const FacilityIncidentsDocument = gql`
  query FacilityIncidents($id: ID!) {
    facilityIncidents: incidents(
      filter: { facilityId: $id, statusIn: [IN_PROGRESS, ACTIVE] }
      orderBy: [{ direction: DESC, field: CREATED_AT }]
    ) {
      edges {
        node {
          id
          name
          type
          description
          floor {
            name
          }
          createdAt
          devices {
            id
            name
            type
          }
        }
      }
    }
  }
`

/**
 * __useFacilityIncidentsQuery__
 *
 * To run a query within a React component, call `useFacilityIncidentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilityIncidentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacilityIncidentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFacilityIncidentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.FacilityIncidentsQuery,
    Types.FacilityIncidentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.FacilityIncidentsQuery,
    Types.FacilityIncidentsQueryVariables
  >(FacilityIncidentsDocument, options)
}
export function useFacilityIncidentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FacilityIncidentsQuery,
    Types.FacilityIncidentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.FacilityIncidentsQuery,
    Types.FacilityIncidentsQueryVariables
  >(FacilityIncidentsDocument, options)
}
export type FacilityIncidentsQueryHookResult = ReturnType<
  typeof useFacilityIncidentsQuery
>
export type FacilityIncidentsLazyQueryHookResult = ReturnType<
  typeof useFacilityIncidentsLazyQuery
>
export type FacilityIncidentsQueryResult = Apollo.QueryResult<
  Types.FacilityIncidentsQuery,
  Types.FacilityIncidentsQueryVariables
>
export const FacilityNamesDocument = gql`
  query FacilityNames($filter: FacilityFilterInput) {
    facilityNames: facilities(filter: $filter) {
      edges {
        node {
          id
          name
          shortName
          archived
        }
      }
    }
  }
`

/**
 * __useFacilityNamesQuery__
 *
 * To run a query within a React component, call `useFacilityNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilityNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacilityNamesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFacilityNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.FacilityNamesQuery,
    Types.FacilityNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.FacilityNamesQuery,
    Types.FacilityNamesQueryVariables
  >(FacilityNamesDocument, options)
}
export function useFacilityNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FacilityNamesQuery,
    Types.FacilityNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.FacilityNamesQuery,
    Types.FacilityNamesQueryVariables
  >(FacilityNamesDocument, options)
}
export type FacilityNamesQueryHookResult = ReturnType<
  typeof useFacilityNamesQuery
>
export type FacilityNamesLazyQueryHookResult = ReturnType<
  typeof useFacilityNamesLazyQuery
>
export type FacilityNamesQueryResult = Apollo.QueryResult<
  Types.FacilityNamesQuery,
  Types.FacilityNamesQueryVariables
>
export const FacilityNormalTypeNamesDocument = gql`
  query FacilityNormalTypeNames {
    facilityNames: facilities(filter: { type: NORMAL }) {
      edges {
        node {
          id
          name
          shortName
        }
      }
    }
  }
`

/**
 * __useFacilityNormalTypeNamesQuery__
 *
 * To run a query within a React component, call `useFacilityNormalTypeNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilityNormalTypeNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacilityNormalTypeNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFacilityNormalTypeNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.FacilityNormalTypeNamesQuery,
    Types.FacilityNormalTypeNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.FacilityNormalTypeNamesQuery,
    Types.FacilityNormalTypeNamesQueryVariables
  >(FacilityNormalTypeNamesDocument, options)
}
export function useFacilityNormalTypeNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FacilityNormalTypeNamesQuery,
    Types.FacilityNormalTypeNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.FacilityNormalTypeNamesQuery,
    Types.FacilityNormalTypeNamesQueryVariables
  >(FacilityNormalTypeNamesDocument, options)
}
export type FacilityNormalTypeNamesQueryHookResult = ReturnType<
  typeof useFacilityNormalTypeNamesQuery
>
export type FacilityNormalTypeNamesLazyQueryHookResult = ReturnType<
  typeof useFacilityNormalTypeNamesLazyQuery
>
export type FacilityNormalTypeNamesQueryResult = Apollo.QueryResult<
  Types.FacilityNormalTypeNamesQuery,
  Types.FacilityNormalTypeNamesQueryVariables
>
export const FacilityTourPlanCheckPointsDocument = gql`
  query facilityTourPlanCheckPoints($id: ID!) {
    tourPlan(id: $id) {
      id
      name
      checkpoints {
        edges {
          node {
            id
            displayId
            device {
              id
              type
              name
              x
              y
              createdAt
              floor {
                id
                name
              }
              facility {
                id
                name
              }
              checkpoint {
                id
                displayId
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useFacilityTourPlanCheckPointsQuery__
 *
 * To run a query within a React component, call `useFacilityTourPlanCheckPointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilityTourPlanCheckPointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacilityTourPlanCheckPointsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFacilityTourPlanCheckPointsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.FacilityTourPlanCheckPointsQuery,
    Types.FacilityTourPlanCheckPointsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.FacilityTourPlanCheckPointsQuery,
    Types.FacilityTourPlanCheckPointsQueryVariables
  >(FacilityTourPlanCheckPointsDocument, options)
}
export function useFacilityTourPlanCheckPointsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FacilityTourPlanCheckPointsQuery,
    Types.FacilityTourPlanCheckPointsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.FacilityTourPlanCheckPointsQuery,
    Types.FacilityTourPlanCheckPointsQueryVariables
  >(FacilityTourPlanCheckPointsDocument, options)
}
export type FacilityTourPlanCheckPointsQueryHookResult = ReturnType<
  typeof useFacilityTourPlanCheckPointsQuery
>
export type FacilityTourPlanCheckPointsLazyQueryHookResult = ReturnType<
  typeof useFacilityTourPlanCheckPointsLazyQuery
>
export type FacilityTourPlanCheckPointsQueryResult = Apollo.QueryResult<
  Types.FacilityTourPlanCheckPointsQuery,
  Types.FacilityTourPlanCheckPointsQueryVariables
>
export const FacilityTourPlansDocument = gql`
  query facilityTourPlans($facilityId: ID) {
    tourPlans(
      filter: { facilityId: $facilityId }
      orderBy: [{ direction: DESC, field: CREATED_AT }]
    ) {
      totalCount
      edges {
        node {
          id
          name
          checkpointIds
        }
      }
    }
  }
`

/**
 * __useFacilityTourPlansQuery__
 *
 * To run a query within a React component, call `useFacilityTourPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilityTourPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacilityTourPlansQuery({
 *   variables: {
 *      facilityId: // value for 'facilityId'
 *   },
 * });
 */
export function useFacilityTourPlansQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.FacilityTourPlansQuery,
    Types.FacilityTourPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.FacilityTourPlansQuery,
    Types.FacilityTourPlansQueryVariables
  >(FacilityTourPlansDocument, options)
}
export function useFacilityTourPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FacilityTourPlansQuery,
    Types.FacilityTourPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.FacilityTourPlansQuery,
    Types.FacilityTourPlansQueryVariables
  >(FacilityTourPlansDocument, options)
}
export type FacilityTourPlansQueryHookResult = ReturnType<
  typeof useFacilityTourPlansQuery
>
export type FacilityTourPlansLazyQueryHookResult = ReturnType<
  typeof useFacilityTourPlansLazyQuery
>
export type FacilityTourPlansQueryResult = Apollo.QueryResult<
  Types.FacilityTourPlansQuery,
  Types.FacilityTourPlansQueryVariables
>
export const FacilityTypesDocument = gql`
  query FacilityTypes($filter: FacilityFilterInput) {
    facilities(filter: $filter) {
      edges {
        node {
          id
          name
          shortName
          type
          address
          latitude
          longitude
          timeZone
        }
      }
    }
  }
`

/**
 * __useFacilityTypesQuery__
 *
 * To run a query within a React component, call `useFacilityTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilityTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacilityTypesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFacilityTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.FacilityTypesQuery,
    Types.FacilityTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.FacilityTypesQuery,
    Types.FacilityTypesQueryVariables
  >(FacilityTypesDocument, options)
}
export function useFacilityTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FacilityTypesQuery,
    Types.FacilityTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.FacilityTypesQuery,
    Types.FacilityTypesQueryVariables
  >(FacilityTypesDocument, options)
}
export type FacilityTypesQueryHookResult = ReturnType<
  typeof useFacilityTypesQuery
>
export type FacilityTypesLazyQueryHookResult = ReturnType<
  typeof useFacilityTypesLazyQuery
>
export type FacilityTypesQueryResult = Apollo.QueryResult<
  Types.FacilityTypesQuery,
  Types.FacilityTypesQueryVariables
>
export const FacilityUnresolvedIncidentsDocument = gql`
  query FacilityUnresolvedIncidents($id: ID!) {
    facility(id: $id) {
      id
      name
      incidents(filter: { statusIn: [IN_PROGRESS, ACTIVE] }) {
        totalCount
      }
    }
  }
`

/**
 * __useFacilityUnresolvedIncidentsQuery__
 *
 * To run a query within a React component, call `useFacilityUnresolvedIncidentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilityUnresolvedIncidentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacilityUnresolvedIncidentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFacilityUnresolvedIncidentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.FacilityUnresolvedIncidentsQuery,
    Types.FacilityUnresolvedIncidentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.FacilityUnresolvedIncidentsQuery,
    Types.FacilityUnresolvedIncidentsQueryVariables
  >(FacilityUnresolvedIncidentsDocument, options)
}
export function useFacilityUnresolvedIncidentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FacilityUnresolvedIncidentsQuery,
    Types.FacilityUnresolvedIncidentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.FacilityUnresolvedIncidentsQuery,
    Types.FacilityUnresolvedIncidentsQueryVariables
  >(FacilityUnresolvedIncidentsDocument, options)
}
export type FacilityUnresolvedIncidentsQueryHookResult = ReturnType<
  typeof useFacilityUnresolvedIncidentsQuery
>
export type FacilityUnresolvedIncidentsLazyQueryHookResult = ReturnType<
  typeof useFacilityUnresolvedIncidentsLazyQuery
>
export type FacilityUnresolvedIncidentsQueryResult = Apollo.QueryResult<
  Types.FacilityUnresolvedIncidentsQuery,
  Types.FacilityUnresolvedIncidentsQueryVariables
>
export const FloorDetailsDocument = gql`
  query FloorDetails($floorId: ID!) {
    floor(id: $floorId) {
      id
      name
      facility {
        id
        name
        shortName
      }
      floorPlan {
        id
        name
      }
    }
  }
`

/**
 * __useFloorDetailsQuery__
 *
 * To run a query within a React component, call `useFloorDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFloorDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFloorDetailsQuery({
 *   variables: {
 *      floorId: // value for 'floorId'
 *   },
 * });
 */
export function useFloorDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.FloorDetailsQuery,
    Types.FloorDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.FloorDetailsQuery,
    Types.FloorDetailsQueryVariables
  >(FloorDetailsDocument, options)
}
export function useFloorDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FloorDetailsQuery,
    Types.FloorDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.FloorDetailsQuery,
    Types.FloorDetailsQueryVariables
  >(FloorDetailsDocument, options)
}
export type FloorDetailsQueryHookResult = ReturnType<
  typeof useFloorDetailsQuery
>
export type FloorDetailsLazyQueryHookResult = ReturnType<
  typeof useFloorDetailsLazyQuery
>
export type FloorDetailsQueryResult = Apollo.QueryResult<
  Types.FloorDetailsQuery,
  Types.FloorDetailsQueryVariables
>
export const FloorDevicesCountDocument = gql`
  query FloorDevicesCount($floorId: ID!) {
    floor(id: $floorId) {
      id
      checkpoints: devices(filter: { typeIn: [CHECKPOINT], isPlaced: true }) {
        totalCount
      }
      devices(filter: { typeIn: [CAMERA, DOOR], isPlaced: true }) {
        totalCount
      }
    }
  }
`

/**
 * __useFloorDevicesCountQuery__
 *
 * To run a query within a React component, call `useFloorDevicesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useFloorDevicesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFloorDevicesCountQuery({
 *   variables: {
 *      floorId: // value for 'floorId'
 *   },
 * });
 */
export function useFloorDevicesCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.FloorDevicesCountQuery,
    Types.FloorDevicesCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.FloorDevicesCountQuery,
    Types.FloorDevicesCountQueryVariables
  >(FloorDevicesCountDocument, options)
}
export function useFloorDevicesCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FloorDevicesCountQuery,
    Types.FloorDevicesCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.FloorDevicesCountQuery,
    Types.FloorDevicesCountQueryVariables
  >(FloorDevicesCountDocument, options)
}
export type FloorDevicesCountQueryHookResult = ReturnType<
  typeof useFloorDevicesCountQuery
>
export type FloorDevicesCountLazyQueryHookResult = ReturnType<
  typeof useFloorDevicesCountLazyQuery
>
export type FloorDevicesCountQueryResult = Apollo.QueryResult<
  Types.FloorDevicesCountQuery,
  Types.FloorDevicesCountQueryVariables
>
export const FloorIncidentsDocument = gql`
  query floorIncidents($floorId: ID!) {
    incidents(filter: { floorId: $floorId, statusIn: [IN_PROGRESS, ACTIVE] }) {
      edges {
        node {
          id
          createdAt
          name
          type
          status
          devices {
            name
            type
          }
        }
      }
    }
  }
`

/**
 * __useFloorIncidentsQuery__
 *
 * To run a query within a React component, call `useFloorIncidentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFloorIncidentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFloorIncidentsQuery({
 *   variables: {
 *      floorId: // value for 'floorId'
 *   },
 * });
 */
export function useFloorIncidentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.FloorIncidentsQuery,
    Types.FloorIncidentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.FloorIncidentsQuery,
    Types.FloorIncidentsQueryVariables
  >(FloorIncidentsDocument, options)
}
export function useFloorIncidentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FloorIncidentsQuery,
    Types.FloorIncidentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.FloorIncidentsQuery,
    Types.FloorIncidentsQueryVariables
  >(FloorIncidentsDocument, options)
}
export type FloorIncidentsQueryHookResult = ReturnType<
  typeof useFloorIncidentsQuery
>
export type FloorIncidentsLazyQueryHookResult = ReturnType<
  typeof useFloorIncidentsLazyQuery
>
export type FloorIncidentsQueryResult = Apollo.QueryResult<
  Types.FloorIncidentsQuery,
  Types.FloorIncidentsQueryVariables
>
export const FloorPlanDetailsDocument = gql`
  query floorPlanDetails($floorId: ID!) {
    currentOrganization {
      name
    }
    floor(id: $floorId) {
      id
      facility {
        id
        name
        shortName
      }
      name
    }
  }
`

/**
 * __useFloorPlanDetailsQuery__
 *
 * To run a query within a React component, call `useFloorPlanDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFloorPlanDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFloorPlanDetailsQuery({
 *   variables: {
 *      floorId: // value for 'floorId'
 *   },
 * });
 */
export function useFloorPlanDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.FloorPlanDetailsQuery,
    Types.FloorPlanDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.FloorPlanDetailsQuery,
    Types.FloorPlanDetailsQueryVariables
  >(FloorPlanDetailsDocument, options)
}
export function useFloorPlanDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FloorPlanDetailsQuery,
    Types.FloorPlanDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.FloorPlanDetailsQuery,
    Types.FloorPlanDetailsQueryVariables
  >(FloorPlanDetailsDocument, options)
}
export type FloorPlanDetailsQueryHookResult = ReturnType<
  typeof useFloorPlanDetailsQuery
>
export type FloorPlanDetailsLazyQueryHookResult = ReturnType<
  typeof useFloorPlanDetailsLazyQuery
>
export type FloorPlanDetailsQueryResult = Apollo.QueryResult<
  Types.FloorPlanDetailsQuery,
  Types.FloorPlanDetailsQueryVariables
>
export const FloorsDocument = gql`
  query Floors {
    floors {
      edges {
        node {
          id
          name
          facility {
            id
            name
          }
        }
      }
    }
  }
`

/**
 * __useFloorsQuery__
 *
 * To run a query within a React component, call `useFloorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFloorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFloorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFloorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.FloorsQuery,
    Types.FloorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.FloorsQuery, Types.FloorsQueryVariables>(
    FloorsDocument,
    options
  )
}
export function useFloorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FloorsQuery,
    Types.FloorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.FloorsQuery, Types.FloorsQueryVariables>(
    FloorsDocument,
    options
  )
}
export type FloorsQueryHookResult = ReturnType<typeof useFloorsQuery>
export type FloorsLazyQueryHookResult = ReturnType<typeof useFloorsLazyQuery>
export type FloorsQueryResult = Apollo.QueryResult<
  Types.FloorsQuery,
  Types.FloorsQueryVariables
>
export const FloorsByFacilityIdsDocument = gql`
  query floorsByFacilityIds($ids: [ID!]) {
    floors(filter: { facilityIdIn: $ids }) {
      edges {
        node {
          id
          name
          facility {
            id
            name
          }
        }
      }
    }
  }
`

/**
 * __useFloorsByFacilityIdsQuery__
 *
 * To run a query within a React component, call `useFloorsByFacilityIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFloorsByFacilityIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFloorsByFacilityIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useFloorsByFacilityIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.FloorsByFacilityIdsQuery,
    Types.FloorsByFacilityIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.FloorsByFacilityIdsQuery,
    Types.FloorsByFacilityIdsQueryVariables
  >(FloorsByFacilityIdsDocument, options)
}
export function useFloorsByFacilityIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FloorsByFacilityIdsQuery,
    Types.FloorsByFacilityIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.FloorsByFacilityIdsQuery,
    Types.FloorsByFacilityIdsQueryVariables
  >(FloorsByFacilityIdsDocument, options)
}
export type FloorsByFacilityIdsQueryHookResult = ReturnType<
  typeof useFloorsByFacilityIdsQuery
>
export type FloorsByFacilityIdsLazyQueryHookResult = ReturnType<
  typeof useFloorsByFacilityIdsLazyQuery
>
export type FloorsByFacilityIdsQueryResult = Apollo.QueryResult<
  Types.FloorsByFacilityIdsQuery,
  Types.FloorsByFacilityIdsQueryVariables
>
export const FloorsFacilityCheckpointsDocument = gql`
  query FloorsFacilityCheckpoints($id: ID!) {
    floors(filter: { facilityId: $id }) {
      edges {
        node {
          id
          name
          devices(filter: { typeIn: [CHECKPOINT], yGt: 0, xGt: 0 }) {
            edges {
              node {
                id
                type
                name
                x
                y
                facility {
                  id
                  name
                }
                checkpoint {
                  id
                  displayId
                }
              }
            }
            totalCount
          }
        }
      }
    }
  }
`

/**
 * __useFloorsFacilityCheckpointsQuery__
 *
 * To run a query within a React component, call `useFloorsFacilityCheckpointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFloorsFacilityCheckpointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFloorsFacilityCheckpointsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFloorsFacilityCheckpointsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.FloorsFacilityCheckpointsQuery,
    Types.FloorsFacilityCheckpointsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.FloorsFacilityCheckpointsQuery,
    Types.FloorsFacilityCheckpointsQueryVariables
  >(FloorsFacilityCheckpointsDocument, options)
}
export function useFloorsFacilityCheckpointsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FloorsFacilityCheckpointsQuery,
    Types.FloorsFacilityCheckpointsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.FloorsFacilityCheckpointsQuery,
    Types.FloorsFacilityCheckpointsQueryVariables
  >(FloorsFacilityCheckpointsDocument, options)
}
export type FloorsFacilityCheckpointsQueryHookResult = ReturnType<
  typeof useFloorsFacilityCheckpointsQuery
>
export type FloorsFacilityCheckpointsLazyQueryHookResult = ReturnType<
  typeof useFloorsFacilityCheckpointsLazyQuery
>
export type FloorsFacilityCheckpointsQueryResult = Apollo.QueryResult<
  Types.FloorsFacilityCheckpointsQuery,
  Types.FloorsFacilityCheckpointsQueryVariables
>
export const GatewayDevicesDocument = gql`
  query GatewayDevices(
    $filter: EdgeDeviceFilterInput
    $first: Int
    $last: Int
    $orderBy: [EdgeDeviceOrderInput]
  ) {
    edgeDevices(
      filter: $filter
      first: $first
      last: $last
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        page
        pageSize
      }
      edges {
        node {
          id
          name
          devices {
            totalCount
            edges {
              node {
                id
                name
                archived
                facility {
                  id
                  name
                  archived
                }
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useGatewayDevicesQuery__
 *
 * To run a query within a React component, call `useGatewayDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGatewayDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGatewayDevicesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGatewayDevicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.GatewayDevicesQuery,
    Types.GatewayDevicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.GatewayDevicesQuery,
    Types.GatewayDevicesQueryVariables
  >(GatewayDevicesDocument, options)
}
export function useGatewayDevicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GatewayDevicesQuery,
    Types.GatewayDevicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.GatewayDevicesQuery,
    Types.GatewayDevicesQueryVariables
  >(GatewayDevicesDocument, options)
}
export type GatewayDevicesQueryHookResult = ReturnType<
  typeof useGatewayDevicesQuery
>
export type GatewayDevicesLazyQueryHookResult = ReturnType<
  typeof useGatewayDevicesLazyQuery
>
export type GatewayDevicesQueryResult = Apollo.QueryResult<
  Types.GatewayDevicesQuery,
  Types.GatewayDevicesQueryVariables
>
export const GlobalIncidentsDocument = gql`
  query GlobalIncidents($filter: IncidentFilterInput) {
    incidents(filter: $filter) {
      edges {
        node {
          id
          name
          real
          createdAt
          status
          type
          description
          displayId
          devices {
            id
            name
          }
          facility {
            id
            shortName
          }
        }
      }
    }
  }
`

/**
 * __useGlobalIncidentsQuery__
 *
 * To run a query within a React component, call `useGlobalIncidentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalIncidentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalIncidentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGlobalIncidentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.GlobalIncidentsQuery,
    Types.GlobalIncidentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.GlobalIncidentsQuery,
    Types.GlobalIncidentsQueryVariables
  >(GlobalIncidentsDocument, options)
}
export function useGlobalIncidentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GlobalIncidentsQuery,
    Types.GlobalIncidentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.GlobalIncidentsQuery,
    Types.GlobalIncidentsQueryVariables
  >(GlobalIncidentsDocument, options)
}
export type GlobalIncidentsQueryHookResult = ReturnType<
  typeof useGlobalIncidentsQuery
>
export type GlobalIncidentsLazyQueryHookResult = ReturnType<
  typeof useGlobalIncidentsLazyQuery
>
export type GlobalIncidentsQueryResult = Apollo.QueryResult<
  Types.GlobalIncidentsQuery,
  Types.GlobalIncidentsQueryVariables
>
export const GlobalUnresolvedIncidentsCountDocument = gql`
  query GlobalUnresolvedIncidentsCount {
    globalUnresolvedIncidentsCount: incidents(filter: { status: ACTIVE }) {
      totalCount
    }
  }
`

/**
 * __useGlobalUnresolvedIncidentsCountQuery__
 *
 * To run a query within a React component, call `useGlobalUnresolvedIncidentsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalUnresolvedIncidentsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalUnresolvedIncidentsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGlobalUnresolvedIncidentsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.GlobalUnresolvedIncidentsCountQuery,
    Types.GlobalUnresolvedIncidentsCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.GlobalUnresolvedIncidentsCountQuery,
    Types.GlobalUnresolvedIncidentsCountQueryVariables
  >(GlobalUnresolvedIncidentsCountDocument, options)
}
export function useGlobalUnresolvedIncidentsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GlobalUnresolvedIncidentsCountQuery,
    Types.GlobalUnresolvedIncidentsCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.GlobalUnresolvedIncidentsCountQuery,
    Types.GlobalUnresolvedIncidentsCountQueryVariables
  >(GlobalUnresolvedIncidentsCountDocument, options)
}
export type GlobalUnresolvedIncidentsCountQueryHookResult = ReturnType<
  typeof useGlobalUnresolvedIncidentsCountQuery
>
export type GlobalUnresolvedIncidentsCountLazyQueryHookResult = ReturnType<
  typeof useGlobalUnresolvedIncidentsCountLazyQuery
>
export type GlobalUnresolvedIncidentsCountQueryResult = Apollo.QueryResult<
  Types.GlobalUnresolvedIncidentsCountQuery,
  Types.GlobalUnresolvedIncidentsCountQueryVariables
>
export const CountIncidentsByTypeBetweenDatesDocument = gql`
  query CountIncidentsByTypeBetweenDates(
    $startDate: String
    $endDate: String
    $real: Boolean
    $types: [IncidentType]
  ) {
    kpi {
      countIncidentsByTypeBetweenDates(
        startDate: $startDate
        endDate: $endDate
        real: $real
        types: $types
      ) {
        totalCount
        incidentsTypeByDate {
          date
          day
          incidentsTypes {
            count
            type
          }
        }
      }
    }
  }
`

/**
 * __useCountIncidentsByTypeBetweenDatesQuery__
 *
 * To run a query within a React component, call `useCountIncidentsByTypeBetweenDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountIncidentsByTypeBetweenDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountIncidentsByTypeBetweenDatesQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      real: // value for 'real'
 *      types: // value for 'types'
 *   },
 * });
 */
export function useCountIncidentsByTypeBetweenDatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.CountIncidentsByTypeBetweenDatesQuery,
    Types.CountIncidentsByTypeBetweenDatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.CountIncidentsByTypeBetweenDatesQuery,
    Types.CountIncidentsByTypeBetweenDatesQueryVariables
  >(CountIncidentsByTypeBetweenDatesDocument, options)
}
export function useCountIncidentsByTypeBetweenDatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.CountIncidentsByTypeBetweenDatesQuery,
    Types.CountIncidentsByTypeBetweenDatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.CountIncidentsByTypeBetweenDatesQuery,
    Types.CountIncidentsByTypeBetweenDatesQueryVariables
  >(CountIncidentsByTypeBetweenDatesDocument, options)
}
export type CountIncidentsByTypeBetweenDatesQueryHookResult = ReturnType<
  typeof useCountIncidentsByTypeBetweenDatesQuery
>
export type CountIncidentsByTypeBetweenDatesLazyQueryHookResult = ReturnType<
  typeof useCountIncidentsByTypeBetweenDatesLazyQuery
>
export type CountIncidentsByTypeBetweenDatesQueryResult = Apollo.QueryResult<
  Types.CountIncidentsByTypeBetweenDatesQuery,
  Types.CountIncidentsByTypeBetweenDatesQueryVariables
>
export const IncidentDocument = gql`
  query Incident($id: ID!) {
    incident(id: $id) {
      id
      name
      description
      dispatched
      dispatchedGuardIds
      lastIncidentDispatchType
      createdAt
      status
      real
      type
      source
      address
      latitude
      longitude
      imageUrls
      guard {
        id
        firstName
        lastName
        avatar {
          ... on UrlContent {
            minExpiryTime
            url
          }
        }
      }
      owner {
        id
        firstName
        lastName
        avatar {
          ... on UrlContent {
            minExpiryTime
            url
          }
        }
      }
      displayId
      floor {
        id
        name
      }
      guard {
        id
        firstName
        lastName
        avatar {
          ... on UrlContent {
            minExpiryTime
            url
          }
        }
      }
      owner {
        id
        firstName
        lastName
        avatar {
          ... on UrlContent {
            minExpiryTime
            url
          }
        }
      }
      audioMessages {
        edges {
          node {
            id
            createdAt
            creator {
              id
              firstName
              lastName
              roleName
              avatar {
                ... on UrlContent {
                  minExpiryTime
                  url
                }
              }
            }
            audioClip {
              id
              url
            }
          }
        }
      }
      actions {
        id
        createdAt
        type
        message
        incident {
          devices {
            id
            name
          }
        }
        creator {
          id
          firstName
          lastName
          roleName
          avatar {
            ... on UrlContent {
              minExpiryTime
              url
            }
          }
        }
        deviceRepairOrder {
          technician {
            firstName
            lastName
          }
        }
      }
      video {
        url
      }
      facility {
        id
        name
        archived
        type
        shortName
        address
        emergencyNumber
        emergencyCalling {
          outboundPhoneNumber
        }
      }
      devices {
        id
        name
        type
        status
        facility {
          id
          name
          shortName
        }
        floor {
          id
          name
        }
      }
      manualIncidentCategory {
        id
        name
      }
      notes {
        edges {
          node {
            id
            message
            media {
              id
              url
              name
              mimeType
            }
            createdAt
            creator {
              id
              firstName
              lastName
              roleName
            }
          }
        }
      }
      systemActions {
        systemMessage
      }
      events {
        ... on SystemAction {
          systemMessage
          createdAt
        }
        ... on IncidentAction {
          creatorId
          message
          technicianId
          type
        }
      }
    }
  }
`

/**
 * __useIncidentQuery__
 *
 * To run a query within a React component, call `useIncidentQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncidentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncidentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIncidentQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.IncidentQuery,
    Types.IncidentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.IncidentQuery, Types.IncidentQueryVariables>(
    IncidentDocument,
    options
  )
}
export function useIncidentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.IncidentQuery,
    Types.IncidentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.IncidentQuery, Types.IncidentQueryVariables>(
    IncidentDocument,
    options
  )
}
export type IncidentQueryHookResult = ReturnType<typeof useIncidentQuery>
export type IncidentLazyQueryHookResult = ReturnType<
  typeof useIncidentLazyQuery
>
export type IncidentQueryResult = Apollo.QueryResult<
  Types.IncidentQuery,
  Types.IncidentQueryVariables
>
export const IncidentFeedItemsDocument = gql`
  query IncidentFeedItems($id: ID!) {
    incidentFeedItems: incident(id: $id) {
      audioMessages {
        totalCount
      }
      notes {
        totalCount
      }
      actions {
        id
      }
      events {
        ... on SystemAction {
          systemMessage
          createdAt
        }
      }
    }
  }
`

/**
 * __useIncidentFeedItemsQuery__
 *
 * To run a query within a React component, call `useIncidentFeedItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncidentFeedItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncidentFeedItemsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIncidentFeedItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.IncidentFeedItemsQuery,
    Types.IncidentFeedItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.IncidentFeedItemsQuery,
    Types.IncidentFeedItemsQueryVariables
  >(IncidentFeedItemsDocument, options)
}
export function useIncidentFeedItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.IncidentFeedItemsQuery,
    Types.IncidentFeedItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.IncidentFeedItemsQuery,
    Types.IncidentFeedItemsQueryVariables
  >(IncidentFeedItemsDocument, options)
}
export type IncidentFeedItemsQueryHookResult = ReturnType<
  typeof useIncidentFeedItemsQuery
>
export type IncidentFeedItemsLazyQueryHookResult = ReturnType<
  typeof useIncidentFeedItemsLazyQuery
>
export type IncidentFeedItemsQueryResult = Apollo.QueryResult<
  Types.IncidentFeedItemsQuery,
  Types.IncidentFeedItemsQueryVariables
>
export const IncidentFiltersEnrichmentDocument = gql`
  query IncidentFiltersEnrichment {
    facilityNames: facilities(filter: { includeArchived: true }) {
      edges {
        node {
          id
          name
          shortName
          archived
        }
      }
    }
    devices {
      edges {
        node {
          id
          name
          facility {
            name
          }
        }
      }
    }
    manualIncidentCategories {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

/**
 * __useIncidentFiltersEnrichmentQuery__
 *
 * To run a query within a React component, call `useIncidentFiltersEnrichmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncidentFiltersEnrichmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncidentFiltersEnrichmentQuery({
 *   variables: {
 *   },
 * });
 */
export function useIncidentFiltersEnrichmentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.IncidentFiltersEnrichmentQuery,
    Types.IncidentFiltersEnrichmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.IncidentFiltersEnrichmentQuery,
    Types.IncidentFiltersEnrichmentQueryVariables
  >(IncidentFiltersEnrichmentDocument, options)
}
export function useIncidentFiltersEnrichmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.IncidentFiltersEnrichmentQuery,
    Types.IncidentFiltersEnrichmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.IncidentFiltersEnrichmentQuery,
    Types.IncidentFiltersEnrichmentQueryVariables
  >(IncidentFiltersEnrichmentDocument, options)
}
export type IncidentFiltersEnrichmentQueryHookResult = ReturnType<
  typeof useIncidentFiltersEnrichmentQuery
>
export type IncidentFiltersEnrichmentLazyQueryHookResult = ReturnType<
  typeof useIncidentFiltersEnrichmentLazyQuery
>
export type IncidentFiltersEnrichmentQueryResult = Apollo.QueryResult<
  Types.IncidentFiltersEnrichmentQuery,
  Types.IncidentFiltersEnrichmentQueryVariables
>
export const IncidentVideoDocument = gql`
  query IncidentVideo($id: ID!) {
    incident(id: $id) {
      id
      video {
        url
        expiryTime
      }
    }
  }
`

/**
 * __useIncidentVideoQuery__
 *
 * To run a query within a React component, call `useIncidentVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncidentVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncidentVideoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIncidentVideoQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.IncidentVideoQuery,
    Types.IncidentVideoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.IncidentVideoQuery,
    Types.IncidentVideoQueryVariables
  >(IncidentVideoDocument, options)
}
export function useIncidentVideoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.IncidentVideoQuery,
    Types.IncidentVideoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.IncidentVideoQuery,
    Types.IncidentVideoQueryVariables
  >(IncidentVideoDocument, options)
}
export type IncidentVideoQueryHookResult = ReturnType<
  typeof useIncidentVideoQuery
>
export type IncidentVideoLazyQueryHookResult = ReturnType<
  typeof useIncidentVideoLazyQuery
>
export type IncidentVideoQueryResult = Apollo.QueryResult<
  Types.IncidentVideoQuery,
  Types.IncidentVideoQueryVariables
>
export const IncidentWithDisplayIdDocument = gql`
  query IncidentWithDisplayId($displayId: String) {
    incidents(filter: { displayId: $displayId, mutedStatus: ALL }) {
      edges {
        node {
          id
        }
      }
    }
  }
`

/**
 * __useIncidentWithDisplayIdQuery__
 *
 * To run a query within a React component, call `useIncidentWithDisplayIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncidentWithDisplayIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncidentWithDisplayIdQuery({
 *   variables: {
 *      displayId: // value for 'displayId'
 *   },
 * });
 */
export function useIncidentWithDisplayIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.IncidentWithDisplayIdQuery,
    Types.IncidentWithDisplayIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.IncidentWithDisplayIdQuery,
    Types.IncidentWithDisplayIdQueryVariables
  >(IncidentWithDisplayIdDocument, options)
}
export function useIncidentWithDisplayIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.IncidentWithDisplayIdQuery,
    Types.IncidentWithDisplayIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.IncidentWithDisplayIdQuery,
    Types.IncidentWithDisplayIdQueryVariables
  >(IncidentWithDisplayIdDocument, options)
}
export type IncidentWithDisplayIdQueryHookResult = ReturnType<
  typeof useIncidentWithDisplayIdQuery
>
export type IncidentWithDisplayIdLazyQueryHookResult = ReturnType<
  typeof useIncidentWithDisplayIdLazyQuery
>
export type IncidentWithDisplayIdQueryResult = Apollo.QueryResult<
  Types.IncidentWithDisplayIdQuery,
  Types.IncidentWithDisplayIdQueryVariables
>
export const IncidentsDocument = gql`
  query Incidents(
    $filter: IncidentFilterInput
    $first: Int
    $last: Int
    $orderBy: [IncidentOrderInput]
  ) {
    incidents(filter: $filter, first: $first, last: $last, orderBy: $orderBy) {
      edges {
        node {
          id
          name
          status
          real
          description
          type
          createdAt
          source
          guard {
            id
            firstName
            lastName
            avatar {
              ... on UrlContent {
                minExpiryTime
                url
              }
            }
          }
          owner {
            id
            firstName
            lastName
            avatar {
              ... on UrlContent {
                minExpiryTime
                url
              }
            }
          }
          ownerId
          displayId
          dispatched
          devices {
            id
            name
            type
            facility {
              id
              name
              shortName
            }
            floor {
              id
              name
            }
          }
          facility {
            id
            name
            shortName
            archived
          }
          floor {
            id
            name
          }
          actions {
            type
          }
          audioMessages {
            totalCount
            edges {
              node {
                createdAt
              }
            }
          }
        }
      }
      totalCount
    }
  }
`

/**
 * __useIncidentsQuery__
 *
 * To run a query within a React component, call `useIncidentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncidentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncidentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useIncidentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.IncidentsQuery,
    Types.IncidentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.IncidentsQuery, Types.IncidentsQueryVariables>(
    IncidentsDocument,
    options
  )
}
export function useIncidentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.IncidentsQuery,
    Types.IncidentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.IncidentsQuery,
    Types.IncidentsQueryVariables
  >(IncidentsDocument, options)
}
export type IncidentsQueryHookResult = ReturnType<typeof useIncidentsQuery>
export type IncidentsLazyQueryHookResult = ReturnType<
  typeof useIncidentsLazyQuery
>
export type IncidentsQueryResult = Apollo.QueryResult<
  Types.IncidentsQuery,
  Types.IncidentsQueryVariables
>
export const IncidentsByMarketFacilityDocument = gql`
  query IncidentsByMarketFacility {
    incidents(
      filter: { facilityType: MARKET, statusIn: [IN_PROGRESS, ACTIVE] }
    ) {
      edges {
        node {
          id
          displayId
          name
          description
          latitude
          longitude
          address
          facility {
            name
          }
        }
      }
      totalCount
    }
  }
`

/**
 * __useIncidentsByMarketFacilityQuery__
 *
 * To run a query within a React component, call `useIncidentsByMarketFacilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncidentsByMarketFacilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncidentsByMarketFacilityQuery({
 *   variables: {
 *   },
 * });
 */
export function useIncidentsByMarketFacilityQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.IncidentsByMarketFacilityQuery,
    Types.IncidentsByMarketFacilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.IncidentsByMarketFacilityQuery,
    Types.IncidentsByMarketFacilityQueryVariables
  >(IncidentsByMarketFacilityDocument, options)
}
export function useIncidentsByMarketFacilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.IncidentsByMarketFacilityQuery,
    Types.IncidentsByMarketFacilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.IncidentsByMarketFacilityQuery,
    Types.IncidentsByMarketFacilityQueryVariables
  >(IncidentsByMarketFacilityDocument, options)
}
export type IncidentsByMarketFacilityQueryHookResult = ReturnType<
  typeof useIncidentsByMarketFacilityQuery
>
export type IncidentsByMarketFacilityLazyQueryHookResult = ReturnType<
  typeof useIncidentsByMarketFacilityLazyQuery
>
export type IncidentsByMarketFacilityQueryResult = Apollo.QueryResult<
  Types.IncidentsByMarketFacilityQuery,
  Types.IncidentsByMarketFacilityQueryVariables
>
export const IncidentsForCaseDocument = gql`
  query IncidentsForCase($filter: IncidentFilterInput) {
    incidents(filter: $filter) {
      edges {
        node {
          createdAt
          displayId
          id
          name
          facility {
            id
            name
            shortName
          }
        }
      }
    }
  }
`

/**
 * __useIncidentsForCaseQuery__
 *
 * To run a query within a React component, call `useIncidentsForCaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncidentsForCaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncidentsForCaseQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useIncidentsForCaseQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.IncidentsForCaseQuery,
    Types.IncidentsForCaseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.IncidentsForCaseQuery,
    Types.IncidentsForCaseQueryVariables
  >(IncidentsForCaseDocument, options)
}
export function useIncidentsForCaseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.IncidentsForCaseQuery,
    Types.IncidentsForCaseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.IncidentsForCaseQuery,
    Types.IncidentsForCaseQueryVariables
  >(IncidentsForCaseDocument, options)
}
export type IncidentsForCaseQueryHookResult = ReturnType<
  typeof useIncidentsForCaseQuery
>
export type IncidentsForCaseLazyQueryHookResult = ReturnType<
  typeof useIncidentsForCaseLazyQuery
>
export type IncidentsForCaseQueryResult = Apollo.QueryResult<
  Types.IncidentsForCaseQuery,
  Types.IncidentsForCaseQueryVariables
>
export const IncidentsTotalCountDocument = gql`
  query IncidentsTotalCount($filter: IncidentFilterInput) {
    incidents(filter: $filter) {
      totalCount
    }
  }
`

/**
 * __useIncidentsTotalCountQuery__
 *
 * To run a query within a React component, call `useIncidentsTotalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncidentsTotalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncidentsTotalCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useIncidentsTotalCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.IncidentsTotalCountQuery,
    Types.IncidentsTotalCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.IncidentsTotalCountQuery,
    Types.IncidentsTotalCountQueryVariables
  >(IncidentsTotalCountDocument, options)
}
export function useIncidentsTotalCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.IncidentsTotalCountQuery,
    Types.IncidentsTotalCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.IncidentsTotalCountQuery,
    Types.IncidentsTotalCountQueryVariables
  >(IncidentsTotalCountDocument, options)
}
export type IncidentsTotalCountQueryHookResult = ReturnType<
  typeof useIncidentsTotalCountQuery
>
export type IncidentsTotalCountLazyQueryHookResult = ReturnType<
  typeof useIncidentsTotalCountLazyQuery
>
export type IncidentsTotalCountQueryResult = Apollo.QueryResult<
  Types.IncidentsTotalCountQuery,
  Types.IncidentsTotalCountQueryVariables
>
export const LastDatabaseResetDocument = gql`
  query LastDatabaseReset {
    lastDatabaseReset {
      createdAt
      createdBy
    }
  }
`

/**
 * __useLastDatabaseResetQuery__
 *
 * To run a query within a React component, call `useLastDatabaseResetQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastDatabaseResetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastDatabaseResetQuery({
 *   variables: {
 *   },
 * });
 */
export function useLastDatabaseResetQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.LastDatabaseResetQuery,
    Types.LastDatabaseResetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.LastDatabaseResetQuery,
    Types.LastDatabaseResetQueryVariables
  >(LastDatabaseResetDocument, options)
}
export function useLastDatabaseResetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.LastDatabaseResetQuery,
    Types.LastDatabaseResetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.LastDatabaseResetQuery,
    Types.LastDatabaseResetQueryVariables
  >(LastDatabaseResetDocument, options)
}
export type LastDatabaseResetQueryHookResult = ReturnType<
  typeof useLastDatabaseResetQuery
>
export type LastDatabaseResetLazyQueryHookResult = ReturnType<
  typeof useLastDatabaseResetLazyQuery
>
export type LastDatabaseResetQueryResult = Apollo.QueryResult<
  Types.LastDatabaseResetQuery,
  Types.LastDatabaseResetQueryVariables
>
export const ManagedAccountsDocument = gql`
  query ManagedAccounts {
    managedAccounts: users(orderBy: [{ direction: ASC, field: LAST_NAME }]) {
      edges {
        node {
          id
          email
          lastName
          firstName
          roleName
          designatedFacility {
            id
            name
          }
        }
      }
    }
  }
`

/**
 * __useManagedAccountsQuery__
 *
 * To run a query within a React component, call `useManagedAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagedAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagedAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useManagedAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.ManagedAccountsQuery,
    Types.ManagedAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.ManagedAccountsQuery,
    Types.ManagedAccountsQueryVariables
  >(ManagedAccountsDocument, options)
}
export function useManagedAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ManagedAccountsQuery,
    Types.ManagedAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.ManagedAccountsQuery,
    Types.ManagedAccountsQueryVariables
  >(ManagedAccountsDocument, options)
}
export type ManagedAccountsQueryHookResult = ReturnType<
  typeof useManagedAccountsQuery
>
export type ManagedAccountsLazyQueryHookResult = ReturnType<
  typeof useManagedAccountsLazyQuery
>
export type ManagedAccountsQueryResult = Apollo.QueryResult<
  Types.ManagedAccountsQuery,
  Types.ManagedAccountsQueryVariables
>
export const MeDocument = gql`
  query Me {
    me {
      id
      avatar {
        ... on UrlContent {
          minExpiryTime
          url
        }
      }
      email
      roleName
      firstName
      lastName
    }
  }
`

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.MeQuery, Types.MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.MeQuery, Types.MeQueryVariables>(
    MeDocument,
    options
  )
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.MeQuery,
    Types.MeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.MeQuery, Types.MeQueryVariables>(
    MeDocument,
    options
  )
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>
export type MeQueryResult = Apollo.QueryResult<
  Types.MeQuery,
  Types.MeQueryVariables
>
export const OrganizationDocument = gql`
  query Organization($box: GeoFenceBoxInput) {
    currentOrganization {
      name
    }
    facilities(filter: { box: $box, type: NORMAL }) {
      edges {
        node {
          id
          shortName
          defaultFloorId
          latitude
          longitude
          incidents(filter: { statusIn: [IN_PROGRESS, ACTIVE] }) {
            totalCount
          }
          floors(first: 1) {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useOrganizationQuery__
 *
 * To run a query within a React component, call `useOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationQuery({
 *   variables: {
 *      box: // value for 'box'
 *   },
 * });
 */
export function useOrganizationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.OrganizationQuery,
    Types.OrganizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.OrganizationQuery,
    Types.OrganizationQueryVariables
  >(OrganizationDocument, options)
}
export function useOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.OrganizationQuery,
    Types.OrganizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.OrganizationQuery,
    Types.OrganizationQueryVariables
  >(OrganizationDocument, options)
}
export type OrganizationQueryHookResult = ReturnType<
  typeof useOrganizationQuery
>
export type OrganizationLazyQueryHookResult = ReturnType<
  typeof useOrganizationLazyQuery
>
export type OrganizationQueryResult = Apollo.QueryResult<
  Types.OrganizationQuery,
  Types.OrganizationQueryVariables
>
export const OrganizationSettingsDocument = gql`
  query OrganizationSettings {
    currentOrganization {
      organizationSettings {
        cameraOfflineDurationUnit
        minimumCameraOfflineDuration
        id
        emailSenderName
        emailReplyToAddress
        emailReplyToName
        inboundPhoneNumber
      }
    }
  }
`

/**
 * __useOrganizationSettingsQuery__
 *
 * To run a query within a React component, call `useOrganizationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.OrganizationSettingsQuery,
    Types.OrganizationSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.OrganizationSettingsQuery,
    Types.OrganizationSettingsQueryVariables
  >(OrganizationSettingsDocument, options)
}
export function useOrganizationSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.OrganizationSettingsQuery,
    Types.OrganizationSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.OrganizationSettingsQuery,
    Types.OrganizationSettingsQueryVariables
  >(OrganizationSettingsDocument, options)
}
export type OrganizationSettingsQueryHookResult = ReturnType<
  typeof useOrganizationSettingsQuery
>
export type OrganizationSettingsLazyQueryHookResult = ReturnType<
  typeof useOrganizationSettingsLazyQuery
>
export type OrganizationSettingsQueryResult = Apollo.QueryResult<
  Types.OrganizationSettingsQuery,
  Types.OrganizationSettingsQueryVariables
>
export const PacsSourcesDocument = gql`
  query PacsSources {
    pacsSources {
      edges {
        node {
          id
          name
          fields {
            id
            name
            secure
          }
        }
      }
    }
  }
`

/**
 * __usePacsSourcesQuery__
 *
 * To run a query within a React component, call `usePacsSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePacsSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePacsSourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePacsSourcesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.PacsSourcesQuery,
    Types.PacsSourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.PacsSourcesQuery,
    Types.PacsSourcesQueryVariables
  >(PacsSourcesDocument, options)
}
export function usePacsSourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.PacsSourcesQuery,
    Types.PacsSourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.PacsSourcesQuery,
    Types.PacsSourcesQueryVariables
  >(PacsSourcesDocument, options)
}
export type PacsSourcesQueryHookResult = ReturnType<typeof usePacsSourcesQuery>
export type PacsSourcesLazyQueryHookResult = ReturnType<
  typeof usePacsSourcesLazyQuery
>
export type PacsSourcesQueryResult = Apollo.QueryResult<
  Types.PacsSourcesQuery,
  Types.PacsSourcesQueryVariables
>
export const PacsSourceSecretsSettingsDocument = gql`
  query PacsSourceSecretsSettings {
    sources: pacsSources {
      edges {
        node {
          id
          name
        }
      }
    }
    instances: pacsSourceInstances {
      edges {
        node {
          id
          name
          pacsSource {
            id
            name
          }
          fields {
            id
            value
            pacsSourceField {
              name
              secure
            }
          }
        }
      }
    }
  }
`

/**
 * __usePacsSourceSecretsSettingsQuery__
 *
 * To run a query within a React component, call `usePacsSourceSecretsSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePacsSourceSecretsSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePacsSourceSecretsSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePacsSourceSecretsSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.PacsSourceSecretsSettingsQuery,
    Types.PacsSourceSecretsSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.PacsSourceSecretsSettingsQuery,
    Types.PacsSourceSecretsSettingsQueryVariables
  >(PacsSourceSecretsSettingsDocument, options)
}
export function usePacsSourceSecretsSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.PacsSourceSecretsSettingsQuery,
    Types.PacsSourceSecretsSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.PacsSourceSecretsSettingsQuery,
    Types.PacsSourceSecretsSettingsQueryVariables
  >(PacsSourceSecretsSettingsDocument, options)
}
export type PacsSourceSecretsSettingsQueryHookResult = ReturnType<
  typeof usePacsSourceSecretsSettingsQuery
>
export type PacsSourceSecretsSettingsLazyQueryHookResult = ReturnType<
  typeof usePacsSourceSecretsSettingsLazyQuery
>
export type PacsSourceSecretsSettingsQueryResult = Apollo.QueryResult<
  Types.PacsSourceSecretsSettingsQuery,
  Types.PacsSourceSecretsSettingsQueryVariables
>
export const RulesDocument = gql`
  query Rules(
    $filter: RuleFilterInput
    $first: Int
    $last: Int
    $orderBy: [RuleOrderInput]
  ) {
    rules(filter: $filter, first: $first, last: $last, orderBy: $orderBy) {
      totalCount
      edges {
        node {
          id
          createdAt
          lastMatched
          name
          status
        }
      }
    }
  }
`

/**
 * __useRulesQuery__
 *
 * To run a query within a React component, call `useRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRulesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useRulesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.RulesQuery,
    Types.RulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.RulesQuery, Types.RulesQueryVariables>(
    RulesDocument,
    options
  )
}
export function useRulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.RulesQuery,
    Types.RulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.RulesQuery, Types.RulesQueryVariables>(
    RulesDocument,
    options
  )
}
export type RulesQueryHookResult = ReturnType<typeof useRulesQuery>
export type RulesLazyQueryHookResult = ReturnType<typeof useRulesLazyQuery>
export type RulesQueryResult = Apollo.QueryResult<
  Types.RulesQuery,
  Types.RulesQueryVariables
>
export const SavedIncidentsFilterDocument = gql`
  query SavedIncidentsFilter($id: ID!) {
    savedFilter(id: $id) {
      id
      isActive
      name
      filterCriteria {
        incident {
          createdAtGteInterval
          deviceIdIn
          facilityIdIn
          floorIdIn
          mutedStatus
          priorityIn
          statusIn
          OR {
            sourceIn
            deviceTypeIn
          }
          AND {
            OR {
              typeIn
              manualIncidentCategoryIdIn
            }
          }
        }
      }
    }
  }
`

/**
 * __useSavedIncidentsFilterQuery__
 *
 * To run a query within a React component, call `useSavedIncidentsFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavedIncidentsFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavedIncidentsFilterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSavedIncidentsFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.SavedIncidentsFilterQuery,
    Types.SavedIncidentsFilterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.SavedIncidentsFilterQuery,
    Types.SavedIncidentsFilterQueryVariables
  >(SavedIncidentsFilterDocument, options)
}
export function useSavedIncidentsFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SavedIncidentsFilterQuery,
    Types.SavedIncidentsFilterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.SavedIncidentsFilterQuery,
    Types.SavedIncidentsFilterQueryVariables
  >(SavedIncidentsFilterDocument, options)
}
export type SavedIncidentsFilterQueryHookResult = ReturnType<
  typeof useSavedIncidentsFilterQuery
>
export type SavedIncidentsFilterLazyQueryHookResult = ReturnType<
  typeof useSavedIncidentsFilterLazyQuery
>
export type SavedIncidentsFilterQueryResult = Apollo.QueryResult<
  Types.SavedIncidentsFilterQuery,
  Types.SavedIncidentsFilterQueryVariables
>
export const SavedIncidentsFiltersDocument = gql`
  query SavedIncidentsFilters {
    savedFilters {
      edges {
        node {
          id
          name
          isActive
        }
      }
    }
  }
`

/**
 * __useSavedIncidentsFiltersQuery__
 *
 * To run a query within a React component, call `useSavedIncidentsFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavedIncidentsFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavedIncidentsFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useSavedIncidentsFiltersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.SavedIncidentsFiltersQuery,
    Types.SavedIncidentsFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.SavedIncidentsFiltersQuery,
    Types.SavedIncidentsFiltersQueryVariables
  >(SavedIncidentsFiltersDocument, options)
}
export function useSavedIncidentsFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SavedIncidentsFiltersQuery,
    Types.SavedIncidentsFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.SavedIncidentsFiltersQuery,
    Types.SavedIncidentsFiltersQueryVariables
  >(SavedIncidentsFiltersDocument, options)
}
export type SavedIncidentsFiltersQueryHookResult = ReturnType<
  typeof useSavedIncidentsFiltersQuery
>
export type SavedIncidentsFiltersLazyQueryHookResult = ReturnType<
  typeof useSavedIncidentsFiltersLazyQuery
>
export type SavedIncidentsFiltersQueryResult = Apollo.QueryResult<
  Types.SavedIncidentsFiltersQuery,
  Types.SavedIncidentsFiltersQueryVariables
>
export const StandardOperatingProcedureDocument = gql`
  query StandardOperatingProcedure($id: ID!) {
    standardOperatingProcedure(id: $id) {
      id
      name
      createdAt
      creator {
        firstName
        lastName
      }
      status
      url
    }
  }
`

/**
 * __useStandardOperatingProcedureQuery__
 *
 * To run a query within a React component, call `useStandardOperatingProcedureQuery` and pass it any options that fit your needs.
 * When your component renders, `useStandardOperatingProcedureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStandardOperatingProcedureQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStandardOperatingProcedureQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.StandardOperatingProcedureQuery,
    Types.StandardOperatingProcedureQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.StandardOperatingProcedureQuery,
    Types.StandardOperatingProcedureQueryVariables
  >(StandardOperatingProcedureDocument, options)
}
export function useStandardOperatingProcedureLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.StandardOperatingProcedureQuery,
    Types.StandardOperatingProcedureQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.StandardOperatingProcedureQuery,
    Types.StandardOperatingProcedureQueryVariables
  >(StandardOperatingProcedureDocument, options)
}
export type StandardOperatingProcedureQueryHookResult = ReturnType<
  typeof useStandardOperatingProcedureQuery
>
export type StandardOperatingProcedureLazyQueryHookResult = ReturnType<
  typeof useStandardOperatingProcedureLazyQuery
>
export type StandardOperatingProcedureQueryResult = Apollo.QueryResult<
  Types.StandardOperatingProcedureQuery,
  Types.StandardOperatingProcedureQueryVariables
>
export const StandardOperatingProcedureEditDocument = gql`
  query StandardOperatingProcedureEdit($id: ID!) {
    standardOperatingProcedure(id: $id) {
      id
      facilityIds
    }
    facilityNames: facilities {
      edges {
        node {
          id
          name
          shortName
        }
      }
    }
  }
`

/**
 * __useStandardOperatingProcedureEditQuery__
 *
 * To run a query within a React component, call `useStandardOperatingProcedureEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useStandardOperatingProcedureEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStandardOperatingProcedureEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStandardOperatingProcedureEditQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.StandardOperatingProcedureEditQuery,
    Types.StandardOperatingProcedureEditQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.StandardOperatingProcedureEditQuery,
    Types.StandardOperatingProcedureEditQueryVariables
  >(StandardOperatingProcedureEditDocument, options)
}
export function useStandardOperatingProcedureEditLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.StandardOperatingProcedureEditQuery,
    Types.StandardOperatingProcedureEditQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.StandardOperatingProcedureEditQuery,
    Types.StandardOperatingProcedureEditQueryVariables
  >(StandardOperatingProcedureEditDocument, options)
}
export type StandardOperatingProcedureEditQueryHookResult = ReturnType<
  typeof useStandardOperatingProcedureEditQuery
>
export type StandardOperatingProcedureEditLazyQueryHookResult = ReturnType<
  typeof useStandardOperatingProcedureEditLazyQuery
>
export type StandardOperatingProcedureEditQueryResult = Apollo.QueryResult<
  Types.StandardOperatingProcedureEditQuery,
  Types.StandardOperatingProcedureEditQueryVariables
>
export const StandardOperatingProceduresDocument = gql`
  query StandardOperatingProcedures(
    $filter: StandardOperatingProcedureFilterInput
    $first: Int
    $last: Int
    $orderBy: [StandardOperatingProcedureOrderInput]
  ) {
    standardOperatingProcedures(
      filter: $filter
      first: $first
      last: $last
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        page
        pageSize
      }
      edges {
        node {
          id
          name
          createdAt
          creatorId
          creator {
            id
            firstName
            lastName
          }
          facilityIds
          facilities {
            edges {
              node {
                id
                name
                archived
              }
            }
          }
          externalId
          updatedAt
          url
        }
      }
    }
  }
`

/**
 * __useStandardOperatingProceduresQuery__
 *
 * To run a query within a React component, call `useStandardOperatingProceduresQuery` and pass it any options that fit your needs.
 * When your component renders, `useStandardOperatingProceduresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStandardOperatingProceduresQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useStandardOperatingProceduresQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.StandardOperatingProceduresQuery,
    Types.StandardOperatingProceduresQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.StandardOperatingProceduresQuery,
    Types.StandardOperatingProceduresQueryVariables
  >(StandardOperatingProceduresDocument, options)
}
export function useStandardOperatingProceduresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.StandardOperatingProceduresQuery,
    Types.StandardOperatingProceduresQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.StandardOperatingProceduresQuery,
    Types.StandardOperatingProceduresQueryVariables
  >(StandardOperatingProceduresDocument, options)
}
export type StandardOperatingProceduresQueryHookResult = ReturnType<
  typeof useStandardOperatingProceduresQuery
>
export type StandardOperatingProceduresLazyQueryHookResult = ReturnType<
  typeof useStandardOperatingProceduresLazyQuery
>
export type StandardOperatingProceduresQueryResult = Apollo.QueryResult<
  Types.StandardOperatingProceduresQuery,
  Types.StandardOperatingProceduresQueryVariables
>
export const StandardOperatingProceduresFilterDataDocument = gql`
  query StandardOperatingProceduresFilterData {
    standardOperatingProcedures {
      edges {
        node {
          creatorId
          creator {
            id
            firstName
            lastName
          }
        }
      }
    }
    facilityNames: facilities {
      edges {
        node {
          id
          name
          shortName
        }
      }
    }
  }
`

/**
 * __useStandardOperatingProceduresFilterDataQuery__
 *
 * To run a query within a React component, call `useStandardOperatingProceduresFilterDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useStandardOperatingProceduresFilterDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStandardOperatingProceduresFilterDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useStandardOperatingProceduresFilterDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.StandardOperatingProceduresFilterDataQuery,
    Types.StandardOperatingProceduresFilterDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.StandardOperatingProceduresFilterDataQuery,
    Types.StandardOperatingProceduresFilterDataQueryVariables
  >(StandardOperatingProceduresFilterDataDocument, options)
}
export function useStandardOperatingProceduresFilterDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.StandardOperatingProceduresFilterDataQuery,
    Types.StandardOperatingProceduresFilterDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.StandardOperatingProceduresFilterDataQuery,
    Types.StandardOperatingProceduresFilterDataQueryVariables
  >(StandardOperatingProceduresFilterDataDocument, options)
}
export type StandardOperatingProceduresFilterDataQueryHookResult = ReturnType<
  typeof useStandardOperatingProceduresFilterDataQuery
>
export type StandardOperatingProceduresFilterDataLazyQueryHookResult =
  ReturnType<typeof useStandardOperatingProceduresFilterDataLazyQuery>
export type StandardOperatingProceduresFilterDataQueryResult =
  Apollo.QueryResult<
    Types.StandardOperatingProceduresFilterDataQuery,
    Types.StandardOperatingProceduresFilterDataQueryVariables
  >
export const SubmittedTourDocument = gql`
  query SubmittedTour($id: ID!) {
    guardTour(id: $id) {
      id
      createdAt
      updatedAt
      tourPlan {
        id
        name
        checkpointIds
        checkpoints {
          edges {
            node {
              id
              displayId
              device {
                id
                type
                name
                x
                y
                floor {
                  id
                  name
                }
                facility {
                  id
                  name
                }
              }
            }
          }
        }
      }
      guard {
        id
        firstName
        lastName
      }
      completedCheckpoints {
        edges {
          node {
            scannedAt
            checkpoint {
              id
            }
          }
        }
      }
    }
  }
`

/**
 * __useSubmittedTourQuery__
 *
 * To run a query within a React component, call `useSubmittedTourQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmittedTourQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmittedTourQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubmittedTourQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.SubmittedTourQuery,
    Types.SubmittedTourQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.SubmittedTourQuery,
    Types.SubmittedTourQueryVariables
  >(SubmittedTourDocument, options)
}
export function useSubmittedTourLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SubmittedTourQuery,
    Types.SubmittedTourQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.SubmittedTourQuery,
    Types.SubmittedTourQueryVariables
  >(SubmittedTourDocument, options)
}
export type SubmittedTourQueryHookResult = ReturnType<
  typeof useSubmittedTourQuery
>
export type SubmittedTourLazyQueryHookResult = ReturnType<
  typeof useSubmittedTourLazyQuery
>
export type SubmittedTourQueryResult = Apollo.QueryResult<
  Types.SubmittedTourQuery,
  Types.SubmittedTourQueryVariables
>
export const SubmittedToursDocument = gql`
  query SubmittedTours(
    $tourPlanNameLike: String
    $pageIndex: Int
    $pageSize: Int
    $orderBy: [GuardTourOrderInput]
  ) {
    guardTours(
      filter: { tourPlanNameLike: $tourPlanNameLike, status: COMPLETED }
      first: $pageIndex
      last: $pageSize
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        page
        pageSize
      }
      edges {
        node {
          id
          updatedAt
          completedCheckpoints {
            edges {
              node {
                checkpoint {
                  id
                }
              }
            }
          }
          tourPlan {
            id
            name
            checkpointIds
            facility {
              id
              name
              address
              archived
            }
          }
          guard {
            firstName
            lastName
          }
        }
      }
    }
  }
`

/**
 * __useSubmittedToursQuery__
 *
 * To run a query within a React component, call `useSubmittedToursQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmittedToursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmittedToursQuery({
 *   variables: {
 *      tourPlanNameLike: // value for 'tourPlanNameLike'
 *      pageIndex: // value for 'pageIndex'
 *      pageSize: // value for 'pageSize'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useSubmittedToursQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.SubmittedToursQuery,
    Types.SubmittedToursQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.SubmittedToursQuery,
    Types.SubmittedToursQueryVariables
  >(SubmittedToursDocument, options)
}
export function useSubmittedToursLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SubmittedToursQuery,
    Types.SubmittedToursQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.SubmittedToursQuery,
    Types.SubmittedToursQueryVariables
  >(SubmittedToursDocument, options)
}
export type SubmittedToursQueryHookResult = ReturnType<
  typeof useSubmittedToursQuery
>
export type SubmittedToursLazyQueryHookResult = ReturnType<
  typeof useSubmittedToursLazyQuery
>
export type SubmittedToursQueryResult = Apollo.QueryResult<
  Types.SubmittedToursQuery,
  Types.SubmittedToursQueryVariables
>
export const TailgateGridDocument = gql`
  query TailgateGrid($cameraId: ID!, $doorId: ID!) {
    tailgateGrid(cameraId: $cameraId, doorId: $doorId) {
      inBox {
        topLeft {
          x
          y
        }
        topRight {
          x
          y
        }
        bottomRight {
          x
          y
        }
        bottomLeft {
          x
          y
        }
      }
      outBox {
        topLeft {
          x
          y
        }
        topRight {
          x
          y
        }
        bottomRight {
          x
          y
        }
        bottomLeft {
          x
          y
        }
      }
    }
  }
`

/**
 * __useTailgateGridQuery__
 *
 * To run a query within a React component, call `useTailgateGridQuery` and pass it any options that fit your needs.
 * When your component renders, `useTailgateGridQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTailgateGridQuery({
 *   variables: {
 *      cameraId: // value for 'cameraId'
 *      doorId: // value for 'doorId'
 *   },
 * });
 */
export function useTailgateGridQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.TailgateGridQuery,
    Types.TailgateGridQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.TailgateGridQuery,
    Types.TailgateGridQueryVariables
  >(TailgateGridDocument, options)
}
export function useTailgateGridLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.TailgateGridQuery,
    Types.TailgateGridQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.TailgateGridQuery,
    Types.TailgateGridQueryVariables
  >(TailgateGridDocument, options)
}
export type TailgateGridQueryHookResult = ReturnType<
  typeof useTailgateGridQuery
>
export type TailgateGridLazyQueryHookResult = ReturnType<
  typeof useTailgateGridLazyQuery
>
export type TailgateGridQueryResult = Apollo.QueryResult<
  Types.TailgateGridQuery,
  Types.TailgateGridQueryVariables
>
export const TechnicianDocument = gql`
  query Technician($id: ID!) {
    technician(id: $id) {
      id
      email
      firstName
      lastName
      facilities {
        archived
        id
        name
        shortName
      }
      deviceTypes
    }
  }
`

/**
 * __useTechnicianQuery__
 *
 * To run a query within a React component, call `useTechnicianQuery` and pass it any options that fit your needs.
 * When your component renders, `useTechnicianQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTechnicianQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTechnicianQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.TechnicianQuery,
    Types.TechnicianQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.TechnicianQuery, Types.TechnicianQueryVariables>(
    TechnicianDocument,
    options
  )
}
export function useTechnicianLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.TechnicianQuery,
    Types.TechnicianQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.TechnicianQuery,
    Types.TechnicianQueryVariables
  >(TechnicianDocument, options)
}
export type TechnicianQueryHookResult = ReturnType<typeof useTechnicianQuery>
export type TechnicianLazyQueryHookResult = ReturnType<
  typeof useTechnicianLazyQuery
>
export type TechnicianQueryResult = Apollo.QueryResult<
  Types.TechnicianQuery,
  Types.TechnicianQueryVariables
>
export const TechniciansDocument = gql`
  query Technicians($filter: TechnicianFilterInput) {
    technicians(
      filter: $filter
      orderBy: [{ direction: ASC, field: LAST_NAME }]
    ) {
      totalCount
      edges {
        node {
          id
          email
          firstName
          lastName
          facilities {
            name
            archived
          }
          deviceTypes
        }
      }
    }
  }
`

/**
 * __useTechniciansQuery__
 *
 * To run a query within a React component, call `useTechniciansQuery` and pass it any options that fit your needs.
 * When your component renders, `useTechniciansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTechniciansQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTechniciansQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.TechniciansQuery,
    Types.TechniciansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.TechniciansQuery,
    Types.TechniciansQueryVariables
  >(TechniciansDocument, options)
}
export function useTechniciansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.TechniciansQuery,
    Types.TechniciansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.TechniciansQuery,
    Types.TechniciansQueryVariables
  >(TechniciansDocument, options)
}
export type TechniciansQueryHookResult = ReturnType<typeof useTechniciansQuery>
export type TechniciansLazyQueryHookResult = ReturnType<
  typeof useTechniciansLazyQuery
>
export type TechniciansQueryResult = Apollo.QueryResult<
  Types.TechniciansQuery,
  Types.TechniciansQueryVariables
>
export const UserDocument = gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      email
      roleName
      firstName
      lastName
      designatedFacilityId
      designatedFacility {
        archived
        id
        name
        shortName
      }
    }
  }
`

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.UserQuery,
    Types.UserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.UserQuery, Types.UserQueryVariables>(
    UserDocument,
    options
  )
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.UserQuery,
    Types.UserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.UserQuery, Types.UserQueryVariables>(
    UserDocument,
    options
  )
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>
export type UserQueryResult = Apollo.QueryResult<
  Types.UserQuery,
  Types.UserQueryVariables
>
export const NotificationAudioMessagesDocument = gql`
  query notificationAudioMessages($id: ID!) {
    audioMessages(filter: { id: $id }) {
      edges {
        node {
          id
          audioClip {
            url
          }
        }
      }
    }
  }
`

/**
 * __useNotificationAudioMessagesQuery__
 *
 * To run a query within a React component, call `useNotificationAudioMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationAudioMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationAudioMessagesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNotificationAudioMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.NotificationAudioMessagesQuery,
    Types.NotificationAudioMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.NotificationAudioMessagesQuery,
    Types.NotificationAudioMessagesQueryVariables
  >(NotificationAudioMessagesDocument, options)
}
export function useNotificationAudioMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.NotificationAudioMessagesQuery,
    Types.NotificationAudioMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.NotificationAudioMessagesQuery,
    Types.NotificationAudioMessagesQueryVariables
  >(NotificationAudioMessagesDocument, options)
}
export type NotificationAudioMessagesQueryHookResult = ReturnType<
  typeof useNotificationAudioMessagesQuery
>
export type NotificationAudioMessagesLazyQueryHookResult = ReturnType<
  typeof useNotificationAudioMessagesLazyQuery
>
export type NotificationAudioMessagesQueryResult = Apollo.QueryResult<
  Types.NotificationAudioMessagesQuery,
  Types.NotificationAudioMessagesQueryVariables
>
