import { useEffect } from 'react'

import { usePermissions } from '@/hooks/usePermissions'
import { useToasts } from '@/hooks/useToasts'
import { SubmitHandler, useForm } from 'react-hook-form'
import { HiOutlineCheckCircle, HiOutlineTrash } from 'react-icons/hi'

import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import * as EmailValidator from 'email-validator'

import {
  Button,
  FormInputControl,
  FormInputSelectControl,
} from '@/components/ui'
import { RoleExtended } from '@/features/managed-accounts'
import {
  ManagedAccountsDocument,
  useFacilityNamesQuery,
  useUpdateUserMutation,
  useUserQuery,
} from '@/graphql/generated/hooks'
import {
  getFacilityOption,
  getFacilityOptions,
} from '@/utils/forms/optionBuilders'

import { RoleIcon, getRoleDisplayName } from './UserRoles'

const FormSkeleton = () => {
  return (
    <Box mb={3} px={6} py={2}>
      <Skeleton h='15px' maxW='120px' mb='9px' />
      <Skeleton h='45px' mb='20px' />
      <Skeleton h='15px' maxW='120px' mb='9px' />
      <Skeleton h='45px' mb='20px' />
      <Skeleton h='15px' maxW='120px' mb='9px' />
      <Skeleton h='45px' mb='20px' />
      <Skeleton h='15px' maxW='120px' mb='9px' />
      <Skeleton h='45px' />
    </Box>
  )
}

const IconWrapperStyled = styled(Box)`
  .icon {
    width: 30px;
  }
`
interface FormInputsIProps {
  firstName: string
  lastName: string
  email: string
  designatedFacility: { value: string; label: string }
}

interface IProps {
  isOpen: boolean
  onClose: () => void
  onRemoveOpen: () => void
  userId: string
  role: RoleExtended
}

export const EditUserModal = ({
  isOpen,
  onClose,
  onRemoveOpen,
  userId,
  role,
}: IProps) => {
  const { shouldEnableUpdateUser } = usePermissions()
  const { handleSubmit, register, control, errors, setValue } =
    useForm<FormInputsIProps>({
      mode: 'onChange',
    })
  const { data: facilitiesData, loading: isFacilietiesLoading } =
    useFacilityNamesQuery({
      fetchPolicy: 'network-only',
    })
  const { data: userData, loading: isUserLoading } = useUserQuery({
    variables: {
      id: userId,
    },
    fetchPolicy: 'network-only',
  })
  const [updateUser, { loading: isUpdateUserLoading }] = useUpdateUserMutation()

  const facilityOptions = getFacilityOptions(facilitiesData)

  const { showSuccess, showError } = useToasts()

  const onSubmit: SubmitHandler<FormInputsIProps> = async (values) => {
    const data = {
      id: userId,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      roleName: role === RoleExtended.EMPLOYEE ? '' : role,
      designatedFacilityId: values.designatedFacility.value,
    }

    try {
      await updateUser({
        variables: {
          input: data,
        },
        refetchQueries: [
          {
            query: ManagedAccountsDocument,
          },
        ],
      })
      onClose()
      showSuccess(`${getRoleDisplayName(role)} edits saved.`)
    } catch {
      showError()
    }
  }

  useEffect(() => {
    if (userData && facilitiesData) {
      setValue('firstName', userData?.user?.firstName, {
        shouldValidate: true,
      })
      setValue('lastName', userData?.user?.lastName, { shouldValidate: true })
      setValue('email', userData?.user?.email, { shouldValidate: true })
      const facility = userData?.user?.designatedFacility
      if (facility) {
        setValue('designatedFacility', getFacilityOption(facility))
      }
    }
  }, [isUserLoading, isFacilietiesLoading])

  return (
    <Modal
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      size='xl'
    >
      <ModalOverlay />
      <ModalContent bg='#fff'>
        <ModalHeader>
          <Box alignItems='center' d='flex'>
            <IconWrapperStyled>
              <RoleIcon role={role} />
            </IconWrapperStyled>
            <Box ml={3}>
              <Box
                color='#353849'
                fontSize='26px'
                fontWeight='extrabold'
                letterSpacing='-0.4px'
                lineHeight='1'
              >
                {`Edit ${getRoleDisplayName(role)}`}
              </Box>
            </Box>
          </Box>
        </ModalHeader>
        <ModalCloseButton color='#000' opacity={0.4} />
        <ModalBody p={0}>
          {userData && !isUserLoading ? (
            <Box px={6} py={2}>
              <form data-testid='EditUserModal:form'>
                <Box mb={3}>
                  <FormInputControl
                    data-testid='EditUserModal:firstName'
                    errorMessage={errors.firstName && errors.firstName.message}
                    id='firstName'
                    inputRef={register({
                      required: 'Please add first name',
                    })}
                    isInvalid={!!errors.firstName}
                    label={`${getRoleDisplayName(role)} First Name`}
                    placeholder={`Enter ${getRoleDisplayName(role)} First Name`}
                  />
                </Box>
                <Box mb={3}>
                  <FormInputControl
                    data-testid='EditUserModal:lastName'
                    errorMessage={errors.lastName && errors.lastName.message}
                    id='lastName'
                    inputRef={register({
                      required: 'Please add last name',
                    })}
                    isInvalid={!!errors.lastName}
                    label={`${getRoleDisplayName(role)} Last Name`}
                    placeholder={`Enter ${getRoleDisplayName(role)} Last Name`}
                  />
                </Box>

                <Box mb={3}>
                  <FormInputControl
                    data-testid='EditUserModal:email'
                    errorMessage={errors.email && errors.email.message}
                    id='email'
                    inputRef={register({
                      required: 'You must specify an email',
                      validate: {
                        isEmail: (v) =>
                          EmailValidator.validate(v) || 'Invalid email address',
                      },
                    })}
                    isInvalid={!!errors.email}
                    label={`${getRoleDisplayName(role)} Email`}
                    placeholder={`Enter ${getRoleDisplayName(role)} Email`}
                    type='email'
                  />
                </Box>
                <Box mb={3}>
                  <FormInputSelectControl
                    control={control}
                    errorMessage={
                      !!errors?.designatedFacility &&
                      'You must select a facility'
                    }
                    id='designatedFacility'
                    isInvalid={!!errors.designatedFacility}
                    label={`${getRoleDisplayName(role)} Facility`}
                    options={facilityOptions}
                    placeholder={`Enter ${getRoleDisplayName(role)} Facility`}
                    rules={{ required: true }}
                  />
                </Box>
              </form>
            </Box>
          ) : (
            <FormSkeleton />
          )}
        </ModalBody>
        <ModalFooter py={4}>
          <Button
            data-testid='EditUserModal:cancel'
            mr={3}
            onClick={onClose}
            variant='secondary'
          >
            Cancel
          </Button>
          <Button
            data-testid='EditUserModal:remove'
            isDisabled={isUserLoading || !userData || !shouldEnableUpdateUser}
            leftIcon={<HiOutlineTrash size='24px' />}
            loadingText={`Removing ${getRoleDisplayName(role)}`}
            mr={3}
            onClick={() => {
              onClose()
              onRemoveOpen()
            }}
            variant='danger'
          >
            {`Remove ${getRoleDisplayName(role)}`}
          </Button>
          <Button
            data-testid='EditUserModal:submit'
            isDisabled={isUserLoading || !userData || !shouldEnableUpdateUser}
            isLoading={isUpdateUserLoading}
            leftIcon={<HiOutlineCheckCircle size='24px' />}
            loadingText='Saving changes...'
            onClick={handleSubmit(onSubmit)}
          >
            Save Changes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
