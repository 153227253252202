import { ErrorHandler } from '@apollo/link-error'

const errorLogger: ErrorHandler = ({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      // eslint-disable-next-line no-console
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )
  if (networkError)
    // eslint-disable-next-line no-console
    console.log(
      `[Network error]: ${networkError}. Backend is unreachable. Is it running?`
    )
}

export { errorLogger }
