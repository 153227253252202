export const IncidentNote = ({
  className,
  strokeColor = '#fff',
}: {
  className?: string
  strokeColor?: string
}) => {
  return (
    <svg
      className={className}
      height='560px'
      viewBox='0 0 560 560'
      width='560px'
    >
      <g data-name='Layer 2' id='Layer_2'>
        <g data-name='Layer 1' id='Layer_1-2'>
          <path
            className={className}
            d='M0,52.5A52.49,52.49,0,0,1,52.5,0h455A52.49,52.49,0,0,1,560,52.5v315a17.53,17.53,0,0,1-5.12,12.38l-175,175A17.53,17.53,0,0,1,367.5,560H52.5A52.49,52.49,0,0,1,0,507.5ZM52.5,35A17.51,17.51,0,0,0,35,52.5v455A17.51,17.51,0,0,0,52.5,525H350V367.5A17.51,17.51,0,0,1,367.5,350H525V52.5A17.51,17.51,0,0,0,507.5,35ZM500.25,385H385V500.25Z'
            fill={strokeColor}
          />
        </g>
      </g>
    </svg>
  )
}
