import { TechniciansQueryResult } from '@/graphql/generated/hooks'
import { DeviceType } from '@/graphql/generated/schemas'

export enum Role {
  PROGRAM_MANAGER = 'PROGRAM_MANAGER',
  GUARD = 'GUARD',
  OPERATOR = 'OPERATOR',
  READ_ONLY_USER = 'READ_ONLY_USER',
  IMPLEMENTER = 'IMPLEMENTER',
  UNKNOWN = 'UNKNOWN',
}

// ExtendedRole provide useful extension of standard role system to support
// users outside of the organization and users without specific role within
// organization. Technicians don't have HiveWatch accounts and exist outside
// of the organization, whereas employees are organization members which are
// NOT program managers, operators or guards.
export enum RoleExtended {
  PROGRAM_MANAGER = 'PROGRAM_MANAGER',
  OPERATOR = 'OPERATOR',
  GUARD = 'GUARD',
  EMPLOYEE = 'EMPLOYEE',
  TECHNICIAN = 'TECHNICIAN',
  IMPLEMENTER = 'IMPLEMENTER',
}

export const DEFAULT_ROLE = 'default-roles-hivewatch-dev.com'

export const PERMITTED_USER_ROLES = [
  Role.OPERATOR,
  Role.PROGRAM_MANAGER,
  Role.READ_ONLY_USER,
  Role.IMPLEMENTER,
  DEFAULT_ROLE,
]

export type TechnicianFacilities =
  TechniciansQueryResult['data']['technicians']['edges']['0']['node']['facilities']

export interface AccountIProps {
  id: string
  email: string
  firstName: string
  lastName: string
  roleName: RoleExtended | '' | 'default-roles-hivewatch-dev.com'
  facilities?: TechnicianFacilities
  deviceTypes?: DeviceType[]
}

export interface AccountGroupIProps {
  role: RoleExtended
  accounts: AccountIProps[]
}

export interface AccountGroupsIProps extends Array<AccountGroupIProps> {}
