import { Box, Center, Circle, Flex } from '@chakra-ui/react'

interface RadioButtonIProps {
  isActive: boolean
  onClick: () => void
  label?: string
  disabled?: boolean
}

export const RadioButton = ({
  isActive,
  onClick,
  label,
  disabled = false,
}: RadioButtonIProps) => {
  return (
    <Flex direction='row'>
      <Circle
        as='button'
        border='2px solid #3279C7'
        disabled={disabled}
        onClick={onClick}
        size='22px'
      >
        <Center>
          {isActive ? (
            <Circle
              backgroundColor='#3279C7'
              data-testid='RadioButton:center'
              h='12px'
              w='12px'
            />
          ) : null}
        </Center>
      </Circle>
      <Box color='#44546F' fontSize='14px' fontWeight='500' ml={3}>
        {label}
      </Box>
    </Flex>
  )
}
