import { Incident, IncidentActionType } from '@/graphql/generated/schemas'
import { formatTimeDistance } from '@/utils/formatDateTime'

export const getTimeSinceDispatched = (incident: Incident): string => {
  const sortedAudioMessages = incident?.audioMessages?.edges.sort(
    (a, b) =>
      new Date(a?.node?.createdAt).valueOf() -
      new Date(b?.node?.createdAt).valueOf()
  )
  const dispatchTime =
    sortedAudioMessages && sortedAudioMessages[0]?.node?.createdAt
  const timeSinceDispatch = formatTimeDistance(dispatchTime)
  return timeSinceDispatch ? `Dispatched: ${timeSinceDispatch}` : ''
}

export const getIsReleased = (
  incident: Incident,
  hasGuard: boolean
): boolean => {
  return (
    incident?.actions?.some(
      (e) => e.type === IncidentActionType.ReleaseByGuard
    ) && !hasGuard
  )
}
