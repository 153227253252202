import { CountUp } from 'use-count-up'

export const TimeFormatCountUp = ({ value }: { value: number }) => {
  const SECONDS_IN_MINUTE = 60
  if (value < SECONDS_IN_MINUTE) {
    return (
      <>
        <CountUp duration={1} end={value} isCounting key={value} />s
      </>
    )
  }

  const minutes = Math.floor(value / SECONDS_IN_MINUTE)
  const seconds = value % SECONDS_IN_MINUTE
  return (
    <>
      <CountUp duration={1} end={minutes} isCounting key={minutes} />m{' '}
      <CountUp duration={1} end={seconds} isCounting key={seconds} />s
    </>
  )
}
