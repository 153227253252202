import {
  Employee,
  Guards,
  Operator,
  ProgramManager,
  Technician,
} from '@/components/icons'

import { Role, RoleExtended } from '../types/types'

const RoleIcon = ({ role }: { role: RoleExtended }) => {
  switch (role) {
    case RoleExtended.GUARD:
      return <Guards className='icon' strokeColor='#000' />
    case RoleExtended.OPERATOR:
      return <Operator className='icon' strokeColor='#000' />
    case RoleExtended.PROGRAM_MANAGER:
      return <ProgramManager className='icon' strokeColor='#000' />
    case RoleExtended.EMPLOYEE:
      return <Employee className='icon' strokeColor='#000' />
    case RoleExtended.TECHNICIAN:
      return <Technician className='icon' strokeColor='#000' />
    default:
      return null
  }
}

const getRoleDisplayName = (role: RoleExtended | Role) => {
  switch (role) {
    case RoleExtended.GUARD:
      return 'Guard'
    case RoleExtended.OPERATOR:
      return 'Operator'
    case RoleExtended.PROGRAM_MANAGER:
      return 'Program Manager'
    case RoleExtended.EMPLOYEE:
      return 'Employee'
    case RoleExtended.TECHNICIAN:
      return 'Technician'
    case RoleExtended.IMPLEMENTER:
      return 'Implementer'
    default:
      return ''
  }
}

export { RoleIcon, getRoleDisplayName }
