import { Notification } from '@/graphql/appsync-notifications'

import { NotificationDataIProps } from './types'

export const isUniqueNotification = (
  notifications: Notification[],
  data: NotificationDataIProps
) =>
  !notifications.some(
    (n) => n.notificationTimestamp === data?.notification?.notificationTimestamp
  )
