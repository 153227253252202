export const MapPin = ({
  className,
  strokeColor = '#fff',
}: {
  className?: string
  strokeColor?: string
}) => (
  <svg
    className={className}
    height='48'
    viewBox='0 0 48 48'
    width='48'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M18.343 14.343A8 8 0 0 1 32 20c0 6.002-8 16-8 16s-8-9.998-8-16a8 8 0 0 1 2.343-5.657zm2.65 8.681a4.253 4.253 0 1 0 6.009-6.021 4.253 4.253 0 0 0-6.01 6.021z'
      fill={strokeColor}
      fillRule='evenodd'
    />
  </svg>
)
