import { Text } from '@chakra-ui/react'
import styled from '@emotion/styled'

type StyledTProps = {
  width: string
  height: string
  ballHeight: string
  ballPadding: string
}

const StyledSwitch = styled.div<StyledTProps>`
  display: flex;
  align-items: center;

  .react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  .react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background: #d9d9d9;
    border-radius: 80px;
    position: relative;
    transition: background-color 0.2s;
    width: ${({ width }) => (width ? width : '50px')};
    height: ${({ height }) => (height ? height : '20px')};
  }

  .react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: ${({ ballPadding }) => (ballPadding ? ballPadding : '1px')};
    left: ${({ ballPadding }) => (ballPadding ? ballPadding : '1px')};
    right: ${({ ballPadding }) => (ballPadding ? ballPadding : '1px')};
    width: ${({ ballHeight }) => (ballHeight ? ballHeight : '18px')};
    height: ${({ ballHeight }) => (ballHeight ? ballHeight : '18px')};
    border-radius: ${({ ballHeight }) => (ballHeight ? ballHeight : '18px')};
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }

  .react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(
      100% - ${({ ballPadding }) => (ballPadding ? ballPadding : '2px')}
    );
    transform: translateX(-100%);
  }
`

export interface SwitchIProps {
  title?: string
  backgroundColor?: string
  checked: boolean
  onChange: (value?: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  width?: string
  height?: string
  ballHeight?: string
  ballPadding?: string
  dataTestId: string
}

export const Switch = ({
  title,
  backgroundColor,
  disabled,
  checked,
  onChange,
  width = '40px',
  height = '15px',
  ballHeight = '13px',
  ballPadding = '1px',
  dataTestId,
}: SwitchIProps) => {
  return (
    <StyledSwitch
      ballHeight={ballHeight}
      ballPadding={ballPadding}
      height={height}
      width={width}
    >
      {title && (
        <Text fontSize='13px' fontWeight='normal' mr='7px'>
          {title}
        </Text>
      )}
      <input
        checked={checked}
        className='react-switch-checkbox'
        data-testid={dataTestId}
        disabled={disabled}
        id={dataTestId}
        onChange={onChange}
        type='checkbox'
      />
      <label
        className='react-switch-label'
        htmlFor={dataTestId}
        style={{ background: checked && backgroundColor }}
      >
        <span className='react-switch-button' />
      </label>
    </StyledSwitch>
  )
}
