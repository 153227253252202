import { BiFilter } from 'react-icons/bi'
import { useSelector } from 'react-redux'

import {
  Box,
  Circle,
  HStack,
  Spacer,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'

import { Button } from '@/components/ui'
import { SearchInput } from '@/components/ui'
import {
  selectActiveFilter,
  selectIncidentFilterCount,
} from '@/redux/incidents/incidentsSlice'

import {
  ActiveFilterBadge,
  IncidentsFilterDrawer,
  IncidentsOwnerToggle,
} from '../incidents-filter-drawer'

interface IProps {
  filter?: string
  setFilter?: React.Dispatch<React.SetStateAction<string>>
}

export const IncidentsSearchFilterHeader = ({ filter, setFilter }: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const filterCount = useSelector(selectIncidentFilterCount)
  const activeFilter = useSelector(selectActiveFilter)

  return (
    <>
      <Box
        bgColor='white'
        borderBottom='1px solid #D5DCE4'
        boxShadow='lg'
        px='6'
        py='4'
        w='full'
      >
        <Stack
          alignItems='start'
          direction={{ base: 'column', md: 'row' }}
          justifyContent='start'
          m='auto'
          maxW='1100px'
        >
          <HStack spacing='2'>
            <Box
              alignItems='center'
              display='flex'
              flexDir='row'
              maxW='300px'
              w='full'
            >
              <SearchInput
                dataTestId='incidentsPage_tableHeader_search'
                onChange={(e) => setFilter(e.target.value)}
                placeholder='Search or Filter'
                value={filter}
              />
            </Box>
            <IncidentsOwnerToggle />
          </HStack>
          <Spacer />
          <HStack>
            {activeFilter && <ActiveFilterBadge label={activeFilter?.name} />}
            <Button
              data-testid='incidentsPage_tableHeader_filterButton'
              h='40px'
              leftIcon={
                filterCount > 0 && (
                  <Circle
                    bgColor='#fff'
                    color='#353849'
                    data-testid='IncidentsSearchFilterHeader:filterCount'
                    fontSize='11px'
                    h='20px'
                    minW='20px'
                    w='auto'
                  >
                    {filterCount}
                  </Circle>
                )
              }
              onClick={onOpen}
              px='6'
              rightIcon={<BiFilter size={24} />}
            >
              Filters
            </Button>
          </HStack>
        </Stack>
      </Box>
      <IncidentsFilterDrawer isOpen={isOpen} onClose={onClose} />
    </>
  )
}
