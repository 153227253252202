import { useSelector } from 'react-redux'

import { useIncidentsTotalCountLazyQuery } from '@/graphql/generated/hooks'
import {
  selectActiveFilter,
  selectIncidentsActiveFilterInput,
} from '@/redux/incidents/incidentsSlice'

export const useFilterNotification = () => {
  const activeFilterInput = useSelector(selectIncidentsActiveFilterInput)
  const activeFilter = useSelector(selectActiveFilter)
  const [execute] = useIncidentsTotalCountLazyQuery()

  const shouldShowNotification = async (incidentId: string) => {
    if (!activeFilter) {
      // Always show notification when there is no active filter
      return true
    }

    const incident = await execute({
      variables: {
        filter: {
          ...activeFilterInput,
          id: incidentId,
        },
      },
    })

    // If returned data has incident, it meets filter criteria of the active filter
    return incident?.data?.incidents.totalCount === 1
  }

  return { shouldShowNotification }
}
