export const ManualIncident = ({
  className,
  strokeColor = '#fff',
}: {
  className?: string
  strokeColor?: string
}) => {
  return (
    <svg
      className={className}
      height='15.668'
      viewBox='0 0 18.083 15.668'
      width='18.083'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.4788208 15.66783142c.73596191 0 1.2243042-.2193756 1.46490479-.65812683.24060058-.45291138.1697998-.99073792-.21234131-1.61349487L10.36456298.95535278C10.01074219.31845093 9.5578003 0 9.00579835 0c-.52368164 0-.96948242.31845093-1.33746338.95535278L.34393311 13.39620972c-.38214112.62275695-.44586182 1.1605835-.19110108 1.61349487.24066162.43875122.72894287.65812683 1.46490479.65812683H16.4788208zm-7.430542-5.83828735c-.24066161 0-.43170165-.07783509-.57324218-.23352051-.16986084-.15568543-.25476074-.34675598-.25476074-.57321167V5.30752563c0-.21229553.0848999-.40336608.25476074-.57321167.14154053-.14152526.33258057-.21229553.57324219-.21229553.21228027 0 .38922119.07077027.53070068.21229553.16986084.16984559.25476074.36091614.25476074.57321167V9.0228119c0 .22645569-.0848999.41752624-.25476074.57321167-.14147949.15568542-.3184204.2335205-.53070068.2335205zm-.76428222 3.12083435q.31842041.29722595.76428223.29722595.42462158 0 .72180176-.29722595c.19818115-.21229553.2972412-.47413635.2972412-.7855072 0-.2689209-.09906005-.50245667-.2972412-.7006073-.19812012-.2264557-.4387207-.3396759-.72180176-.3396759-.29724121 0-.55200195.1132202-.76428223.3396759-.19818115.18400573-.2972412.41752624-.2972412.7006073 0 .31137085.09906005.57321167.2972412.7855072z'
        fill={strokeColor}
        fillRule='evenodd'
      />
    </svg>
  )
}
