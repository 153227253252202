import { IncidentsFilterDrawerMode } from '../hooks/useDrawerMode'
import { HiArrowLeft } from 'react-icons/hi'

import { Box, DrawerHeader, HStack, IconButton, Spacer } from '@chakra-ui/react'

import { Button } from '@/components/ui'

import { SavedFilterI } from '../types/generic'

interface IncidentsFilterDrawerHeaderIProps {
  editedFilter: SavedFilterI | null
  onGoBack: () => void
  onOpenList: () => void
  drawerMode: IncidentsFilterDrawerMode
}

export const IncidentsFilterDrawerHeader = ({
  editedFilter,
  onGoBack,
  onOpenList,
  drawerMode,
}: IncidentsFilterDrawerHeaderIProps) => {
  return (
    <DrawerHeader
      alignItems='center'
      borderBottomWidth='1px'
      borderColor='#D5DCE4'
      display='flex'
      minH='65px'
      px='24px'
      py='0'
    >
      <HStack spacing='3'>
        <IconButton
          aria-label='Back button'
          borderRadius='4px'
          h='30px'
          icon={<HiArrowLeft size={18} />}
          minWidth='30px'
          onClick={onGoBack}
          variant='ghost'
          w='30px'
        />
        <DrawerHeaderTitle
          drawerMode={drawerMode}
          editedFilter={editedFilter}
        />
      </HStack>
      <Spacer />
      {drawerMode === IncidentsFilterDrawerMode.DETAIL && (
        <Button
          onClick={onOpenList}
          style={{
            height: '40px',
            paddingLeft: '20px',
            paddingRight: '20px',
            width: '120px',
          }}
        >
          View Filters
        </Button>
      )}
    </DrawerHeader>
  )
}

const DrawerHeaderTitle = ({
  drawerMode,
  editedFilter,
}: {
  drawerMode: IncidentsFilterDrawerMode
  editedFilter: SavedFilterI
}) => {
  switch (drawerMode) {
    case IncidentsFilterDrawerMode.DETAIL:
      return (
        <Box fontSize='20px' fontWeight='bold' letterSpacing='-0.53px'>
          Filter Incidents
        </Box>
      )
    case IncidentsFilterDrawerMode.LIST:
      return (
        <Box fontSize='20px' fontWeight='bold' letterSpacing='-0.53px'>
          Saved Filters
        </Box>
      )
    case IncidentsFilterDrawerMode.UPDATE:
      return (
        <Box fontSize='20px' fontWeight='bold' letterSpacing='-0.53px'>
          {editedFilter.name}
        </Box>
      )
  }
}
