import { useEffect, useRef, useState } from 'react'

import {
  MdFiberManualRecord,
  MdPause,
  MdPlayArrow,
  MdStop,
} from 'react-icons/md'
import { useRecorder } from 'use-recorder'

import { Box, Flex, HStack, Square } from '@chakra-ui/react'

import { AudioWave, formWaveSurferOptions } from '../AudioWave'

export interface VoiceRecorderIProps {
  getAudioFile: (audioFile) => void
  onRecording: () => void
  hasFile: boolean
}

const RecorderStatus = {
  PAUSED: 'paused',
  RECORDING: 'recording',
  PLAYING: 'playing',
  SILENT: 'silent',
}

export const VoiceRecorder = ({
  getAudioFile,
  onRecording,
  hasFile,
}: VoiceRecorderIProps) => {
  const waveformRef = useRef(null)
  const wavesurfer = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isReset, setIsReset] = useState(false)
  const [status, setStatus] = useState(RecorderStatus.PAUSED)
  const { start, stop, player, audio } = useRecorder()
  const shouldShowAudioWave =
    (status === RecorderStatus.PAUSED || status === RecorderStatus.PLAYING) &&
    player

  const actions = {
    [RecorderStatus.RECORDING]: start,
    [RecorderStatus.PAUSED]: stop,
    [RecorderStatus.PLAYING]: () => player.play(),
    [RecorderStatus.SILENT]: () => player.pause(),
  }

  const handleAction = (action) => {
    if (action === RecorderStatus.RECORDING && onRecording) {
      setIsReset(true)
      onRecording()
    }
    setStatus(action)
    actions[action]()
  }

  useEffect(() => {
    if (player) {
      create(player.src)
      getAudioFile(audio)
      setIsReset(false)
    }

    return () => {
      if (wavesurfer.current) {
        wavesurfer.current.destroy()
      }
    }
  }, [player])

  useEffect(() => {
    if (!hasFile && !isReset && wavesurfer.current) {
      handleAction(RecorderStatus.SILENT)
      wavesurfer.current.destroy()
      setIsReset(true)
    }
  }, [hasFile, player])

  const create = async (url: string) => {
    const WaveSurfer = (await import('wavesurfer.js')).default

    const options = formWaveSurferOptions(waveformRef.current)
    wavesurfer.current = WaveSurfer.create(options)

    wavesurfer.current.load(url)
    wavesurfer.current.on('finish', function () {
      setIsPlaying(false)
      wavesurfer.current.stop()
    })
  }

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying)
    wavesurfer.current.playPause()
  }

  return (
    <Flex alignItems='center' bg='#e3eefb' maxW='350px' p='4px' rounded='5px'>
      <HStack spacing={1}>
        {(status === RecorderStatus.PAUSED ||
          status === RecorderStatus.SILENT) && (
          <Square
            bg='white'
            cursor='pointer'
            data-testid='incidentDrawer_radioGuards_startRecording'
            h='30px'
            onClick={() => handleAction(RecorderStatus.RECORDING)}
            rounded='5px'
            w='30px'
          >
            <MdFiberManualRecord color='#D01030' size='18px' />
          </Square>
        )}
        {status === RecorderStatus.RECORDING && (
          <Square
            bg='white'
            cursor='pointer'
            h='30px'
            onClick={() => handleAction(RecorderStatus.PAUSED)}
            rounded='5px'
            w='30px'
          >
            <MdStop color='#D01030' size='22px' />
          </Square>
        )}
        {!!player && !isReset && (
          <Square
            bg='white'
            cursor='pointer'
            h='30px'
            onClick={handlePlayPause}
            rounded='5px'
            w='30px'
          >
            {isPlaying ? (
              <MdPause color='#2d2e41' size='26px' />
            ) : (
              <MdPlayArrow color='#2d2e41' size='26px' />
            )}
          </Square>
        )}
      </HStack>
      <Box
        color='#3471DE'
        fontSize='14px'
        fontWeight='medium'
        letterSpacing='-0.4px'
        ml='8px'
        noOfLines={1}
      >
        {(status === RecorderStatus.PAUSED ||
          status === RecorderStatus.SILENT) &&
          (!player || isReset) &&
          `Click here to start recording`}
        {status === RecorderStatus.RECORDING && `Recording...`}
      </Box>
      {shouldShowAudioWave && <AudioWave id='waveform' ref={waveformRef} />}
    </Flex>
  )
}
