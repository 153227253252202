import { Global, css } from '@emotion/react'

const styles = css`
  body {
    background-color: #f0f5fd;
  }
  html {
    overflow: hidden;
  }
  .react-datepicker {
    font-family: 'Euclid Square';
    font-weight: 400;
    font-style: normal;
  }
`

export const GlobalStyles = () => <Global styles={styles} />
