import { ApolloLink, HttpLink, Operation } from '@apollo/client'
import { onError } from '@apollo/link-error'
import { createUploadLink } from 'apollo-upload-client'
import { createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import { IncomingHttpHeaders } from 'http'

import { store } from '@/redux/store'

import { errorLogger } from './logger'
import { isBrowser } from './utils'

type SplitConditionFunctionType = (op: Operation) => boolean

enum AppSyncAuthentiactionType {
  OPENID_CONNECT = 'OPENID_CONNECT',
  AMAZON_COGNITO_USER_POOLS = 'AMAZON_COGNITO_USER_POOLS',
}

export enum GraphqlClientType {
  APPSYNC_NOTIFICATIONS,
  APPSYNC_COMPONENT_HEALTH,
}

const appSyncRegion = 'us-west-2'

/* -------------------------------------------------------------------------- */
/*                                 api-general                                */
/* -------------------------------------------------------------------------- */
export const apiGeneralUrl = process.env.NEXT_PUBLIC_SERVICE_URL

/**
 * Build isomorphic fetch for passing the cookies along with each GraphQL request
 */
const enhancedFetchBuilder =
  (headers: IncomingHttpHeaders | null) =>
  async (url: RequestInfo, init: RequestInit) => {
    const tokenValue = store.getState().token.token
    return fetch(url, {
      ...init,
      headers: {
        ...init.headers,
        Authorization: tokenValue ? `Bearer ${tokenValue}` : '',
        cookie: headers?.cookie ?? '',
      },
    }).then((response) => response)
  }

/**
 * Build HTTP link for queries/mutation to api-general
 */
export const apiGeneralHttpLinkBuilder = (
  headers: IncomingHttpHeaders | null
) =>
  ApolloLink.from([
    onError(errorLogger),
    // this uses apollo-link-http under the hood, so all the options here come from that package
    // TO CONNECT TO LOCAL BACKEND
    // USE 'http://localhost:8080/graphql',
    createUploadLink({
      uri: apiGeneralUrl,
      fetch: enhancedFetchBuilder(headers),
    }),
  ])

export const shouldUseApiGeneralLink: SplitConditionFunctionType = ({
  getContext,
}) => {
  return (
    getContext()?.target === undefined ||
    !isBrowser ||
    process.env.JEST_WORKER_ID !== undefined
  )
}

/* -------------------------------------------------------------------------- */
/*                          appSync Notifications API                         */
/* -------------------------------------------------------------------------- */
export const appSyncNotificationsUrl = process.env.NEXT_PUBLIC_NOTIFICATIONS_URL

const appSyncNotificationsAuth = {
  url: appSyncNotificationsUrl,
  region: appSyncRegion,
  auth: {
    type: AppSyncAuthentiactionType.OPENID_CONNECT,
    jwtToken: async () => store.getState().token.token,
  },
}

export const appSyncNotificationsLinkBuilder = () =>
  ApolloLink.from([
    createAuthLink(appSyncNotificationsAuth),
    createSubscriptionHandshakeLink(
      appSyncNotificationsAuth,
      new HttpLink({ uri: appSyncNotificationsUrl })
    ),
  ])

export const shouldUseNotificationsLink: SplitConditionFunctionType = ({
  getContext,
}) =>
  appSyncNotificationsUrl &&
  getContext()?.target === GraphqlClientType.APPSYNC_NOTIFICATIONS

/* -------------------------------------------------------------------------- */
/*                        appSync Component Health API                        */
/* -------------------------------------------------------------------------- */
export const appSyncComponentHealthUrl =
  process.env.NEXT_PUBLIC_COMPONENT_HEALTH_URL

const appSyncComponentHealthAuth = {
  url: appSyncComponentHealthUrl,
  region: appSyncRegion,
  auth: {
    type: AppSyncAuthentiactionType.OPENID_CONNECT,
    jwtToken: async () => store.getState().token.token,
  },
}

export const appSyncComponentHealthLinkBuilder = () =>
  ApolloLink.from([
    createAuthLink(appSyncComponentHealthAuth),
    createSubscriptionHandshakeLink(
      appSyncComponentHealthAuth,
      new HttpLink({ uri: appSyncComponentHealthUrl })
    ),
  ])

export const shouldUseComponentHealthLink: SplitConditionFunctionType = ({
  getContext,
}) =>
  appSyncComponentHealthUrl &&
  getContext()?.target === GraphqlClientType.APPSYNC_COMPONENT_HEALTH
