import { Box, Circle, Flex, Skeleton } from '@chakra-ui/react'

interface CountBadgeIProps {
  title: string
  count?: number
}

export const CountBadge = ({ title, count }: CountBadgeIProps) => {
  return (
    <Flex alignItems='center'>
      <Box fontSize='20px' fontWeight='extrabold'>
        {title}
      </Box>
      {count !== undefined ? (
        <Circle
          bg='#14327A'
          color='white'
          data-testid='CountBadge:number'
          fontSize='16px'
          fontWeight='bold'
          h='24px'
          ml='3'
          paddingX='9px'
        >
          {count}
        </Circle>
      ) : (
        <Skeleton
          data-testid='CountBadge:skeleton'
          h='24px'
          ml='3'
          rounded='full'
          w='24px'
        />
      )}
    </Flex>
  )
}
