import { Box, Flex, Tooltip } from '@chakra-ui/react'

export interface HeaderDrawerToggleIProps {
  count: number
  title: string
  isActive: boolean
  onClick: () => void
  dataTestId: string
  icon: JSX.Element
}

export const HeaderDrawerToggle = ({
  count,
  title,
  isActive,
  onClick,
  dataTestId,
  icon,
}: HeaderDrawerToggleIProps) => {
  const activeBg = '#EAEDFE'
  return (
    <Tooltip
      bgColor={isActive ? activeBg : '#fff'}
      boxShadow='md'
      color='#1a202c'
      fontSize='16px'
      fontWeight='semibold'
      label={title}
      letterSpacing='-0.65px'
      p='2'
      placement='bottom'
      rounded='5px'
    >
      <Flex
        _hover={{
          bgColor: 'whitesmoke',
        }}
        alignItems='center'
        bgColor={isActive ? activeBg : '#fff'}
        cursor='pointer'
        h='full'
        onClick={onClick}
        px='4'
        transition='ease-in-out'
        transitionDuration='300ms'
        transitionProperty='background-color'
      >
        {icon}
        <Box
          color='#1a202c'
          data-testid='floorPlanPage_header_floorsCount'
          dataTestId={dataTestId}
          fontSize='20px'
          fontWeight='bold'
          ml='2'
        >
          {count ? count : '-'}
        </Box>
      </Flex>
    </Tooltip>
  )
}
