import makeAnimated from 'react-select/animated'

import { Box, Flex } from '@chakra-ui/react'

import { Option } from './Option'
import { StyledSelect } from './StyledSelect'
import { colourStyles } from './colorStyles'

export interface InputSelectIProps {
  options: {
    value: string
    label: string
  }[]
  [key: string]: unknown
}

const animatedComponents = makeAnimated()

export const InputSelect = ({ options, ...rest }: InputSelectIProps) => {
  return (
    <StyledSelect
      {...rest}
      className='input-select-container'
      classNamePrefix='react-select'
      components={{
        IndicatorSeparator: null,
        Option,
        ...(rest?.shouldAnimate ? animatedComponents : {}),
      }}
      formatGroupLabel={FormatGroupLabel}
      options={options}
      styles={{
        ...colourStyles,
        menu: (base) => ({
          ...base,
          zIndex: 9999,
        }),
      }}
    />
  )
}

const FormatGroupLabel = (data: { label: string; options: [] }) => (
  <Flex alignItems='center' justifyContent='space-between' py='1'>
    <Box alignItems='center' as='span' d='flex'>
      <Box
        as='span'
        fontSize='15px'
        fontWeight='bold'
        letterSpacing='-0.53px'
        textTransform='capitalize'
      >
        {data.label}
      </Box>
    </Box>
    <Box
      bgColor='#EBECF0'
      borderRadius='2em'
      color='#2D2E41'
      d='inline-block'
      fontSize='14px'
      lineHeight='1'
      minH='1'
      p='0.16666666666667em 0.5em'
    >
      {data.options.length}
    </Box>
  </Flex>
)
