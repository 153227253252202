import { Flex } from '@chakra-ui/react'

export const DrawerContainer = ({
  children,
  ...rest
}: {
  children: React.ReactNode
  [key: string]: unknown
}) => (
  <Flex
    bg='#fff'
    boxShadow='dark-lg'
    direction='column'
    h='100%'
    w='320px'
    {...rest}
  >
    {children}
  </Flex>
)
