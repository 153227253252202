export const GuardTour = ({
  className,
  strokeColor = '#fff',
}: {
  className?: string
  strokeColor?: string
}) => {
  return (
    <svg
      className={className}
      fill='none'
      height='48'
      viewBox='0 0 50 48'
      width='50'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        clipRule='evenodd'
        d='M7.391 13.887l-.689-.248C3.967 12.652 2 10.06 2 7.04 2 3.186 5.186 0 9.041 0c3.855 0 7.04 3.186 7.04 7.041 0 3.02-1.966 5.61-4.701 6.598l-.69.248V24.86H7.392V13.886zm5.387-6.846c0-2.097-1.64-3.737-3.737-3.737-2.097 0-3.737 1.64-3.737 3.737 0 2.097 1.64 3.737 3.737 3.737 2.097 0 3.737-1.64 3.737-3.737zM34.532 5.391l.25-.689C35.767 1.967 38.358 0 41.378 0c3.855 0 7.04 3.186 7.04 7.041 0 3.855-3.185 7.04-7.04 7.04-3.02 0-5.611-1.966-6.598-4.701l-.249-.69H23.56V5.392h10.972zm10.583 1.65c0-2.097-1.639-3.737-3.736-3.737-2.097 0-3.737 1.64-3.737 3.737 0 2.097 1.64 3.737 3.737 3.737 2.097 0 3.736-1.64 3.736-3.737z'
        fill={strokeColor}
        fillRule='evenodd'
      ></path>
      <path
        d='M39.73 21.56h3.298v10.167l6.486 6.485.001.002a1.643 1.643 0 010 2.33l-.001.002-6.968 6.968-.002.001a1.643 1.643 0 01-2.33 0l-6.97-6.97a1.653 1.653 0 010-2.333l6.485-6.486V21.56z'
        fill={strokeColor}
      ></path>
      <path
        clipRule='evenodd'
        d='M2 35.337c0-1.627 1.372-3 2.999-3h8.084c1.627 0 2.999 1.373 2.999 3v8.084c0 1.627-1.372 2.999-2.999 2.999H5C3.372 46.42 2 45.048 2 43.42v-8.084zm3.304.305v7.473h7.474v-7.473H5.304z'
        fill={strokeColor}
        fillRule='evenodd'
      ></path>
    </svg>
  )
}
