export const AssociatedDevices = ({
  strokeColor = '#4DBD6B',
  ...props
}: {
  strokeColor?: string
}) => (
  <svg
    fill='none'
    height={14}
    width={14}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <circle cx={7} cy={7} fill={strokeColor} r={7} />
    <path
      clipRule='evenodd'
      d='M9.56 5.696a.344.344 0 0 1 .14-.03c.157 0 .3.106.3.252v2.164c0 .146-.143.251-.3.251a.344.344 0 0 1-.141-.03l-.87-.472V6.166l.87-.47Zm-4.941-.363h3.096c.342 0 .618.25.618.557v2.22c0 .308-.276.557-.618.557H4.619c-.342 0-.619-.25-.619-.557V5.89c0-.308.277-.557.619-.557Z'
      fill='#fff'
      fillRule='evenodd'
    />
  </svg>
)
