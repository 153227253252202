export const SecurityDevice = ({
  className,
  strokeColor = '#fff',
}: {
  className?: string
  strokeColor?: string
}) => {
  return (
    <svg
      className={className}
      height='48'
      viewBox='0 0 48 48'
      width='48'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <path d='M0 0h48v48H0V0z' fill='none' />
        <path
          d='M23.9985744 6c4.67796297 4.13872543 10.78346884 6.29360986 17.02292921 6.00809267 1.83536171 6.24347008 1.06026769 12.96434985-2.14808417 18.62614725C35.6650676 36.2960373 30.29777161 40.4146693 23.9985744 42.048556c-6.29919722-1.6338867-11.6664932-5.7525187-14.87484505-11.41431608-3.20835185-5.6617974-3.98344588-12.38267717-2.14808417-18.62614725C13.21510555 12.29360986 19.32061141 10.13872543 23.99857439 6'
          fill='none'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
        <path
          d='M22.48466991 35s-7.11992043-3.51069295-9.07793896-6.96601977c-1.95801854-3.45532683-2.4310491-7.55699889-1.31094993-11.36731356C15.90364862 16.84091437 22.48466992 13 22.48466992 13c.0156866 6.6 0 22 0 22z'
          fill='none'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
        <rect
          fill='none'
          height='3'
          rx='0'
          ry='0'
          stroke={strokeColor}
          strokeLinecap='butt'
          strokeLinejoin='round'
          strokeWidth='3'
          width='3'
          x='27'
          y='15'
        />
        <rect
          fill='none'
          height='3'
          rx='0'
          ry='0'
          stroke={strokeColor}
          strokeLinecap='butt'
          strokeLinejoin='round'
          strokeWidth='3'
          width='3'
          x='31'
          y='23'
        />
        <rect
          fill='none'
          height='3'
          rx='0'
          ry='0'
          stroke={strokeColor}
          strokeLinecap='butt'
          strokeLinejoin='round'
          strokeWidth='3'
          width='3'
          x='27'
          y='31'
        />
        <path
          d='M23.5 16.5h4'
          fill='none'
          stroke={strokeColor}
          strokeLinecap='square'
          strokeLinejoin='miter'
          strokeWidth='3'
        />
        <path
          d='M25.5 24.5h4'
          fill='none'
          stroke={strokeColor}
          strokeLinecap='square'
          strokeLinejoin='miter'
          strokeWidth='3'
        />
        <path
          d='M23.5 32.5h4'
          fill='none'
          stroke={strokeColor}
          strokeLinecap='square'
          strokeLinejoin='miter'
          strokeWidth='3'
        />
      </g>
    </svg>
  )
}
