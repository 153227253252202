export const captureVideoFrame = (video: Record<string, any>) => {
  if (!video) {
    return null
  }

  const canvas = document.createElement('canvas') as HTMLCanvasElement
  canvas.width = video.videoWidth
  canvas.height = video.videoHeight
  canvas.getContext('2d').drawImage(video as HTMLVideoElement, 0, 0)

  const dataUri = canvas.toDataURL('image/png')
  const data = dataUri.split(',')[1]
  const mimeType = dataUri.split(';')[0].slice(5)

  const bytes = window.atob(data)
  const buffer = new ArrayBuffer(bytes.length)

  const arr = new Uint8Array(buffer)
  for (let i = 0; i < bytes.length; i++) {
    arr[i] = bytes.charCodeAt(i)
  }

  const blob = new Blob([arr], { type: mimeType })
  const frameDimensions = {
    width: video.videoWidth,
    height: video.videoHeight,
  }
  return {
    blob,
    imageUrl: dataUri,
    frameDimensions,
  }
}
