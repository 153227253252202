import { usePermissions } from '@/hooks/usePermissions'
import { useToasts } from '@/hooks/useToasts'

import { useDisclosure } from '@chakra-ui/react'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { ConfirmRemovalDialog, EditRemoveButton } from '@/components/ui'
import {
  ManagedAccountsDocument,
  TechniciansDocument,
  useDeleteTechnicianMutation,
  useDeleteUserMutation,
} from '@/graphql/generated/hooks'

import { RoleExtended } from '../types/types'
import { EditTechnicianModal } from './EditTechnicianModal'
import { EditUserModal } from './EditUserModal'
import { RoleIcon, getRoleDisplayName } from './UserRoles'

const IconWrapperStyled = styled(Box)`
  .icon {
    width: 30px;
    margin-right: 10px;
  }
`

export const AccountEdit = ({
  role,
  userId,
  fullName,
}: {
  role: RoleExtended
  userId: string
  fullName: string
}) => {
  const {
    isOpen: isEditAccountOpen,
    onOpen: onEditAccountOpen,
    onClose: onEditAccountClose,
  } = useDisclosure()
  const {
    isOpen: isRemoveAccountOpen,
    onOpen: onRemoveAccountOpen,
    onClose: onRemoveAccountClose,
  } = useDisclosure()

  const { showSuccess, showError } = useToasts()
  const { hasCRUDPermission } = usePermissions()
  const hasPermission = hasCRUDPermission(role)
  const [deleteUser, { loading: isDeleteUserLoading }] = useDeleteUserMutation()
  const [deleteTechnician, { loading: isDeleteTechnicianLoading }] =
    useDeleteTechnicianMutation()
  const isDeleteAccountLoading =
    role === RoleExtended.TECHNICIAN
      ? isDeleteTechnicianLoading
      : isDeleteUserLoading

  const onAccountRemove = async () => {
    try {
      if (role === RoleExtended.TECHNICIAN) {
        await deleteTechnician({
          variables: {
            input: {
              id: userId,
            },
          },
          refetchQueries: [
            {
              query: TechniciansDocument,
            },
          ],
        })
      } else {
        await deleteUser({
          variables: {
            input: {
              id: userId,
            },
          },
          refetchQueries: [
            {
              query: ManagedAccountsDocument,
            },
          ],
        })
      }
      showSuccess(`${getRoleDisplayName(role)} removed.`)
      onRemoveAccountClose()
    } catch {
      showError()
    }
  }

  return (
    <Box height={90}>
      {hasPermission && (
        <EditRemoveButton
          handleOnDelete={onRemoveAccountOpen}
          handleOnEdit={onEditAccountOpen}
        />
      )}
      {isEditAccountOpen &&
        (role !== RoleExtended.TECHNICIAN ? (
          <EditUserModal
            isOpen={isEditAccountOpen}
            onClose={onEditAccountClose}
            onRemoveOpen={onRemoveAccountOpen}
            role={role}
            userId={userId}
          />
        ) : (
          <EditTechnicianModal
            isOpen={isEditAccountOpen}
            onClose={onEditAccountClose}
            onRemoveOpen={onRemoveAccountOpen}
            technicianId={userId}
          />
        ))}
      {isRemoveAccountOpen && (
        <ConfirmRemovalDialog
          handleOnClick={onAccountRemove}
          isLoading={isDeleteAccountLoading}
          isOpen={isRemoveAccountOpen}
          itemTitle={`${getRoleDisplayName(role)} ${fullName}`}
          onClose={onRemoveAccountClose}
          title={
            <Flex alignItems='center'>
              <IconWrapperStyled>
                <RoleIcon role={role} />
              </IconWrapperStyled>
              <span>Remove {getRoleDisplayName(role)}</span>
            </Flex>
          }
        />
      )}
    </Box>
  )
}
