import { roundToNearestMinutes } from 'date-fns'

export const MID_NIGHT = new Date().setHours(23, 59, 59)
export const TIME_INTERVAL = 15
export const currentDateRounded = roundToNearestMinutes(new Date(), {
  nearestTo: TIME_INTERVAL,
  roundingMethod: 'ceil',
})

// Returns any time greater than the current time.
// If the selectedDate is greater than the currentDate, the time is set to midnight so all times are selectable
export const getMinTime = (date: Date) => {
  const currentDate = new Date()
  const selectedDate = new Date(date?.getTime()).getDate()
  return currentDate.getDate() === selectedDate
    ? currentDate
    : new Date().setHours(0, 0, 0)
}
