import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useSelector } from 'react-redux'

import Keycloak from 'keycloak-js'

import { useMeQuery } from '@/graphql/generated/hooks'
import { useAppDispatch } from '@/redux/hooks'
import { setMe } from '@/redux/me/meSlice'
import { setNotifications } from '@/redux/notifications/notificationsSlice'
import { store } from '@/redux/store'
import {
  selectHasToken,
  selectTokens,
  setAuthTokens,
} from '@/redux/token/tokenSlice'
import { resetUi } from '@/redux/ui/uiSlice'
import { mixpanel } from '@/utils/analytics'

import { AuthClientEvent, AuthClientTokens, ROLE_STATUS } from './types'
import {
  MIN_TOKEN_VALIDITY_IN_MINUTES,
  TOKEN_UPDATE_TIMEOUT_IN_MILLISECONDS,
  getInitOptions,
  getKeycloakInstance,
  getRoleStatus,
} from './utils'

interface KeyCloakProviderIProps {
  autoRefreshToken?: boolean
  children: ReactNode
}

type KeyCloakProviderContextT = {
  keycloak: Keycloak
  logIn: () => void
  logOut: () => void
  isKcInitilised: boolean
  isLoggedIn: boolean
  roleStatus: ROLE_STATUS
}

export const KeyCloakContext = createContext<KeyCloakProviderContextT>(null)

export const KeyCloakProvider = ({
  autoRefreshToken,
  children,
}: KeyCloakProviderIProps) => {
  const dispatch = useAppDispatch()
  const tokens = useSelector(selectTokens)
  const [keycloak] = useState<Keycloak>(getKeycloakInstance())
  const [isKcInitilised, setIsKcInitilised] = useState(false)
  const hasToken = useSelector(selectHasToken)
  const {
    data,
    loading: isLoading,
    refetch,
    client,
  } = useMeQuery({
    fetchPolicy: 'network-only',
    skip: !hasToken && !isKcInitilised,
  })
  const user = data?.me
  const roleStatus = getRoleStatus(tokens?.token)
  const isLoggedIn = Boolean(
    roleStatus !== ROLE_STATUS.NOT_SET &&
      keycloak.authenticated &&
      isKcInitilised
  )

  const initializeKeyCloak = () => {
    // Attach Keycloak listeners
    keycloak.onReady = updateState('onReady')
    keycloak.onAuthSuccess = updateState('onAuthSuccess')
    keycloak.onAuthError = updateState('onAuthError')
    keycloak.onAuthRefreshSuccess = updateState('onAuthRefreshSuccess')
    keycloak.onAuthRefreshError = updateState('onAuthRefreshError')
    keycloak.onAuthLogout = updateState('onAuthLogout')
    keycloak.onTokenExpired = refreshToken('onTokenExpired')

    setTimeout(() => {
      if (keycloak.isTokenExpired()) {
        keycloak.updateToken(MIN_TOKEN_VALIDITY_IN_MINUTES)
      }
    }, TOKEN_UPDATE_TIMEOUT_IN_MILLISECONDS)

    keycloak
      .init({ ...tokens, ...getInitOptions() })
      .catch(updateState('onInitError'))
  }

  const updateState = (event: AuthClientEvent) => () => {
    onEvent(event)
    const { idToken, refreshToken, token } = keycloak
    onTokens({
      idToken,
      refreshToken,
      token,
    })
  }

  const refreshToken = (event: AuthClientEvent) => () => {
    onEvent(event)
    if (autoRefreshToken !== false) {
      // Refresh Keycloak token
      keycloak.updateToken(MIN_TOKEN_VALIDITY_IN_MINUTES)
    }
  }

  const onEvent = async (event: AuthClientEvent) => {
    switch (event) {
      case 'onAuthError': {
        keycloak.clearToken()
        break
      }
      case 'onAuthLogout': {
        resetReduxState(store.getState().token.idToken)
        mixpanel.track('Logout')
        break
      }
      case 'onAuthRefreshError': {
        handleInvalidSession()
        break
      }
      case 'onAuthRefreshSuccess': {
        break
      }
      case 'onAuthSuccess': {
        mixpanel.track('Login', {
          type: 'KC',
          realm: keycloak.realm,
        })
        break
      }
      case 'onInitError': {
        break
      }
      case 'onReady': {
        handleOnReady()
        break
      }
      case 'onTokenExpired': {
        break
      }
      default:
        break
    }
  }

  const onTokens = useCallback((tokens: AuthClientTokens) => {
    if (tokens.token) {
      dispatch(setAuthTokens(tokens))
    }
  }, [])

  const logIn = async () => {
    try {
      window.localStorage.removeItem('viewport')
      await keycloak.login({ redirectUri: window.location.origin })
    } catch (error) {
      // TODO Handle error
    }
  }

  const logOut = async () => {
    try {
      await keycloak.logout({ redirectUri: window.location.origin })
    } catch (error) {
      // TODO Handle error
    }
  }

  const handleOnReady = async () => {
    try {
      // Check if a validation token, if not update
      if (keycloak.isTokenExpired()) {
        await keycloak.updateToken(-1)
        setIsKcInitilised(true)
      } else {
        setIsKcInitilised(true)
      }
    } catch (e) {
      // No token, redirect to login
      logIn()
    }
  }

  const handleInvalidSession = async () => {
    keycloak.idToken = store.getState().token.idToken
    keycloak.logout()
  }

  const resetReduxState = (idToken) => {
    dispatch(resetUi())
    dispatch(setMe(false))
    dispatch(setNotifications([]))
    dispatch(
      setAuthTokens({
        refreshToken: null,
        token: null,
        idToken,
      })
    )
  }

  useEffect(() => {
    initializeKeyCloak()
  }, [])

  useEffect(() => {
    if (!user && tokens.token) {
      refetch()
    }
  }, [tokens])

  useEffect(() => {
    if (user && isLoggedIn) {
      mixpanel.track('Login', {
        realm: keycloak.realm,
        type: 'GQL',
        firstName: user?.firstName,
        lastName: user?.lastName,
        role: user?.roleName,
        userName: user?.email,
        email: user?.email,
        userId: user?.id,
      })
      dispatch(setMe(user))
      client.resetStore()
    }
  }, [isLoggedIn, isLoading])

  return (
    <KeyCloakContext.Provider
      value={{
        keycloak,
        isKcInitilised,
        isLoggedIn,
        roleStatus,
        logIn,
        logOut,
      }}
    >
      {children}
    </KeyCloakContext.Provider>
  )
}

export const useKeyCloak = () => {
  return useContext(KeyCloakContext)
}
