import { useCreateFilter } from '../hooks/useCreateFilter'
import { useToasts } from '@/hooks/useToasts'
import { SubmitHandler, useForm } from 'react-hook-form'
import { BiFilterAlt } from 'react-icons/bi'
import { HiOutlineCheckCircle } from 'react-icons/hi'

import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
} from '@chakra-ui/react'

import { Button, FormInputControl } from '@/components/ui'
import { maxLength } from '@/utils/forms/validation'

import {
  CreateIncidentFilterFormInputsI,
  IncidentFiltersFormInputsI,
} from '../types/form'

interface CreateFilterModalIProps {
  isOpen: boolean
  onClose: () => void
  incidentFiltersFormValues: IncidentFiltersFormInputsI
}

export const CreateFilterModal = ({
  isOpen,
  onClose,
  incidentFiltersFormValues,
}: CreateFilterModalIProps) => {
  const { showSuccess, showError } = useToasts()

  const { handleSubmit, register, errors } =
    useForm<CreateIncidentFilterFormInputsI>({
      reValidateMode: 'onChange',
    })

  const { create, isCreateLoading } = useCreateFilter()

  const onSubmit: SubmitHandler<CreateIncidentFilterFormInputsI> = async (
    createIncidentFilterFormValues
  ) => {
    try {
      create(createIncidentFilterFormValues, incidentFiltersFormValues)
      showSuccess('Successfully created new Filter')
      onClose()
    } catch (error) {
      showError(error)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      size='xl'
    >
      <ModalOverlay />
      <ModalContent bg='#fff'>
        <ModalHeader>
          <Box align-items='center' display='flex' m='20px auto'>
            <Box display='grid' placeItems='center'>
              <BiFilterAlt size='28px' />
            </Box>
            <Box
              color='#353849'
              fontSize='26px'
              fontWeight='extrabold'
              letterSpacing='-0.4px'
              ml={2}
            >
              Create Filter
            </Box>
            <Spacer />
            <ModalCloseButton color='#000' opacity={0.4} position='initial' />
          </Box>
        </ModalHeader>
        <ModalBody p={0}>
          <Box px={6} py={2}>
            <Box mb={3}>
              <FormInputControl
                errorMessage={errors.name?.message}
                id='name'
                inputRef={register({
                  required: 'Filter name is required',
                  maxLength: maxLength(255),
                })}
                isDisabled={isCreateLoading}
                isInvalid={!!errors.name}
                label='Filter Name'
                placeholder='Enter a name for this filter'
              />
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter pb={6} py={4}>
          <Button mr={3} onClick={onClose} variant='secondary'>
            Cancel
          </Button>
          <Button
            isDisabled={isCreateLoading}
            isLoading={isCreateLoading}
            leftIcon={<HiOutlineCheckCircle size='24px' />}
            loadingText='Saving'
            onClick={handleSubmit(onSubmit)}
          >
            Create Filter
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
