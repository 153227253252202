import styled from '@emotion/styled'

export const AudioWave = styled.div`
  width: 100%;
  &::-webkit-scrollbar {
    display: 'none',
    width: 0;
  }
`

export const formWaveSurferOptions = (ref) => ({
  container: ref,
  waveColor: '#99b6e0',
  progressColor: '#0f2764',
  cursorColor: '#99b6e0',
  barWidth: 1,
  barRadius: 3,
  responsive: true,
  height: 30,
  normalize: true,
  partialRender: true,
  hideScrollbar: true,
})
