import { useEffect, useState } from 'react'

import { FaClock } from 'react-icons/fa'

import { Avatar, Tooltip } from '@chakra-ui/react'

import { Incident } from '@/graphql/generated/schemas'

import { getIsReleased, getTimeSinceDispatched } from '../utils/helpers'

export const IncidentGuardCell = ({ incident }: { incident: Incident }) => {
  const hasGuard = incident?.guard ? true : false
  const hasDispatched = incident?.dispatched
  const guard = incident?.guard
    ? `${incident?.guard?.firstName} ${incident?.guard?.lastName}`
    : null

  const [timeLabel, setTimeLabel] = useState<string>(
    getTimeSinceDispatched(incident)
  )
  const [isReleased, setIsReleased] = useState(
    getIsReleased(incident, hasGuard)
  )

  useEffect(() => {
    if (incident?.actions) {
      setIsReleased(getIsReleased(incident, hasGuard))
    }
  }, [incident])

  // If not dispatched show nothing
  if (!hasDispatched) {
    return null
  }
  // If dispatched but no guard owner then display clock
  if (hasDispatched && !hasGuard && !isReleased) {
    return (
      <Tooltip
        data-testid='IncidentGuardCell:clock'
        hasArrow
        label={timeLabel}
        onOpen={() => setTimeLabel(getTimeSinceDispatched(incident))}
        openDelay={500}
        placement='auto'
        shouldWrapChildren
        textTransform='capitalize'
      >
        <FaClock data-testid='clock-icon' size={24} />
      </Tooltip>
    )
  } else {
    // If guard show avatar else show placeholder
    return (
      <Tooltip
        data-testid='IncidentGuardCell:avatar'
        hasArrow
        label={guard?.toLowerCase() ?? 'No guard'}
        openDelay={500}
        placement='auto'
        shouldWrapChildren
        textTransform='capitalize'
      >
        <Avatar data-testid='guard-cell' name={guard} size='xs' />
      </Tooltip>
    )
  }
}
