import React from 'react'

import { CallStatus, useCall } from '../hooks/useCall'
import { CgCloseO, CgMinimizeAlt } from 'react-icons/cg'

import { Box } from '@chakra-ui/react'

import { CallActionIcon } from './CallActionIcon'
import { CallEnded } from './CallEnded'
import { CallHeader } from './CallHeader'
import { CallLive } from './CallLive'

export const CallExpanded = ({ callStatus }: { callStatus: CallStatus }) => {
  const { callDuration, closeCall, minimizeCall } = useCall()

  return (
    <Box display='flex' flex={1} flexDirection='column' height='100%' w='410px'>
      <Box alignItems='center' display='flex' w='410px'>
        <CallHeader
          duration={callDuration}
          subtitle={callStatus === CallStatus.Ended ? 'Call Ended' : 'Live'}
          title='Local Law Enforcement'
        />
        {callStatus === CallStatus.Ended ? (
          <CallActionIcon
            icon={<CgCloseO data-testid='CallClose:icon' size={24} />}
            label='Close'
            onClick={closeCall}
          />
        ) : (
          <CallActionIcon
            icon={<CgMinimizeAlt data-testid='CallExpanded:icon' size={24} />}
            label='Collapse'
            onClick={minimizeCall}
          />
        )}
      </Box>
      <Box bgColor='#e2e8f0' h='2px' ml='-20px' mt='20px' w='450px' />
      {callStatus === CallStatus.Ended ? <CallEnded /> : <CallLive />}
    </Box>
  )
}
