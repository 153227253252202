import { Box, CloseButton, Spacer } from '@chakra-ui/react'

export const ErrorToast = ({
  value,
  onClose,
}: {
  value: string
  onClose?: () => void
}) => {
  return (
    <Box
      alignItems='center'
      bg='#fff'
      borderRadius='4px'
      boxShadow='3px 3px 5px 6px rgba(0, 0, 0, 0.2)'
      data-testid='ErrorToast'
      display='flex'
      justifyContent='space-between'
      mt='20px'
      px='4'
      py='3'
    >
      <Box as='span' mr={4}>
        ❌
      </Box>
      <Box
        as='span'
        color='#353849'
        fontWeight='bold'
        letterSpacing='-0.4px'
      >{`${value}`}</Box>
      <Spacer />
      <CloseButton ml={4} onClick={onClose} />
    </Box>
  )
}
