import { Center, Circle, Tooltip } from '@chakra-ui/react'

interface RadioToggleIProps {
  isActive: boolean
  onClick: () => void
  tooltipLabel?: string
  disabled?: boolean
}

export const RadioToggle = ({
  isActive,
  onClick,
  tooltipLabel,
  disabled = false,
}: RadioToggleIProps) => {
  return (
    <Tooltip label={tooltipLabel} openDelay={200} placement='left'>
      <Circle
        _focus={{
          outline: '3px solid',
          outlineColor: 'brand.900',
        }}
        _hover={{
          backgroundColor: '#f8f8f8',
          border: '1px solid #bbbbbb',
        }}
        as='button'
        disabled={disabled}
        onClick={onClick}
        size='22px'
      >
        <Center>
          {isActive ? (
            <Circle
              backgroundColor='brand.100'
              data-testid='RadioToggle:center'
              h='12px'
              w='12px'
            />
          ) : null}
        </Center>
      </Circle>
    </Tooltip>
  )
}
