import { createContext, useContext, useRef, useState } from 'react'

import { useStopWatch } from './useStopWatch'
import { FeatureFlag, flagProvider } from '@/hooks/useFeatureFlags'
import { useToasts } from '@/hooks/useToasts'
import { useDispatch, useSelector } from 'react-redux'

import { Call, Device } from '@twilio/voice-sdk'

import { MixpanelDataIProps } from '@/features/incident-drawer'
import {
  useCreateEmergencyCallMutation,
  useEmergencyCallSetupLazyQuery,
  useUpdateEmergencyCallMutation,
} from '@/graphql/generated/hooks'
import {
  closeEmergencyCall,
  expandEmergencyCall,
  minimizeEmergencyCall,
  openEmergencyCall,
  selectEmergencyCall,
  toggleMuteEmergencyCall,
} from '@/redux/ui/uiSlice'
import { EmergencyCallPayload } from '@/redux/ui/uiSlice.types'
import { BUTTON_PRESS, mixpanel } from '@/utils/analytics'

const CallContext = createContext<UseCallValuesIProps | null>(null)

export enum CallStatus {
  Initial,
  Connecting,
  Ended,
}

interface UseCallValuesIProps {
  isOpen: boolean
  isExpanded: boolean
  isMuted: boolean
  callDuration: string
  callStatus: CallStatus
  disconnectCall: () => void
  toggleMuteCall: () => void
  expandCall: () => void
  minimizeCall: () => void
  closeCall: () => void
  openCall: (
    emergencyCallPayload: EmergencyCallPayload,
    mixpanelData: MixpanelDataIProps
  ) => void
}

export const CallProvider = ({ children }: { children?: React.ReactNode }) => {
  const [getCallSetup] = useEmergencyCallSetupLazyQuery()
  const dispatch = useDispatch()
  const { showError } = useToasts()
  const [createEmergencyCall] = useCreateEmergencyCallMutation()
  const [updateEmergencyCall] = useUpdateEmergencyCallMutation()
  const {
    time: callDuration,
    start: startTimer,
    reset: resetTimer,
    stop: stopTimer,
  } = useStopWatch()

  const { isExpanded, isOpen, isMuted } = useSelector(selectEmergencyCall)

  const [analyticsData, setAnalyticsData] = useState<MixpanelDataIProps>(null)
  const [callStatus, setCallStatus] = useState<CallStatus>(CallStatus.Initial)
  const [device, setDevice] = useState<Device>(null)
  const [call, setCall] = useState<Call>(null)
  const emergencyCallId = useRef<string>(null)

  // Internal handlers
  // - they generally modify internal call provider state
  const handleCallSetup = async (
    incidentId: string,
    emergencyNumber: string
  ) => {
    try {
      const { data } = await getCallSetup({
        variables: { incidentId },
      })
      const token = data?.emergencyCallingAccessToken?.token
      const callerId =
        data?.incident?.facility?.emergencyCalling?.outboundPhoneNumber

      if (callerId === undefined) {
        throw new Error()
      }
      // Connect to call provider, create call and set event handlers
      const device = new Device(token, { appName: 'HiveWatch Web App' })
      const call = await device.connect({
        params: { callerId, emergencyNumber },
      })

      call.on('accept', (c: Call) => handleCallAccept(c, incidentId))
      call.on('disconnect', () => handleCallDisconnect())
      setDevice(device)
      setCall(call)
    } catch {
      closeCall()
      showError('Error placing call. Please try again')
    }
  }

  const handleCallAccept = async (call: Call, incidentId: string) => {
    setCallStatus(CallStatus.Connecting)
    startTimer()

    const { data } = await createEmergencyCall({
      variables: {
        input: {
          incidentId: incidentId,
          externalCallId: call?.parameters?.CallSid,
          callStartTime: new Date().toISOString(),
        },
      },
    })
    emergencyCallId.current = data?.createEmergencyCall?.emergencyCall?.id
  }

  const handleCallDisconnect = () => {
    setCallStatus(CallStatus.Ended)
    stopTimer()

    if (emergencyCallId.current) {
      updateEmergencyCall({
        variables: {
          input: {
            id: emergencyCallId.current,
            callEndTime: new Date().toISOString(),
          },
        },
      })
    }
  }

  const handleCallReset = () => {
    setCallStatus(CallStatus.Initial)
    resetTimer()

    device?.destroy()
    setDevice(null)
    setCall(null)
  }

  // Exposed handlers
  // - they generally modify call Redux state
  const openCall = (
    emergencyCallPayload: EmergencyCallPayload,
    mixpanelData: MixpanelDataIProps
  ) => {
    const { incidentId, emergencyNumber } = emergencyCallPayload
    setAnalyticsData(mixpanelData)
    mixpanel.track(`${BUTTON_PRESS} Emergency Call`, mixpanelData)
    handleCallSetup(incidentId, emergencyNumber)
    dispatch(openEmergencyCall(emergencyCallPayload))
  }

  const closeCall = () => {
    handleCallReset()
    dispatch(closeEmergencyCall())
  }

  const expandCall = () => {
    mixpanel.track(`${BUTTON_PRESS} Expand Emergency Call`, analyticsData)
    dispatch(expandEmergencyCall())
  }

  const minimizeCall = () => {
    mixpanel.track(`${BUTTON_PRESS} Collapse Emergency Call`, analyticsData)
    dispatch(minimizeEmergencyCall())
  }

  const disconnectCall = () => {
    mixpanel.track(`${BUTTON_PRESS} End Emergency Call`, analyticsData)
    call?.disconnect()
  }

  const toggleMuteCall = () => {
    mixpanel.track(
      `${BUTTON_PRESS} ${isMuted ? 'Unmute' : 'Mute'} Emergency Call`,
      analyticsData
    )
    call?.mute(!isMuted)
    dispatch(toggleMuteEmergencyCall())
  }

  return (
    <CallContext.Provider
      value={{
        isOpen,
        isExpanded,
        isMuted,
        callDuration,
        callStatus,
        openCall,
        closeCall,
        expandCall,
        minimizeCall,
        toggleMuteCall,
        disconnectCall,
      }}
    >
      {children}
    </CallContext.Provider>
  )
}

export const CallFlaggedProvider = flagProvider(
  CallProvider,
  FeatureFlag.emergencyCall
)

export const useCall = () => useContext(CallContext)
