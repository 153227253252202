import { Spinner as ChakraSpinner, Flex, ThemingProps } from '@chakra-ui/react'

interface SpinnerIProps {
  height?: number
  size?: ThemingProps<'Spinner'>['size']
}

export const Spinner = ({ height = 12, size = 'md' }: SpinnerIProps) => {
  return (
    <Flex alignItems='center' flex={1} height={height} justifyContent='center'>
      <ChakraSpinner
        color='#298bbd'
        emptyColor='gray.300'
        size={size}
        thickness='4px'
      />
    </Flex>
  )
}
