import { Box, BoxProps } from '@chakra-ui/react'

export const Card = (props: BoxProps) => {
  return (
    <Box
      bg='white'
      px='10'
      py='8'
      rounded='5px'
      shadow='0px 2px 6px 2px rgba(0, 0, 0, 0.1)'
      {...props}
    />
  )
}
