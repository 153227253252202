import { IncidentsListType, RefetchIncidentsFnType } from '../types/types'
import { columns } from './Columns'
import { DataTable } from './DataTable'

export const IncidentsTable = ({
  incidents,
  totalIncidents,
  refetch,
}: {
  incidents?: IncidentsListType
  totalIncidents?: number
  refetch?: RefetchIncidentsFnType
}) => {
  const tableData = incidents.map((e) => {
    const device = e?.node?.devices[0]
    return {
      id: e?.node?.id,
      displayId: e?.node?.displayId,
      facilityName: {
        name: e?.node?.facility?.name,
        shortName: e?.node?.facility?.shortName,
        archived: e?.node?.facility?.archived,
      },
      incidentName: e?.node?.name,
      floor: e?.node?.floor?.name,
      date: e?.node?.createdAt,
      incidentStatus: e?.node?.status,
      incidentType: e?.node?.type,
      source: device?.type || e?.node?.source,
      operator: e?.node?.owner
        ? `${e?.node?.owner?.firstName} ${e?.node?.owner?.lastName}`
        : null,
      guard: e?.node ? e?.node : null,
    }
  })

  return tableData ? (
    <DataTable
      columns={columns}
      data={tableData}
      refetch={refetch}
      totalIncidents={totalIncidents}
    />
  ) : null
}
