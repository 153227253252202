import { useEffect, useState } from 'react'

import {
  useManagedAccountsQuery,
  useTechniciansQuery,
} from '@/graphql/generated/hooks'

import {
  AccountGroupsIProps,
  AccountIProps,
  RoleExtended,
} from '../types/types'
import { filterAccounts, groupAccounts } from '../utils/helpers'

export const useManagedAccounts = () => {
  const { loading: isUsersLoading, data: usersData } = useManagedAccountsQuery({
    nextFetchPolicy: 'network-only',
    errorPolicy: 'all',
  })
  const { loading: isTechniciansLoading, data: techniciansData } =
    useTechniciansQuery({
      nextFetchPolicy: 'network-only',
    })

  const [filter, setFilter] = useState('')
  const [groups, setGroups] = useState<AccountGroupsIProps>([])

  useEffect(() => {
    const users = (usersData?.managedAccounts.edges.map((e) => e.node) ??
      []) as AccountIProps[]
    const technicians = (techniciansData?.technicians.edges.map((e) => ({
      ...e.node,
      roleName: RoleExtended.TECHNICIAN,
    })) ?? []) as AccountIProps[]

    const accounts = [...users, ...technicians]
    const accountsFiltered = filterAccounts(accounts, filter)
    const accountsGrouped = groupAccounts(accountsFiltered)

    setGroups(accountsGrouped)
  }, [usersData, techniciansData, filter])

  const isLoading = isUsersLoading || isTechniciansLoading

  return { groups, isLoading, filter, setFilter }
}
