export const CallStart = ({
  className,
  strokeColor = '#fff',
}: {
  className?: string
  strokeColor?: string
}) => {
  return (
    <svg
      className={className}
      fill={strokeColor}
      height='47'
      viewBox='0 0 49 47'
      width='49'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.9268 12.5331C16.4125 14.8916 15.3535 15.6014 15.3535 15.6014L12.2643 17.4637C11.0302 18.1659 11.707 19.6237 11.707 19.6237C16.5319 29.6604 21.8664 32.6829 23.8091 34.0262C25.2184 34.9955 26.0703 34.3086 26.0703 34.3086L30.0513 31.1259C31.4605 29.7978 32.5433 30.7137 32.5433 30.7137L40.3858 36.3388C41.7235 37.2395 41.1183 39.056 40.155 40.6283C39.1995 42.1777 35.2345 45.8794 30.306 45.0246C25.3776 44.1774 16.6115 40.8115 10.6162 32.202C4.5651 23.4934 2.45519 15.8761 2.45519 15.8761C-0.108553 7.96126 6.46801 3.7405 6.46801 3.7405C11.6433 0.611181 14.0637 3.94658 14.0637 3.94658C14.0637 3.94658 15.4411 10.1747 15.9268 12.5331Z'
        fill={strokeColor}
        stroke-linejoin='round'
        stroke-width='3'
      />
      <path
        d='M33.1991 1.94116C32.1216 1.94116 31.2481 2.81468 31.2481 3.89222V9.98176H23.5499C22.4723 9.98176 21.5988 10.8553 21.5988 11.9328V14.8594C21.5988 15.9369 22.4723 16.8104 23.5499 16.8104H31.2481V24.3573C31.2481 25.4349 32.1216 26.3084 33.1991 26.3084H36.1257C37.2033 26.3084 38.0768 25.4349 38.0768 24.3573V16.8104H45.0874C46.165 16.8104 47.0385 15.9369 47.0385 14.8594V11.9328C47.0385 10.8553 46.165 9.98176 45.0874 9.98176H38.0768V3.89222C38.0768 2.81468 37.2033 1.94116 36.1257 1.94116H33.1991Z'
        fill={strokeColor}
        stroke-linejoin='round'
        stroke-width='3'
      />
    </svg>
  )
}
