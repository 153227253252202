import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa'

import { Box, HStack, Text } from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'

export interface PageMessageToastIProps {
  show?: boolean
  success?: boolean
  message?: string
}

export const PageMessageToast = ({
  show,
  success = true,
  message = 'Success!',
}: PageMessageToastIProps) => {
  return (
    <AnimatePresence>
      {show && (
        <HStack
          animate={{ top: '36px', x: '-50%', opacity: 1 }}
          as={motion.div}
          bgColor={success ? '#D3F8EF' : '#FFEDEA'}
          data-testid={success ? 'SuccessToast' : 'ErrorToast'}
          exit={{ top: '-36px', x: '-50%', opacity: 0 }}
          initial={{ top: '-36px', x: '-50%', opacity: 0 }}
          left='50%'
          maxW='767px'
          pos='absolute'
          px='40px'
          py='20px'
          rounded='10px'
          spacing={4}
          w='full'
          zIndex='11'
        >
          <Box w='24px'>
            {success ? (
              <FaCheckCircle color='#00513E' size={24} />
            ) : (
              <FaExclamationCircle color='#E24A36' size={24} />
            )}
          </Box>
          <Text
            color={success ? '#00513E' : '#931100'}
            fontSize='20px'
            fontWeight={300}
            lineHeight='25px'
            noOfLines={3}
          >
            {message}
          </Text>
        </HStack>
      )}
    </AnimatePresence>
  )
}
