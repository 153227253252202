import Svg, { Path, SvgProps } from 'react-native-svg'

export const Info = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox='0 0 15 13' fill='none' {...props}>
    <Path
      d='M7.50869 3.61206C7.30975 3.61206 7.13733 3.54906 6.99143 3.42306C6.85217 3.29106 6.78254 3.13206 6.78254 2.94606C6.78254 2.76606 6.85217 2.61306 6.99143 2.48706C7.13733 2.35506 7.30975 2.28906 7.50869 2.28906C7.71427 2.28906 7.88669 2.35506 8.02596 2.48706C8.17185 2.61306 8.2448 2.76606 8.2448 2.94606C8.2448 3.13206 8.17185 3.29106 8.02596 3.42306C7.88669 3.54906 7.71427 3.61206 7.50869 3.61206ZM6.96159 8.91306V4.41306H8.0558V8.91306H6.96159Z'
      fill={props.color}
    />
    <Path
      d='M13.8412 6.03906C13.8412 9.09387 11.0909 11.6371 7.61158 11.6371C4.13226 11.6371 1.38196 9.09387 1.38196 6.03906C1.38196 2.98426 4.13226 0.441024 7.61158 0.441024C11.0909 0.441024 13.8412 2.98426 13.8412 6.03906Z'
      stroke={props.color}
      strokeWidth='0.803924'
    />
  </Svg>
)
