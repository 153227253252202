import { useState } from 'react'

import { useDisclosure } from '@chakra-ui/react'

import { useDeleteSavedFilterMutation } from '@/graphql/generated/hooks'

import { SavedFilterI } from '../types/generic'

export const useDeleteFilter = () => {
  const [filter, setFilter] = useState<SavedFilterI>(null)
  const [deleteSavedFilter, { loading: isDeleteLoading }] =
    useDeleteSavedFilterMutation()

  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure()

  const onConfirmFilterDelete = (filter: SavedFilterI) => {
    setFilter(filter)
    onDeleteModalOpen()
  }

  const deleteFilter = async () => {
    await deleteSavedFilter({
      variables: {
        input: {
          id: filter.id,
        },
      },
      refetchQueries: ['SavedIncidentsFilters', 'ActiveIncidentsFilter'],
    })
    onDeleteModalClose()
  }

  return {
    filter,
    deleteFilter,
    isDeleteLoading,
    isDeleteModalOpen,
    onConfirmFilterDelete,
    onDeleteModalClose,
  }
}
