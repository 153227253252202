// Component style overrides
import { extendTheme } from '@chakra-ui/react'

import { buttonStyles } from './components/button'
import { drawerStyles } from './components/drawer'
import { inputStyles } from './components/input'
import { tabStyles } from './components/tab'

export const SIDE_BAR_WIDTH_CLOSED = '60px'
export const SIDE_BAR_WIDTH_OPEN = '240px'
export const CALL_CONTAINER_Z_INDEX = 1401

const fonts = {
  heading: 'Euclid Square',
  body: 'Euclid Square',
}

const colors = {
  brand: {
    100: '#2E6CDB',
    200: '#6096F5',
    300: '#e4effb',
    400: '#2D2E41',
    500: '#353849',
    600: '#8E919F',
    700: '#1CFDE5',
    800: '#07DB94',
    900: '#3279C7',
  },
  bg: {
    100: '#F0F5FD',
    200: '#C9DBF8',
    300: '#DCE8FA',
  },
  btn: {
    100: '#548BEF',
    200: '#96ABD2',
  },
  link: { 100: '#3D7FCE' },
  incident: {
    active: {
      100: '#D01030',
    },
    progress: {
      100: '#D16A1E',
    },
    resolved: {
      100: '#29D11E',
    },
  },
  success: {
    100: '#009a0d',
  },
  alert: {
    100: '#fd0e0a',
  },
}

const components = {
  Tooltip: {
    baseStyle: {
      borderRadius: 'md',
    },
  },
  Button: buttonStyles,
  Input: inputStyles,
  Drawer: drawerStyles,
  Modal: {
    parts: ['overlay', 'item'],
    baseStyle: {
      overlay: {
        background: 'rgba(48,56,78,0.7)',
      },
    },
  },
  Skeleton: {
    baseStyle: {
      borderRadius: 'md',
    },
  },
  Tabs: tabStyles,
}

export const theme = extendTheme({
  colors,
  fonts,
  components,
})
