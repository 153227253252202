import { usePermissions } from '@/hooks/usePermissions'
import { useSelector } from 'react-redux'

import { AccordionItem, AccordionPanel } from '@chakra-ui/react'
import {
  AccordionButton,
  AccordionIcon,
  Box,
  Circle,
  Flex,
} from '@chakra-ui/react'

import { selectEditMode } from '@/redux/ui/uiSlice'
import { removeUnderscoreAndCapitalize } from '@/utils/formats'

import { RoleExtended } from '../types/types'
import { getPascalCaseRole } from '../utils/helpers'
import { AccountAdd } from './AccountAdd'
import { getRoleDisplayName } from './UserRoles'

const getAccountListEmptyMessage = (role: RoleExtended) => {
  switch (role) {
    case RoleExtended.EMPLOYEE:
      return 'Employees list is empty. Add your Employees to share Security Communications and enrich Access Data.'
    case RoleExtended.GUARD:
      return 'Guards list is empty. Add your Guards to dispatch to Incidents.'
    case RoleExtended.PROGRAM_MANAGER:
      return 'Can’t see an empty list since this is only available as a Program Manager.'
    case RoleExtended.TECHNICIAN:
      return 'Technicians list is empty. Add your Technicians to Order Repairs for Security Devices.'
    case RoleExtended.OPERATOR:
      return 'Operators list is empty. Add your Operators to respond to Incidents.'
    case RoleExtended.IMPLEMENTER:
      return 'Implementers list is empty.'
  }
}

export const AccountListEmpty = ({
  role,
  filter,
}: {
  role: RoleExtended
  filter: string
}) => {
  if (filter !== '') {
    return (
      <Box px='4' py='6' textAlign='center'>
        No {`${getRoleDisplayName(role)}s`} with name{' '}
        <Box as='span' fontStyle='italic' fontWeight='bold'>
          {filter}
        </Box>
      </Box>
    )
  } else
    return (
      <AccordionPanel bgColor='transparent' borderTop='1px solid #eaedf3' p='0'>
        <Box px='4' py='6' textAlign='center'>
          {getAccountListEmptyMessage(role)}
        </Box>
      </AccordionPanel>
    )
}

export const AccountGroup = ({
  role,
  filter,
  children,
}: {
  role: RoleExtended
  filter: string
  children: React.ReactChild[]
}) => {
  const isEditMode = useSelector(selectEditMode)
  const numberOfAccounts = children.length
  const { hasCRUDPermission } = usePermissions()
  const hasPermission = hasCRUDPermission(role)
  return (
    <AccordionItem
      _hover={{
        boxShadow:
          numberOfAccounts === 0
            ? 'base'
            : '0px 2px 5px 6px rgba(0, 0, 0, 0.15)x',
      }}
      border='none'
      borderRadius='5px'
      boxShadow='0px 2px 6px 2px rgba(0, 0, 0, 0.1)'
      mb='6'
      overflow='hidden'
      p='0'
      transition='ease-in-out'
      transitionDuration='300ms'
      transitionProperty='all'
    >
      <h2 style={{ position: 'relative' }}>
        <AccordionButton
          _focus={{ boxShadow: 'none' }}
          bg='white'
          data-testid={`settingsPage_managedAccounts_section${getPascalCaseRole(
            role
          )}GroupButton`}
          p='4'
        >
          <Box
            flex='1'
            fontSize='20px'
            fontWeight='extrabold'
            letterSpacing='-0.6px'
            textAlign='left'
          >
            {`${removeUnderscoreAndCapitalize(role)}s`}
          </Box>
          <Flex alignItems='center'>
            {isEditMode && hasPermission && <AccountAdd role={role} />}
            <Circle
              bg='#14327A'
              color='white'
              data-testid={`settingsPage_managedAccounts_count${getPascalCaseRole(
                role
              )}`}
              fontSize='14px'
              fontWeight='bold'
              mr='3'
              size='20px'
            >
              {numberOfAccounts}
            </Circle>
            <AccordionIcon />
          </Flex>
        </AccordionButton>
      </h2>
      {children.length ? (
        children
      ) : (
        <AccountListEmpty filter={filter} role={role} />
      )}
    </AccordionItem>
  )
}
