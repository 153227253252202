import { Checkbox as ChakraCheckbox, CheckboxProps } from '@chakra-ui/react'
import styled from '@emotion/styled'

const StyledCheckbox = styled(ChakraCheckbox)`
  .chakra-checkbox__control {
    border: 3px solid
      ${(props) => (props.borderColor ? props.borderColor : '#9191ae')};
    border-radius: 2px;
    height: 25px;
    width: 25px;

    &[aria-checked='true'],
    &[data-checked] {
      background: ${(props) =>
        props.color
          ? props.color
          : 'linear-gradient(158.67deg, #3379c7 0%, #6096f5 100%)'};
      border-color: transparent;
    }

    &:focus,
    &[data-focus] {
      border-color: transparent;
      box-shadow: 0 0 0 3px
        ${(props) => props.boxShadow ?? 'rgba(44, 133, 244, 0.7)'};
    }

    svg {
      width: 16px !important;
    }
  }
`

export interface CheckboxIProps extends CheckboxProps {
  /**
   * The react node provided as children to checkbox.
   * @type React.ReactNode
   */
  children?: React.ReactNode
  [key: string]: unknown
}

export const Checkbox = ({ children, ...rest }: CheckboxIProps) => {
  return (
    <StyledCheckbox size='lg' {...rest}>
      {children}
    </StyledCheckbox>
  )
}
