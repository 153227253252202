import { useSavedIncidentsFilterQuery } from '@/graphql/generated/hooks'
import { IncidentFilterCriteria } from '@/graphql/generated/schemas'

import { SavedFilterI } from '../types/generic'

interface UseSavedFilterIProps {
  savedFilter: SavedFilterI
}

export const useSavedFilter = ({
  savedFilter,
}: UseSavedFilterIProps): IncidentFilterCriteria => {
  const { data: savedFilterData } = useSavedIncidentsFilterQuery({
    fetchPolicy: 'network-only',
    variables: { id: savedFilter?.id },
    skip: savedFilter === undefined,
  })

  return savedFilterData?.savedFilter?.filterCriteria?.incident
}
