export const LinkIcon = ({
  width = '16',
  height = '16',
  fillColor = 'white',
  className,
}: {
  width?: string
  height?: string
  fillColor?: string
  className?: string
}) => (
  <svg
    className={className}
    fill='none'
    height={height}
    viewBox='0 0 14 6'
    width={width}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5.354 0H3C2.20435 1.18561e-08 1.44129 0.316071 0.87868 0.87868C0.31607 1.44129 0 2.20435 0 3C0 3.79565 0.31607 4.55871 0.87868 5.12132C1.44129 5.68393 2.20435 6 3 6H6C6.48007 6.00025 6.9532 5.88529 7.37963 5.66476C7.80606 5.44424 8.17333 5.1246 8.45061 4.73269C8.72788 4.34079 8.90705 3.88806 8.97307 3.41255C9.03909 2.93703 8.99002 2.45262 8.83 2H8C7.91333 2 7.83 2.01033 7.75 2.031C7.91865 2.33551 8.0049 2.67876 8.00024 3.02681C7.99557 3.37487 7.90014 3.71569 7.72339 4.01556C7.54664 4.31544 7.29468 4.56399 6.99242 4.73664C6.69017 4.90929 6.34809 5.00007 6 5H3C2.46957 5 1.96086 4.78929 1.58579 4.41421C1.21071 4.03914 1 3.53043 1 3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H4.535C4.753 0.624 5.029 0.286 5.354 0Z'
      fill={fillColor}
    />
    <path
      d='M8 4.12482e-07C7.51993 -0.00025131 7.0468 0.114713 6.62037 0.335236C6.19395 0.55576 5.82667 0.8754 5.54939 1.26731C5.27212 1.65921 5.09295 2.11194 5.02693 2.58745C4.96091 3.06297 5.00998 3.54738 5.17 4H6.268C6.09247 3.69597 6.00006 3.35108 6.00005 3.00001C6.00005 2.64894 6.09246 2.30406 6.26799 2.00002C6.44352 1.69599 6.69599 1.44351 7.00002 1.26797C7.30405 1.09243 7.64893 1.00001 8 1H11C11.5304 1 12.0391 1.21071 12.4142 1.58579C12.7893 1.96086 13 2.46957 13 3C13 3.53043 12.7893 4.03914 12.4142 4.41421C12.0391 4.78929 11.5304 5 11 5H9.465C9.24772 5.3756 8.97077 5.71335 8.645 6H11C11.394 6 11.7841 5.9224 12.1481 5.77164C12.512 5.62088 12.8427 5.3999 13.1213 5.12132C13.3999 4.84275 13.6209 4.51203 13.7716 4.14805C13.9224 3.78407 14 3.39397 14 3C14 2.60603 13.9224 2.21593 13.7716 1.85195C13.6209 1.48797 13.3999 1.15726 13.1213 0.87868C12.8427 0.600104 12.512 0.379126 12.1481 0.228362C11.7841 0.0775979 11.394 4.06611e-07 11 4.12482e-07H8Z'
      fill={fillColor}
    />
  </svg>
)
