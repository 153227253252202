import { useRef } from 'react'

import {
  AlertDialog,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react'

import { BasicAlertHeader } from './BasicAlertHeader'

interface BasicAlertDialogIProps {
  children?: React.ReactNode
  dataTestId?: string
  isOpen: boolean
  isCentered?: boolean
  onClose: () => void
  title: string | JSX.Element
}

export const BasicAlertDialog = ({
  children,
  dataTestId,
  isOpen,
  isCentered,
  onClose,
  title,
}: BasicAlertDialogIProps) => {
  const cancelRef = useRef()
  return (
    <AlertDialog
      isCentered={isCentered}
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      motionPreset='slideInBottom'
      onClose={onClose}
    >
      <AlertDialogOverlay
        {...(dataTestId ? { 'data-testid': `${dataTestId}:overlay` } : {})}
      />

      <AlertDialogContent>
        <BasicAlertHeader title={title} />
        <AlertDialogCloseButton
          {...(dataTestId ? { 'data-testid': `${dataTestId}:close` } : {})}
        />
        {children}
      </AlertDialogContent>
    </AlertDialog>
  )
}
