import { useEffect, useRef, useState } from 'react'

import { MdPause, MdPlayArrow } from 'react-icons/md'

import { Flex, HStack, Square } from '@chakra-ui/react'

import { AudioWave, formWaveSurferOptions } from '../AudioWave'

export interface AudioPlayerIProps {
  url: string
  onPlay?: () => void
}

export const AudioPlayer = ({ url, onPlay }: AudioPlayerIProps) => {
  const waveformRef = useRef(null)
  const wavesurfer = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)

  useEffect(() => {
    if (url) {
      create(url)
    }

    return () => {
      if (wavesurfer.current) {
        wavesurfer.current.destroy()
      }
    }
  }, [url])

  const create = async (url: string) => {
    const WaveSurfer = (await import('wavesurfer.js')).default

    const options = formWaveSurferOptions(waveformRef.current)
    wavesurfer.current = WaveSurfer.create(options)

    wavesurfer.current.load(url)

    wavesurfer.current.on('finish', function () {
      setIsPlaying(false)
      wavesurfer.current.stop()
    })
  }

  const handlePlayPause = (e: React.MouseEvent) => {
    onPlay?.()
    e.stopPropagation()
    setIsPlaying(!isPlaying)
    wavesurfer.current.playPause()
  }

  return (
    <Flex
      alignItems='center'
      bg='#e3eefb'
      maxW='375px'
      mt='20px'
      p='4px'
      rounded='5px'
      w='full'
    >
      <HStack spacing={1}>
        {!isPlaying && (
          <Square
            bg='white'
            cursor='pointer'
            data-testid='audioPlayer_playButton'
            h='30px'
            onClick={handlePlayPause}
            rounded='5px'
            w='30px'
          >
            <MdPlayArrow color='#2d2e41' size='26px' />
          </Square>
        )}
        {isPlaying && (
          <Square
            bg='white'
            cursor='pointer'
            data-testid='audioPlayer_pauseButton'
            h='30px'
            onClick={handlePlayPause}
            rounded='5px'
            w='30px'
          >
            <MdPause color='#2d2e41' size='26px' />
          </Square>
        )}
      </HStack>
      <AudioWave
        id='waveform'
        onClick={(e) => e.stopPropagation()}
        ref={waveformRef}
      />
    </Flex>
  )
}
