import {
  FloorsByFacilityIdsQuery,
  IncidentFiltersEnrichmentQuery,
} from '@/graphql/generated/operations'
import { IncidentFilterCriteria } from '@/graphql/generated/schemas'
import { Filter, MultiFilter } from '@/redux/types/filters'
import { OptionI } from '@/utils/forms/optionBuilders'

export const combineSourceType = (criteria: IncidentFilterCriteria[]) => {
  if (!criteria) return []

  let combinedArray = []
  criteria.forEach((obj) => {
    if (obj.sourceIn) {
      combinedArray = combinedArray.concat(obj.sourceIn)
    }
    if (obj.deviceTypeIn) {
      combinedArray = combinedArray.concat(obj.deviceTypeIn)
    }
  })

  return [...new Set(combinedArray)]
}

export const combineIncidentType = (criteria: IncidentFilterCriteria[]) => {
  if (!criteria) return []

  let combinedArray = []
  criteria.forEach((obj) => {
    if (obj.typeIn) {
      combinedArray = combinedArray.concat(obj.typeIn)
    }
    if (obj.manualIncidentCategoryIdIn) {
      combinedArray = combinedArray.concat(obj.manualIncidentCategoryIdIn)
    }
  })

  return [...new Set(combinedArray)]
}

export const getLabels = (
  enrichmentData: IncidentFiltersEnrichmentQuery & FloorsByFacilityIdsQuery
) => ({
  facilityLabels: Object.fromEntries(
    enrichmentData?.facilityNames?.edges
      ?.map((e) => e.node)
      ?.map((e) => [e.id, `${e.name} - (${e.shortName})`]) || []
  ),
  floorLabels: Object.fromEntries(
    enrichmentData?.floors?.edges
      ?.map((e) => e.node)
      ?.map((e) => [e.id, `${e.name} - (${e.facility?.name})`]) || []
  ),
  deviceLabels: Object.fromEntries(
    enrichmentData?.devices?.edges
      ?.map((e) => e.node)
      ?.map((e) => [e.id, `${e.name} - (${e.facility?.name})`]) || []
  ),
})

export const getFilterFromLabels = (
  ids: string[],
  labels: Record<string, string>
): MultiFilter =>
  ids.map((id) => ({
    value: id,
    label: labels[id],
  }))

export const getFilterFromOptions = <T>(
  values: T[],
  options: OptionI<T>[]
): MultiFilter<T> =>
  options.filter((option) => values.includes(option.value)) || null

export const getSingleFilterFromOptions = <T>(
  value: T,
  options: OptionI<T>[]
): Filter<T> => options.find((option) => option.value === value) || null

export const getCreatedAtGteTimestamp = (now: number, interval: number) =>
  new Date(now - interval * 1000).toISOString()

/**
 * This function takes array of filter objects with values of different types end extracts an
 * array of values for specified type.
 *
 * For input = [ { value: a1 as A }, { value: a2 as A }, { value: b1 as B } ]
 *    filterByType(input, A) => [ a1, a2 ]
 *    filterByType(input, B) => [ b1 ]
 */
export const filterByType = <T>(
  multiFilter: MultiFilter<T | unknown>,
  _enum: T
): T[] => {
  return (
    multiFilter
      ?.filter((f) => Object.values(_enum).includes(f.value as T))
      .map((f) => f.value as T) || []
  )
}

export const removeNullValues = (
  obj: Record<string, unknown>
): Record<string, unknown> => {
  return Object.fromEntries(
    // eslint-disable-next-line no-unused-vars
    Object.entries(obj).filter(([_, value]) => value !== null)
  )
}

export const removeTypename = (
  obj: Record<string, unknown>
): Omit<Record<string, unknown>, '__typename'> =>
  Object.fromEntries(
    // eslint-disable-next-line
    Object.entries(obj).filter(([key]) => key !== '__typename')
  )
