import * as React from 'react'

export const PACS = ({
  className,
  strokeColor = '#353849',
}: {
  className?: string
  strokeColor?: string
}) => (
  <svg
    className={className}
    fill='none'
    height={24}
    viewBox='0 0 25 24'
    width={25}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M18.2727 16.8889L13.3838 12V8.11333C14.8016 7.6 15.8283 6.25556 15.8283 4.66667C15.8283 2.63778 14.1905 1 12.1616 1C10.1327 1 8.49495 2.63778 8.49495 4.66667C8.49495 6.25556 9.52162 7.6 10.9394 8.11333V12L6.05051 16.8889H1.16162V23H7.27273V19.2722L12.1616 14.1389L17.0505 19.2722V23H23.1616V16.8889H18.2727Z'
      fill={strokeColor}
    />
  </svg>
)
