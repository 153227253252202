import { Box } from '@chakra-ui/react'

export const DrawerScrollContainer = ({
  children,
  ...rest
}: {
  children: React.ReactNode
  [key: string]: unknown
}) => (
  <Box
    css={{
      '&::-webkit-scrollbar': {
        display: 'none',
        width: '0',
      },
    }}
    h='100%'
    overflowX='hidden'
    overflowY='scroll'
    padding='16px'
    paddingBottom='60px'
    {...rest}
  >
    {children}
  </Box>
)
