const devHostNames = [
  'localhost',
  'vercel.hivewatch-dev.com',
  'webapp.sales.hivewatch-dev.com',
  'review.vercel.hivewatch-dev.com',
  'webapp.hivewatch-dev.com',
  'vercel.app',
]

export const getIsDev = () => {
  if (typeof window !== 'undefined') {
    return devHostNames.includes(window?.location?.hostname)
  }
  return false
}
