import { Box } from '@chakra-ui/react'

import { InvalidMessage } from './InvalidMessage'
import { Logo } from './Logo'
import { BackgroundStyled } from './StyledBackground'

export const InvalidRole = () => {
  return (
    <BackgroundStyled alignItems='center' display='flex' flexDirection='column'>
      <Box alignItems='center' position='absolute'>
        <Logo />
        <InvalidMessage />
      </Box>
    </BackgroundStyled>
  )
}
