import { Center, Spinner } from '@chakra-ui/react'
import { motion } from 'framer-motion'

export const VideoProcessing = () => {
  return (
    <Center
      color='#fff'
      fontSize='20px'
      fontWeight={500}
      height='100%'
      justifyContent='center'
      left={0}
      pos='absolute'
      textAlign='center'
      top={0}
      width='100%'
    >
      <Spinner
        animate={{ opacity: 1 }}
        as={motion.div}
        color='white'
        emptyColor='gray.500'
        exit={{ opacity: 0 }}
        initial={{ opacity: 0 }}
        mr='10px'
        size='lg'
        thickness='4px'
      />
      Video clip processing
    </Center>
  )
}
