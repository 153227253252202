import { QueryHookOptions, QueryResult, gql, useQuery } from '@apollo/client'

import { GraphqlClientType } from '@/config/apollo'
import { Maybe } from '@/graphql/generated/schemas'

import { NotificationCount } from '../types'

export type GetNotificationCountQuery = {
  __typename?: 'Query'
  getNotificationCount?: Maybe<NotificationCount>
}

export const GET_NOTIFICATION_COUNT = gql`
  query GetNotificationCount {
    getNotificationCount {
      userId
      updatedAt
      notificationCount
    }
  }
`

export const useGetNotificationCountQuery = (
  options?: QueryHookOptions<GetNotificationCountQuery>
): QueryResult<GetNotificationCountQuery> => {
  return useQuery(GET_NOTIFICATION_COUNT, {
    ...options,
    context: { target: GraphqlClientType.APPSYNC_NOTIFICATIONS },
  })
}
