// @UI
import { forwardRef, useEffect, useState } from 'react'

import { BiFullscreen } from 'react-icons/bi'
import { FaPause, FaPlay } from 'react-icons/fa'
import { IoPlayBack, IoPlayForward } from 'react-icons/io5'

import {
  Box,
  Button,
  Circle,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  VStack,
} from '@chakra-ui/react'
import styled from '@emotion/styled'

import { ToggleButtonSwitch } from '@/components/ui'

interface IProps {
  onSeek: (newValue: unknown) => void
  onRewind: () => void
  onPlayPause: () => void
  onFastForward: () => void
  isPlaying: boolean
  played: number
  onToggleFullScreen: () => void
  showToggleLiveButton?: boolean
  liveEnabled?: boolean
  onToggleLive?: (boolean) => void
  liveOnly?: boolean
}
interface VideoControlButtonIProps {
  ariaLabel: string
  handleOnClick: () => void
  children: string
}

interface VideoControlIconButtonIProps {
  ariaLabel: string
  handleOnClick: () => void
  icon: React.ReactElement
  [key: string]: unknown
}

const Wrapper = styled.div`
  visibility: hidden;
  display: flex;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 150px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
`

const VideoControlButton = ({
  ariaLabel,
  handleOnClick,
  children,
}: VideoControlButtonIProps) => {
  return (
    <Button
      _active={{
        bg: 'rgb(226 232 240 / 20%)',
      }}
      _focus={{
        bg: 'rgb(226 232 240 / 20%)',
      }}
      _hover={{
        bg: '',
      }}
      aria-label={ariaLabel}
      borderRadius='full'
      color='white'
      fontSize='12px'
      fontWeight='normal'
      onClick={handleOnClick}
      px='0'
      size='sm'
      variant='ghost'
    >
      {children}
    </Button>
  )
}

const VideoControlIconButton = ({
  ariaLabel,
  icon,
  handleOnClick,
  ...rest
}: VideoControlIconButtonIProps) => {
  return (
    <IconButton
      _active={{
        bg: 'rgb(226 232 240 / 20%)',
      }}
      _focus={{
        bg: 'rgb(226 232 240 / 20%)',
      }}
      _hover={{
        bg: '',
      }}
      aria-label={ariaLabel}
      borderRadius='full'
      color='white'
      icon={icon}
      onClick={handleOnClick}
      variant='ghost'
      {...rest}
    />
  )
}

export const VideoControls = forwardRef<HTMLDivElement, IProps>(
  (
    {
      liveOnly,
      onSeek,
      onRewind,
      onPlayPause,
      onFastForward,
      isPlaying,
      played,
      onToggleFullScreen,
      onToggleLive,
      liveEnabled = false,
      showToggleLiveButton = true,
    },
    ref
  ) => {
    const [isLive, setIsLive] = useState(liveEnabled)

    useEffect(() => {
      if (onToggleLive) {
        onToggleLive(isLive)
      }
    }, [isLive, onToggleLive])

    return (
      <Wrapper ref={ref}>
        <Box pb='20px' px='15px' w='full'>
          <VStack align='stretch' spacing='16px'>
            {!isLive && (
              <Slider onChange={onSeek} value={played * 100}>
                <SliderTrack bg='black' borderRadius='8px' h='8px'>
                  <SliderFilledTrack bg='white' />
                </SliderTrack>
                <SliderThumb boxSize='14px' />
              </Slider>
            )}

            <Grid gap={2} templateColumns='repeat(3, 1fr)'>
              <GridItem alignSelf='center'>
                {showToggleLiveButton ? (
                  !liveOnly ? (
                    <ToggleButtonSwitch
                      activeBgColor='btn.100'
                      activeTextColor='white'
                      inactiveBgColor='white'
                      inactiveTextColor='black'
                      isFull={true}
                      isLeftActive={!isLive}
                      leftArialLabel='switch to incident video.'
                      leftButtonText='Incident'
                      onLeftButtonClick={() => setIsLive(false)}
                      onRightButtonClick={() => setIsLive(true)}
                      rightArialLabel='switch to live video.'
                      rightButtonText='Live'
                    />
                  ) : (
                    <Flex alignItems='center'>
                      <Circle
                        bg={isPlaying ? 'alert.100' : 'brand.600'}
                        mr='2'
                        size='8px'
                        transition='ease-in-out'
                        transitionDuration='300ms'
                        transitionProperty='background-color'
                      />
                      <Box color='white' fontSize='12px' fontWeight='semibold'>
                        LIVE
                      </Box>
                    </Flex>
                  )
                ) : null}
              </GridItem>
              <GridItem justifySelf='center'>
                <HStack spacing='5px'>
                  {!liveOnly && (
                    <>
                      <VideoControlButton
                        ariaLabel='skip back 10 seconds.'
                        handleOnClick={onRewind}
                      >
                        10s
                      </VideoControlButton>
                      <VideoControlIconButton
                        ariaLabel='skip back 10 seconds.'
                        handleOnClick={onRewind}
                        icon={<Icon as={IoPlayBack} />}
                        size='sm'
                      />
                    </>
                  )}

                  <VideoControlIconButton
                    ariaLabel='play video'
                    borderWidth='2px'
                    h='40px'
                    handleOnClick={onPlayPause}
                    icon={<Icon as={isPlaying ? FaPause : FaPlay} />}
                    w='40px'
                  />
                  {!liveOnly && (
                    <>
                      <VideoControlIconButton
                        ariaLabel='skip forward 10 seconds.'
                        handleOnClick={onFastForward}
                        icon={<Icon as={IoPlayForward} />}
                        size='sm'
                      />
                      <VideoControlButton
                        ariaLabel='skip forward 10 seconds.'
                        handleOnClick={onFastForward}
                      >
                        10s
                      </VideoControlButton>
                    </>
                  )}
                </HStack>
              </GridItem>
              <GridItem alignSelf='center' justifySelf='end'>
                <VideoControlIconButton
                  ariaLabel='toggle full screen view.'
                  handleOnClick={onToggleFullScreen}
                  icon={<Icon as={BiFullscreen} boxSize='24px' />}
                  size='sm'
                />
              </GridItem>
            </Grid>
          </VStack>
        </Box>
      </Wrapper>
    )
  }
)
