export const Help = ({
  className,
  strokeColor = '#fff',
}: {
  className?: string
  strokeColor?: string
}) => {
  return (
    <svg
      className={className}
      fill='none'
      height='48'
      viewBox='0 0 48 48'
      width='48'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M31.0472 17.4782C30.6818 16.6796 30.1624 15.9618 29.4994 15.3484C28.1526 14.0982 26.3647 13.4091 24.4698 13.4091C22.5749 13.4091 20.787 14.0982 19.4402 15.3458C18.7772 15.9618 18.2578 16.6769 17.8924 17.4782C17.5113 18.3135 17.3182 19.1983 17.3182 20.1118V20.8165C17.3182 20.9784 17.4487 21.1089 17.6105 21.1089H19.02C19.1818 21.1089 19.3123 20.9784 19.3123 20.8165V20.1118C19.3123 17.5148 21.6248 15.4032 24.4698 15.4032C27.3148 15.4032 29.6273 17.5148 29.6273 20.1118C29.6273 21.1767 29.2489 22.179 28.5311 23.0142C27.8212 23.8416 26.8189 24.4341 25.7096 24.6847C25.0753 24.8282 24.5037 25.1858 24.0992 25.6974C23.696 26.2072 23.4763 26.838 23.4754 27.4879V28.3075C23.4754 28.4693 23.6059 28.5998 23.7677 28.5998H25.1771C25.339 28.5998 25.4695 28.4693 25.4695 28.3075V27.4879C25.4695 27.0781 25.754 26.7179 26.1481 26.6292C27.6724 26.2847 29.0557 25.4625 30.045 24.3141C30.5435 23.732 30.9324 23.0847 31.2012 22.3826C31.4805 21.6544 31.6214 20.8896 31.6214 20.1118C31.6214 19.1983 31.4283 18.3109 31.0472 17.4782V17.4782ZM24.4698 30.6879C23.6633 30.6879 23.0082 31.343 23.0082 32.1495C23.0082 32.956 23.6633 33.6112 24.4698 33.6112C25.2763 33.6112 25.9315 32.956 25.9315 32.1495C25.9315 31.343 25.2763 30.6879 24.4698 30.6879Z'
        fill={strokeColor}
        strokeWidth='2'
      />
      <path
        d='M3 12.4545V35.3636L24 46.8182L45 35.3636V12.4545L24 1L3 12.4545Z'
        stroke={strokeColor}
        strokeWidth='3'
      />
    </svg>
  )
}
