import { ComponentStyleConfig } from '@chakra-ui/react'

export const tabStyles: ComponentStyleConfig = {
  parts: ['tabs', 'tab'],
  variants: {
    primary: {
      tabs: {
        bgColor: '#FFFFFF',
        border: '1px solid #D9D9D9',
        borderRadius: '0.5rem',
      },
      tab: {
        _focus: null,
        _selected: {
          bg: '#FFFFFF',
          color: '#005BBF',
          borderBottomColor: '#005BBF',
        },
        _active: {
          bg: '#E2E8F0',
        },
        h: '53.6px',
        color: '#A3A3A3',
        fontSize: '12px',
        fontWeight: '600',
        borderWidth: '0px',
        borderBottomWidth: '2px',
      },
    },
  },
}
