import { useToasts } from 'react-toast-notifications'

import { Box, HStack, VStack } from '@chakra-ui/react'

import { Button } from '@/components/ui'

export const AlertToast = () => {
  return (
    <HStack align='flex-start' cursor='pointer' spacing='13px' w='full'>
      <Box
        bg='#D01030'
        boxShadow='0 2px 7px 0 rgba(189,25,25,0.5)'
        h='14px'
        mt='2px'
        rounded='2px'
        w='14px'
      />
      <VStack alignItems='flex-start' spacing={2} w='fit-content'>
        <Box
          color='#282424'
          fontSize='12px'
          fontWeight='500'
          letterSpacing='-0.4px'
          lineHeight='15px'
        >
          Tailgate detected
        </Box>
        <Box
          color='#b2b2b9'
          d='flex'
          flexDirection='row'
          fontSize='10px'
          fontWeight='bold'
          letterSpacing='-0.33px'
          lineHeight='13px'
          noOfLines={1}
        >
          02.02.2021 @ 08 : 16 : 37{' '}
          <Box bg='#979797' d='inline-block' h='13px' mx='10px' w='1px' /> Main
          Door <Box bg='#979797' d='inline-block' h='13px' mx='10px' w='1px' />{' '}
          GHQ
        </Box>
        <Box
          color='#9696a0'
          fontSize='12px'
          fontWeight='500'
          letterSpacing='-0.47px'
          lineHeight='15px'
          noOfLines={2}
        >
          Two people entering the building unauthorized.
        </Box>
      </VStack>
    </HStack>
  )
}

export const Toast = () => {
  const { addToast } = useToasts()
  const notify = () =>
    addToast(<AlertToast />, {
      appearance: 'error',
      autoDismiss: true,
    })

  return (
    <div>
      <Button onClick={notify}>Notify !</Button>
    </div>
  )
}
