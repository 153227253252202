import { Map, Marker } from 'react-map-gl'

export interface StaticMapBoxIProps {
  /**
   * The latitude value for the map and map marker.
   */
  latitude: number
  /**
   * The longitude value for the map and map marker.
   */
  longitude: number
  /**
   * The height of the static map box view.
   */
  height?: string
  /**
   * The width of the static map box view.
   */
  width?: string
  /**
   * Will be the child of the `react-map-gl` `Marker` component.
   */
  children?: React.ReactNode
}

export const StaticMapBox = ({
  height = '300px',
  width = '100%',
  latitude,
  longitude,
  children,
}: StaticMapBoxIProps) => {
  return (
    <Map
      interactive={false}
      latitude={latitude}
      longitude={longitude}
      mapStyle='mapbox://styles/mapbox/satellite-v9'
      mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_API_TOKEN}
      style={{ height, width }}
      zoom={11}
    >
      {children && (
        <Marker latitude={latitude} longitude={longitude} offset={[-25, -25]}>
          {children}
        </Marker>
      )}
    </Map>
  )
}
