import { useEffect, useRef, useState } from 'react'

import { FeatureFlag, useFeatureFlag } from '@/hooks/useFeatureFlags'

import { NetworkStatus } from '@apollo/client'

import { getCannedCameraFeedStreamState } from '@/features/canned-video'
import { useCameraFeedQuery } from '@/graphql/generated/hooks'
import { CameraFeedQuery } from '@/graphql/generated/operations'

import { VideoFeedEvent } from '../types/types'
import { CameraStreamStateIProps, IncidentPlayerError } from '../types/types'

export const useCameraFeed = (
  cameraId?: string,
  onEvent?: (event: VideoFeedEvent) => void
): CameraStreamStateIProps => {
  const timeoutRef = useRef(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [url, setUrl] = useState(null)

  const isCannedVideoEnabled = useFeatureFlag(FeatureFlag.cannedVideo)

  const processData = (data: CameraFeedQuery) => {
    onEvent?.(VideoFeedEvent.Received)
    const { url, expiryTime } = data.streamingUrlWrapper
    if (url && expiryTime) {
      const expiresTimestamp = Date.parse(expiryTime)
      const nowTimestamp = Date.now()
      setUrl(url)
      setError(null)
      timeoutRef.current = setTimeout(refetch, expiresTimestamp - nowTimestamp)
    }
  }

  const handleCompleted = (data: CameraFeedQuery) => {
    setIsLoading(false)
    if (data.streamingUrlWrapper) {
      processData(data)
    } else {
      setUrl(null)
      if (isCannedVideoEnabled) {
        return
      } else {
        setError(IncidentPlayerError.NO_VIDEO_FEED)
      }
    }
  }

  const handleError = () => {
    setIsLoading(false)
    setUrl(null)
    setError(IncidentPlayerError.ERROR_FETCHING_STREAM)
  }

  const { refetch, networkStatus } = useCameraFeedQuery({
    variables: { deviceId: cameraId },
    fetchPolicy: 'no-cache',
    skip: !cameraId || isCannedVideoEnabled,
    onCompleted: handleCompleted,
    onError: handleError,
  })

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  useEffect(() => {
    if (networkStatus == NetworkStatus.setVariables) {
      setIsLoading(true)
      onEvent?.(VideoFeedEvent.BeginRequesting)
    }
  }, [networkStatus])

  return isCannedVideoEnabled
    ? getCannedCameraFeedStreamState(cameraId)
    : { url, isLoading, errorMessage: error, refetch }
}
