import CountUp from 'react-countup'

export const TimeFormatCountUp = ({ value }: { value: number }) => {
  const SECONDS_IN_MINUTE = 60
  if (value < 1) {
    return <div>N/A</div>
  }

  if (value < SECONDS_IN_MINUTE) {
    return (
      <CountUp preserveValue start={0} end={value} delay={0}>
        {({ countUpRef }) => (
          <>
            <span ref={countUpRef} />s
          </>
        )}
      </CountUp>
    )
  }

  const minutes = Math.floor(value / SECONDS_IN_MINUTE)
  const seconds = value % SECONDS_IN_MINUTE
  return (
    <>
      <CountUp preserveValue start={0} end={minutes} delay={0}>
        {({ countUpRef }) => (
          <>
            <span ref={countUpRef} />m
          </>
        )}
      </CountUp>{' '}
      <CountUp preserveValue start={0} end={seconds} delay={0}>
        {({ countUpRef }) => (
          <>
            <span ref={countUpRef} />s
          </>
        )}
      </CountUp>
    </>
  )
}
