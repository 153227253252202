export {
  useGetNotificationCountQuery,
  GET_NOTIFICATION_COUNT,
} from './queries/getNotificationCount'
export {
  useListNotifications,
  LIST_ALL_NOTIFICATIONS,
} from './queries/listNotifications'

export {
  useResetNotificationCountMutation,
  RESET_NOTIFICATION_COUNT,
} from './mutations/resetNotificationCount'
export {
  useUpdateNotification,
  UPDATE_NOTIFICATION,
} from './mutations/updateNotification'

export { ON_CREATE_NOTIFICATION } from './subscriptions/onCreateNotification'
export { ON_UPDATE_NOTIFICATION_COUNT } from './subscriptions/onUpdateNotificationCount'

export { NotificationType } from './types'

export type { GetNotificationCountQuery } from './queries/getNotificationCount'
export type { ListNotificationsQuery } from './queries/listNotifications'
export type { ResetNotificationCountMutation } from './mutations/resetNotificationCount'
export type { UpdateNotificationMutation } from './mutations/updateNotification'
export type { OnCreateNotificationSubscription } from './subscriptions/onCreateNotification'
export type { OnUpdateNotificationCountSubscription } from './subscriptions/onUpdateNotificationCount'
export type { NotificationCount, Notification } from './types'
