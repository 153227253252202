import { MutationHookOptions, gql, useMutation } from '@apollo/client'

import { GraphqlClientType } from '@/config/apollo'
import { Maybe } from '@/graphql/generated/schemas'

import { NotificationCount } from '../types'

export type ResetNotificationCountMutation = {
  __typename?: 'Mutation'
  resetNotificationCount?: Maybe<NotificationCount>
}

export const RESET_NOTIFICATION_COUNT = gql`
  mutation ResetNotificationCount {
    resetNotificationCount {
      userId
      updatedAt
      notificationCount
    }
  }
`

export const useResetNotificationCountMutation = (
  options?: MutationHookOptions<ResetNotificationCountMutation>
) => {
  return useMutation(RESET_NOTIFICATION_COUNT, {
    ...options,
    context: { target: GraphqlClientType.APPSYNC_NOTIFICATIONS },
  })
}
