import { useEffect } from 'react'

import { MeStateIProps } from '@/redux/me/meSlice'

import { mixpanel } from '../mixpanel'

const isAnalyticsIdentify = true

export const useIdentifyUser = (user: MeStateIProps) => {
  useEffect(() => {
    if (isAnalyticsIdentify && user) {
      mixpanel.identify(user.email)
      mixpanel.people.set({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        role: user.roleName,
        userId: user.id,
      })
    }
  }, [user])
}
