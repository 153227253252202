import { useEffect, useState } from 'react'

import { SavedFilterI } from '../types/generic'

export enum IncidentsFilterDrawerMode {
  DETAIL,
  LIST,
  UPDATE,
}

interface UseDrawerModeIProps {
  onDrawerClose: () => void
}

export const useDrawerMode = ({ onDrawerClose }: UseDrawerModeIProps) => {
  const [drawerMode, setDrawerMode] = useState(IncidentsFilterDrawerMode.DETAIL)
  const [editedFilter, setEditedFilter] = useState<SavedFilterI>(null)

  const goBack = () => {
    switch (drawerMode) {
      case IncidentsFilterDrawerMode.LIST:
        setDrawerMode(IncidentsFilterDrawerMode.DETAIL)
        return
      case IncidentsFilterDrawerMode.UPDATE:
        setDrawerMode(IncidentsFilterDrawerMode.LIST)
        return
      case IncidentsFilterDrawerMode.DETAIL:
        onDrawerClose()
        return
    }
  }

  const openFilterList = () => {
    setDrawerMode(IncidentsFilterDrawerMode.LIST)
  }

  const openFilterDetail = () => {
    setDrawerMode(IncidentsFilterDrawerMode.DETAIL)
  }

  const openFilterUpdate = (filter: SavedFilterI) => {
    setDrawerMode(IncidentsFilterDrawerMode.UPDATE)
    setEditedFilter(filter)
  }

  useEffect(() => {
    if (drawerMode !== IncidentsFilterDrawerMode.UPDATE) {
      setEditedFilter(null)
    }
  }, [drawerMode])

  return {
    drawerMode,
    goBack,
    openFilterDetail,
    openFilterList,
    openFilterUpdate,
    editedFilter,
  }
}
