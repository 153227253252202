import { ApolloClient, NormalizedCacheObject, split } from '@apollo/client'
import merge from 'deepmerge'
import { IncomingHttpHeaders } from 'http'
import isEqual from 'lodash/isEqual'

import { appCache } from './cache'
import {
  apiGeneralHttpLinkBuilder,
  appSyncComponentHealthLinkBuilder,
  appSyncNotificationsLinkBuilder,
  shouldUseApiGeneralLink,
  shouldUseNotificationsLink,
} from './links'
import { isBrowser, isDev, isTest } from './utils'

type InitialState = NormalizedCacheObject | undefined

interface InitializeApolloI {
  headers?: IncomingHttpHeaders | null
  initialState?: InitialState | null
}

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined

const createApolloClient = (headers: IncomingHttpHeaders | null = null) => {
  const apiGeneralHttpLink = apiGeneralHttpLinkBuilder(headers)

  // Use different links based on targeted API
  // Using nested split is not ideal, but Apollo doesn't provide other options out of the box
  // More reading:
  // <https://stackoverflow.com/questions/69629051/using-multiple-endpoints-in-apollo-client>
  // <https://www.apollographql.com/docs/federation/#maintain-a-single-api>
  const splitLink = !isTest
    ? split(
        shouldUseApiGeneralLink,
        apiGeneralHttpLink,
        split(
          shouldUseNotificationsLink,
          appSyncNotificationsLinkBuilder(),
          appSyncComponentHealthLinkBuilder()
        )
      )
    : apiGeneralHttpLink

  return new ApolloClient({
    // Connect to Apollo DevTools
    connectToDevTools: isBrowser && isDev,
    // SSR only for Node.js
    ssrMode: !isBrowser,
    link: splitLink,
    cache: appCache,
  })
}

export const initializeApollo = (
  { headers, initialState }: InitializeApolloI = {
    headers: null,
    initialState: null,
  }
) => {
  const _apolloClient = apolloClient ?? createApolloClient(headers)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    })

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }

  // For SSG and SSR always create a new Apollo Client
  if (!isBrowser) return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export const client = createApolloClient()
