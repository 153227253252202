import { Global } from '@emotion/react'

export const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Euclid Square';
        src: url(/fonts/EuclidSquare-Light.woff) format('truetype');
        font-weight: 300;
        font-style: normal;
      }
      @font-face {
        font-family: 'Euclid Square';
        src: url(/fonts/EuclidSquare-LightItalic.woff) format('truetype');
        font-weight: 300;
        font-style: italic;
      }
      @font-face {
        font-family: 'Euclid Square';
        src: url(/fonts/EuclidSquare-Regular.woff) format('truetype');
        font-weight: 400;
        font-style: normal;
      }
      @font-face {
        font-family: 'Euclid Square';
        src: url(/fonts/EuclidSquare-RegularItalic.woff) format('truetype');
        font-weight: 400;
        font-style: italic;
      }
      @font-face {
        font-family: 'Euclid Square';
        src: url(/fonts/EuclidSquare-Medium.woff) format('truetype');
        font-weight: 500;
        font-style: normal;
      }
      @font-face {
        font-family: 'Euclid Square';
        src: url(/fonts/EuclidSquare-MediumItalic.woff) format('truetype');
        font-weight: 500;
        font-style: italic;
      }
      @font-face {
        font-family: 'Euclid Square';
        src: url(/fonts/EuclidSquare-Semibold.woff) format('truetype');
        font-weight: 600;
        font-style: normal;
      }
      @font-face {
        font-family: 'Euclid Square';
        src: url(/fonts/EuclidSquare-SemiboldItalic.woff) format('truetype');
        font-weight: 600;
        font-style: italic;
      }
      @font-face {
        font-family: 'Euclid Square';
        src: url(/fonts/EuclidSquare-Bold.woff) format('truetype');
        font-weight: 700;
        font-style: normal;
      }
      @font-face {
        font-family: 'Euclid Square';
        src: url(/fonts/EuclidSquare-BoldItalic.woff) format('truetype');
        font-weight: 700;
        font-style: italic;
      }
    `}
  />
)
