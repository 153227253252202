import { SvgProps } from 'react-native-svg'

import { HStack, useRadioGroup } from '@chakra-ui/react'

import { ToggleButton } from './ToggleButton'

interface GroupToggleButtonIProps {
  labels: {
    text: string
    icon?: (props: SvgProps) => JSX.Element
    value: string
  }[]
  hasBorder?: boolean
  onChange?: (string) => void
}

export const GroupToggleButtons = ({
  labels,
  hasBorder,
  onChange,
}: GroupToggleButtonIProps) => {
  const handleChange = (value) => {
    onChange?.(value)
  }

  const { getRadioProps, getRootProps } = useRadioGroup({
    defaultValue: labels[0].value,
    onChange: handleChange,
  })

  return (
    <HStack {...getRootProps()}>
      {labels.map((label, index) => {
        return (
          <ToggleButton
            hasBorder={hasBorder}
            icon={label.icon}
            key={index}
            text={label.text}
            {...getRadioProps({ value: label.value })}
          />
        )
      })}
    </HStack>
  )
}
