export const MediaIcon = () => (
  <svg
    fill='none'
    height='16'
    viewBox='0 0 16 16'
    width='16'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1.33333 4.00001H0V7.33334H0.00666667L0 13.3333C0 14.0667 0.6 14.6667 1.33333 14.6667H13.3333V13.3333H1.33333V4.00001ZM14.6667 2.66668H9.33333L8 1.33334H4C3.26667 1.33334 2.67333 1.93334 2.67333 2.66668L2.66667 10.6667C2.66667 11.4 3.26667 12 4 12H14.6667C15.4 12 16 11.4 16 10.6667V4.00001C16 3.26668 15.4 2.66668 14.6667 2.66668ZM4.66667 10L7.66667 6.00001L10 9.00668L11.6667 7.00001L14 10H4.66667Z'
      fill='white'
    />
  </svg>
)
