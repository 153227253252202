import { useState } from 'react'
import { ReactNode, createContext, useContext, useEffect } from 'react'

import { useFilterNotification } from './useFilterNotification'
import { useDispatch, useSelector } from 'react-redux'

import { ApolloError, useApolloClient } from '@apollo/client'
import { useSubscription } from '@apollo/client'

import { GraphqlClientType } from '@/config/apollo'
import {
  ON_UPDATE_NOTIFICATION_COUNT,
  OnUpdateNotificationCountSubscription,
} from '@/graphql/appsync-notifications'
import { useAppSelector } from '@/redux/hooks'
import { selectMe } from '@/redux/me/meSlice'
import {
  selectNotifications,
  setNotificationCount,
  setNotifications,
} from '@/redux/notifications/notificationsSlice'

import { createSubscription } from './subscription'
import { NotificationDataIProps, UseOnNotificationIProps } from './types'
import { isUniqueNotification } from './utils'

export const NotificationsContext = createContext<UseOnNotificationIProps>(null)

export const NotificationsProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const dispatch = useDispatch()
  const notifications = useSelector(selectNotifications)
  const me = useAppSelector(selectMe)

  const { shouldShowNotification } = useFilterNotification()

  const client = useApolloClient()

  const [shouldReconnect, setShouldReconnect] = useState(true)
  const [error, setError] = useState<ApolloError | undefined>(undefined)
  const [data, setData] = useState<NotificationDataIProps | undefined>(
    undefined
  )

  useEffect(() => {
    if (me?.id) {
      const subscription = createSubscription({
        client: client,
        userId: me.id,
        setData,
        setError,
        setShouldReconnect,
      })
      return () => subscription.unsubscribe()
    }
  }, [shouldReconnect, me?.id])

  useEffect(() => {
    const showNotification = async () => {
      if (data?.notification) {
        // Add new notification. Check if unique to prevent dups
        if (isUniqueNotification(notifications, data)) {
          if (await shouldShowNotification(data.notification.incidentId)) {
            dispatch(setNotifications([...notifications, data?.notification]))
          }
          client.refetchQueries({ include: ['GlobalUnresolvedIncidentsCount'] })
        }
      }
    }
    showNotification()
  }, [data])

  useSubscription<OnUpdateNotificationCountSubscription>(
    ON_UPDATE_NOTIFICATION_COUNT,
    {
      variables: { userId: me?.id },
      skip: !me?.id,
      onSubscriptionData: ({ subscriptionData }) => {
        dispatch(
          setNotificationCount(
            subscriptionData?.data?.onUpdateNotificationCount.notificationCount
          )
        )
      },
      context: { target: GraphqlClientType.APPSYNC_NOTIFICATIONS },
    }
  )

  return (
    <NotificationsContext.Provider value={{ data, error }}>
      {children}
    </NotificationsContext.Provider>
  )
}

export const useOnNotification = () => useContext(NotificationsContext)
