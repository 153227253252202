/* eslint-disable react/prop-types */
import { components } from 'react-select'

export const Option = (props) => {
  const index = props?.options?.findIndex((o) => o?.label === props?.label)
  const id =
    (props?.selectProps['data-testid'] || 'selectMenu') + `Option${index}`
  return (
    <components.Option
      {...props}
      innerProps={{
        ...props.innerProps,
        'data-testid': id,
      }}
    />
  )
}
