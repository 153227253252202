/* eslint-disable react/jsx-max-depth */
import React, { FC } from 'react'

import { FeatureFlag, useFeatureFlag } from '@/hooks/useFeatureFlags'
import { KeyCloakProvider } from '@/hooks/useKeyCloak/KeyCloakProvider'
import { NotificationsProvider } from '@/hooks/useOnNotification'
import { AppProps } from 'next/app'
import NProgress from 'nextjs-progressbar'
import 'react-datepicker/dist/react-datepicker.css'
import { Provider } from 'react-redux'

import { ApolloProvider } from '@apollo/client'
import { ChakraProvider } from '@chakra-ui/react'
import { AnimateSharedLayout } from 'framer-motion'
import 'mapbox-gl/dist/mapbox-gl.css'
import 'raf/polyfill'
import { PersistGate } from 'redux-persist/integration/react'

import { Head } from '@/components/layouts/Head'
import { useApollo } from '@/config/apollo'
import {
  CallContainer,
  CallFlaggedProvider as CallProvider,
} from '@/features/emergency-call'
import { ActiveFilterProvider } from '@/features/incidents-table'
import { wrapper } from '@/redux/store'
import { Fonts } from '@/styles/fonts'
import { GlobalStyles } from '@/styles/global'
import { theme } from '@/styles/theme'
import { useIdentifyUser } from '@/utils/analytics'
import '@/utils/analytics/mixpanel'

import '../../global.css'

const MyApp: FC<AppProps> = ({ Component, ...rest }) => {
  const { store, props } = wrapper.useWrappedStore(rest)
  const client = useApollo(props.pageProps)
  const isEmergencyCallEnabled = useFeatureFlag(FeatureFlag.emergencyCall)
  const me = store?.getState()?.me
  useIdentifyUser(me)

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={store.__persistor}>
        <Head />
        <ApolloProvider client={client}>
          <KeyCloakProvider autoRefreshToken>
            <ChakraProvider resetCSS theme={theme}>
              <ActiveFilterProvider>
                <NotificationsProvider>
                  <CallProvider>
                    <NProgress
                      color='#2E6CDB'
                      height={4}
                      options={{
                        showSpinner: false,
                        easing: 'ease',
                        speed: 500,
                      }}
                    />
                    <Fonts />
                    <GlobalStyles />
                    <AnimateSharedLayout>
                      <Component {...props.pageProps} />
                    </AnimateSharedLayout>
                    {isEmergencyCallEnabled && <CallContainer />}
                  </CallProvider>
                </NotificationsProvider>
              </ActiveFilterProvider>
            </ChakraProvider>
          </KeyCloakProvider>
        </ApolloProvider>
      </PersistGate>
    </Provider>
  )
}

// Default export is needed for NextJS /pages/*
// eslint-disable-next-line import/no-default-export
export default MyApp
