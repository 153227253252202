import { useEffect } from 'react'

import { useRouter } from 'next/compat/router'

import { PageLoader } from '@/components/ui'

interface RedirectIProps {
  to: string
  showLoaderSplash?: boolean
}

export const Redirect = ({ to, showLoaderSplash = true }: RedirectIProps) => {
  const { replace } = useRouter()
  useEffect(() => {
    replace(to)
  }, [to])

  return showLoaderSplash ? <PageLoader /> : null
}
