import { Box, Flex, Icon } from '@chakra-ui/react'

import { Logo as LogoIcon } from '@/components/icons'

export const Logo = () => {
  return (
    <Flex alignItems='center' justifyContent='center' mb='60px' pt={100}>
      <Icon as={LogoIcon} boxSize='80px' />
      <Box
        color='white'
        fontSize='32px'
        fontWeight='semibold'
        letterSpacing='-0.68px'
        ml={2}
      >
        HiveWatch
      </Box>
    </Flex>
  )
}
