export enum AnalyticsScreen {
  DeviceDrawer = 'DeviceDrawer',
  ActiveIncidentDrawer = 'ActiveIncidentDrawer',
  DeviceHoverModal = 'DeviceHoverModal',
}

export enum PlayerEvents {
  backward10 = 'ButtonPress:Backward10',
  forward10 = 'ButtonPress:Forward10',
  play = 'ButtonPress:Play',
  pause = 'ButtonPress:Pause',
  fullscreen = 'ButtonPress:Fullscreen',
  toggleLiveFeed = 'ButtonPress:ToggleLiveFeed',
  toggleIncidentClip = 'ButtonPress:ToggleIncidentClip',
  requestLiveFeed = 'RequestLiveFeed',
  receivedLiveFeed = 'ReceivedLiveFeed',
  playLiveFeed = 'PlayLiveFeed',
  requestIncidentClip = 'RequestIncidentClip',
  receivedIncidentClip = 'ReceivedIncidentClip',
  playIncidentClip = 'PlayIncidentClip',
}

export type MixpanelEvents = PlayerEvents
