import { AlertDialogHeader } from '@chakra-ui/react'

interface BasicAlertHeaderIProps {
  title: string | JSX.Element
}

export const BasicAlertHeader = ({ title }: BasicAlertHeaderIProps) => {
  return (
    <AlertDialogHeader
      fontSize='22px'
      fontWeight='bold'
      letterSpacing='-0.53px'
    >
      {title}
    </AlertDialogHeader>
  )
}
