import * as React from 'react'

export const Rule = ({
  className,
  strokeColor = '#fff',
}: {
  className?: string
  strokeColor?: string
}) => (
  <svg
    className={className}
    fill='none'
    height={32}
    width={32}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M16.105 28.21c6.632 0 12.107-5.483 12.107-12.105C28.212 9.473 22.727 4 16.095 4 9.475 4 4 9.473 4 16.105 4 22.727 9.485 28.21 16.105 28.21Zm0-2.384a9.68 9.68 0 0 1-9.71-9.721 9.671 9.671 0 0 1 9.7-9.72 9.698 9.698 0 0 1 9.733 9.72 9.69 9.69 0 0 1-9.723 9.72Z'
      fill={strokeColor}
    />
    <path
      d='M13.139 20.845c.249 0 .438-.05.628-.143l6.794-3.492c.524-.266.765-.585.765-1.095 0-.49-.256-.842-.765-1.113l-6.794-3.597a1.296 1.296 0 0 0-.647-.153c-.61 0-1.061.444-1.061 1.046 0 .496.242.81.811 1.067l5.44 2.672v.083l-5.44 2.612c-.58.258-.811.551-.811 1.058 0 .61.462 1.055 1.08 1.055Z'
      fill={strokeColor}
    />
  </svg>
)
