import NextHead from 'next/head'

interface HeadIProps {
  metaTitle?: string
  metaSubTitle?: string
  metaKeywords?: string
  metaDescription?: string
  ogImage?: string
}

export const Head = ({
  metaTitle = 'HiveWatch',
  metaSubTitle,
  metaKeywords = 'HiveWatch',
  metaDescription = 'The first ever Security Fusion Platform',
  ogImage = 'ogImage.jpeg',
}: HeadIProps) => {
  return (
    <NextHead>
      <title>
        {metaSubTitle ? `${metaTitle} • ${metaSubTitle}` : metaTitle}
      </title>
      <meta content='text/html; charset=utf-8' httpEquiv='Content-Type' />
      <meta content='width=device-width, initial-scale=1' name='viewport' />
      {metaDescription && <meta content={metaDescription} name='description' />}
      {metaKeywords && <meta content={metaKeywords} name='keywords' />}
      <meta content='summary_large_image' name='twitter:card' />
      <meta content='@HiveWatch' name='twitter:site' />
      <meta content='@HiveWatch' name='twitter:creator' />
      {metaTitle && <meta content={metaTitle} name='twitter:title' />}
      {metaDescription && (
        <meta content={metaDescription} name='twitter:description' />
      )}
      <meta content='HiveWatch' property='og:site_name' />
      {ogImage && (
        <meta
          content={`https://hivewatch.com/${ogImage}`}
          property='twitter:image'
        />
      )}
      <link
        as='font'
        crossOrigin=''
        href='/fonts/EuclidSquare-Light.woff'
        rel='preload'
      />
      <link
        as='font'
        crossOrigin=''
        href='/fonts/EuclidSquare-LightItalic.woff'
        rel='preload'
      />
      <link
        as='font'
        crossOrigin=''
        href='/fonts/EuclidSquare-Regular.woff'
        rel='preload'
      />
      <link
        as='font'
        crossOrigin=''
        href='/fonts/EuclidSquare-RegularItalic.woff'
        rel='preload'
      />
      <link
        as='font'
        crossOrigin=''
        href='/fonts/EuclidSquare-Medium.woff'
        rel='preload'
      />
      <link
        as='font'
        crossOrigin=''
        href='/fonts/EuclidSquare-MediumItalic.woff'
        rel='preload'
      />
      <link
        as='font'
        crossOrigin=''
        href='/fonts/EuclidSquare-Semibold.woff'
        rel='preload'
      />
      <link
        as='font'
        crossOrigin=''
        href='/fonts/EuclidSquare-SemiboldItalic.woff'
        rel='preload'
      />
      <link
        as='font'
        crossOrigin=''
        href='/fonts/EuclidSquare-Bold.woff'
        rel='preload'
      />
      <link
        as='font'
        crossOrigin=''
        href='/fonts/EuclidSquare-BoldItalic.woff'
        rel='preload'
      />
      <link
        href='/favicon/apple-touch-icon.png'
        rel='apple-touch-icon'
        sizes='180x180'
      />
      <link
        href='/favicon/favicon-32x32.png'
        rel='icon'
        sizes='32x32'
        type='image/png'
      />
      <link
        href='/favicon/favicon-16x16.png'
        rel='icon'
        sizes='16x16'
        type='image/png'
      />
      <link href='/site.webmanifest' rel='manifest' />
      <link
        color='#5bbad5'
        href='/favicon/safari-pinned-tab.svg'
        rel='mask-icon'
      />
      <meta content='#2b5797' name='msapplication-TileColor' />
      <meta content='#000000' name='theme-color' />
    </NextHead>
  )
}
