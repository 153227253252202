export const CANNED_CAMERA_FEED_PATH = '/videos/cameraFeed'
export const CANNED_INCIDENT_VIDEO_PATH = '/videos/incidentVideo'

// Mapping between device name and device id
const salesDeviceNameDeviceIdMap = {
  Reception: '1d34d4d7-87a2-40d2-b6d7-dd594b769371',
  'Front Door 360': '2c60c4b8-f236-4e51-b3bb-2c8913d5f5f9',
  'IDF Hallway': '35f0269f-72d1-4946-b59c-42f4ae0a2509',
  'Continental Conference': '5024f5bf-b9f5-413a-b645-d65b189759c8',
  'Server Room Fixed': '5b90671a-6d03-4783-8947-206935e1b14f',
  'Stairwell B': 'b4fc0a26-37a5-47df-88ef-85d1b90b9abd',
  'Storage Room': '72caf02f-9d04-4189-a915-477167a97701',
  Malibu: '74467927-2da5-4894-bc60-63e45d72d5a2',
  'The Hive': '752f920a-584f-498b-a3ba-220a44c93398',
  'Front Door Fixed': '8e779d5f-ce0b-47dc-acc6-6841a0729e24',
  IDF: '9b18959d-cf04-47de-a8bf-8c44e5028301',
  'Hermosa Bch': 'a0709479-0e1e-4d39-93b1-d8b49b0f8c90',
  'The Pacific': 'a45b345e-0f7b-4cff-815c-6818a1baff6e',
  'Bullpen 360': 'a5b8183a-4086-4f0d-94fc-baefba72c244',
  'GSOC 360': 'a88ec3c1-8117-41c9-8f5f-54fb0a4326d4',
  'The Hive 360': 'ac8433fa-793a-4244-a22e-9864e77a129d',
  'Dockweiler Bch': 'af0cf53f-c6e8-4ce1-98cb-5416012352f2',
  'Copy 360': 'b37065b1-0842-4212-b008-c60067bb2586',
  Breakroom: 'b456cfe4-c7b4-4d5d-beb6-7abcc929e744',
  'EOC Conference': 'b9f61e3a-ae4c-47c1-8169-c01d0ae5864e',
  'Topanga Cyn': 'c8ba2b3c-1ac1-4fa9-ba58-eb19d1856cd8',
  'Front Hallway': 'd89c4a84-2227-4968-9774-6061dfab0a19',
  Lunch: 'f109af1d-626f-4335-b8f6-ac5066c843d4',
  'Reception 360': 'f3381123-db0c-47db-ba50-3563a2716929',
  'Elevator 360': 'ff2ff131-eab4-465e-a847-82d84c595e6f',

  // HW Austin
  'Reception Aus': '01dee4ef-2087-4694-8684-c1ab1756ea74',
  'Front Door 360 Aus': '457adcbd-7301-46ef-8288-06f40e9e374c',
  'IDF Hallway Aus': '44562a6f-65f9-415c-8b68-f7378c27bf84',
  'Continental Conference Aus': 'bcb35a05-76e0-41db-be63-4481d0086bdc',
  'Server Room Fixed Aus': 'aff2efdd-bd29-493e-ae48-2206d0c73ce0',
  'Stairwell B Aus': '5b78c261-980b-46b7-bfbc-8c6c49af7bcb',
  'Storage Room Aus': '65f8953c-8ced-4ce9-a487-e68ed5f4827d',
  'Malibu Aus': '8f4bf895-4cac-46a8-a5e2-f3e39076c2eb',
  'The Hive Aus': 'b37e9cc1-6d1b-4db2-bf51-6c545353dce2',
  'Front Door Fixed Aus': '1b531ab6-e9e7-4b7a-9586-a5a47dad73b6',
  'IDF Aus': '560f432d-b1ea-4d71-a468-eb4e7b726eba',
  'Hermosa Bch Aus': '5db00d21-a67d-4494-8b7e-aebdb7466a03',
  'The Pacific Aus': '4ba1dbbd-9a3d-4a3f-9be5-476f55db34bc',
  'Bullpen 360 Aus': '9d12c92c-6a0b-4184-bb23-a8e12bba0ebc',
  'GSOC 360 Aus': 'c8d45969-9b66-4ffa-99cf-caf06888914f',
  'The Hive 360 Aus': 'a5b38b2c-2930-4315-97bc-fa3f15f753fc',
  'Dockweiler Bch Aus': '22937e6d-b795-4134-b214-2f32eba455ef',
  'Copy 360 Aus': '99c96b51-55eb-409c-8ae7-bc76ef6c2b5c',
  'Breakroom Aus': '365f3d41-6d77-4b67-a737-ba71c76efb61',
  'EOC Conference Aus': '7df8768f-749b-4853-91b3-beb55f801bb6',
  'Topanga Cyn Aus': '999eeae5-6885-4c9c-8798-30afcbc322ef',
  'Front Hallway Aus': '3174fb36-a3ac-482f-9ad4-c1dc462cc5eb',
  'Lunch Aus': 'b67a5b83-4b4b-40fe-85b9-3231d67f5077',
  'Reception 360 Aus': 'bf47a724-5ba4-4e74-847d-d0d1eb93bcd4',
  'Elevator 360 Aus': '258d8750-f6d9-4a9a-9a69-08387dd32b53',

  // HW NYC
  'Reception NYC': '2053122e-8219-432c-a5ce-46d622c34cea',
  'Front Door 360 NYC': 'a482b3bc-3e1d-4b91-a267-5058399a303c',
  'IDF Hallway NYC': '1abcba5e-6025-4d9f-8513-ed3f9777f8cb',
  'The Pacific NYC': 'c38dec12-f32f-4f91-a314-3185727aedfe',
  'Continental Conference NYC': 'b35c08ae-59a8-48a0-8bbc-9fe419f5743d',
  'Server Room Fixed NYC': 'd80bd560-13e1-48e8-b41d-b3cb6ca6b64d',
  'Stairwell B NYC': 'cb41a7f8-2378-4bd1-b1c5-fac277d7fcb5',
  'Storage Room NYC': '3e92a29a-5d44-4daf-912f-8827cf0784ee',
  'Malibu NYC': '7a3f1fc2-5725-4abd-91c5-62bcd88e3aa1',
  'The Hive NYC': '92b3b89d-7d8b-4850-89dc-3efbfba42be0',
  'Front Door Fixed NYC': '4dd8768b-5f58-4f18-a7ea-681c05a673bb',
  'IDF NYC': 'f0fd1204-63fb-4a8d-a1e2-2588ed3aed66',
  'Hermosa Bch NYC': '9dfc9934-4454-4ad8-805e-a47f9b21f1d4',
  'Bullpen 360 NYC': '57fac1ad-d9d3-4809-a273-984ae2c9a895',
  'GSOC 360 NYC': '3ece2ef3-cc20-4094-9e92-53b9bff26277',
  'The Hive 360 NYC': '5997805d-31a1-44b2-882a-93837a65af90',
  'Dockweiler Bch NYC': '81787c1d-0362-4b70-b534-af11f94d398a',
  'Copy 360 NYC': 'f43b2a84-eae6-43c1-a905-bf58a75bb1cc',
  'Breakroom NYC': '9c41df54-9838-47bf-b031-5a55b7814e79',
  'EOC Conference NYC': '1e8738a9-d662-41c4-829e-bbd0c25ab508',
  'Topanga Cyn NYC': '390c6d4f-4448-40cd-b790-993463f45c27',
  'Front Hallway NYC': '03cb7570-d74c-43a3-92d7-d0892c031ec8',
  'Lunch NYC': '00a865e9-873e-4bb7-b771-cdb143600c29',
  'Reception 360 NYC': '91c6f78f-fbaf-4168-9c5f-7cc9001d0c95',
  'Elevator 360 NYC': 'ab3022dc-4d30-46cb-b061-ececd1adb2d6',
}

// Mapping between device name and corresponding canned video id
const salesDeviceNameVideoIdMap = {
  'Hermosa Bch': 'daa5df50-a8b2-4d07-bd53-8d2c0298d322',
  'Dockweiler Bch': '8808cb47-78ab-4b4a-a83b-d59938af30c9',
  'The Hive': '04affdd1-3f07-423e-afac-1f7392c7b7b9',
  'Stairwell B': '12d1f368-805d-4a7a-a07a-25c9d30891c4',
  'Copy 360': 'ecd902e0-3b71-4afb-814f-2fca4ee24e31',
  'Reception 360': 'd248e421-9891-4dc3-bc8d-8ed6e815de05',
  'Elevator 360': '357c32df-cfd7-48b8-bb2f-ae64af061972',
  'The Pacific': '48da284a-696c-4edb-a3a0-63e9e5b773de',
  Malibu: '349d51e9-3047-4777-93f6-24b0b773fd8e',
  Lunch: 'b72e3798-c78b-4013-8291-6d0fdb6f180a',
  'IDF Hallway': 'e1a99b3a-8d48-446b-ba96-7c7e9c4958c8',
  'Topanga Cyn': 'ca714ce0-d815-4b75-a4f9-5e1f1822ac96',
  Reception: '9944f4fd-d45e-4ecb-8855-5c292e6d0998',
  IDF: '2d405c55-0ae1-44c8-81a2-6e75f8ba7f70',
  'The Hive 360': '7d42c67e-0061-4d39-bfa0-6af21a4e319e',
  'Continental Conference': 'daa5df50-a8b2-4d07-bd53-8d2c0298d322',
  'Front Hallway': '04affdd1-3f07-423e-afac-1f7392c7b7b9',
  'Storage Room': '12d1f368-805d-4a7a-a07a-25c9d30891c4',
  'GSOC 360': 'ecd902e0-3b71-4afb-814f-2fca4ee24e31',
  'Front Door 360': 'd248e421-9891-4dc3-bc8d-8ed6e815de05',
  'EOC Conference': '48da284a-696c-4edb-a3a0-63e9e5b773de',
  Breakroom: 'b72e3798-c78b-4013-8291-6d0fdb6f180a',
  'Front Door Fixed': '9944f4fd-d45e-4ecb-8855-5c292e6d0998',
  'Server Room Fixed': '2d405c55-0ae1-44c8-81a2-6e75f8ba7f70',
  'Bullpen 360': '7d42c67e-0061-4d39-bfa0-6af21a4e319e',

  // HW Austin
  'Reception Aus': '9944f4fd-d45e-4ecb-8855-5c292e6d0998',
  'Front Door 360 Aus': 'd248e421-9891-4dc3-bc8d-8ed6e815de05',
  'IDF Hallway Aus': 'e1a99b3a-8d48-446b-ba96-7c7e9c4958c8',
  'Continental Conference Aus': 'daa5df50-a8b2-4d07-bd53-8d2c0298d322',
  'Server Room Fixed Aus': '2d405c55-0ae1-44c8-81a2-6e75f8ba7f70',
  'Stairwell B Aus': '12d1f368-805d-4a7a-a07a-25c9d30891c4',
  'Storage Room Aus': '12d1f368-805d-4a7a-a07a-25c9d30891c4',
  'Malibu Aus': '349d51e9-3047-4777-93f6-24b0b773fd8e',
  'The Hive Aus': '04affdd1-3f07-423e-afac-1f7392c7b7b9',
  'Front Door Fixed Aus': '9944f4fd-d45e-4ecb-8855-5c292e6d0998',
  'IDF Aus': '2d405c55-0ae1-44c8-81a2-6e75f8ba7f70',
  'Hermosa Bch Aus': 'daa5df50-a8b2-4d07-bd53-8d2c0298d322',
  'The Pacific Aus': 'a45b345e-0f7b-4cff-815c-6818a1baff6e',
  'Bullpen 360 Aus': '7d42c67e-0061-4d39-bfa0-6af21a4e319e',
  'GSOC 360 Aus': 'ecd902e0-3b71-4afb-814f-2fca4ee24e31',
  'The Hive 360 Aus': '7d42c67e-0061-4d39-bfa0-6af21a4e319e',
  'Dockweiler Bch Aus': '8808cb47-78ab-4b4a-a83b-d59938af30c9',
  'Copy 360 Aus': 'ecd902e0-3b71-4afb-814f-2fca4ee24e31',
  'Breakroom Aus': 'b72e3798-c78b-4013-8291-6d0fdb6f180a',
  'EOC Conference Aus': '48da284a-696c-4edb-a3a0-63e9e5b773de',
  'Topanga Cyn Aus': 'ca714ce0-d815-4b75-a4f9-5e1f1822ac96',
  'Front Hallway Aus': '04affdd1-3f07-423e-afac-1f7392c7b7b9',
  'Lunch Aus': 'b72e3798-c78b-4013-8291-6d0fdb6f180a',
  'Reception 360 Aus': 'd248e421-9891-4dc3-bc8d-8ed6e815de05',
  'Elevator 360 Aus': '357c32df-cfd7-48b8-bb2f-ae64af061972',

  // HW NYC
  'Reception NYC': '9944f4fd-d45e-4ecb-8855-5c292e6d0998',
  'Front Door 360 NYC': 'd248e421-9891-4dc3-bc8d-8ed6e815de05',
  'IDF Hallway NYC': 'e1a99b3a-8d48-446b-ba96-7c7e9c4958c8',
  'Continental Conference NYC': 'daa5df50-a8b2-4d07-bd53-8d2c0298d322',
  'Server Room Fixed NYC': '2d405c55-0ae1-44c8-81a2-6e75f8ba7f70',
  'Stairwell B NYC': '12d1f368-805d-4a7a-a07a-25c9d30891c4',
  'Storage Room NYC': '12d1f368-805d-4a7a-a07a-25c9d30891c4',
  'Malibu NYC': '349d51e9-3047-4777-93f6-24b0b773fd8e',
  'The Hive NYC': '04affdd1-3f07-423e-afac-1f7392c7b7b9',
  'Front Door Fixed NYC': '9944f4fd-d45e-4ecb-8855-5c292e6d0998',
  'IDF NYC': '2d405c55-0ae1-44c8-81a2-6e75f8ba7f70',
  'Hermosa Bch NYC': 'daa5df50-a8b2-4d07-bd53-8d2c0298d322',
  'The Pacific NYC': 'a45b345e-0f7b-4cff-815c-6818a1baff6e',
  'Bullpen 360 NYC': '7d42c67e-0061-4d39-bfa0-6af21a4e319e',
  'GSOC 360 NYC': 'ecd902e0-3b71-4afb-814f-2fca4ee24e31',
  'The Hive 360 NYC': '7d42c67e-0061-4d39-bfa0-6af21a4e319e',
  'Dockweiler Bch NYC': '8808cb47-78ab-4b4a-a83b-d59938af30c9',
  'Copy 360 NYC': 'ecd902e0-3b71-4afb-814f-2fca4ee24e31',
  'Breakroom NYC': 'b72e3798-c78b-4013-8291-6d0fdb6f180a',
  'EOC Conference NYC': '48da284a-696c-4edb-a3a0-63e9e5b773de',
  'Topanga Cyn NYC': 'ca714ce0-d815-4b75-a4f9-5e1f1822ac96',
  'Front Hallway NYC': '04affdd1-3f07-423e-afac-1f7392c7b7b9',
  'Lunch NYC': 'b72e3798-c78b-4013-8291-6d0fdb6f180a',
  'Reception 360 NYC': 'd248e421-9891-4dc3-bc8d-8ed6e815de05',
  'Elevator 360 NYC': '357c32df-cfd7-48b8-bb2f-ae64af061972',
}

// Mapping between device id and canned video id
export const salesDeviceIdVideoIdMap = Object.fromEntries(
  Object.entries(salesDeviceNameDeviceIdMap).map(([deviceName, deviceId]) => [
    deviceId,
    salesDeviceNameVideoIdMap[deviceName],
  ])
)

// Mapping between incident id and canned incident video id
export const salesIncidentIdVideoIdMap = {
  // HW HQ
  // Tailgating - IDF Hallway - Floor 10
  'd9ab24de-f40c-4097-90ef-09e91e98e9ed':
    'd1beee61-eb18-4f09-90ae-1250c0b20897',
  // Door Held Open - IDF Hallway device - Floor 10
  'eb2d8aae-7672-4504-95d6-24b273fbc8e4':
    '274d2749-a85a-4b4e-ba1a-8ffdb02229aa',
  'dc7b739a-fa37-411c-ab34-77dd7171d02a':
    '274d2749-a85a-4b4e-ba1a-8ffdb02229aa',
  '96ba163a-d576-4e33-8740-dc99940ceabd':
    'd1beee61-eb18-4f09-90ae-1250c0b20897',

  // HW Austin
  'c51608ce-47b5-45eb-a968-6580818311bc':
    'd1beee61-eb18-4f09-90ae-1250c0b20897',
  'b3f8f0c2-b783-4306-8fb7-c65198d0a36b':
    '274d2749-a85a-4b4e-ba1a-8ffdb02229aa',
  '1a0ee9c9-525b-47ed-ae80-4f69df9ab5a4':
    'd1beee61-eb18-4f09-90ae-1250c0b20897',
  '21443c39-2525-48dd-bc24-23edebe60a64':
    '274d2749-a85a-4b4e-ba1a-8ffdb02229aa',
  '12084e58-b7ed-4464-a9c6-3d3e1089c0c5':
    '274d2749-a85a-4b4e-ba1a-8ffdb02229aa',
  '816ccce0-6e73-4151-a96c-b0486046b70b':
    'd1beee61-eb18-4f09-90ae-1250c0b20897',

  // HW NYC
  '816ccce0-6e73-4151-a96c-b0486046b70a':
    'd1beee61-eb18-4f09-90ae-1250c0b20897',
  'b3f8f0c2-b783-4306-8fb7-c65198d0a36a':
    '274d2749-a85a-4b4e-ba1a-8ffdb02229aa',
  '12084e58-b7ed-4464-a9c6-3d3e1089c0c4':
    '274d2749-a85a-4b4e-ba1a-8ffdb02229aa',
  'b7449606-8dd1-4945-acfc-ca2e18eddd35':
    '274d2749-a85a-4b4e-ba1a-8ffdb02229aa',
  '21443c39-2525-48dd-bc24-23edebe60a63':
    '274d2749-a85a-4b4e-ba1a-8ffdb02229aa',

  // Extras Tailgate incidents
  'd9956b01-ca61-11ed-a901-0800200c9a66':
    'd1beee61-eb18-4f09-90ae-1250c0b20897',
  'd9956b00-ca61-11ed-a901-0800200c9a66':
    'd1beee61-eb18-4f09-90ae-1250c0b20897',
  'd9956b02-ca61-11ed-a901-0800200c9a66':
    'd1beee61-eb18-4f09-90ae-1250c0b20897',
  'd9956b03-ca61-11ed-a901-0800200c9a66':
    'd1beee61-eb18-4f09-90ae-1250c0b20897',
  'd9956b04-ca61-11ed-a901-0800200c9a66':
    'd1beee61-eb18-4f09-90ae-1250c0b20897',
}
