import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'

export const BackgroundStyled = styled(Box)`
  --base-size: 2rem;
  --scaling-factor: 1.25;
  background-color: #0b1c4f;
  background-image: url('/hexagon.svg'), url('/hexagon.svg'),
    url('/hexagon.svg'), url('/hexagon.svg'), url('/hexagon.svg'),
    url('/hexagon.svg'), linear-gradient(10deg, #15357e, #091848);
  background-position: 85% 65%, 10% 7%, -1% 80%, 22% 40%, 102% 12%, 63% 115%,
    center;
  background-size: var(--base-size) var(--base-size),
    calc((1 + (1 * var(--scaling-factor))) * var(--base-size))
      calc((1 + (1 * var(--scaling-factor))) * var(--base-size)),
    calc((1 + (2 * var(--scaling-factor))) * var(--base-size))
      calc((1 + (2 * var(--scaling-factor))) * var(--base-size)),
    calc((1 + (3 * var(--scaling-factor))) * var(--base-size))
      calc((1 + (3 * var(--scaling-factor))) * var(--base-size)),
    calc((1 + (4 * var(--scaling-factor))) * var(--base-size))
      calc((1 + (4 * var(--scaling-factor))) * var(--base-size)),
    calc((1 + (5 * var(--scaling-factor))) * var(--base-size))
      calc((1 + (5 * var(--scaling-factor))) * var(--base-size)),
    100%;
  background-repeat: no-repeat;
  min-height: 100vh;
  margin: 0;
`
