export const getCurrentRangeMessage = (
  pageIndex: number,
  pageSize: number,
  totalItems: number,
  showPageRangeStart = true
): string => {
  const rangeStart = pageIndex * pageSize + 1
  if (rangeStart > totalItems || pageIndex < 0) {
    return `${totalItems}`
  }
  const rangeEnd =
    (pageIndex + 1) * pageSize > totalItems
      ? totalItems
      : (pageIndex + 1) * pageSize
  if (showPageRangeStart) {
    return `${rangeStart} - ${rangeEnd} of ${totalItems}`
  }
  return `${rangeEnd} of ${totalItems}`
}

export const getPageNumbers = (
  currentPage: number,
  pageCount: number
): Array<number | null> => {
  if (pageCount === 0) {
    return [1]
  }
  const aroundPages = [currentPage - 1, currentPage, currentPage + 1].filter(
    (i) => i >= 1 && i <= pageCount
  )
  const edgePages = [1, pageCount]
  const allPages = Array.from(new Set([...aroundPages, ...edgePages])).sort(
    (a, b) => a - b
  )
  // add null between not adjacent numbers
  const allPagesWithEllipsis = allPages.map((v, i, arr) =>
    arr[i + 1] === arr[i] + 1 ? [v] : [v, null]
  )
  return allPagesWithEllipsis
    .reduce((prev, current) => [...prev, ...current], [])
    .slice(0, -1)
}

export const getSortedColumn = (
  tableColumns: {
    id: string
    canSort: boolean
    isSorted?: boolean
    isSortedDesc?: boolean
  }[]
): { id?: string; isSortedDesc?: boolean } =>
  tableColumns
    .filter((c) => c.canSort && c.isSorted)
    .map((c) => ({
      id: c.id,
      isSortedDesc: c.isSortedDesc,
    }))?.[0] ?? {}
