export const IncidentMapPin = ({ className }: { className?: string }) => (
  <svg
    className={className}
    fill='none'
    height='48'
    viewBox='0 0 126 140'
    width='42'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g filter='url(#filter0_d_1764_26148)'>
      <path
        d='M63 10.6191C42.6825 10.6191 26.25 27.0516 26.25 47.3691C26.25 56.5041 28.875 65.0616 33.6525 72.7791C38.64 80.8641 45.2025 87.7941 50.2425 95.8791C52.71 99.8166 54.495 103.492 56.385 107.744C57.75 110.632 58.8525 115.619 63 115.619C67.1475 115.619 68.25 110.632 69.5625 107.744C71.505 103.492 73.2375 99.8166 75.705 95.8791C80.745 87.8466 87.3075 80.9166 92.295 72.7791C97.125 65.0616 99.75 56.5041 99.75 47.3691C99.75 27.0516 83.3175 10.6191 63 10.6191ZM63 61.8066C59.519 61.8066 56.1806 60.4238 53.7192 57.9624C51.2578 55.501 49.875 52.1626 49.875 48.6816C49.875 45.2007 51.2578 41.8623 53.7192 39.4009C56.1806 36.9394 59.519 35.5566 63 35.5566C66.481 35.5566 69.8194 36.9394 72.2808 39.4009C74.7422 41.8623 76.125 45.2007 76.125 48.6816C76.125 52.1626 74.7422 55.501 72.2808 57.9624C69.8194 60.4238 66.481 61.8066 63 61.8066V61.8066Z'
        fill='#D01031'
      />
      <path
        d='M35.8866 71.401L35.8844 71.3975C31.3446 64.0638 28.875 55.9806 28.875 47.3691C28.875 28.5014 44.1322 13.2441 63 13.2441C81.8678 13.2441 97.125 28.5014 97.125 47.3691C97.125 55.9798 94.656 64.0587 90.0699 71.3865L90.0633 71.3969L90.0569 71.4074C87.6474 75.3387 84.8445 78.9995 81.9408 82.7657C81.8522 82.8807 81.7635 82.9957 81.6747 83.1108C78.8808 86.7339 76.0012 90.4682 73.4815 94.484L73.4807 94.4852C70.9143 98.5805 69.1215 102.392 67.1748 106.653L67.1728 106.658C66.9184 107.218 66.6561 107.885 66.4149 108.498C66.3166 108.748 66.2219 108.989 66.1326 109.21C65.7919 110.054 65.4619 110.796 65.0894 111.421C64.355 112.653 63.7477 112.994 63 112.994C62.2521 112.994 61.6435 112.653 60.9 111.414C60.5229 110.785 60.188 110.041 59.8381 109.192C59.7556 108.992 59.6688 108.777 59.5791 108.555C59.3258 107.927 59.0483 107.239 58.7718 106.651C56.8639 102.36 55.0241 98.5664 52.4688 94.4884C49.9066 90.3785 46.9736 86.5744 44.1346 82.8922L44.0067 82.7264C41.1005 78.9569 38.2972 75.3086 35.8866 71.401ZM51.8631 59.8186C54.8168 62.7723 58.8228 64.4316 63 64.4316C67.1772 64.4316 71.1832 62.7723 74.1369 59.8186C77.0906 56.8649 78.75 52.8588 78.75 48.6816C78.75 44.5045 77.0906 40.4984 74.1369 37.5447C71.1832 34.591 67.1772 32.9316 63 32.9316C58.8228 32.9316 54.8168 34.591 51.8631 37.5447C48.9094 40.4984 47.25 44.5045 47.25 48.6816C47.25 52.8588 48.9094 56.8649 51.8631 59.8186Z'
        stroke='white'
        strokeWidth='5.25'
      />
    </g>
    <defs>
      <filter
        colorInterpolationFilters='sRGB'
        filterUnits='userSpaceOnUse'
        height='129'
        id='filter0_d_1764_26148'
        width='97.5'
        x='14.25'
        y='10.6191'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          result='hardAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dy='12' />
        <feGaussianBlur stdDeviation='6' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
        />
        <feBlend
          in2='BackgroundImageFix'
          mode='normal'
          result='effect1_dropShadow_1764_26148'
        />
        <feBlend
          in='SourceGraphic'
          in2='effect1_dropShadow_1764_26148'
          mode='normal'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
)
