import { BiFilterAlt } from 'react-icons/bi'

import { Box, HStack, Text, Tooltip, VStack } from '@chakra-ui/react'

import { FilterListItemMenu } from './FilterListItemMenu'

export interface FilterListItemIProps {
  label: string
  onFilterActivate: () => void
  onFilterDeactivate: () => void
  onFilterUpdate: () => void
  onFilterDelete: () => void
  isActive: boolean
}

export const FilterListItem = ({
  label,
  isActive,
  onFilterActivate,
  onFilterDeactivate,
  onFilterUpdate,
  onFilterDelete,
}: FilterListItemIProps) => {
  return (
    <HStack
      _active={{
        backgroundColor: '#DCDFE4',
        boxShadow:
          '0px 0px 1px rgba(9, 30, 66, 0.31), 0px 1px 1px rgba(9, 30, 66, 0.25)',
      }}
      _hover={{
        backgroundColor: '#F1F2F4',
        boxShadow:
          '0px 0px 1px rgba(9, 30, 66, 0.31), 0px 8px 12px rgba(9, 30, 66, 0.15)',
      }}
      backgroundColor='#fff'
      borderRadius='4px'
      boxShadow='0px 0px 1px rgba(9, 30, 66, 0.31), 0px 1px 1px rgba(9, 30, 66, 0.25)'
      cursor='pointer'
      height='52px'
      justifyContent='space-between'
      p='14px 8px'
      role='group'
    >
      <HStack minWidth='0' px={2} spacing='12px'>
        <Box mr='4px'>
          <BiFilterAlt color={isActive ? '#43BF9B' : '#44546F'} size={22} />
        </Box>
        <VStack align='flex-start' spacing='0'>
          <Tooltip
            hasArrow
            label={label}
            openDelay={500}
            placement='auto'
            shouldWrapChildren
          >
            <Text
              color='#172B4D'
              fontSize='14px'
              fontWeight={500}
              lineHeight='20px'
              noOfLines={1}
              w='full'
            >
              {label}
            </Text>
          </Tooltip>
          {isActive && (
            <HStack spacing='2px' w='full'>
              <Text
                color='#172B4D'
                fontSize='12px'
                fontWeight={400}
                lineHeight='18px'
                w='full'
              >
                Active Filter
              </Text>
            </HStack>
          )}
        </VStack>
      </HStack>
      <FilterListItemMenu
        isActive={isActive}
        onFilterActivate={onFilterActivate}
        onFilterDeactivate={onFilterDeactivate}
        onFilterDelete={onFilterDelete}
        onFilterUpdate={onFilterUpdate}
      />
    </HStack>
  )
}
