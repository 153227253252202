import { FeatureFlag, useFeatureFlag } from '@/hooks/useFeatureFlags'

import { Box } from '@chakra-ui/react'

import {
  CANNED_CAMERA_FEED_PATH,
  CANNED_INCIDENT_VIDEO_PATH,
  salesDeviceIdVideoIdMap,
  salesIncidentIdVideoIdMap,
} from '../utils/data'

const unique = <T,>(arr: T[]): T[] => [...new Set(arr)]

const cannedVideosPaths = unique([
  ...Object.values(salesIncidentIdVideoIdMap).map(
    (id: string) => `${CANNED_INCIDENT_VIDEO_PATH}/${id}.mp4`
  ),
  ...Object.values(salesDeviceIdVideoIdMap).map(
    (id: string) => `${CANNED_CAMERA_FEED_PATH}/${id}.mp4`
  ),
])

export const PreloadCannedVideos = () => {
  const isCannedVideoEnabled = useFeatureFlag(FeatureFlag.cannedVideo)

  return isCannedVideoEnabled ? (
    <Box display='none'>
      {cannedVideosPaths.map((path) => (
        <video key={path} src={path}></video>
      ))}
    </Box>
  ) : null
}
