import { VideoDimensionsIProps } from '../../types/types'

export interface VideoFrameStateIProps {
  imageUrl: string
  frameDimensions: VideoDimensionsIProps
  refreshing?: boolean
  captureCount?: number
}

export enum VideoFrameActionType {
  CAPTURE_IMAGE = 'CAPTURE_IMAGE',
  SET_REFRESH_TRUE = 'SET_REFRESH_TRUE',
  SET_REFRESH_FALSE = 'SET_REFRESH_FALSE',
  RESET = 'RESET',
}

export type VideoFrameAction =
  | { type: VideoFrameActionType.CAPTURE_IMAGE; payload: VideoFrameStateIProps }
  | { type: VideoFrameActionType.SET_REFRESH_TRUE }
  | { type: VideoFrameActionType.SET_REFRESH_FALSE }
  | { type: VideoFrameActionType.RESET }
