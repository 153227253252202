export const ProgramManager = ({
  className,
  strokeColor = '#fff',
}: {
  className?: string
  strokeColor?: string
}) => (
  <svg
    className={className}
    data-name='Layer 1'
    id='Layer_1'
    viewBox='0 0 48 48'
    xmlns='http://www.w3.org/2000/svg'
  >
    <defs>
      <style>
        {`.cls-2{fill:none;stroke:${strokeColor};strokeLinecap:round;strokeLinejoin:round;strokeWidth:3px}`}
      </style>
    </defs>
    <g data-name='guards-II 48px' id='guards-II_48px'>
      <path
        d='M0 0h48v48H0V0z'
        id='Path-47'
        style={{
          fill: 'none',
        }}
      />
      <g id='icon'>
        <path
          className='cls-2'
          d='M44 37.76 24 45 4 37.76V3h40v34.76z'
          id='Path-48'
        />
        <path className='cls-2' d='m44 29-20 8-20-8' id='Path-49' />
        <path
          d='M24 21.18 20.29 24l1.42-4.57L18 16.77h4.58L24 12l1.42 4.77H30l-3.71 2.66L27.71 24 24 21.18z'
          id='Path-50'
          style={{
            fill: '#868686',
            fillRule: 'evenodd',
            stroke: strokeColor,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: 3,
          }}
        />
      </g>
    </g>
  </svg>
)
