import { MenuItem, Text } from '@chakra-ui/react'

export const MenuSelectItem = ({
  label,
  textColor = 'rgb(26, 32, 44)',
  showDivider = true,
  isDisabled = false,
  ...menuItemProps
}: {
  label: string
  textColor?: string
  showDivider?: boolean
  isDisabled?: boolean
  [key: string]: unknown
}) => (
  <MenuItem
    _disabled={{ opacity: 0.5 }}
    _focus={{
      backgroundColor: 'rgba(0,0,0,0.03)',
    }}
    _hover={{ backgroundColor: 'rgba(0,0,0,0.03)' }}
    borderBottom={showDivider ? '2px solid rgba(0,0,0,0.03)' : 'auto'}
    isDisabled={isDisabled}
    px='18px'
    py='10px'
    {...menuItemProps}
  >
    <Text
      color={textColor}
      fontSize='14px'
      height='100%'
      letterSpacing='-0.2px'
    >
      {label}
    </Text>
  </MenuItem>
)
