export const DoorForced = ({
  className,
  strokeColor = '#fff',
}: {
  className?: string
  strokeColor?: string
}) => {
  return (
    <svg
      className={className}
      height='32'
      version='1.1'
      viewBox='0 0 32 32'
      width='32'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g id='Artboard' transform='translate(-119.000000, -284.000000)'>
          <g id='forceddoor' transform='translate(119.000000, 284.000000)'>
            <rect
              fill='url(#linearGradient-1)'
              height='32'
              id='Rectangle'
              rx='16'
              width='32'
              x='0'
              y='0'
            ></rect>
            <line
              id='Line'
              stroke={strokeColor}
              strokeLinecap='round'
              strokeWidth='1.5'
              x1='7.5'
              x2='7.5'
              y1='9.5'
              y2='21.5'
            ></line>
            <g
              id='person'
              strokeLinecap='round'
              strokeLinejoin='round'
              transform='translate(13.582294, 15.255836) scale(-1, 1) translate(-13.582294, -15.255836) translate(9.449138, 9.312500)'
            >
              <g
                id='Path'
                transform='translate(4.133156, 4.761569) scale(-1, 1) rotate(-33.000000) translate(-4.133156, -4.761569) '
              >
                <use stroke={strokeColor} strokeWidth='1.5'></use>
              </g>
              <polyline
                id='Path'
                points='5.28945306 11.5897471 6.38840031 9.20438102 3.20682453 7.8774526 3.95682453 3.3774526'
                stroke={strokeColor}
                strokeWidth='1.5'
                transform='translate(4.797612, 7.483600) rotate(22.000000) translate(-4.797612, -7.483600) '
              ></polyline>
              <circle
                cx='7.12794929'
                cy='0.75'
                fill={strokeColor}
                id='Oval'
                r='1'
                stroke={strokeColor}
                strokeWidth='1.25'
              ></circle>
              <line
                id='Path'
                stroke={strokeColor}
                strokeWidth='1.5'
                x1='0.291885852'
                x2='2.07651273'
                y1='11.7821969'
                y2='9.03030311'
              ></line>
            </g>
            <rect
              fill={strokeColor}
              height='1.2'
              id='Rectangle'
              rx='0.6'
              width='6'
              x='21.5'
              y='12'
            ></rect>
            <rect
              fill={strokeColor}
              height='1.2'
              id='Rectangle'
              rx='0.6'
              width='5'
              x='18.5'
              y='15.5'
            ></rect>
            <rect
              fill={strokeColor}
              height='1.2'
              id='Rectangle'
              rx='0.6'
              width='6'
              x='20.5'
              y='19'
            ></rect>
          </g>
        </g>
      </g>
    </svg>
  )
}
