import { usePermissions } from '@/hooks/usePermissions'
import { useToasts } from '@/hooks/useToasts'
import { SubmitHandler, useForm } from 'react-hook-form'
import { HiOutlineCheckCircle } from 'react-icons/hi'

import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import * as EmailValidator from 'email-validator'

import {
  Button,
  FormInputControl,
  FormInputSelectControl,
} from '@/components/ui'
import {
  TechniciansDocument,
  useCreateTechnicianMutation,
  useFacilityNamesQuery,
} from '@/graphql/generated/hooks'
import { DeviceType } from '@/graphql/generated/schemas'
import { getFacilityOptions } from '@/utils/forms/optionBuilders'

import { RoleExtended } from '../types/types'
import { RoleIcon } from './UserRoles'

const IconWrapperStyled = styled(Box)`
  .icon {
    width: 30px;
  }
`
interface FormInputsIProps {
  firstName: string
  lastName: string
  email: string
  facilities: { label: string; value: string }[]
  deviceTypes: { label: string; value: DeviceType }[]
}

const deviceTypesOptions = [
  { label: 'Camera', value: DeviceType.Camera },
  { label: 'Door', value: DeviceType.Door },
]

interface IProps {
  isOpen: boolean
  onClose: () => void
}

export const CreateTechnicianModal = ({ isOpen, onClose }: IProps) => {
  const { shouldEnableCreateTechnician } = usePermissions()
  const [createTechnician, { loading: isLoadingCreateTechnician }] =
    useCreateTechnicianMutation()
  const { handleSubmit, register, control, errors } = useForm<FormInputsIProps>(
    {
      mode: 'onChange',
    }
  )
  const { data: facilitiesData } = useFacilityNamesQuery({
    fetchPolicy: 'network-only',
  })

  const facilityOptions = getFacilityOptions(facilitiesData)

  const { showSuccess, showError } = useToasts()

  const onSubmit: SubmitHandler<FormInputsIProps> = async (values) => {
    const data = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      facilityIds: values.facilities.map((f) => f.value),
      deviceTypes: values.deviceTypes.map((d) => d.value),
    }

    try {
      await createTechnician({
        variables: {
          input: data,
        },
        refetchQueries: [
          {
            query: TechniciansDocument,
          },
        ],
      })
      onClose()
      showSuccess('Technician created.')
    } catch {
      showError()
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      size='xl'
    >
      <ModalOverlay />
      <ModalContent bg='#fff'>
        <ModalHeader>
          <Box alignItems='center' d='flex'>
            <IconWrapperStyled>
              <RoleIcon role={RoleExtended.TECHNICIAN} />
            </IconWrapperStyled>
            <Box ml={3}>
              <Box
                color='#353849'
                fontSize='26px'
                fontWeight='extrabold'
                letterSpacing='-0.4px'
                lineHeight='1'
              >
                Create Technician
              </Box>
            </Box>
          </Box>
        </ModalHeader>
        <ModalCloseButton color='#000' opacity={0.4} />
        <ModalBody p={0}>
          <Box px={6} py={2}>
            <form data-testid='settingsPage_createTechnicianModal_form'>
              <Box mb={3}>
                <FormInputControl
                  data-testid='settingsPage_createTechnicianModal_firstName'
                  errorMessage={errors.firstName && errors.firstName.message}
                  id='firstName'
                  inputRef={register({
                    required: 'Please add first name',
                  })}
                  isInvalid={!!errors.firstName}
                  label='Technician First Name'
                  placeholder='Enter Technician First Name'
                />
              </Box>
              <Box mb={3}>
                <FormInputControl
                  data-testid='settingsPage_createTechnicianModal_lastName'
                  errorMessage={errors.lastName && errors.lastName.message}
                  id='lastName'
                  inputRef={register({
                    required: 'Please add last name',
                  })}
                  isInvalid={!!errors.lastName}
                  label='Technician Last Name'
                  placeholder='Enter Technician Last Name'
                />
              </Box>
              <Box mb={3}>
                <FormInputControl
                  data-testid='settingsPage_createTechnicianModal_email'
                  errorMessage={errors.email && errors.email.message}
                  id='email'
                  inputRef={register({
                    required: 'You must specify an email',
                    validate: {
                      isEmail: (v) =>
                        EmailValidator.validate(v) || 'Invalid email address',
                    },
                  })}
                  isInvalid={!!errors.email}
                  label='Technician Email'
                  placeholder='Enter Technician Email'
                  type='email'
                />
              </Box>
              <Box mb={3}>
                <FormInputSelectControl
                  closeMenuOnSelect={false}
                  control={control}
                  dataTestId='settingsPage_createTechnicianModal_facilities'
                  defaultValue={null}
                  errorMessage='You must select at least one facility'
                  id='facilities'
                  isClearable
                  isInvalid={!!errors.facilities}
                  isMulti
                  isSearchable
                  label='Facilities'
                  options={facilityOptions}
                  placeholder='Select Facility(-ies)'
                  rules={{
                    required: true,
                    validate: (values) => values.length !== 0,
                  }}
                />
              </Box>
              <Box mb={3}>
                <FormInputSelectControl
                  closeMenuOnSelect={false}
                  control={control}
                  dataTestId='settingsPage_createTechnicianModal_deviceTypes'
                  defaultValue={null}
                  errorMessage='You must select at least one device type'
                  id='deviceTypes'
                  isClearable
                  isInvalid={!!errors.deviceTypes}
                  isMulti
                  isSearchable
                  label='Device Types'
                  options={deviceTypesOptions}
                  placeholder='Select Device Type(-s)'
                  rules={{
                    required: true,
                    validate: (values) => values.length !== 0,
                  }}
                />
              </Box>
            </form>
          </Box>
        </ModalBody>
        <ModalFooter py={4}>
          <Button
            data-testid='settingsPage_createTechnicianModal_cancelButton'
            mr={3}
            onClick={onClose}
            variant='secondary'
          >
            Cancel
          </Button>
          <Button
            data-testid='settingsPage_createTechnicianModal_submitButton'
            isDisabled={!shouldEnableCreateTechnician}
            isLoading={isLoadingCreateTechnician}
            leftIcon={<HiOutlineCheckCircle size='24px' />}
            loadingText='Creating Technician'
            onClick={handleSubmit(onSubmit)}
            type='submit'
          >
            Create Technician
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
