export const Globe = ({
  className,
  strokeColor = '#fff',
}: {
  className?: string
  strokeColor?: string
}) => {
  return (
    <svg
      className={className}
      height='48'
      viewBox='0 0 48 48'
      width='48'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <g opacity='.374'>
          <path d='M0 0h48v48H0V0z' fill='none' />
        </g>
        <ellipse
          cx='24'
          cy='24'
          fill='none'
          rx='18'
          ry='18'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
        <path
          d='M6 24h36'
          fill='none'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
        <path
          d='M24 6c-5.16601049 4.555993-8.12549213 11.11198602-8.12549213 18S18.8339895 37.444007 24 42'
          fill='none'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
        <path
          d='M24 6c5.16601049 4.555993 8.12549213 11.11198602 8.12549213 18S29.1660105 37.444007 24 42'
          fill='none'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
      </g>
    </svg>
  )
}
