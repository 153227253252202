export const Logout = ({
  className,
  strokeColor = '#fff',
}: {
  className?: string
  strokeColor?: string
}) => {
  return (
    <svg
      className={className}
      height='48'
      viewBox='0 0 48 48'
      width='48'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <path d='M0 0h48v48H0V0z' fill='none' />
        <path
          d='M28 16v-4c0-2.209139-1.790861-4-4-4H10c-2.209139 0-4 1.790861-4 4v24c0 2.209139 1.790861 4 4 4h14c2.209139 0 4-1.790861 4-4v-4'
          fill='none'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
        <path
          d='M42 24H14l22-6 6 6z'
          fill='none'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
      </g>
    </svg>
  )
}
