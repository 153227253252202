export const Tailgating = ({
  strokeColor = '#1A202C',
}: {
  strokeColor?: string
}) => {
  return (
    <svg
      fill='none'
      height='22'
      viewBox='0 0 26 22'
      width='26'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.514 20.804l-2.745-3.273c-.278-.324-.32-.403-.428-.74l-.17-.526 1.514-1.898.426 1.997 2.71 3.211c.888 1.067-.57 2.108-1.308 1.229zm-6.646-.28c-.583 1.179-2.324.45-1.692-.83l1.866-3.776a6.62 6.62 0 01.523-.85l1.17-1.66-.076-.23c-.313-.96-.44-1.528-.505-2.469l-.182-2.644c-.094-1.278.669-2.238 1.962-2.238.979 0 1.638.494 2.526 1.364l1.4 1.379c.459.449.611.822.658 1.429l.174 2.16c.048.565-.277.964-.798.982-.53.038-.854-.28-.901-.878l-.2-2.356-.646-.577c-.222-.204-.526-.07-.508.171l.145 1.962c.081 1.009-.149 1.506-.866 2.39l-1.848 2.323c-.186.233-.208.32-.29.472l-1.912 3.876zm-3.2-11.129h2.09l1.283-1.414.162 2.28-.45.448c-.299.299-.57.394-1.121.394H8.668c-.578 0-.948-.325-.948-.854 0-.512.38-.854.948-.854zm5.235-4.344a2.027 2.027 0 01-2.027-2.026c0-1.123.904-2.026 2.027-2.026a2.027 2.027 0 010 4.052zM2.042 20.432a.908.908 0 001.815 0V1.88a.908.908 0 00-1.815 0v18.552z'
        fill={strokeColor}
      ></path>
      <path
        d='M23.916 20.78l-2.747-3.271c-.277-.325-.318-.404-.427-.741l-.17-.525 1.514-1.899.426 1.997 2.71 3.211c.888 1.067-.569 2.108-1.306 1.229zm-6.647-.278c-.576 1.178-2.322.45-1.69-.831l1.864-3.776c.146-.284.343-.592.523-.85l1.17-1.66-.076-.23c-.313-.96-.44-1.528-.503-2.469l-.184-2.644c-.092-1.278.669-2.238 1.962-2.238.979 0 1.638.494 2.526 1.364l1.4 1.379c.459.45.611.822.658 1.429l.174 2.16c.048.565-.27.964-.797.982-.53.038-.855-.28-.902-.878l-.2-2.356-.647-.577c-.22-.204-.525-.07-.505.171l.143 1.962c.081 1.01-.149 1.506-.866 2.39l-1.848 2.323c-.186.234-.208.32-.29.472l-1.912 3.877zm-3.2-11.13h2.089l1.284-1.414.161 2.28-.449.448c-.299.3-.57.394-1.12.394h-1.966c-.577 0-.947-.325-.947-.854 0-.512.38-.854.947-.854zm5.235-4.343a2.026 2.026 0 01-2.025-2.027A2.02 2.02 0 0119.304.977c1.123 0 2.027.902 2.027 2.025a2.027 2.027 0 01-2.027 2.027z'
        fill={strokeColor}
      ></path>
    </svg>
  )
}
