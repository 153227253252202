import { ButtonOptions, Button as ChakraButton } from '@chakra-ui/react'

interface ButtonIProps extends ButtonOptions {
  /**
   * The button styles type.
   */
  variant?:
    | 'primary'
    | 'success'
    | 'secondary'
    | 'tertiary'
    | 'link'
    | 'danger'
    | 'real'
    | 'false'
    | 'outline'
    | 'affirm'
    | 'ghost'
    | 'reset'
    | 'default'
    | 'defaultWhite'
  /**
   * If `true`, the button will show a spinner.
   */
  isLoading?: boolean
  /**
   * The label to show in the button when `isLoading` is true
   * If no text is passed, it only shows the spinner
   */
  loadingText?: string
  /**
   * If `true`, the button will be disabled.
   */
  isDisabled?: boolean
  /**
   * The html button type to use.
   */
  type?: 'button' | 'reset' | 'submit'
  /**
   * The react node provided as children to button.
   * @type React.ReactNode
   */
  children?: React.ReactNode
  /**
   * If added, the button will show an icon before the button's label.
   * @type React.ReactElement
   */
  leftIcon?: React.ReactElement
  /**
   * The `onClick` prop to handle click event with a callback.
   */
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  [key: string]: unknown
}

export const Button = ({
  variant = 'primary',
  type,
  children,
  isLoading,
  loadingText,
  isDisabled,
  leftIcon,
  onClick,
  ...rest
}: ButtonIProps) => {
  return (
    <ChakraButton
      isDisabled={isDisabled}
      isLoading={isLoading}
      leftIcon={leftIcon}
      loadingText={loadingText}
      onClick={onClick}
      rounded='4px'
      size='md'
      type={type}
      variant={variant}
      {...rest}
    >
      {children}
    </ChakraButton>
  )
}
