import { DocumentNode } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'
export const isBrowser = typeof window !== 'undefined'
export const isDev = process.env.NODE_ENV === 'development'
export const isTest = process.env.NODE_ENV === 'test'

export const isSubscription = (query: DocumentNode) => {
  const definition = getMainDefinition(query)
  return (
    definition.kind === 'OperationDefinition' &&
    definition.operation === 'subscription'
  )
}
