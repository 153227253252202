export const Camera = ({
  className,
  strokeColor = '#fff',
}: {
  className?: string
  strokeColor?: string
}) => {
  return (
    <svg
      className={className}
      height='48'
      viewBox='0 0 54.114 48'
      width='54.114'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <path d='M3.05694516 0h48v48h-48V0z' fill='none' />
        <path
          d='M35.16505327 14c0-1.1045695-.8954305-2-2-2H9.05694517c-1.1045695 0-2 .8954305-2 2v20.8275862c0 1.1045695.8954305 2 2 2h24.1081081c1.1045695 0 2-.8954305 2-2v-2.65701574c0-1.63015205 1.84555306-2.57556632 3.16852625-1.62312858l5.5548394 3.99905203c1.32297319.95243774 3.16852624.00702347 3.16852624-1.62312858V15.90422088c0-1.63015206-1.84555305-2.57556632-3.16852625-1.62312858l-5.55483939 3.99905203c-1.3229732.95243773-3.16852625.00702347-3.16852625-1.62312859V14z'
          fill='none'
          stroke={strokeColor}
          strokeLinecap='butt'
          strokeLinejoin='miter'
          strokeWidth='3'
        />
      </g>
    </svg>
  )
}
