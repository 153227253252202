export const AiOperator = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      viewBox='0 0 304.8 351.9'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M152.4,0L0,88v175.9l152.4,88,152.4-88V88L152.4,0ZM243.5,146.1c.6-1.1,1.7-1.7,3-1.7h13.3v6.8h-11.3l-9.2,15.8c-2.7-.1-5.5,0-8.3.7l12.6-21.7ZM262.8,156.7v6.8h-6.2l-4.1,7.1c-2-1-4.2-1.9-6.4-2.5l5.6-9.7c.6-1.1,1.7-1.7,3-1.7h8.2ZM192.5,165.8c.7.2,1.4.4,2.1.5,6.6,1.8,13.5,4.4,20.9,7.9v41.3c-5.5,3.5-10.5,6.5-15,8.9-2.5,1.3-5,2.5-7.3,3.5-4.8,2-9.3,3.3-13.6,3.9-2.3.3-4.6.4-6.9.3-4.2-.2-8.4-1.1-12.9-2.7-2.1-.7-4.3-1.6-6.6-2.7-5.8-2.7-12.2-6.4-19.6-11.2v-41.3c4.4-2,8.6-3.8,12.6-5.2l44.6,23.3,3.9,2,.9.5h0s0,0,0,0l-4.6-6.7c-.9-1.4-7.5-11-16.7-24.5.6,0,1.2,0,1.8,0,4.7.1,9.4.7,14.2,1.7.7.2,1.4.3,2.1.5ZM180.8,75.9c1.2,10.9,5.4,50.1,9.2,85.1-5.6-1.1-11.1-1.7-16.6-1.6l-19.6-28.7c.7-27.6,9.3-45.1,27-54.8ZM37.4,229.2l-11.1,1.9c-.2-7.1,3.8-15.7,11.1-24.3v22.5ZM59.5,225.5l-13.9,2.3v-29.6c2.1-1.9,4.3-3.7,6.6-5.6,2.3-1.8,4.7-3.5,7.3-5.2v38ZM81.5,221.8l-13.9,2.3v-41.5c4.5-2.4,9.1-4.5,13.9-6.3v45.4ZM103.6,218.1l-13.9,2.3v-46.6c4.7-1.2,9.3-2,13.9-2.2v46.5ZM103.5,66.8c7.4,10.7,37.7,55.4,63.4,93,.4.7.9,1.3,1.3,2,.4.7.9,1.3,1.3,2,4.8,7,9.3,13.7,13.5,19.9l-31.5-16.4-2.6-1.4-2.5-1.3-40.9-21.3c-15.5-30.9-16.2-55.3-2.1-76.5ZM122.7,214.9l-10.9,1.8v-45c3.9.4,7.6,1.2,11.1,2.6l-.2,40.5ZM163,267.5h-10.9c-1.2,0-2.3-.6-3-1.7l-16.4-28.3c-.4-.6-.5-1.4-.4-2.1l1.7-14.7c2.2,1.4,4.4,2.7,6.4,3.9l-1.2,10.5,14.9,25.6h8.9v6.8ZM183,267.5h-10.9c-1.2,0-2.3-.6-3-1.7l-16.4-28.3c-.4-.6-.5-1.4-.4-2.1l.5-4.3c2.3,1,4.4,1.9,6.6,2.6l-.2,1.4,14.9,25.6h8.9v6.8ZM203.1,267.5h-10.9c-1.2,0-2.3-.6-3-1.7l-16.4-28.3c-.2-.4-.3-.8-.4-1.2.8,0,1.5,0,2.3,0,1.7,0,3.5-.1,5.2-.3l14.3,24.6h8.9v6.8ZM223.1,267.5h-10.9c-1.2,0-2.3-.6-3-1.7l-16.4-28.3c-.4-.6-.5-1.4-.4-2.1l.3-2.8c2.3-.9,4.7-2,7.3-3.2l-.7,5.7,14.9,25.6h8.9v6.8ZM251.9,225.5l-26.7-10.7v-40.6c2-1,4.1-1.7,6.3-2.2,1.7-.4,3.5-.6,5.4-.7,2.3,0,4.7.1,7,.6,2.2.5,4.5,1.2,6.6,2.3,4.5,2.2,8.6,5.7,11.9,10.7,6.8,10.4,7.5,26.5-10.5,40.7Z'
        fill='#303d5d'
      />
    </svg>
  )
}
