import { IncidentFiltersFormInputsI } from '../types/form'

export const removeEmptyFilters = (values: IncidentFiltersFormInputsI) => {
  return Object.keys(values).forEach((key) => {
    // Delete null values and empty arrays
    if (values[key] === null || values[key].length == 0) {
      delete values[key]
    }
  })
}

export const compareAlphabetically = (
  a: { name: string },
  b: { name: string }
) => (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1)
