import { HYDRATE } from 'next-redux-wrapper'

import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { Role } from '@/features/managed-accounts'

import type { AppState } from '../store'

export interface MeStateIProps {
  id?: string
  email?: string
  roleName?: Role
  firstName?: string
  lastName?: string
  username?: string
}

const initialState: MeStateIProps = null

export const meSlice = createSlice({
  name: 'me',
  initialState,
  reducers: {
    setMe: (_, action: PayloadAction<MeStateIProps>) => {
      return action.payload
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.subject,
      }
    },
  },
})

export const { setMe } = meSlice.actions

export const selectMe = (state: AppState) => state.me
export const selectFullName = (state: AppState) =>
  `${state.me?.firstName} ${state.me?.lastName}`
export const selectEmail = (state: AppState) => state.me?.email
export const selectRole = (state: AppState) => state.me?.roleName

// Default export for reducers is a pattern recommended by Redux
// See here: <https://redux.js.org/tutorials/fundamentals/part-3-state-actions-reducers#combinereducers>
// eslint-disable-next-line import/no-default-export
export default meSlice.reducer
