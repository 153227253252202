import {
  IncidentsFilterDrawerMode,
  useDrawerMode,
} from '../hooks/useDrawerMode'

import { Drawer, DrawerContent, DrawerOverlay } from '@chakra-ui/react'

import { IncidentsFilterDrawerHeader } from './IncidentsFilterDrawerHeader'
import { IncidentsFilterForm } from './IncidentsFilterForm'
import { IncidentsFilterList } from './IncidentsFilterList'

interface IncidentsFilterDrawerIProps {
  isOpen: boolean
  onClose: () => void
}

export const IncidentsFilterDrawer = ({
  isOpen,
  onClose,
}: IncidentsFilterDrawerIProps) => {
  const { drawerMode, goBack, openFilterList, openFilterUpdate, editedFilter } =
    useDrawerMode({
      onDrawerClose: onClose,
    })

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement='right' size='sm'>
      <DrawerOverlay />
      <DrawerContent>
        <IncidentsFilterDrawerHeader
          drawerMode={drawerMode}
          editedFilter={editedFilter}
          onGoBack={goBack}
          onOpenList={openFilterList}
        />
        {drawerMode === IncidentsFilterDrawerMode.LIST ? (
          <IncidentsFilterList onFilterUpdate={openFilterUpdate} />
        ) : (
          <IncidentsFilterForm
            drawerMode={drawerMode}
            editedFilter={editedFilter}
            onClose={onClose}
            onGoBack={goBack}
          />
        )}
      </DrawerContent>
    </Drawer>
  )
}
