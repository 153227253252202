export const VoiceMessage = ({
  className,
  strokeColor = '#fff',
}: {
  className?: string
  strokeColor?: string
}) => {
  return (
    <svg
      className={className}
      height='48'
      viewBox='0 0 48 48'
      width='48'
      xmlns='http://www.w3.org/2000/svg'
    >
      <defs>
        <rect height='48' id='rect-1' width='48' x='0' y='0' />
      </defs>
      <g>
        <path d='M0 0h48v48H0V0z' fill='none' />
        <path
          d='M15.18 19.31541672l-6.4-5.14198713c-.24-.21424946-.56-.21424946-.84-.08569979-.28.12854968-.44.42849893-.44.77129807v10.28397427c0 .34279915.16.6427484.44.77129807C8.06 25.95715011 8.18 26 8.4 26c.16 0 .32-.0428499.48-.17139957l6.4-5.14198714c.2-.17139957.32-.42849892.32-.68559828s-.12-.51419871-.32-.68559829z'
          fill='none'
          stroke={strokeColor}
          strokeLinecap='butt'
          strokeLinejoin='miter'
          strokeWidth='4'
        />
        <path
          d='M21.5 17v6'
          fill='none'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='miter'
          strokeWidth='4'
        />
        <path
          d='M27.5 13v14'
          fill='none'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='miter'
          strokeWidth='4'
        />
        <path
          d='M33.5 17v6'
          fill='none'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='miter'
          strokeWidth='4'
        />
        <path
          d='M39.5 15v10'
          fill='none'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='miter'
          strokeWidth='4'
        />
        <path
          d='M42.56666667 5H4.43333333C2.82 5 1.5 6.50882353 1.5 8.35294118V31.8235294c0 1.84411765 1.32 4.35294118 2.93333333 4.35294118H6.3888889v6.70588235c0 .44705882.24444444.8382353.58666667 1.00588235C7.12222222 42.94411765 7.22 43 7.36666666 43c.24444445 0 .44-.1117647.63555556-.27941176l7.5288889-7.54411765h27.03555555C44.18 35.17647059 45.5 33.66764706 45.5 31.8235294V8.35294118C45.5 6.50882353 44.18 5 42.56666667 5z'
          fill='none'
          stroke={strokeColor}
          strokeLinecap='butt'
          strokeLinejoin='round'
          strokeWidth='4'
        />
      </g>
    </svg>
  )
}
