import { Flex, useDisclosure } from '@chakra-ui/react'

import { SettingsAddButton } from '@/components/ui'

import { RoleExtended } from '../types/types'
import { getPascalCaseRole } from '../utils/helpers'
import { CreateTechnicianModal } from './CreateTechnicianModal'
import { CreateUserModal } from './CreateUserModal'

export const AccountAdd = ({ role }: { role: RoleExtended }) => {
  const {
    isOpen: isCreateAccountOpen,
    onOpen: onCreateAccountOpen,
    onClose: onCreateAccountClose,
  } = useDisclosure()
  return (
    <Flex marginRight='1.5rem'>
      <SettingsAddButton
        data-testid={`settingsPage_managedAccounts_add${getPascalCaseRole(
          role
        )}Button`}
        onClick={onCreateAccountOpen}
      />
      {isCreateAccountOpen &&
        (role === RoleExtended.TECHNICIAN ? (
          <CreateTechnicianModal
            isOpen={isCreateAccountOpen}
            onClose={onCreateAccountClose}
          />
        ) : (
          <CreateUserModal
            isOpen={isCreateAccountOpen}
            onClose={onCreateAccountClose}
            role={role}
          />
        ))}
    </Flex>
  )
}
