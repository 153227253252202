import { ApolloQueryResult } from '@apollo/client'

import { CameraFeedQuery } from '@/graphql/generated/operations'
import { Exact } from '@/graphql/generated/schemas'

export interface IncidentVideoStateIProps {
  url: string
  isLoading: boolean
  errorMessage?: string
  isProcessing?: boolean
}

export interface DeviceFeedIProps {
  isDeviceDataLoading?: boolean
  stream: CameraStreamStateIProps
  nextCamera: () => void
  previousCamera: () => void
  availableCamerasIds: string[]
  errorMessage?: string
  hasCamera: boolean
}

export interface CameraStreamStateIProps {
  url: string
  isLoading: boolean
  errorMessage?: string
  refetch?: (
    variables?: Partial<
      Exact<{
        deviceId: string
      }>
    >
  ) => Promise<ApolloQueryResult<CameraFeedQuery>>
}

export interface VideoDimensionsIProps {
  width: number
  height: number
}

// Enums
export enum IncidentPlayerError {
  NO_INCIDENT_CLIP = 'No incident clip attached',
  ERROR_FETCHING_INCIDENT_CLIP = 'Error fetching incident clip',
  ERROR_FETCHING_STREAM = 'Error fetching live stream',
  NO_VIDEO_FEED = 'Live stream not available',
}

export enum VideoFeedEvent {
  BeginRequesting,
  Received,
  Playing,
}

export enum VideoControlsAction {
  Play,
  Pause,
  Backward10,
  Forward10,
  Rewind,
  FastForward,
  Fullscreen,
}
