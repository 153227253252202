import { HiOutlinePencil } from 'react-icons/hi'

import { Center } from '@chakra-ui/react'

interface EditButtonIProps {
  handleOnEdit: () => void
}

export const EditButton = ({ handleOnEdit }: EditButtonIProps) => {
  return (
    <Center gap='10px' gridTemplateRows='1fr 1fr' maxW='100%' ml='10px'>
      <Center
        _hover={{
          bgColor: '#d2d2d4',
        }}
        bgColor='#E5E8EE'
        boxShadow='0 2px 7px 0 rgba(50,121,199,0.5)'
        cursor='pointer'
        data-testid='EditButton:edit'
        onClick={handleOnEdit}
        padding='10px'
        rounded='5px'
        transition='ease-in-out'
        transitionDuration='300ms'
        transitionProperty='background-color'
        w='60px'
      >
        <HiOutlinePencil color='#3279C7' size='24px' />
      </Center>
    </Center>
  )
}
