export const Share = ({
  className,
  strokeColor = '#fff',
}: {
  className?: string
  strokeColor?: string
}) => {
  return (
    <svg className={className} height='40' viewBox='0 0 40 40' width='40'>
      <g>
        <path d='M0 0h40v40H0V0z' fill='none' />
        <path
          d='M36 4L18.4 21.6'
          fill='none'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
        <path
          d='M36 4L24.8 36l-6.4-14.4L4 15.2 36 4h0z'
          fill='none'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
      </g>
    </svg>
  )
}
