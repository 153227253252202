import { Dispatch, SetStateAction } from 'react'

import { ApolloClient, ApolloError } from '@apollo/client'

import { GraphqlClientType } from '@/config/apollo'
import { ON_CREATE_NOTIFICATION } from '@/graphql/appsync-notifications'

import { NotificationDataIProps } from './types'

const RECONNECT_INTERVAL = 30000

interface CreateSubscriptionArgsI {
  client: ApolloClient<any>
  userId: string
  setData: Dispatch<SetStateAction<NotificationDataIProps>>
  setError: Dispatch<SetStateAction<ApolloError>>
  setShouldReconnect: Dispatch<SetStateAction<boolean>>
}

export const createSubscription = ({
  client,
  userId,
  setData,
  setError,
  setShouldReconnect,
}: CreateSubscriptionArgsI) => {
  const observer = client.subscribe({
    query: ON_CREATE_NOTIFICATION,
    variables: { userId },
    context: { target: GraphqlClientType.APPSYNC_NOTIFICATIONS },
  })

  // Recreate new subscription connection
  const subscription = observer.subscribe(
    ({ data }: { data: NotificationDataIProps }) => {
      setData(data)
    },
    (error: ApolloError) => {
      if (error && subscription.closed) {
        // Trigger rerender after interval to recreate a subscription connection
        setTimeout(() => {
          setShouldReconnect((prevShouldReconnect) => !prevShouldReconnect)
        }, RECONNECT_INTERVAL)
      } else {
        setError(error)
      }
    }
  )

  return subscription
}
