import { Role } from '@/features/managed-accounts'
import { IncidentSource, Maybe, Scalars } from '@/graphql/generated/schemas'
import { DeviceType, IncidentActionType } from '@/graphql/generated/schemas'

export type NotificationCount = {
  __typename?: 'NotificationCount'
  userId: Scalars['ID']
  updatedAt: Scalars['String']
  notificationCount?: Maybe<Scalars['Int']>
}

export type Notification = {
  __typename?: 'Notification'
  userId: Scalars['ID']
  userRole: Role
  notificationTimestamp: Scalars['String']
  notificationType: NotificationType
  read?: Maybe<Scalars['Boolean']>
  incidentId: Scalars['ID']
  incidentDisplayId?: Maybe<Scalars['ID']>
  incidentName?: Maybe<Scalars['String']>
  incidentDescription?: Maybe<Scalars['String']>
  incidentType?: Maybe<Scalars['String']>
  incidentSourceId?: Maybe<Scalars['ID']>
  incidentSourceName?: Maybe<Scalars['String']>
  incidentSourceUserRole?: Maybe<Role>
  incidentSourceDeviceType?: Maybe<DeviceType>
  incidentSourceType?: Maybe<IncidentSource>
  facilityId: Scalars['ID']
  facilityShortName: Scalars['String']
  incidentActionId?: Maybe<Scalars['ID']>
  incidentActionType?: Maybe<IncidentActionType>
  incidentActionUserId?: Maybe<Scalars['ID']>
  incidentActionUserName?: Maybe<Scalars['String']>
  incidentActionUserRole?: Maybe<Role>
  audioMessageId?: Maybe<Scalars['ID']>
  audioMessageUserName?: Maybe<Scalars['String']>
  audioMessageUserRole?: Maybe<Role>
}

export enum NotificationType {
  AudiomessageNew = 'audiomessage.new',
  IncidentNew = 'incident.new',
  IncidentClear = 'incident.clear',
}
