export const Guards = ({
  className,
  strokeColor = '#fff',
}: {
  className?: string
  strokeColor?: string
}) => {
  return (
    <svg
      className={className}
      height='48'
      viewBox='0 0 48 48'
      width='48'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <path d='M0 0h48v48H0V0z' fill='none' />
        <path
          d='M12.40002441 18.66668701V15l5.40002442 17.2000122C15.39117432 30.59851075 13.67663574 28.14575196 13 25.333313c-2.0666504 0-2.5333252-2.93328858-2.5333252-3.5333252l-.00012207-.05645752c-.00201416-.68719482-.00897216-3.07684326 1.93347168-3.07684326zM30.5333252 25.333313c-.67657471 2.81243896-2.39117432 5.26519775-4.7999878 6.86669922l5.333313-16.7333374v3.2000122c1.94177245 0 1.93548583 2.32507324 1.93347167 3.07055664L33 21.7999878c0 .66668701-.4666748 3.5333252-2.4666748 3.5333252z'
          fill='none'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
        <path
          d='M16.4 31.2v.8c0 1.33333333.66666667 3.26666667-6.66666667 4.66666667C2.4 38.06666667 2 44 2 44h40s0-5.8-8.26666667-7.33333333C26.6 35.33333333 27.06666667 33.33333333 27.06666667 32v-.8'
          fill='none'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
        <path
          d='M8.66668701 13.33331299h2.7333374c.59997559 2.40002441 3.7333374 4 10.59997559 4 6.86669922 0 10-1.66668701 10.60003662-4h2.7333374v-4C33.48168945 9.26812744 31.66455078 8.81384277 30 8c-2.66662598-1.33331299-2.66662598-4-8-4-5.33331299 0-5.33331299 3.13336182-8 4-1.75.54901123-3.53082275.99420166-5.33331299 1.33331299v4z'
          fill='none'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
        <path
          d='M22 34.66668701L16.40002441 33h10.66668702L22 34.66668701zm-8 .7999878L22 40l8-4.33331299-16-.2000122z'
          fill='none'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
        <path
          d='M22 8v2'
          fill='none'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
      </g>
    </svg>
  )
}
