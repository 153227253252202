import { createElement } from 'react'

import { SvgProps } from 'react-native-svg'

import { Box, HStack, Text, chakra, useRadio } from '@chakra-ui/react'

interface ToggleButtonIProps {
  text: string
  icon?: (props: SvgProps) => JSX.Element
  hasBorder?: boolean
}

export const ToggleButton = ({
  text,
  icon,
  hasBorder = false,
  ...radioProps
}: ToggleButtonIProps) => {
  const { state, getInputProps, getCheckboxProps, htmlProps, getLabelProps } =
    useRadio(radioProps)

  return (
    <chakra.label {...htmlProps} cursor='pointer'>
      <input {...getInputProps({})} hidden />
      <Box
        {...getCheckboxProps()}
        _hover={{
          bg: state.isChecked ? '#1F2023' : '#F0F0F0',
        }}
        bg={state.isChecked ? '#1F2023' : 'transparent'}
        border={hasBorder ? '1px' : null}
        borderColor={
          hasBorder ? (state.isChecked ? '#1F2023' : '#F0F0F0') : null
        }
        color={state.isChecked ? '#fff' : '#1F2023'}
        px='10px'
        py='8px'
        rounded='4px'
        transition='all ease 300ms'
        w='auto'
      >
        <HStack spacing={1}>
          {icon &&
            createElement(icon, {
              width: 12,
              height: 12,
              color: state.isChecked ? '#fff' : '#1F2023',
            })}
          {text && (
            <Text fontSize='12px' {...getLabelProps()}>
              {text}
            </Text>
          )}
        </HStack>
      </Box>
    </chakra.label>
  )
}
