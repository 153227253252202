import React, { useEffect, useReducer, useRef } from 'react'

import ReactPlayer from 'react-player'

import { useCameraFeed } from '@/features/video-player'

import { initialVideoFrameState, reducer } from './reducer'
import { VideoFrameActionType } from './types'
import { captureVideoFrame } from './utils'

interface UseVideoFrameIProps {
  cameraId: string
}

export const useVideoFrame = ({ cameraId }: UseVideoFrameIProps) => {
  const { url, isLoading, errorMessage, refetch } = useCameraFeed(cameraId)
  const playerRef = useRef<ReactPlayer>(null)
  const [state, dispatch] = useReducer(reducer, initialVideoFrameState)

  const captureSnapshot = () => {
    if (state.refreshing && !isLoading) {
      setTimeout(() => {
        const frame = captureVideoFrame(playerRef?.current?.getInternalPlayer())
        if (frame?.blob?.size > 0) {
          dispatch({
            type: VideoFrameActionType.CAPTURE_IMAGE,
            payload: frame,
          })
        }
      }, 500)
    }
  }

  const refreshSnapshot = async () => {
    if (!errorMessage) {
      dispatch({ type: VideoFrameActionType.SET_REFRESH_TRUE })
      captureSnapshot()
    } else {
      try {
        await refetch()
        dispatch({ type: VideoFrameActionType.SET_REFRESH_TRUE })
        captureSnapshot()
      } catch {
        dispatch({ type: VideoFrameActionType.SET_REFRESH_FALSE })
      }
    }
  }

  const resetVideoFrame = () => {
    dispatch({ type: VideoFrameActionType.RESET })
  }

  useEffect(() => {
    if (errorMessage) {
      dispatch({ type: VideoFrameActionType.SET_REFRESH_FALSE })
    }
  }, [errorMessage])

  const DummyPlayer = () => (
    <ReactPlayer
      id='video'
      muted
      onBufferEnd={captureSnapshot}
      playing={true}
      ref={playerRef}
      style={{ display: 'none' }}
      url={url}
    />
  )

  return {
    imageUrl: state.imageUrl,
    frameDimensions: state.frameDimensions,
    errorMessage,
    refreshSnapshot,
    resetVideoFrame,
    DummyPlayer,
    isLoading: Boolean(!state.imageUrl) && !errorMessage,
    isRefreshingImage: state.refreshing,
    captureCount: state.captureCount,
  }
}
