export const DeviceHealthIncident = ({
  className,
  strokeColor = '#fff',
}: {
  className?: string
  strokeColor?: string
}) => {
  return (
    <svg
      className={className}
      height='32px'
      version='1.1'
      viewBox='0 0 32 32'
      width='32px'
    >
      <title>Device-Health</title>
      <g
        fill='none'
        fillRule='evenodd'
        id='Styleguide'
        stroke='none'
        strokeWidth='1'
      >
        <g
          id='Additional-icons'
          stroke={strokeColor}
          transform='translate(-57.000000, -120.000000)'
        >
          <g id='Device-Health' transform='translate(57.000000, 120.000000)'>
            <g id='icon' transform='translate(1.990000, 1.333333)'>
              <path
                d='M10,8.64435494 C12.5990742,10.9403884 15.9912905,12.1358498 19.457932,11.9774539 C20.4776581,15.4411328 20.0470162,19.1696635 18.2644574,22.3106488 C16.4818985,25.4516342 13.4998312,27.7365204 10,28.6429489 C6.5001688,27.7365204 3.51810152,25.4516342 1.73554265,22.3106488 C-0.0470162314,19.1696635 -0.47765813,15.4411328 0.542068027,11.9774539 C4.00870946,12.1358498 7.40092583,10.9403884 10,8.64435494'
                id='Path'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='1.995'
              ></path>
              <path
                d='M9.15887534,24.7326339 C6.5216589,23.4342199 4.84042594,22.1460444 4.11517645,20.8681073 C3.02730221,18.9512018 2.76448664,16.6757244 3.38681325,14.5618828 C4.79724629,14.6263275 6.72126698,13.9482775 9.15887534,12.5277326 C9.16468565,14.9687129 9.16468565,19.0370133 9.15887534,24.7326339 Z'
                id='Path'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='1.995'
              ></path>
              <rect
                height='1'
                id='Rectangle'
                strokeLinejoin='round'
                strokeWidth='1.995'
                width='1.0014985'
                x='12.01'
                y='13.6666667'
              ></rect>
              <rect
                height='1'
                id='Rectangle'
                strokeLinejoin='round'
                strokeWidth='1.995'
                width='1.0014985'
                x='13.5324793'
                y='18.6666667'
              ></rect>
              <rect
                height='1'
                id='Rectangle'
                strokeLinejoin='round'
                strokeWidth='1.995'
                width='1.0014985'
                x='12.01'
                y='22.7666667'
              ></rect>
              <line
                id='Line'
                strokeLinecap='square'
                strokeWidth='1.995'
                x1='9.72299229'
                x2='11.9453905'
                y1='14.1666667'
                y2='14.1666667'
              ></line>
              <line
                id='Line'
                strokeLinecap='square'
                strokeWidth='1.995'
                x1='11.01'
                x2='13.01'
                y1='19.2666667'
                y2='19.2666667'
              ></line>
              <line
                id='Line'
                strokeLinecap='square'
                strokeWidth='1.995'
                x1='9.72299229'
                x2='11.9453905'
                y1='23.3457133'
                y2='23.3457133'
              ></line>
              <polyline
                id='Path'
                points='13.01 4.53333333 17.6766667 4.53333333 19.6766667 8.26666667 22.3433333 -2.14532096e-14 24.3433333 4.53333333 27.01 4.53333333'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
              ></polyline>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
