import { Box, Divider, HStack, Skeleton, Tooltip } from '@chakra-ui/react'

interface DrawerBreadcrumbIProps {
  dataTestId?: string
  disabled?: boolean
  hideDivider?: boolean
  label: string
  isLoading: boolean
  onClick?: () => void
  tooltipLabel?: string
}

export const DrawerBreadcrumb = ({
  dataTestId,
  disabled = false,
  hideDivider = false,
  label,
  isLoading,
  onClick,
  tooltipLabel,
}: DrawerBreadcrumbIProps) => {
  if (!label && !isLoading) {
    return null
  }

  const isLink = onClick !== undefined
  const isActiveLink = isLink && !disabled

  const labelColor = disabled ? 'brand.600' : isLink ? 'link.100' : 'inherit'
  const tooltipLabelColor = disabled ? 'brand.600' : '#1a202c'

  return (
    <HStack isTruncated maxW='175px'>
      {isLoading ? (
        <Skeleton h='16px' w='40px' />
      ) : (
        <Tooltip
          bgColor='#fff'
          boxShadow='md'
          color={tooltipLabelColor}
          fontSize='16px'
          fontWeight='semibold'
          label={tooltipLabel ? tooltipLabel : label}
          letterSpacing='-0.65px'
          p='2'
          placement='bottom'
          rounded='5px'
        >
          <Box
            _hover={isActiveLink ? { textDecoration: 'underline' } : null}
            color={labelColor}
            cursor={isActiveLink ? 'pointer' : 'auto'}
            data-testid={dataTestId}
            fontSize='16px'
            fontWeight='semibold'
            isTruncated
            letterSpacing='-0.53px'
            onClick={isActiveLink ? onClick : null}
            px='7px'
          >
            {label}
          </Box>
        </Tooltip>
      )}
      {!hideDivider && (
        <Divider
          borderColor='#D7D4D4'
          borderLeftWidth='2px'
          h='20px'
          orientation='vertical'
        />
      )}
    </HStack>
  )
}
