import {
  VideoFrameAction,
  VideoFrameActionType,
  VideoFrameStateIProps,
} from './types'

export const initialVideoFrameState: VideoFrameStateIProps = {
  imageUrl: null,
  frameDimensions: null,
  refreshing: true,
  captureCount: 0,
}

export const reducer = (
  state: VideoFrameStateIProps,
  action: VideoFrameAction
) => {
  switch (action.type) {
    case VideoFrameActionType.CAPTURE_IMAGE: {
      return {
        imageUrl: action.payload.imageUrl,
        frameDimensions: action.payload.frameDimensions,
        refreshing: false,
        captureCount: state.captureCount + 1,
      }
    }
    case VideoFrameActionType.SET_REFRESH_TRUE: {
      return {
        ...state,
        refreshing: true,
      }
    }
    case VideoFrameActionType.SET_REFRESH_FALSE: {
      return {
        ...state,
        refreshing: false,
      }
    }
    case VideoFrameActionType.RESET: {
      return {
        imageUrl: null,
        frameDimensions: null,
        refreshing: true,
        captureCount: 0,
      }
    }
    default:
      return state
  }
}
