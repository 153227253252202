import CountUp from 'react-countup'

export const PercentCountUp = ({ value }: { value: number }) => (
  <CountUp preserveValue start={0} end={value} delay={0}>
    {({ countUpRef }) => (
      <>
        <span ref={countUpRef} />%
      </>
    )}
  </CountUp>
)
