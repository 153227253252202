import styled from '@emotion/styled'

export const StyledAddButton = styled.button`
  cursor: pointer;
  background-color: green;
  width: 1.85rem;
  height: 1.85rem;
  border-radius: 4px;
  color: white;
  font-weight: bolder;
  background-image: linear-gradient(to right, #13b0b0, #46ef9f);
  background-color: #46ef9f;
`

interface SettingsAddButtonIProps {
  onClick: () => void
}

export const SettingsAddButton = ({ onClick }: SettingsAddButtonIProps) => {
  return (
    <StyledAddButton
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}
    >
      &#65291;
    </StyledAddButton>
  )
}
