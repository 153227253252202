import { default as RDatePicker } from 'react-datepicker'

import styled from '@emotion/styled'

const StyledDatePicker = styled(RDatePicker)`
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  color: #000;
  border: 2px solid
    ${(p) => (p.valid ? '#2BD2B4' : p.isInvalid ? '#D01030' : '#9FB8E5')};
  min-height: 48px;
  border-radius: 6px;
  padding-inline-start: 16px;
  padding-inline-end: 16px;

  ::placeholder {
    font-size: 14px;
    color: #2d2e41;
    opacity: 30%;
    font-style: italic;
    font-weight: 500;
  }
`

interface DatePickerIProps {
  date: Date
  name: string
  minDate?: Date
  onChange: (date: Date) => void
  placeholder?: string
  [key: string]: unknown
}

export const DatePicker = ({
  date,
  minDate = new Date(),
  onChange,
  placeholder,
  ...rest
}: DatePickerIProps) => {
  return (
    <StyledDatePicker
      {...rest}
      dateFormat='MMMM dd, yyyy'
      minDate={minDate}
      onChange={onChange}
      placeholder={placeholder}
      popperPlacement='bottom'
      selected={date}
    />
  )
}
