import { useDispatch, useSelector } from 'react-redux'

import { ToggleButtonSwitch } from '@/components/ui'
import {
  selectIncidentOwnerIdFilter,
  setIncidentOwnerFilter,
} from '@/redux/incidents/incidentsSlice'
import { selectMe } from '@/redux/me/meSlice'

export const IncidentsOwnerToggle = () => {
  const me = useSelector(selectMe)
  const dispatch = useDispatch()
  const ownerIdFilterValue = useSelector(selectIncidentOwnerIdFilter)

  const onFilterAllIncidents = () => {
    dispatch(setIncidentOwnerFilter(null))
  }

  const onFilterMyIncidents = () => {
    dispatch(setIncidentOwnerFilter(me?.id))
  }

  return (
    <ToggleButtonSwitch
      activeBgColor='btn.100'
      activeTextColor='white'
      height='40px'
      inactiveBgColor='white'
      inactiveTextColor='gray.800'
      isLeftActive={!ownerIdFilterValue}
      leftArialLabel='show all incidents'
      leftButtonDataTestId='incidentsPage_tableHeader_allButton'
      leftButtonText='All'
      onLeftButtonClick={onFilterAllIncidents}
      onRightButtonClick={onFilterMyIncidents}
      rightArialLabel='show incidents assigned to me'
      rightButtonDataTestId='incidentsPage_tableHeader_acknowledgedByMeButton'
      rightButtonText='Acknowledged by Me'
      size='sm'
    />
  )
}
