import {
  useActivateSavedFilterMutation,
  useDeactivateSavedFilterMutation,
} from '@/graphql/generated/hooks'

import { SavedFilterI } from '../types/generic'

export const useActivateFilter = () => {
  const [activateSavedFilter, { loading: isActivateLoading }] =
    useActivateSavedFilterMutation()

  const [deactivateSavedFilter, { loading: isDeactivateLoading }] =
    useDeactivateSavedFilterMutation()

  const onActivateFilter = async (filter: SavedFilterI) => {
    await activateSavedFilter({
      variables: {
        id: filter.id,
      },
      refetchQueries: ['SavedIncidentsFilters', 'ActiveIncidentsFilter'],
    })
  }

  const onDectivateFilter = async (filter: SavedFilterI) => {
    await deactivateSavedFilter({
      variables: {
        id: filter.id,
      },
      refetchQueries: ['SavedIncidentsFilters', 'ActiveIncidentsFilter'],
    })
  }

  return {
    isActivateLoading,
    isDeactivateLoading,
    onActivateFilter,
    onDectivateFilter,
  }
}
