import { BiCheckCircle } from 'react-icons/bi'
import { HiOutlineExclamationCircle } from 'react-icons/hi'

import { Box, Text } from '@chakra-ui/react'

const EmergencyCallConfigured = () => {
  return (
    <Box mb='3' px='6' py='3'>
      <Box alignItems='center' color='#16B3B0' display='flex' flexDir='row'>
        <BiCheckCircle size='26px' />
        <Text fontWeight='600' ml='5px'>
          Emergency calling configured
        </Text>
      </Box>
    </Box>
  )
}

const EmergencyCallEnabled = () => {
  return (
    <Box mb='3' px='6' py='3'>
      <Box alignItems='center' color='black' display='flex' flexDir='row'>
        <HiOutlineExclamationCircle size='26px' />
        <Text fontSize='14px' fontWeight='600' ml='5px'>
          Emergency calling enabled. Please contact HiveWatch to configure
        </Text>
      </Box>
    </Box>
  )
}

interface EmergencyCallConfigurationStatusIProps {
  isEnabled: boolean
  isConfigured: boolean
}

export const EmergencyCallConfigurationStatus = ({
  isEnabled,
  isConfigured,
}: EmergencyCallConfigurationStatusIProps) => {
  if (!isEnabled) {
    return null
  }
  if (!isConfigured) {
    return <EmergencyCallEnabled />
  } else {
    return <EmergencyCallConfigured />
  }
}
