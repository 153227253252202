export const CaseOverviewIcon = () => (
  <svg
    fill='none'
    height='16'
    viewBox='0 0 16 16'
    width='16'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13.34 13.9L13.9 13.34L12.4 11.84V9.6H11.6V12.16L13.34 13.9ZM12 16C10.8933 16 9.95013 15.6099 9.1704 14.8296C8.39066 14.0493 8.00053 13.1061 8 12C7.99946 10.8939 8.3896 9.95067 9.1704 9.1704C9.9512 8.39013 10.8944 8 12 8C13.1056 8 14.0491 8.39013 14.8304 9.1704C15.6117 9.95067 16.0016 10.8939 16 12C15.9984 13.1061 15.6083 14.0496 14.8296 14.8304C14.0509 15.6112 13.1077 16.0011 12 16ZM3.2 4.8H11.2V3.2H3.2V4.8ZM6.94 14.4H1.6C1.16 14.4 0.783466 14.2435 0.4704 13.9304C0.157333 13.6173 0.000533333 13.2405 0 12.8V1.6C0 1.16 0.1568 0.783467 0.4704 0.4704C0.784 0.157333 1.16053 0.000533333 1.6 0H12.8C13.24 0 13.6168 0.1568 13.9304 0.4704C14.244 0.784 14.4005 1.16053 14.4 1.6V6.96C14.0133 6.77333 13.6232 6.63333 13.2296 6.54C12.836 6.44667 12.4261 6.4 12 6.4C11.8533 6.4 11.7165 6.4032 11.5896 6.4096C11.4627 6.416 11.3328 6.4328 11.2 6.46V6.4H3.2V8H8.1C7.86 8.22667 7.64346 8.47333 7.4504 8.74C7.25733 9.00667 7.0872 9.29333 6.94 9.6H3.2V11.2H6.46C6.43333 11.3333 6.4168 11.4635 6.4104 11.5904C6.404 11.7173 6.40053 11.8539 6.4 12C6.4 12.44 6.44 12.8501 6.52 13.2304C6.6 13.6107 6.74 14.0005 6.94 14.4Z'
      fill='white'
    />
  </svg>
)
