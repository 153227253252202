import {
  FormControl as ChakraFormControl,
  FormControlOptions,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react'
import styled from '@emotion/styled'

export const StyledFormNumberInputControl = styled(ChakraFormControl)`
  .chakra-form__label {
    opacity: ${(p) => (p.labelOpacity ? p.labelOpacity : 0.5)};
    color: ${(p) => (p.labelColour ? p.labelColour : '#2d2e41')};
    font-size: 16px;
    letter-spacing: -0.8px;
  }
`
const MAX_INPUT = 365

export interface FormNumberInputControlIProps extends FormControlOptions {
  label?: string
  labelColour?: string
  labelOpacity?: number
  id?: string
  width?: string
  isInvalid?: boolean
  onChange: (valueAsString: string, valueAsNumber: number) => void
}

export const FormNumberInputControl = ({
  id,
  label,
  labelColour,
  labelOpacity,
  width = '100%',
  onChange,
}: FormNumberInputControlIProps) => {
  return (
    <StyledFormNumberInputControl
      id={id}
      labelColour={labelColour}
      labelOpacity={labelOpacity}
      width={width}
    >
      <FormLabel htmlFor={id} mr='0'>
        {label}
      </FormLabel>
      <NumberInput
        clampValueOnBlur
        defaultValue={0}
        max={MAX_INPUT}
        min={0}
        onChange={onChange}
        size='lg'
      >
        <NumberInputField
          bgColor='white'
          borderColor='#9FB8E5'
          borderWidth='2px'
          fontSize='14px'
          fontWeight={600}
          variant='outline'
        />
        <NumberInputStepper>
          <NumberIncrementStepper color='#CCCCCC' />
          <NumberDecrementStepper color='#CCCCCC' />
        </NumberInputStepper>
      </NumberInput>
    </StyledFormNumberInputControl>
  )
}
