import { BiLinkAlt } from 'react-icons/bi'
import { HiCheckCircle } from 'react-icons/hi'

import {
  Box,
  Divider,
  HStack,
  Icon,
  Skeleton,
  Tooltip,
  useClipboard,
} from '@chakra-ui/react'

interface DrawerTextClipboardIProps {
  isLoading: boolean
  label: string
  hideDivider?: boolean
  copyMessage?: string
  hasCopiedMessage?: string
  link: string
}

export const DrawerTextClipboard = ({
  isLoading,
  label,
  link,
  hideDivider = false,
  copyMessage = 'Copy',
  hasCopiedMessage = 'Copied!',
}: DrawerTextClipboardIProps) => {
  const { hasCopied, onCopy } = useClipboard(link, 5000)
  if (!label && !isLoading) {
    return null
  }

  return (
    <HStack spacing='14px'>
      {isLoading ? (
        <Skeleton h='16px' w='40px' />
      ) : (
        <Tooltip
          bgColor='#fff'
          boxShadow='md'
          closeOnClick={false}
          color='#1a202c'
          fontSize='16px'
          fontWeight='semibold'
          label={hasCopied ? hasCopiedMessage : copyMessage}
          letterSpacing='-0.65px'
          p='2'
          placement='bottom'
          rounded='5px'
          shouldWrapChildren
        >
          <HStack
            alignItems='center'
            cursor='pointer'
            onClick={onCopy}
            spacing='1'
          >
            <Box
              _hover={{ textDecoration: 'underline' }}
              data-testid='incidentDrawer_header_incidentId'
              fontSize='16px'
              fontWeight='semibold'
              letterSpacing='-0.53px'
            >
              {label}
            </Box>
            {hasCopied ? (
              <Icon
                aria-label='Copied!'
                as={HiCheckCircle}
                boxSize='4'
                color='success.100'
              />
            ) : (
              <Icon
                aria-label='Copy to clipboard'
                as={BiLinkAlt}
                boxSize='4'
                color='#1a202c'
              />
            )}
          </HStack>
        </Tooltip>
      )}
      {!hideDivider && (
        <Divider
          borderColor='#D7D4D4'
          borderLeftWidth='2px'
          h='20px'
          orientation='vertical'
        />
      )}
    </HStack>
  )
}
