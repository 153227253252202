import { useSelector } from 'react-redux'

import { AccordionPanel, Flex, Grid } from '@chakra-ui/react'

import { SettingsRow } from '@/components/ui'
import { selectEditMode } from '@/redux/ui/uiSlice'

import { AccountIProps, RoleExtended } from '../types/types'
import {
  getDeviceTypeDisplayName,
  getFacilitiesDisplayName,
} from '../utils/helpers'
import { AccountEdit } from './AccountEdit'

export const Account = ({
  index,
  account,
  role,
}: {
  index: number
  account: AccountIProps
  role: RoleExtended
}) => {
  const isEditMode = useSelector(selectEditMode)
  const {
    id: userId,
    firstName,
    lastName,
    email,
    facilities,
    deviceTypes,
  } = account
  const fullName = `${firstName} ${lastName}`
  const facilitiesDisplayName = getFacilitiesDisplayName(facilities)
  const deviceTypesDisplayName = getDeviceTypeDisplayName(deviceTypes)
  return (
    <AccordionPanel
      bgColor={index % 2 === 0 ? 'transparent' : 'white'}
      borderTop='1px solid #eaedf3'
      p='0'
    >
      <Flex alignItems='center' justifyContent='space-between' px='4' py='6'>
        <Grid columnGap='6' gridTemplateColumns='auto 1fr' rowGap='4' w='full'>
          <SettingsRow
            dataTestId='AccountRow:value'
            label='Name'
            value={fullName}
          />
          <SettingsRow
            dataTestId='AccountRow:value'
            label='Email'
            value={email}
          />
          {role === RoleExtended.TECHNICIAN && (
            <>
              <SettingsRow
                dataTestId='AccountRow:value'
                label='Devices'
                value={deviceTypesDisplayName}
              />
              <SettingsRow
                dataTestId='AccountRow:value'
                label='Facilities'
                value={facilitiesDisplayName}
              />
            </>
          )}
        </Grid>
        {isEditMode && (
          <AccountEdit fullName={fullName} role={role} userId={userId} />
        )}
      </Flex>
    </AccordionPanel>
  )
}
