export const LineCrossing = ({
  className,
  strokeColor = '#fff',
}: {
  className?: string
  strokeColor?: string
}) => (
  <svg
    className={className}
    fill='none'
    height={24}
    width={24}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='m7.004 21.932-.008-.008-.007-.007c-.065-.057-.12-.122-.174-.184l-2.207.622L5.97 23l2.186-.615a1.95 1.95 0 0 1-1.152-.456v.003ZM14.34 19.615l-3.674 1.036-.756.872-.173.2c-.104.122-.22.226-.35.318l5.516-1.554-.431-.664-.134-.205.003-.003ZM21.494 17.6l-3.273.921.446.692.116.178 4.205-1.182-1.494-.61ZM12.975 17.506l-.128-.203v-.005l-.005.005-.077.389-.04.2v.018l-.007.017c-.042.171-.112.34-.196.496l.887-.25-.354-.548-.08-.122v.003ZM16.727 16.21l.448.692.114.18 1.732-.488-1.486-.612-.808.228ZM6.555 19.592c.082-.158.174-.292.27-.401l.223-.255L1 20.643l.168.086 1.187.563 4.02-1.132c.031-.194.09-.384.185-.565l-.005-.003ZM11.315 18.764l.409-.47c.124-.149.223-.347.272-.546l.04-.2v-.015l.23-1.135-.005-.007-.004-.01-.005-.01-.065-.11-.344-.514-1.39-2.156-.711 3.387-1.403 1.608-.951 1.093a1.186 1.186 0 0 0 .1 1.685 1.226 1.226 0 0 0 .326.195c.005 0 .012.005.017.008.018.007.037.012.055.017.124.042.255.064.384.064.337 0 .671-.136.902-.408l.178-.206.017-.02 1.933-2.232.018-.02-.003.002Z'
      fill={strokeColor}
    />
    <path
      d='m18.042 19.617-.007-.012-.555-.857-.008-.013-.921-1.427-.008-.012-.555-.858-.007-.012-2.25-3.479.396-1.685.298-1.239c.842 1.537 2.824 1.958 4.162 2.28.07.02.14.027.206.027.372 0 .706-.27.81-.646a.826.826 0 0 0-.62-.992c-1.51-.347-2.303-.644-2.8-1.115-.247-.247-.495-.594-.693-1.14-.198-.545-.297-1.14-.446-1.66-.198-.52-.718-.916-1.264-.941h-.57c-.693 0-1.486.198-2.08.495-1.264.694-2.107 1.338-2.627 2.206-.62 1.04-.694 2.205-.67 3.84 0 .471.373.843.843.843a.8.8 0 0 0 .818-.843c0-1.56.074-2.378.446-2.973.273-.446.768-.892 1.71-1.437l-.768 3.642-.075.297c-.077.367-.047.66.087.96.037.084.082.168.136.255l1.437 2.23.347.52.075.124.166.26.547.857.08.122.485.748.932 1.44.565.87.522.804c.223.365.607.558.999.558.22 0 .441-.06.637-.186.545-.347.718-1.09.347-1.66l-.122-.188-.005-.003ZM13.716 5.087c.218.077.451.122.694.124h.012c1.165 0 2.131-.941 2.131-2.106A2.142 2.142 0 0 0 14.423 1a2.104 2.104 0 0 0-.707 4.088Z'
      fill={strokeColor}
    />
  </svg>
)
