export const Gateway = ({
  className,
  strokeColor = '#fff',
}: {
  className?: string
  strokeColor?: string
}) => {
  return (
    <svg
      className={className}
      fill='none'
      height='42'
      viewBox='0 0 180 147'
      width='48'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M133.594 94.678H9.84375C4.40719 94.678 0 98.5822 0 103.398V138.28C0 143.096 4.40719 147 9.84375 147H133.594C139.03 147 143.437 143.096 143.437 138.28V103.398C143.437 98.5822 139.03 94.678 133.594 94.678ZM9.84375 102.153H133.594C134.371 102.153 135 102.71 135 103.398V138.28C135 138.968 134.371 139.525 133.594 139.525H9.84375C9.06686 139.525 8.4375 138.968 8.4375 138.28V103.398C8.4375 102.71 9.06686 102.153 9.84375 102.153Z'
        fill={strokeColor}
      />
      <path
        d='M106.875 56.0593C99.8847 56.0593 94.2188 61.0787 94.2188 67.2712C94.2188 73.4637 99.8847 78.4831 106.875 78.4831C113.865 78.4831 119.531 73.4637 119.531 67.2712C119.531 61.0787 113.865 56.0593 106.875 56.0593ZM106.875 63.5339C109.206 63.5339 111.094 65.2065 111.094 67.2712C111.094 69.3359 109.206 71.0085 106.875 71.0085C104.544 71.0085 102.656 69.3359 102.656 67.2712C102.656 65.2065 104.544 63.5339 106.875 63.5339Z'
        fill={strokeColor}
      />
      <path
        d='M106.875 80.9746C109.068 80.9746 110.869 82.4568 111.075 84.3518L111.094 84.7119V94.678C111.094 96.7427 109.206 98.4153 106.875 98.4153C104.682 98.4153 102.881 96.9331 102.675 95.0381L102.656 94.678V84.7119C102.656 82.6472 104.544 80.9746 106.875 80.9746Z'
        fill={strokeColor}
      />
      <path
        d='M43.5937 117.102C45.9244 117.102 47.8125 118.774 47.8125 120.839C47.8125 122.781 46.1394 124.377 44.0002 124.56L43.5937 124.576H21.0937C18.7631 124.576 16.875 122.904 16.875 120.839C16.875 118.897 18.5481 117.3 20.6873 117.118L21.0937 117.102H43.5937Z'
        fill={strokeColor}
      />
      <path
        d='M61.875 117.102C64.2057 117.102 66.0937 118.774 66.0937 120.839C66.0937 122.781 64.4207 124.377 62.2815 124.56L61.875 124.576H60.4687C58.1381 124.576 56.25 122.904 56.25 120.839C56.25 118.897 57.9231 117.3 60.0623 117.118L60.4687 117.102H61.875Z'
        fill={strokeColor}
      />
      <path
        d='M78.75 117.102C81.0807 117.102 82.9687 118.774 82.9687 120.839C82.9687 122.781 81.2957 124.377 79.1565 124.56L78.75 124.576H77.3437C75.0131 124.576 73.125 122.904 73.125 120.839C73.125 118.897 74.7981 117.3 76.9373 117.118L77.3437 117.102H78.75Z'
        fill={strokeColor}
      />
      <path
        d='M105.469 22.4237C132.652 22.4237 154.687 41.9445 154.687 66.0254C154.687 68.0901 152.799 69.7627 150.469 69.7627C148.138 69.7627 146.25 68.0901 146.25 66.0254C146.25 46.0722 127.992 29.8983 105.469 29.8983C82.945 29.8983 64.6875 46.0722 64.6875 66.0254C64.6875 68.0901 62.7994 69.7627 60.4687 69.7627C58.1381 69.7627 56.25 68.0901 56.25 66.0254C56.25 41.9445 78.2855 22.4237 105.469 22.4237Z'
        fill={strokeColor}
      />
      <path
        d='M105.469 0C146.632 0 180 29.5605 180 66.0254C180 68.0901 178.112 69.7627 175.781 69.7627C173.451 69.7627 171.562 68.0901 171.562 66.0254C171.562 33.6883 141.972 7.47458 105.469 7.47458C68.9657 7.47458 39.375 33.6883 39.375 66.0254C39.375 68.0901 37.4869 69.7627 35.1562 69.7627C32.8256 69.7627 30.9375 68.0901 30.9375 66.0254C30.9375 29.5602 64.3062 0 105.469 0Z'
        fill={strokeColor}
      />
    </svg>
  )
}
