import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { getPageNumbers } from '@/utils/pageNavigation'

interface NavigationIProps {
  pageIndex: number
  pageCount: number
  previousPage: () => void
  nextPage: () => void
  gotoPage: (pageNumber: number) => void
  dataTestId?: string
  btnBgColor?: string
}

export const Navigation = ({
  pageIndex,
  pageCount,
  previousPage,
  nextPage,
  gotoPage,
  dataTestId,
  btnBgColor,
}: NavigationIProps) => {
  return (
    <NavigationWrapper btnBgColor={btnBgColor} data-testid={dataTestId}>
      <button
        aria-label='previous'
        disabled={pageIndex <= 0}
        onClick={previousPage}
      >
        {'\u276e'}
      </button>
      {getPageNumbers(pageIndex + 1, pageCount).map((page, key) =>
        page === null ? (
          <span key={key}>...</span>
        ) : (
          <button
            aria-label={`go to page ${page}`}
            data-active={page === pageIndex + 1}
            key={key}
            onClick={() => gotoPage(page - 1)}
          >
            {page}
          </button>
        )
      )}
      <button
        aria-label='next'
        disabled={pageIndex + 1 >= pageCount}
        onClick={nextPage}
      >
        {'\u276f'}
      </button>
    </NavigationWrapper>
  )
}

type NavigationWrapperTProps = {
  btnBgColor: string
}

const NavigationWrapper = styled(Box)<NavigationWrapperTProps>`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;

  span {
    letter-spacing: 3px;
    color: #777;
    pointer-events: none;
  }

  button[aria-label='previous'],
  button[aria-label='next'] {
    padding-bottom: 2px;
    color: #777;
  }

  button,
  span {
    width: 30px;
    height: 30px;
    display: grid;
    place-items: center;
    line-height: 1;
    border-radius: 100%;
    text-align: center;
  }

  button:disabled {
    color: #ccc;
    cursor: not-allowed;
  }

  button[data-active='true'] {
    font-weight: bold;
    background-color: ${({ btnBgColor }) =>
      btnBgColor ? btnBgColor : '#3d7fce'};
    color: white;
  }
`
