import { useKeyCloak } from '@/hooks/useKeyCloak/KeyCloakProvider'

import { Box } from '@chakra-ui/react'

import { Button } from '@/components/ui'

export const InvalidMessage = () => {
  const { logOut } = useKeyCloak()
  return (
    <Box bg='white' borderRadius='6px' flexDirection='column' width='480px'>
      <Box
        bg='#f0f5fd'
        borderRadius='6px'
        color='#353849'
        fontSize='20px'
        fontWeight='bold'
        letterSpacing='-0.45px'
        p='20px 50px 20px'
      >
        Incorrect role
      </Box>

      <Box
        color='#2d2e41b2'
        fontSize='15px'
        fontWeight='500'
        letterSpacing='-0.3px'
        pb='10px'
        pt='30px'
        px='50px'
        textAlign='left'
      >
        <Box
          color='#2d2e41b2'
          fontSize='15px'
          fontWeight='500'
          letterSpacing='-0.3px'
          textAlign='left'
        >
          You cannot access the app as a Guard
        </Box>
        <Box pb='30px' pt='30px'>
          <Button isFullWidth onClick={logOut}>
            Logout
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
