import { usePermissions } from '@/hooks/usePermissions'
import { HiExclamation, HiOutlineTrash } from 'react-icons/hi'

import { AlertDialogBody, AlertDialogFooter, Box, Flex } from '@chakra-ui/react'

import { BasicAlertDialog, Button } from '@/components/ui'

interface ConfirmRemovalDialogIProps {
  isOpen: boolean
  isLoading?: boolean
  onClose: () => void
  title: string | JSX.Element
  handleOnClick: () => void
  itemTitle: string
}

export const ConfirmRemovalDialog = ({
  isOpen,
  isLoading,
  onClose,
  title,
  itemTitle,
  handleOnClick,
}: ConfirmRemovalDialogIProps) => {
  const { shouldEnableRemoveModal } = usePermissions()
  return (
    <BasicAlertDialog
      dataTestId='ConfirmRemovalDialog'
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      title={title}
    >
      <AlertDialogBody>
        <Box
          data-testid='ConfirmRemovalDialog:question'
          letterSpacing='-0.53px'
        >
          {'Are you sure you want to remove '}
          <Box as='span' fontWeight='bold'>
            {itemTitle}
          </Box>
          ?
        </Box>
        <Flex alignItems='center' color='#D11D3A' flexDirection='row' pt='4'>
          <HiExclamation size='24px' />
          <Box
            fontSize='16px'
            fontWeight='medium'
            letterSpacing='-0.53px'
            ml='1'
          >
            <Box as='span' fontWeight='bold'>
              Warning:
            </Box>{' '}
            this action cannot be undone!
          </Box>
        </Flex>
      </AlertDialogBody>
      <AlertDialogFooter>
        <Button
          data-testid='ConfirmRemovalDialog:cancel'
          mr='3'
          onClick={onClose}
          variant='secondary'
        >
          Cancel
        </Button>
        <Button
          data-testid='ConfirmRemovalDialog:confirm'
          isDisabled={isLoading || !shouldEnableRemoveModal}
          isLoading={isLoading}
          leftIcon={<HiOutlineTrash size='24px' />}
          onClick={handleOnClick}
          variant='danger'
        >
          Confirm Removal
        </Button>
      </AlertDialogFooter>
    </BasicAlertDialog>
  )
}
