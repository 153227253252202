import {
  Box,
  Circle,
  HStack,
  Menu,
  MenuButton,
  MenuList,
} from '@chakra-ui/react'

import { MenuSelectItem } from '@/components/ui'

interface FilterListItemMenuIProps {
  isActive: boolean
  onFilterActivate: () => void
  onFilterDeactivate: () => void
  onFilterUpdate: () => void
  onFilterDelete: () => void
}

export const FilterListItemMenu = ({
  isActive,
  onFilterActivate,
  onFilterDeactivate,
  onFilterUpdate,
  onFilterDelete,
}: FilterListItemMenuIProps) => {
  return (
    <Menu closeOnBlur={true} gutter={0} isLazy={true} matchWidth={false}>
      <MenuButton dataTestId='FilterListItemMenu_open'>
        <DotsButton />
      </MenuButton>
      <MenuList p={0}>
        {isActive ? (
          <MenuSelectItem label='Deactivate' onClick={onFilterDeactivate} />
        ) : (
          <MenuSelectItem label='Activate' onClick={onFilterActivate} />
        )}
        <MenuSelectItem label='Update' onClick={onFilterUpdate} />
        <MenuSelectItem
          label='Delete'
          onClick={onFilterDelete}
          showDivider={false}
          textColor='#D11D3A'
        />
      </MenuList>
    </Menu>
  )
}

const DotsButton = () => (
  <Box p='10px' pr='0px'>
    <HStack borderRadius='2px' p='6px' spacing='4px'>
      <Circle backgroundColor='#7a828f' size='5px' />
      <Circle backgroundColor='#7a828f' size='5px' />
      <Circle backgroundColor='#7a828f' size='5px' />
    </HStack>
  </Box>
)
