export enum TimeFrame {
  hours1 = '1hr',
  hours8 = '8hr',
  days1 = '24hr',
  days7 = '7d',
  days30 = '30d',
  days90 = '90d',
}

export function timeFrameLabel(timeFrame: TimeFrame): string {
  switch (timeFrame) {
    case TimeFrame.hours1:
      return 'last hour'
    case TimeFrame.hours8:
      return 'last 8 hours'
    case TimeFrame.days1:
      return 'last 24 hours'
    case TimeFrame.days7:
      return 'last 7 days'
    case TimeFrame.days30:
      return 'last 30 days'
    case TimeFrame.days90:
      return 'last 90 days'
  }
}

export function timeFrameInHours(timeFrame: TimeFrame): number {
  switch (timeFrame) {
    case TimeFrame.hours1:
      return 1
    case TimeFrame.hours8:
      return 8
    case TimeFrame.days1:
      return 24
    case TimeFrame.days7:
      return 24 * 7
    case TimeFrame.days30:
      return 24 * 30
    case TimeFrame.days90:
      return 24 * 90
  }
}
