import { Text } from '@chakra-ui/react'

export const NoFilterMessage = () => {
  return (
    <Text
      color='#44546F'
      fontSize='13px'
      fontWeight={500}
      lineHeight='16px'
      mb='10px'
      textAlign='center'
    >
      You don't have any saved filters
    </Text>
  )
}
