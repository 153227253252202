export const OrderRepair = ({
  className,
  strokeColor = 'none',
}: {
  className?: string
  strokeColor?: string
}) => {
  return (
    <svg
      className={className}
      height='32px'
      version='1.1'
      viewBox='0 0 32 32'
      width='32px'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>Order-Repair</title>
      <g
        fill='none'
        fillRule='evenodd'
        id='Styleguide'
        opacity='0.900000036'
        stroke={strokeColor}
        stroke-width='1'
      >
        <g id='3' transform='translate(-149.000000, -362.000000)'>
          <g id='Icons' transform='translate(149.000000, 135.996120)'>
            <g id='Order-Repair' transform='translate(0.000000, 226.003880)'>
              <polygon id='Path' points='0 0 32 0 32 32 0 32'></polygon>
              <g
                id='Orderrepair'
                stroke='#fff'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='1.995'
                transform='translate(2.000000, 2.000000)'
              >
                <polyline
                  id='Path'
                  points='1.70348136 13.5202351 9.01057887 6.09773326 11.4556013 5.63225834 13.5202351 0.899377591 12.58791 -0.0476720839 7.81300735 1.93166174 7.33332612 4.43755518 0.0476720839 11.8382749'
                  transform='translate(6.783954, 6.736282) rotate(-90.000000) translate(-6.783954, -6.736282) '
                ></polyline>
                <path
                  d='M17.5242903,26.898121 L23.8529126,20.4690743 C24.3768514,19.9362129 24.7197889,19.2474681 24.8318539,18.5029991 L27.8884816,16.3494082 C28.216103,16.1402445 28.4307987,15.7896251 28.4706628,15.3986486 C28.5105269,15.007672 28.371128,14.6197996 28.0926608,14.3468716 L24.9437329,11.1482114 C24.6750484,10.8653462 24.2932066,10.7237457 23.9083089,10.7642394 C23.5234112,10.804733 23.1782432,11.0228196 22.9723316,11.3556152 L20.8778634,14.4226356 C20.1233818,14.5259717 19.4229559,14.8773916 18.8836202,15.4231936 L12.5568624,21.8498727 C11.1845015,23.2432542 11.1839798,25.5029019 12.5556971,26.8969371 C13.9274144,28.2909724 16.1519293,28.2915024 17.5242903,26.898121 L17.5242903,26.898121 Z'
                  id='Path'
                  transform='translate(20.002309, 19.350119) rotate(-90.000000) translate(-20.002309, -19.350119) '
                ></path>
                <path
                  d='M7.64159048,16.481433 C7.23423765,16.8828134 6.66446477,17.0713982 6.09941808,16.9918649 C5.87487016,16.9628952 5.64871343,16.9483262 5.42232173,16.9482462 C3.83831744,16.9370616 2.32977241,17.6271989 1.29823123,18.8349571 C0.266690043,20.0427154 -0.183181021,21.6455484 0.0683647335,23.2168139 L3.72634777,20.8966689 C3.94708476,20.7556206 4.2333134,20.7743914 4.43392732,20.9430718 L5.76179364,22.0567414 C5.8547952,22.1344892 5.92224914,22.2386508 5.95531572,22.3555761 L6.37699988,23.8404689 C6.45385393,24.1082226 6.33815898,24.394019 6.09710875,24.5318721 L2.19941697,26.7694199 C3.22613726,27.538729 4.49196482,27.9155999 5.77010724,27.8325104 C7.26954248,27.7363613 8.66195926,27.0191399 9.61491056,25.8520866 C10.5678619,24.6850333 10.9958175,23.1728946 10.7966009,21.6767017 C10.7126702,21.110659 10.8970531,20.5377523 11.2949549,20.1282369'
                  id='Path'
                ></path>
                <path
                  d='M20.2808422,11.3623849 C20.6881951,10.9610045 21.2579679,10.7724197 21.8230146,10.851953 C22.0475626,10.8809227 22.2737193,10.8954917 22.500111,10.8955718 C24.0841153,10.9067563 25.5926603,10.216619 26.6242015,9.00886078 C27.6557427,7.80110251 28.1056137,6.19826954 27.854068,4.62700399 L24.1960849,6.94714899 C23.975348,7.08819727 23.6891193,7.06942647 23.4885054,6.90074609 L22.1606391,5.78707649 C22.0676375,5.70932874 22.0001836,5.60516711 21.967117,5.48824182 L21.5454328,4.00334902 C21.4685788,3.73559526 21.5842737,3.4497989 21.825324,3.31194581 L25.7230157,1.07439797 C24.6962955,0.305088903 23.4304679,-0.0717819761 22.1523255,0.0113075285 C20.6528902,0.107456572 19.2604735,0.824677981 18.3075222,1.99173127 C17.3545709,3.15878457 16.9266152,4.67092332 17.1258319,6.16711624 C17.2097625,6.73315891 17.0253797,7.30606557 16.6274779,7.71558102'
                  id='Path'
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
