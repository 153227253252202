import { Box, Skeleton } from '@chakra-ui/react'

import { getCurrentRangeMessage } from '@/utils/pageNavigation'

interface PageRangeIndicatorIProps {
  isLoading: boolean
  pageIndex: number
  pageSize: number
  totalCount: number
}

export const PageRangeIndicator = ({
  isLoading,
  pageIndex,
  pageSize,
  totalCount,
}: PageRangeIndicatorIProps) => {
  return isLoading ? (
    <Box alignItems='center' display='flex'>
      <Skeleton color='black' h='14px' w='32px' />
      <Box as='p' mx='6px'>
        of
      </Box>
      <Skeleton color='black' h='14px' w='12px' />
    </Box>
  ) : (
    <Box as='p' textAlign='left'>
      {getCurrentRangeMessage(pageIndex, pageSize, totalCount)}
    </Box>
  )
}
