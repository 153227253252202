import { HiOutlineCheckCircle } from 'react-icons/hi'

import {
  Input as ChakraInput,
  Icon,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react'
import styled from '@emotion/styled'

const StyledInput = styled(InputGroup)`
  .chakra-input {
    font-weight: 600;
    font-size: 14px;
    color: #000;

    ::placeholder {
      font-size: 14px;
      color: #2d2e41;
      opacity: 30%;
      font-style: italic;
      font-weight: 500;
    }
  }
`

export interface InputIProps extends InputProps {
  /**
   * Input name.
   */
  name?: string
  /**
   * If `true`, the input will display valid state styles with check icon.
   */
  valid?: boolean
  /**
   * If added, the button will show an icon to the left of the input.
   */
  leftIcon?: React.ElementType
  /**
   * If `true`, the input will display error state styles.
   */
  isInvalid?: boolean
  /**
   * The placeholder value for this input.
   */
  placeholder?: string
  /**
   * The width of the input element.
   */
  width?: string
  [key: string]: unknown
}

export const Input = ({
  name,
  isInvalid,
  placeholder,
  leftIcon,
  valid,
  inputRef,
  width = '100%',
  ...rest
}: InputIProps) => {
  const dataTestId = rest.dataTestId || 'text-input'
  return (
    <StyledInput size='lg' width={width}>
      {leftIcon && (
        <InputLeftElement
          children={<Icon as={leftIcon} boxSize={4} color='#33344B' />}
          data-testid='left-input-icon'
        />
      )}
      <ChakraInput
        autoCapitalize='off'
        autoComplete='off'
        autoCorrect='off'
        bgColor='white'
        borderColor={valid ? '#2BD2B4' : '#9FB8E5'}
        borderWidth='2px'
        data-testid={dataTestId}
        errorBorderColor='#D01030'
        isInvalid={isInvalid}
        name={name}
        placeholder={placeholder}
        ref={inputRef as React.RefObject<HTMLInputElement>}
        spellCheck='false'
        variant='outline'
        {...rest}
      />
      {valid && (
        <InputRightElement
          children={
            <Icon as={HiOutlineCheckCircle} boxSize={6} color='#2BD2B4' />
          }
          data-testid='input-valid-icon'
        />
      )}
    </StyledInput>
  )
}
