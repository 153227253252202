export const Dashboard = ({
  className,
  strokeColor = '#fff',
}: {
  className?: string
  strokeColor?: string
}) => {
  return (
    <svg
      className={className}
      height='48'
      viewBox='0 0 48 48'
      width='48'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <path d='M0 0h48v48H0V0z' fill='none' />
        <path
          d='M8 38h32'
          fill='none'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
        <path
          d='M8 38.00487338v-32'
          fill='none'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
        <path
          d='M39.99998877 7.99998652l-6.22221174 9.33334032-6.22222572-3.11110412L22.000009 26.00000674l-5.99999776 2.00000674'
          fill='none'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
      </g>
    </svg>
  )
}
