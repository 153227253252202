export const CallEnd = ({
  className,
  strokeColor = '#fff',
}: {
  className?: string
  strokeColor?: string
}) => {
  return (
    <svg
      className={className}
      fill={strokeColor}
      height='51'
      viewBox='0 0 55 51'
      width='55'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M39.6372 24.4348C37.5567 23.2224 37.7349 21.9601 37.7349 21.9601L38.4097 18.4166C38.7105 17.029 37.1732 16.56 37.1732 16.56C26.4787 13.4547 20.6676 15.4101 18.3707 15.9603C16.7083 16.3628 16.6512 17.4557 16.6512 17.4557L16.3645 22.5445C16.413 24.4803 15.0082 24.6754 15.0082 24.6754L5.58513 26.7615C4.02171 27.1566 3.07279 25.4936 2.54487 23.7268C2.02869 21.9812 1.91826 16.5579 5.84409 13.4582C9.76424 10.3534 18.1213 6.07174 28.5341 7.35294C39.0579 8.65886 46.1386 12.1713 46.1386 12.1713C53.7438 15.5445 52.4979 23.259 52.4979 23.259C51.3743 29.2015 47.275 28.778 47.275 28.778C47.275 28.778 41.7177 25.6471 39.6372 24.4348Z'
        fill={strokeColor}
        stroke-linejoin='round'
        stroke-width='3'
      />
      <path
        d='M35.9975 44.3666C36.7166 43.5641 36.6491 42.3306 35.8467 41.6115L31.3118 37.5473L36.4496 31.8144C37.1687 31.012 37.1012 29.7785 36.2988 29.0594L34.1193 27.1062C33.3169 26.387 32.0834 26.4545 31.3643 27.257L26.2265 32.9899L20.6063 27.9531C19.8039 27.2339 18.5704 27.3015 17.8512 28.1039L15.898 30.2833C15.1789 31.0858 15.2464 32.3193 16.0488 33.0384L21.669 38.0752L16.9901 43.296C16.271 44.0985 16.3385 45.3319 17.1409 46.0511L19.3203 48.0043C20.1228 48.7234 21.3563 48.6559 22.0754 47.8535L26.7543 42.6326L31.2892 46.6968C32.0917 47.4159 33.3251 47.3484 34.0443 46.546L35.9975 44.3666Z'
        fill={strokeColor}
        stroke-linejoin='round'
        stroke-width='3'
      />
    </svg>
  )
}
