import { FiUpload } from 'react-icons/fi'

import { Button } from '@/components/ui'

interface FileAttachButtonIProps {
  placeholder?: string
  onFileAttach: () => void
}

export const FileAttachButton = ({
  placeholder,
  onFileAttach,
}: FileAttachButtonIProps) => (
  <Button leftIcon={<FiUpload size={24} />} onClick={onFileAttach} width='100%'>
    {placeholder}
  </Button>
)
