import { Flex } from '@chakra-ui/react'

export const PanelContainer = ({
  children,
  ...rest
}: {
  children: React.ReactNode
  [key: string]: unknown
}) => {
  return (
    <Flex
      bg='#fff'
      boxShadow='0px 2px 6px 2px rgba(0, 0, 0, 0.1)'
      direction='column'
      h='100%'
      w='320px'
      {...rest}
    >
      {children}
    </Flex>
  )
}
