export const TailgatingIncident = ({
  className,
  strokeColor = '#fff',
}: {
  className?: string
  strokeColor?: string
}) => {
  return (
    <svg
      className={className}
      height='32'
      version='1.1'
      viewBox='0 0 32 32'
      width='32'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g id='Artboard' transform='translate(-249.000000, -283.000000)'>
          <g id='tailgating' transform='translate(249.000000, 283.000000)'>
            <rect
              fill='url(#linearGradient-1)'
              height='32'
              id='Rectangle'
              rx='16'
              width='32'
              x='0'
              y='0'
            ></rect>
            <g id='door' transform='translate(5.000000, 10.000000)'>
              <rect
                height='10.4762808'
                id='Rectangle'
                rx='0.752941176'
                stroke={strokeColor}
                width='7'
                x='0.5'
                y='0.5'
              ></rect>
              <path
                d='M1.70827354,1.32323198 L0.214155892,2.85379153 C0.0768578436,2.99443831 -2.40705477e-17,3.18319765 0,3.37974859 L0,12.2876334 C-5.80041088e-16,12.7034713 0.337103247,13.0405746 0.752941176,13.0405746 C0.941405163,13.0405746 1.12301665,12.9698967 1.26190229,12.8425019 L2.75601994,11.4720008 C2.91149039,11.3293933 3,11.1281015 3,10.9171324 L3,1.84918905 C3,1.43335112 2.66289675,1.09624787 2.24705882,1.09624787 C2.04422116,1.09624787 1.84996309,1.17808659 1.70827354,1.32323198 Z'
                fill={strokeColor}
                id='Path-5'
                transform='translate(1.500000, 7.000000) scale(-1, 1) translate(-1.500000, -7.000000) '
              ></path>
            </g>
            <g
              id='person'
              strokeLinecap='round'
              strokeLinejoin='round'
              transform='translate(14.666667, 16.350000) scale(-1, 1) translate(-14.666667, -16.350000) translate(10.000000, 10.200000)'
            >
              <circle
                cx='6'
                cy='0.666666667'
                fill={strokeColor}
                id='Oval'
                r='1'
                stroke={strokeColor}
                strokeWidth='1.25'
              ></circle>
              <polyline
                id='Path'
                points='0 9.63333333 3.33333333 10.3 3.83333333 9.3'
                stroke={strokeColor}
                strokeWidth='1.5'
              ></polyline>
              <g id='Path'>
                <use stroke='#4786DC' strokeWidth='3.5'></use>
                <use stroke={strokeColor} strokeWidth='1.5'></use>
              </g>
              <polyline
                id='Path'
                points='7.33333333 12.3 7.33333333 9.63333333 4.66666667 7.63333333 5.33333333 3.63333333'
                stroke={strokeColor}
                strokeWidth='1.5'
              ></polyline>
              <g id='Path'>
                <use stroke='url(#linearGradient-3)' strokeWidth='3.5'></use>
                <use stroke={strokeColor} strokeWidth='1.5'></use>
              </g>
            </g>
            <line
              stroke={strokeColor}
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='1.5'
              x1='14.186087'
              x2='12.084725'
              y1='18.1629234'
              y2='19.8046873'
            ></line>
            <g
              id='person'
              stroke={strokeColor}
              strokeLinecap='round'
              strokeLinejoin='round'
              transform='translate(23.800000, 17.050000) scale(-1, 1) translate(-23.800000, -17.050000) translate(19.600000, 11.500000)'
            >
              <circle
                cx='5.4'
                cy='0.6'
                fill={strokeColor}
                id='Oval'
                r='1'
                strokeWidth='1.25'
              ></circle>
              <polyline
                id='Path'
                points='0 9.15 3 9.75 3.45 8.85'
                strokeWidth='1.5'
              ></polyline>
              <polyline
                id='Path'
                points='6.6 11.1 6.6 8.7 4.2 6.9 4.8 3.3'
                strokeWidth='1.5'
              ></polyline>
              <polyline
                id='Path'
                points='1.8 5.7 1.8 3.9 4.8 3.3 6.6 5.1 8.4 5.7'
                strokeWidth='1.5'
              ></polyline>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
