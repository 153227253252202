import { QueryHookOptions, QueryResult, gql, useQuery } from '@apollo/client'

import { GraphqlClientType } from '@/config/apollo'
import { Notification } from '@/graphql/appsync-notifications'
import { Maybe } from '@/graphql/generated/schemas'

export type ListNotificationsQuery = {
  __typename?: 'Query'
  listNotifications?: Maybe<{
    items?: Maybe<Notification[]>
    nextToken?: string | null
  }>
}

type ListNotificationQueryInput = {
  nextToken?: string | null
}

export const LIST_ALL_NOTIFICATIONS = gql`
  query ListNotifications($nextToken: String) {
    listNotifications(
      limit: 10
      nextToken: $nextToken
      sortByNotificationTimestampAsc: false
    ) {
      items {
        notificationTimestamp
        notificationType
        read
        incidentId
        incidentDisplayId
        incidentName
        incidentSourceName
        facilityShortName
        incidentActionUserName
        incidentActionUserRole
        audioMessageId
        audioMessageUserName
        audioMessageUserRole
      }
      nextToken
    }
  }
`

export const LIST_UNREAD_NOTIFICATIONS = gql`
  query ListNotifications($nextToken: String) {
    listNotifications(
      limit: 10
      nextToken: $nextToken
      sortByNotificationTimestampAsc: false
      filter: { read: { eq: false } }
    ) {
      items {
        notificationTimestamp
        notificationType
        read
        incidentId
        incidentDisplayId
        incidentName
        incidentSourceName
        facilityShortName
        incidentActionUserName
        incidentActionUserRole
        audioMessageId
        audioMessageUserName
        audioMessageUserRole
      }
      nextToken
    }
  }
`

export const useListNotifications = (
  showUnread: boolean,
  options?: QueryHookOptions<ListNotificationsQuery, ListNotificationQueryInput>
): QueryResult<ListNotificationsQuery, ListNotificationQueryInput> => {
  if (showUnread) {
    return useQuery(LIST_UNREAD_NOTIFICATIONS, {
      ...options,
      context: { target: GraphqlClientType.APPSYNC_NOTIFICATIONS },
    })
  }
  return useQuery(LIST_ALL_NOTIFICATIONS, {
    ...options,
    context: { target: GraphqlClientType.APPSYNC_NOTIFICATIONS },
  })
}
