import { HYDRATE } from 'next-redux-wrapper'

import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { Notification } from '@/graphql/appsync-notifications'

import type {
  AppState,
  /*, AppThunk*/
} from '../store'

export interface NotificationStateIProps {
  notifications: Notification[]
  notificationCount: number | undefined
}

const initialState: NotificationStateIProps = {
  notifications: [],
  notificationCount: undefined,
}

export const incidentSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<Notification[]>) => {
      state.notifications = action.payload
    },
    setNotificationCount: (state, action: PayloadAction<number>) => {
      state.notificationCount = action.payload
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.subject,
      }
    },
  },
})

export const { setNotifications, setNotificationCount } = incidentSlice.actions

export const selectNotifications = (state: AppState) =>
  state.notification?.notifications

export const selectNotificationCount = (state: AppState) =>
  state.notification?.notificationCount

// Default export for reducers is a pattern recommended by Redux
// See here: <https://redux.js.org/tutorials/fundamentals/part-3-state-actions-reducers#combinereducers>
// eslint-disable-next-line import/no-default-export
export default incidentSlice.reducer
