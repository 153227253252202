import { BiFilterAlt } from 'react-icons/bi'

import { Box, HStack, Text, Tooltip, VStack } from '@chakra-ui/react'

export interface ActiveFilterBadgeIProps {
  label: string
}

export const ActiveFilterBadge = ({ label }: ActiveFilterBadgeIProps) => {
  return (
    <HStack
      borderRadius='4px'
      height='40px'
      justifyContent='space-between'
      marginRight='16px'
      maxW='280px'
      minWidth='0'
      role='group'
    >
      <Box mr='4px'>
        <BiFilterAlt color='#43BF9B' size={24} />
      </Box>
      <VStack align='flex-start' spacing='0px'>
        <Tooltip
          hasArrow
          label={label}
          openDelay={500}
          placement='top'
          shouldWrapChildren
        >
          <Text
            color='#172B4D'
            fontSize='14px'
            fontWeight={500}
            lineHeight='20px'
            noOfLines={1}
            w='full'
          >
            {label}
          </Text>
        </Tooltip>
        <Text
          color='#172B4D'
          fontSize='12px'
          fontWeight={400}
          lineHeight='18px'
          w='full'
        >
          Active filter
        </Text>
      </VStack>
    </HStack>
  )
}
