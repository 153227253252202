import type { AppProps } from 'next/app'

import { InMemoryCache } from '@apollo/client'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import { APOLLO_STATE_PROP_NAME } from './utils'

interface CacheEntryI {
  __ref: string
}

export const appCache = new InMemoryCache({
  typePolicies: {
    Notification: {
      keyFields: ['notificationTimestamp'],
    },
    Query: {
      fields: {
        listNotifications: {
          // Separately cache all notifications and only unread
          keyArgs: [['filter', 'read']],
          merge(existing = { items: [] }, incoming, options) {
            if (options.args.nextToken === undefined) {
              return incoming
            }

            const existingKeys = new Set(
              existing.items?.map((e: CacheEntryI) => e?.__ref)
            )
            const combinedItems = [
              ...existing.items,
              ...incoming.items.filter(
                (e: CacheEntryI) => !existingKeys.has(e?.__ref)
              ),
            ]

            return {
              ...incoming,
              items: combinedItems,
            }
          },
        },
      },
    },
  },
})

export const addApolloState = (
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: AppProps['pageProps']
) => {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pageProps
}
