import { useCreateSavedFilterMutation } from '@/graphql/generated/hooks'
import { FilterContext } from '@/graphql/generated/schemas'
import { buildFilterCriteria } from '@/redux/incidents/conversion'

import {
  CreateIncidentFilterFormInputsI,
  IncidentFiltersFormInputsI,
} from '../types/form'

export const useCreateFilter = () => {
  const [createSavedFilter, { loading: isCreateLoading }] =
    useCreateSavedFilterMutation()

  const create = async (
    createIncidentFilterFormValues: CreateIncidentFilterFormInputsI,
    incidentFiltersFormValues: IncidentFiltersFormInputsI
  ) => {
    // Convert between incident filter drawer form state and incidents query filter JSON
    const incidentFilterCriteria = buildFilterCriteria(
      incidentFiltersFormValues
    )

    await createSavedFilter({
      variables: {
        input: {
          context: FilterContext.Incident,
          filterCriteria: {
            incident: incidentFilterCriteria,
          },
          name: createIncidentFilterFormValues.name?.trim(),
        },
      },
      refetchQueries: ['SavedIncidentsFilters'],
    })
  }

  return {
    create,
    isCreateLoading,
  }
}
