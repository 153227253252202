import { ChangeEvent } from 'react'

import { BiSearch } from 'react-icons/bi'

import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react'

interface SearchInputIProps {
  placeholder?: string
  value?: string
  showIcon?: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  dataTestId?: string
}

export const SearchInput = ({
  placeholder,
  value,
  showIcon = true,
  onChange,
  dataTestId,
}: SearchInputIProps) => {
  return (
    <InputGroup>
      {showIcon && (
        <InputLeftElement
          children={<BiSearch />}
          fontSize='1.2em'
          pointerEvents='none'
        />
      )}
      <Input
        _placeholder={{
          fontSize: '14px',
        }}
        autoCapitalize='off'
        autoComplete='off'
        autoCorrect='off'
        bgColor='white'
        borderColor='#D5DCE4'
        borderWidth='1px'
        color='#353849'
        data-testid={dataTestId}
        fontWeight='medium'
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        variant='outline'
      />
    </InputGroup>
  )
}
