import { useActivateFilter } from '../hooks/useActivateFilter'

import { AlertDialogBody, AlertDialogFooter, Box } from '@chakra-ui/react'

import { Button } from '@/components/ui'
import { BasicAlertDialog } from '@/components/ui'

import { SavedFilterI } from '../types/generic'

interface DeactivateFilterConfirmationModalIProps {
  isOpen: boolean
  onClose: () => void
  activeFilter: SavedFilterI
  onApplyFilters: () => void
}

export const DeactivateFilterConfirmationModal = ({
  isOpen,
  onClose,
  activeFilter,
  onApplyFilters,
}: DeactivateFilterConfirmationModalIProps) => {
  const { onDectivateFilter, isDeactivateLoading } = useActivateFilter()

  const onSubmit = async () => {
    try {
      onDectivateFilter(activeFilter)
      onApplyFilters()
      onClose()
    } catch {
      onClose()
    }
  }

  return (
    <BasicAlertDialog
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      title='Confirm Filter Application'
    >
      <AlertDialogBody letterSpacing='-0.53px' py='24px'>
        <Box mb='15px'>
          Applying these changes will disable the currently active filter.
        </Box>
        <Box>Do you want to proceed?</Box>
      </AlertDialogBody>
      <AlertDialogFooter>
        <Button mr='3' onClick={onClose} variant='secondary'>
          Cancel
        </Button>
        <Button
          isDisabled={isDeactivateLoading}
          isLoading={isDeactivateLoading}
          onClick={onSubmit}
        >
          Apply Filters
        </Button>
      </AlertDialogFooter>
    </BasicAlertDialog>
  )
}
