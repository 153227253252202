import { useManagedAccounts } from '../hooks/useManagedAccounts'

import { Accordion, Box, Flex, Spinner } from '@chakra-ui/react'

import { Input } from '@/components/ui'

import { Account } from './Account'
import { AccountGroup } from './AccountGroup'

export const ManagedAccounts = () => {
  const { groups, isLoading, filter, setFilter } = useManagedAccounts()

  return (
    <Box>
      <Box fontSize='24px' fontWeight='extrabold' letterSpacing='-0.8px' mb='4'>
        Managed Accounts
      </Box>
      <Input
        data-testid='settingsPage_managedAccounts_nameFilter'
        mb='6'
        onChange={(event) => setFilter(event.target.value)}
        placeholder='Filter by name'
      />
      {isLoading ? (
        <Flex justifyContent='center' mt='10'>
          <Spinner size='lg' />
        </Flex>
      ) : (
        <Accordion allowMultiple allowToggle>
          {groups.map(({ role, accounts }) => (
            <AccountGroup filter={filter} key={role} role={role}>
              {accounts.map((account, index: number) => (
                <Account
                  account={account}
                  index={index}
                  key={account.id}
                  role={role}
                />
              ))}
            </AccountGroup>
          ))}
        </Accordion>
      )}
    </Box>
  )
}
