export const Note = ({
  className,
  strokeColor = '#fff',
}: {
  className?: string
  strokeColor?: string
}) => (
  <svg
    className={className}
    fill='none'
    height={22}
    viewBox='0 0 22 22'
    width={22}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      clipRule='evenodd'
      d='M1.67 3.448c.5-.5 1.178-.781 1.885-.781h6.223a.889.889 0 1 1 0 1.778H3.555a.889.889 0 0 0-.889.889v12.444a.889.889 0 0 0 .89.889H16a.889.889 0 0 0 .889-.889v-6.222a.889.889 0 1 1 1.777 0v6.222A2.667 2.667 0 0 1 16 20.445H3.555A2.667 2.667 0 0 1 .89 17.778V5.334c0-.708.28-1.386.78-1.886Z'
      fill={strokeColor}
      fillRule='evenodd'
    />
    <path
      clipRule='evenodd'
      d='M17.778 2.559a.997.997 0 0 0-.705.292l-8.27 8.27-.47 1.88 1.88-.47 8.27-8.27a.996.996 0 0 0-.705-1.702Zm-1.962-.965a2.774 2.774 0 1 1 3.924 3.924l-8.445 8.444a.89.89 0 0 1-.413.234l-3.555.889a.89.89 0 0 1-1.078-1.078l.889-3.556c.039-.156.12-.299.233-.413l8.445-8.444Z'
      fill={strokeColor}
      fillRule='evenodd'
    />
  </svg>
)
