import {
  Box,
  EffectProps,
  HStack,
  Spinner,
  TypographyProps,
} from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'

interface PageSpinnerIProps {
  loading: boolean
  label?: string
  showLabel?: boolean
  placementTop?: boolean
  fontWeight?: TypographyProps['fontWeight']
  boxShadow?: EffectProps['boxShadow']
  spinnerColor?: string
  spinnerThickness?: string
  borderColor?: string
}

export const PageSpinnerToast = ({
  loading,
  label = 'Loading',
  showLabel = false,
  placementTop = true,
  fontWeight = 'semibold',
  boxShadow = '0px 2px 5px 6px rgba(0, 0, 0, 0.2)',
  spinnerColor = '#298bbd',
  spinnerThickness = '4px',
  borderColor,
}: PageSpinnerIProps) => {
  const initialToastPlacementStyles = {
    top: placementTop ? '-50px' : 'auto',
    bottom: placementTop ? 'auto' : '-50px',
  }
  const finalToastPlacementStyles = {
    top: placementTop ? '50px' : 'auto',
    bottom: placementTop ? 'auto' : '50px',
  }
  return (
    <AnimatePresence>
      {loading && (
        <HStack
          animate={{ ...finalToastPlacementStyles, x: '-50%', opacity: 1 }}
          as={motion.div}
          bgColor='white'
          borderColor={borderColor}
          borderWidth={borderColor && '1px'}
          boxShadow={boxShadow}
          exit={{ ...initialToastPlacementStyles, x: '-50%', opacity: 0 }}
          initial={{ ...initialToastPlacementStyles, x: '-50%', opacity: 0 }}
          left='50%'
          pos='absolute'
          px='6'
          py='2'
          rounded='10px'
          spacing={4}
          zIndex='11'
        >
          {showLabel && (
            <Box fontWeight={fontWeight} letterSpacing='-0.7px'>
              {label}
            </Box>
          )}
          <Spinner
            color={spinnerColor}
            emptyColor='gray.300'
            size='md'
            thickness={spinnerThickness}
          />
        </HStack>
      )}
    </AnimatePresence>
  )
}
