export const DoorHeldOpen = ({
  className,
  strokeColor = '#fff',
}: {
  className?: string
  strokeColor?: string
}) => {
  return (
    <svg
      className={className}
      height='32px'
      version='1.1'
      viewBox='0 0 32 32'
      width='32px'
    >
      <g
        fill='none'
        fillRule='evenodd'
        id='Styleguide'
        stroke='none'
        strokeWidth='1'
      >
        <g id='Additional-icons' transform='translate(-203.000000, -14.000000)'>
          <g id='door-held-open' transform='translate(203.000000, 14.000000)'>
            <g id='Group' transform='translate(6.000000, 9.500000)'>
              <rect
                height='12.1745731'
                id='Rectangle'
                rx='0.941176471'
                stroke={strokeColor}
                strokeWidth='0.941176471'
                width='9.05882353'
                x='0.470588235'
                y='0.470588235'
              ></rect>
              <path
                d='M5.39246066,0.713491689 L0.507617833,3.24873993 C0.195738832,3.41060607 4.55037386e-13,3.73272636 4.54747351e-13,4.08410814 L4.54747351e-13,14.5247628 C4.54255896e-13,15.0445602 0.421379059,15.4659392 0.941176471,15.4659392 C1.07819846,15.4659392 1.21356803,15.4360207 1.33782719,15.378274 L6.22267002,13.1081497 C6.55477186,12.9538126 6.76719577,12.620851 6.76719577,12.2546385 L6.76719577,1.54885991 C6.76719577,1.02906249 6.34581671,0.607683435 5.8260193,0.607683435 C5.67510692,0.607683435 5.52640727,0.64397299 5.39246066,0.713491689 Z'
                fill={strokeColor}
                id='Path-5'
                transform='translate(3.383598, 8.000000) scale(-1, 1) translate(-3.383598, -8.000000) '
              ></path>
            </g>
            <g
              fill={strokeColor}
              fillRule='nonzero'
              id='Time'
              transform='translate(18.000000, 9.500000)'
            >
              <path
                d='M4.5,0 C5.6934742,0 6.83806681,0.474105782 7.68198052,1.31801948 C8.52589422,2.16193319 9,3.3065258 9,4.5 C9,6.98528137 6.98528137,9 4.5,9 C2.01471863,9 0,6.98528137 0,4.5 C0,2.01471863 2.01471863,0 4.5,0 Z M4.545,0.9 C4.17220779,0.9 3.87,1.20220779 3.87,1.575 L3.87,1.575 L3.87,4.725 C3.87,5.02699934 4.06832799,5.2826767 4.34179909,5.36870573 C4.3708055,5.38743492 4.40162377,5.40417391 4.43415161,5.41865624 L4.43415161,5.41865624 L6.48962889,6.33381369 C6.83019152,6.48544194 7.22919106,6.33228036 7.38081931,5.99171774 C7.53244756,5.65115511 7.37928599,5.25215557 7.03872336,5.10052732 L7.03872336,5.10052732 L5.22,4.291 L5.22,1.575 C5.22,1.20220779 4.91779221,0.9 4.545,0.9 Z'
                id='Combined-Shape'
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
