import { gql } from '@apollo/client'

import { Maybe } from '@/graphql/generated/schemas'

import { NotificationCount } from '../types'

export type OnUpdateNotificationCountSubscription = {
  __typename: 'Subscription'
  onUpdateNotificationCount?: Maybe<NotificationCount>
}

export const ON_UPDATE_NOTIFICATION_COUNT = gql`
  subscription OnUpdateNotificationCount($userId: ID!) {
    onUpdateNotificationCount(userId: $userId) {
      userId
      notificationCount
    }
  }
`
