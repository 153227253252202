export const Incident = ({
  className,
  strokeColor = '#fff',
}: {
  className?: string
  strokeColor?: string
}) => {
  return (
    <svg
      className={className}
      height='48'
      viewBox='0 0 48 48'
      width='48'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <path d='M0 0h48v48H0V0z' fill='none' />
        <ellipse cx='24' cy='34' fill={strokeColor} rx='2' ry='2' />
        <path
          d='M24 20v8'
          fill='none'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
        <path
          d='M10.14 40H38c1.2929253-.06665748 2.47363546-.75495269 3.16869726-1.84719265C41.86375906 37.06056738 41.98720806 35.6994696 41.5 34.5L27.5 10c-.70447536-1.27326329-2.04484196-2.06350833-3.5-2.06350833-1.45515804 0-2.79552464.79024504-3.5 2.06350833l-14 24.5c-.48720806 1.19946961-.36375905 2.56056738.33130274 3.65280735C7.52636454 39.2450473 8.7070747 39.93334252 10 40'
          fill='none'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
      </g>
    </svg>
  )
}
