export const drawerStyles = {
  parts: ['overlay'],
  baseStyle: {
    overlay: {
      background: 'rgba(48,56,78,0.7)',
      zIndex: 1400,
    },
  },
  sizes: {
    xs: {
      dialog: {
        maxW: '140px',
      },
    },
    md: {
      dialog: {
        maxW: '400px',
      },
    },
    lg: {
      dialog: {
        maxW: '625px',
      },
    },
  },
}
