export const CaseIncidentIcon = () => (
  <svg
    fill='none'
    height='16'
    viewBox='0 0 16 16'
    width='16'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.99982 6.16687C8.27596 6.16687 8.49982 6.39072 8.49982 6.66687V9.33353C8.49982 9.60968 8.27596 9.83353 7.99982 9.83353C7.72368 9.83353 7.49982 9.60968 7.49982 9.33353V6.66687C7.49982 6.39072 7.72368 6.16687 7.99982 6.16687Z'
      fill='white'
    />
    <path
      d='M7.99995 12.0001C8.36814 12.0001 8.66662 11.7017 8.66662 11.3335C8.66662 10.9653 8.36814 10.6668 7.99995 10.6668C7.63176 10.6668 7.33328 10.9653 7.33328 11.3335C7.33328 11.7017 7.63176 12.0001 7.99995 12.0001Z'
      fill='white'
    />
    <path
      clip-rule='evenodd'
      d='M14.2675 11.2519L9.60231 3.08779C9.11099 2.20546 7.99931 1.88673 7.11293 2.37489C6.80779 2.54159 6.56164 2.79331 6.39773 3.08788L1.73259 11.2519C1.72147 11.2713 1.71167 11.2915 1.70326 11.3123C1.32378 12.2496 1.77112 13.3177 2.71047 13.6993C2.8993 13.7773 3.10252 13.8235 3.31118 13.8328C3.32658 13.8335 3.34184 13.8334 3.35695 13.8328C3.3646 13.8331 3.3723 13.8333 3.38005 13.8333H12.6667C12.675 13.8333 12.6833 13.8331 12.6916 13.8327C13.7056 13.7822 14.479 12.9196 14.4295 11.9097C14.42 11.7008 14.3736 11.4974 14.2953 11.3084C14.2872 11.289 14.2779 11.2701 14.2675 11.2519ZM3.38005 12.8333C3.37219 12.8333 3.36438 12.8335 3.35661 12.8338L3.35558 12.8338C3.26496 12.8297 3.17551 12.8096 3.09155 12.7747L3.08769 12.7732C2.67089 12.6044 2.46554 12.1371 2.61945 11.7154L7.2675 3.58135L7.27078 3.57553C7.34669 3.43846 7.45951 3.32494 7.59281 3.25223L7.59486 3.2511C8.00062 3.0273 8.50673 3.17404 8.72942 3.57572L8.73259 3.58135L13.3817 11.7173C13.4103 11.7936 13.4269 11.8741 13.4305 11.9555L13.4307 11.9581C13.4535 12.4173 13.1063 12.8048 12.6528 12.8333H3.38005Z'
      fill='white'
      fill-rule='evenodd'
    />
  </svg>
)
