import { HiOutlineTrash } from 'react-icons/hi'

import { Box, Spacer } from '@chakra-ui/react'

import { Button } from '@/components/ui'
import { formatBytes } from '@/utils/formats'

const getBorderColor = (isInvalid: boolean, isDisabled: boolean): string =>
  isInvalid
    ? '3px solid #D01030'
    : isDisabled
    ? '1px solid var(--chakra-colors-gray-200)'
    : '1px solid #9FB8E5'

interface FileUploadInputIProps {
  isInvalid: boolean
  isDisabled: boolean
  file: File
  onFileRemove: () => void
}

export const FileUploadInput = ({
  isInvalid,
  isDisabled,
  file,
  onFileRemove,
}: FileUploadInputIProps) => (
  <Box
    alignItems='center'
    border={getBorderColor(isInvalid, isDisabled)}
    borderRadius='var(--chakra-radii-md)'
    display='flex'
    fontSize='14px'
    paddingLeft='16px'
  >
    <Box
      as='span'
      fontWeight='600'
      letterSpacing='-0.53px'
      maxW='45%'
      mr={3}
      noOfLines={1}
      opacity={isDisabled ? '0.2' : 'initial'}
    >
      {file?.name}
    </Box>
    <Box
      as='span'
      color='var(--chakra-colors-gray-600)'
      fontWeight='500'
      letterSpacing='-0.53px'
      opacity={isDisabled ? '0.2' : 'initial'}
    >
      {formatBytes(file?.size)}
    </Box>
    <Spacer />
    <Button
      isDisabled={isDisabled}
      leftIcon={<HiOutlineTrash size={24} />}
      onClick={onFileRemove}
      variant='secondary'
    >
      Remove
    </Button>
  </Box>
)
