import { useMemo } from 'react'

import { ApolloError } from '@apollo/client'
import { ToastPosition, useToast as useChakraToast } from '@chakra-ui/react'

import { PageMessageToast } from '@/components/ui'
import { uuid } from '@/utils/helpers'

export interface UseToastsMethodsIProps {
  showSuccess: (message: string) => void
  showError: (message?: string | ApolloError) => void
}

export interface UseToastsOptionsIProps {
  toastPosition?: ToastPosition
  toastDuration?: number
  defaultErrorMessage?: string
  preventDuplicate?: boolean
}

export const useToasts = ({
  toastPosition = 'top',
  toastDuration = 2000,
  defaultErrorMessage = 'Something went wrong.',
  preventDuplicate = true,
}: UseToastsOptionsIProps = {}): UseToastsMethodsIProps => {
  const toast = useChakraToast()
  const successId = useMemo(uuid, [])
  const errorId = useMemo(uuid, [])

  const showSuccessToast = (message: string) => {
    if (!toast.isActive(successId)) {
      toast({
        id: preventDuplicate ? successId : null,
        position: toastPosition,
        duration: toastDuration,
        render: () => <PageMessageToast message={message} show />,
      })
    }
  }

  const showErrorToast = (message: string) => {
    if (!toast.isActive(errorId)) {
      toast({
        id: preventDuplicate ? errorId : null,
        position: toastPosition,
        duration: toastDuration,
        render: () => (
          <PageMessageToast message={message} show success={false} />
        ),
      })
    }
  }

  const showSuccess = (message: string) => {
    showSuccessToast(message)
  }

  const showError = (error?: string | ApolloError) => {
    if (typeof error === 'string') {
      showErrorToast(error ?? defaultErrorMessage)
    } else {
      showErrorToast(error?.message ?? defaultErrorMessage)
    }
  }

  return { showSuccess, showError }
}
