export const DeviceAssociation = ({
  strokeColor = '#1A202C',
}: {
  strokeColor?: string
}) => (
  <svg
    fill='none'
    height='20'
    viewBox='0 0 26 20'
    width='26'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      clipRule='evenodd'
      d='M17.962 1.402l7.375 1.875a.217.217 0 01.163.21v14.376c0 .102-.07.19-.17.212L17.955 19.7a.217.217 0 01-.264-.212V3.828h-1.128v13.91c0 .12-.098.217-.217.217h-1.25a.217.217 0 01-.217-.217V2.488c0-.12.097-.218.217-.218h2.595v-.658c0-.14.134-.245.271-.21zm1.446 8.181c.43 0 .78.35.78.78h-.002a.78.78 0 11-.778-.78z'
      fill={strokeColor}
      fillRule='evenodd'
    ></path>
    <path
      d='M11.85 3.654a.977.977 0 00-.804-.215l-1.991.456.17-.633c.142-.53-.218-1.103-.8-1.258L3.109.579c-.58-.156-1.179.16-1.32.69L.76 5.1c-.142.53.218 1.103.799 1.259l5.334 1.43c.582.155 1.18-.161 1.321-.691l.175-.65 1.496 1.39c.338.311.866.324 1.185.025a.673.673 0 00.203-.349l.83-3.095c.068-.256-.039-.541-.254-.764z'
      fill={strokeColor}
    ></path>
  </svg>
)
