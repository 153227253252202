import { format, formatDistance } from 'date-fns'

export function formatDateTime(
  dateTime?: number | Date | string,
  formatString = 'HH:mm:ss | MM/dd/yyyy'
): string {
  // formats dateTime to military time [hh]:[MM]:[ss], [mm]/[dd]/[yyyy]
  return format(dateTime ? new Date(dateTime) : new Date(), formatString)
}

export const formatDate = (dateTime: number | Date | string): string => {
  return dateTime ? format(new Date(dateTime), 'MM/dd/yyyy') : ''
}

export function formatDateTimeWithAt(
  dateTime?: number | Date | string
): string {
  const formatString = 'MM.dd.yyyy  @ HH:mm:ss'

  if (dateTime === undefined) {
    return format(new Date(), formatString)
  }
  try {
    return format(new Date(dateTime), formatString)
  } catch {
    return ''
  }
}

export function formatTimeDistance(dateTime: number | Date | string): string {
  return formatDistance(
    dateTime ? new Date(dateTime) : new Date(),
    new Date(),
    {
      addSuffix: true,
    }
  )
}

export function formatTimeForTableCell(
  dateTime: number | Date | string
): string {
  return `${formatDateTime(dateTime)} • ${formatTimeDistance(dateTime)}`
}
