import * as moment from 'moment'

type LabelValues = {
  value: unknown
  label: string
}

export interface LabelValueIProps {
  label: string
  value: string
}

export const hours24 = moment.utc().subtract(24, 'hours').format()
export const last2Days = moment.utc().subtract(2, 'days').format()
export const last7Days = moment.utc().subtract(7, 'days').format()
export const last30Days = moment.utc().subtract(30, 'days').format()

export const createdAtOptions = [
  {
    value: {
      gte: hours24,
    },
    label: 'Last 24 hours',
  },
  {
    value: {
      gte: last2Days,
    },
    label: 'Last 2 Days',
  },
  {
    value: {
      gte: last7Days,
    },
    label: 'Last 7 Days',
  },
  {
    value: {
      gte: last30Days,
    },
    label: 'Last 30 Days',
  },
]

export const activeIncidentsOptions = [
  { value: true, label: 'Has Active Incidents' },
  { value: false, label: 'Does Not Have Active Incidents' },
]

export const getRelatedFloors = (
  facilities: LabelValues[],
  floors: LabelValues[]
) => {
  return floors?.filter((flr) =>
    facilities?.some((fac) => {
      const facility = fac.label
      const floorFacility = flr.label.substring(
        flr.label.indexOf('(') + 1,
        flr.label.indexOf(')')
      )
      return facility.includes(floorFacility)
    })
  )
}

export const getUniqueValues = (items: LabelValueIProps[]) => [
  ...new Map(items.map((i) => [i.value, i])).values(),
]
