import { useUpdateFilter } from '../hooks/useUpdateFilter'
import { useToasts } from '@/hooks/useToasts'
import { HiOutlineCheckCircle } from 'react-icons/hi'

import { AlertDialogBody, AlertDialogFooter, Box } from '@chakra-ui/react'

import { Button } from '@/components/ui'
import { BasicAlertDialog } from '@/components/ui'

import { IncidentFiltersFormInputsI } from '../types/form'
import { SavedFilterI } from '../types/generic'

interface UpdateFilterConfirmationModalIProps {
  isOpen: boolean
  onClose: () => void
  incidentFiltersFormValues: IncidentFiltersFormInputsI
  editedFilter: SavedFilterI
}

export const UpdateFilterConfirmationModal = ({
  isOpen,
  onClose,
  incidentFiltersFormValues,
  editedFilter,
}: UpdateFilterConfirmationModalIProps) => {
  const { showSuccess, showError } = useToasts()

  const { update, isUpdateLoading } = useUpdateFilter()

  const onSubmit = async () => {
    try {
      update(editedFilter?.id, incidentFiltersFormValues)
      showSuccess('Successfully updated the Filter')
      onClose()
    } catch (error) {
      showError(error)
    }
  }

  return (
    <BasicAlertDialog
      dataTestId='ConfirmRemovalDialog'
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      title='Update Filter'
    >
      <AlertDialogBody py='24px'>
        <Box letterSpacing='-0.53px'>
          {'Are you sure you want to update the filter '}
          <Box as='span' fontWeight='bold'>
            {editedFilter?.name}
          </Box>
          ?
        </Box>
      </AlertDialogBody>
      <AlertDialogFooter>
        <Button mr='3' onClick={onClose} variant='secondary'>
          Cancel
        </Button>
        <Button
          isDisabled={isUpdateLoading}
          isLoading={isUpdateLoading}
          leftIcon={<HiOutlineCheckCircle size='24px' />}
          onClick={onSubmit}
        >
          Save Filter
        </Button>
      </AlertDialogFooter>
    </BasicAlertDialog>
  )
}
