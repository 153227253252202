import { DeviceType } from '@/graphql/generated/schemas'
import {
  getArchivedLabel,
  removeUnderscoreAndCapitalize,
} from '@/utils/formats'

import {
  AccountGroupsIProps,
  AccountIProps,
  DEFAULT_ROLE,
  RoleExtended,
  TechnicianFacilities,
} from '../types/types'

/**
 * Filter account based on full name of the user using filter phrase.
 */
export const filterAccounts = (
  accounts: AccountIProps[],
  filter: string
): AccountIProps[] =>
  accounts.filter(
    (account) =>
      `${account.firstName} ${account.lastName}`
        .toLocaleLowerCase()
        .includes(filter.toLocaleLowerCase()) || filter === ''
  )

/**
 * Take account list and split it into groups according to assigned role.
 * Here three origial roles (Role) are mapped into useful extended roles
 * (RoleExtended).
 */
export const groupAccounts = (
  accounts: AccountIProps[]
): AccountGroupsIProps => {
  const groupsMapInitial: { [key in RoleExtended]: AccountIProps[] } = {
    [RoleExtended.PROGRAM_MANAGER]: [],
    [RoleExtended.OPERATOR]: [],
    [RoleExtended.GUARD]: [],
    [RoleExtended.EMPLOYEE]: [],
    [RoleExtended.TECHNICIAN]: [],
    [RoleExtended.IMPLEMENTER]: [],
  }
  const groupsMap = accounts.reduce((groups, account) => {
    if (account.roleName in RoleExtended) {
      groups[account.roleName].push(account)
    } else if (account.roleName === DEFAULT_ROLE) {
      // users with role 'default-roles-hivewarchdev.com' are in fact operators
      groups[RoleExtended.OPERATOR].push({
        ...account,
        roleName: RoleExtended.OPERATOR,
      })
    } else if (account.roleName === '') {
      groups[RoleExtended.EMPLOYEE].push(account)
    }
    return groups
  }, groupsMapInitial)
  return Object.entries(groupsMap).map(
    ([role, accounts]: [RoleExtended, AccountIProps[]]) => ({
      role,
      accounts,
    })
  )
}

export const getPascalCaseRole = (role: RoleExtended) => {
  switch (role) {
    case RoleExtended.PROGRAM_MANAGER:
      return 'ProgramManager'
    case RoleExtended.OPERATOR:
      return 'Operator'
    case RoleExtended.GUARD:
      return 'Guard'
    case RoleExtended.EMPLOYEE:
      return 'Employee'
    case RoleExtended.TECHNICIAN:
      return 'Technician'
    case RoleExtended.IMPLEMENTER:
      return 'Implementer'
    default:
      return ''
  }
}

export const getFacilitiesDisplayName = (facilities: TechnicianFacilities) => {
  return (
    (facilities?.map((f) => getArchivedLabel(f.name, f.archived)).join(', ') ??
      'n/a') ||
    'n/a'
  )
}

export const getDeviceTypeDisplayName = (deviceTypes: DeviceType[]) => {
  return (
    (deviceTypes?.map((d) => removeUnderscoreAndCapitalize(d)).join(', ') ??
      'n/a') ||
    'n/a'
  )
}
