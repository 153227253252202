import React from 'react'

import { Box, Flex, Tooltip } from '@chakra-ui/react'

interface CallActionIconIProps {
  onClick: () => void
  label: string
  icon: React.ReactNode
}

export const CallActionIcon = ({
  label,
  icon,
  onClick,
}: CallActionIconIProps) => {
  return (
    <Flex flex={1} justifyContent='flex-end'>
      <Tooltip label={label} placement='top'>
        <Box
          _hover={{
            opacity: 1.0,
          }}
          cursor='pointer'
          data-testid='CallExpanded:button'
          onClick={onClick}
          opacity={0.3}
        >
          {icon}
        </Box>
      </Tooltip>
    </Flex>
  )
}
