import { DrawerFooter, useDisclosure } from '@chakra-ui/react'

import { Button } from '@/components/ui'

import { SavedFilterI } from '../types/generic'
import { DeactivateFilterConfirmationModal } from './DeactivateFilterConfirmationModal'

interface IncidentsFilterDrawerFooterIProps {
  onApplyFilters: () => void
  activeFilter: SavedFilterI
  isApplyDisabled: boolean
}

export const IncidentsFilterDrawerFooter = ({
  onApplyFilters,
  activeFilter,
  isApplyDisabled,
}: IncidentsFilterDrawerFooterIProps) => {
  const {
    isOpen: isModalOpen,
    onClose: onModalClose,
    onOpen: onModalOpen,
  } = useDisclosure()

  return (
    <DrawerFooter borderColor='#D5DCE4' borderTopWidth='1px'>
      <Button
        data-testid='incidentsPage_filterDrawer_applyButton'
        isDisabled={isApplyDisabled}
        isFullWidth
        onClick={activeFilter ? onModalOpen : onApplyFilters}
      >
        Apply Filters
      </Button>
      {isModalOpen && (
        <DeactivateFilterConfirmationModal
          activeFilter={activeFilter}
          isOpen={isModalOpen}
          onApplyFilters={onApplyFilters}
          onClose={onModalClose}
        />
      )}
    </DrawerFooter>
  )
}
