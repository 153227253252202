import { Flex, FlexProps } from '@chakra-ui/react'

export const DrawerListCell = ({ children, ...rest }: FlexProps) => (
  <Flex
    _hover={{
      boxShadow: 'lg',
    }}
    bg='#fff'
    borderRadius='4px'
    borderWidth='1px'
    boxShadow='md'
    marginBottom='10px'
    overflow='hidden'
    transition='ease-in-out'
    transitionDuration='300ms'
    transitionProperty='all'
    {...rest}
  >
    {children}
  </Flex>
)
