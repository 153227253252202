import { Button, ButtonGroup } from '@chakra-ui/react'

interface ToggleButtonIProps {
  isFull?: boolean
  isLoading?: boolean
  isDisabled?: boolean
  bgColor: string
  textColor: string
  borderColor: string
  ariaLabel: string
  handleOnClick: () => void
  children: string
  dataTestId?: string
}

export interface ToggleButtonSwitchIProps {
  size?: 'xs' | 'sm' | 'md' | 'lg'
  isFull?: boolean
  isLoading?: boolean
  isDisabled?: boolean
  isLeftActive: boolean
  onLeftButtonClick: () => void
  onRightButtonClick: () => void
  activeBgColor: string
  activeTextColor: string
  inactiveBgColor?: string
  inactiveTextColor?: string
  leftButtonText: string
  rightButtonText: string
  leftArialLabel: string
  rightArialLabel: string
  height?: string
  leftButtonDataTestId?: string
  rightButtonDataTestId?: string
  dataTestId?: string
}

export const ToggleButton = ({
  isFull = false,
  isLoading = false,
  isDisabled,
  bgColor,
  textColor,
  borderColor,
  ariaLabel,
  handleOnClick,
  children,
  dataTestId,
}: ToggleButtonIProps) => {
  return (
    <Button
      _active={{
        _disabled: {
          bg: bgColor,
        },
      }}
      _disabled={{
        bg: bgColor,
      }}
      _focus={{
        boxShadow: '',
      }}
      _hover={{
        bg: '',
      }}
      aria-label={ariaLabel}
      bgColor={bgColor}
      borderColor={borderColor}
      borderWidth='2px'
      data-testid={dataTestId}
      fontSize='12px'
      fontWeight='500'
      h='auto'
      isDisabled={isDisabled}
      isLoading={isLoading}
      letterSpacing='-0.4px'
      minH='8'
      onClick={handleOnClick}
      textColor={textColor}
      w={isFull ? 'full' : ''}
    >
      {children}
    </Button>
  )
}

export const ToggleButtonSwitch = ({
  size = 'sm',
  isFull = false,
  isLoading = false,
  isDisabled = false,
  isLeftActive,
  onLeftButtonClick,
  onRightButtonClick,
  activeBgColor,
  activeTextColor,
  inactiveBgColor = 'white',
  inactiveTextColor = 'black',
  leftButtonText,
  rightButtonText,
  leftArialLabel,
  rightArialLabel,
  height = 'auto',
  leftButtonDataTestId,
  rightButtonDataTestId,
  dataTestId,
}: ToggleButtonSwitchIProps) => {
  const isRightActive = !isLeftActive
  return (
    <ButtonGroup
      data-testid={dataTestId}
      h={height}
      isAttached
      size={size}
      variant='outline'
      w={isFull ? 'full' : ''}
    >
      <ToggleButton
        ariaLabel={leftArialLabel}
        bgColor={isLeftActive ? activeBgColor : inactiveBgColor}
        borderColor={isLeftActive ? activeBgColor : 'gray.200'}
        dataTestId={leftButtonDataTestId}
        handleOnClick={onLeftButtonClick}
        isDisabled={isLeftActive || isDisabled}
        isFull={isFull}
        isLoading={isLoading}
        textColor={isLeftActive ? activeTextColor : inactiveTextColor}
      >
        {leftButtonText}
      </ToggleButton>
      <ToggleButton
        ariaLabel={rightArialLabel}
        bgColor={isRightActive ? activeBgColor : inactiveBgColor}
        borderColor={isRightActive ? activeBgColor : 'gray.200'}
        dataTestId={rightButtonDataTestId}
        handleOnClick={onRightButtonClick}
        isDisabled={isRightActive || isDisabled}
        isFull={isFull}
        isLoading={isLoading}
        textColor={isRightActive ? activeTextColor : inactiveTextColor}
      >
        {rightButtonText}
      </ToggleButton>
    </ButtonGroup>
  )
}
