import { IncidentOrderField, OrderDirection } from '@/graphql/generated/schemas'

const orderByMapping = {
  displayId: IncidentOrderField.DisplayId,
  date: IncidentOrderField.CreatedAt,
  incidentName: IncidentOrderField.Name,
  incidentStatus: IncidentOrderField.Status,
  guard: IncidentOrderField.GuardId,
  operator: IncidentOrderField.OwnerId,
  facilityName: IncidentOrderField.FacilityName,
  floor: IncidentOrderField.FloorName,
}

export const getOrderBy = (sortedColumn: {
  id?: string
  isSortedDesc?: boolean
}): { direction: OrderDirection; field: IncidentOrderField }[] => {
  if (!sortedColumn?.id) {
    // Apply default sorting by date
    return [
      {
        direction: OrderDirection.Desc,
        field: IncidentOrderField.CreatedAt,
      },
    ]
  } else {
    const direction = sortedColumn.isSortedDesc
      ? OrderDirection.Desc
      : OrderDirection.Asc
    const field = orderByMapping[sortedColumn.id]
    return [
      {
        direction,
        field,
      },
    ]
  }
}

export const getSortedColumn = (
  tableColumns: {
    id: string
    canSort: boolean
    isSorted?: boolean
    isSortedDesc?: boolean
  }[]
): { id?: string; isSortedDesc?: boolean } =>
  tableColumns
    .filter((c) => c.canSort && c.isSorted)
    .map((c) => ({
      id: c.id,
      isSortedDesc: c.isSortedDesc,
    }))?.[0] ?? {}
