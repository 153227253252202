import { useUpdateSavedFilterMutation } from '@/graphql/generated/hooks'
import { FilterContext } from '@/graphql/generated/schemas'
import { buildFilterCriteria } from '@/redux/incidents/conversion'

import { IncidentFiltersFormInputsI } from '../types/form'

export const useUpdateFilter = () => {
  const [updateSavedFilter, { loading: isUpdateLoading }] =
    useUpdateSavedFilterMutation()

  const update = async (
    savedFilterId: string,
    incidentFiltersFormValues: IncidentFiltersFormInputsI
  ) => {
    // Convert between incident filter drawer form state and incidents query filter JSON
    const incidentFilterCriteria = buildFilterCriteria(
      incidentFiltersFormValues
    )

    await updateSavedFilter({
      variables: {
        input: {
          id: savedFilterId,
          context: FilterContext.Incident,
          filterCriteria: { incident: incidentFilterCriteria },
        },
      },
      refetchQueries: ['ActiveIncidentsFilter'],
    })
  }

  return {
    update,
    isUpdateLoading,
  }
}
