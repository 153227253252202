import { MdFiberManualRecord } from 'react-icons/md'

import { Box, Flex, Square, Tooltip } from '@chakra-ui/react'

export interface VoiceRecorderSkeletonIProps {
  tooltipLabel?: string
}

export const VoiceRecorderSkeleton = ({
  tooltipLabel,
}: VoiceRecorderSkeletonIProps) => {
  return (
    <Tooltip
      bgColor='#fff'
      boxShadow='md'
      color='#1a202c'
      fontSize='16px'
      fontWeight='semibold'
      label={tooltipLabel}
      letterSpacing='-0.65px'
      maxWidth='none'
      p='2'
      placement='left-start'
      rounded='5px'
      shouldWrapChildren
    >
      <Flex
        alignItems='center'
        bg='#f0f0f0'
        cursor='not-allowed'
        data-testid='VoiceRecorderSkeleton'
        maxW='350px'
        mt='20px'
        p='4px'
        rounded='5px'
      >
        <Square bg='white' h='30px' rounded='5px' w='30px'>
          <MdFiberManualRecord color='#898989' size='18px' />
        </Square>
        <Box
          color='#8a8a8a'
          fontSize='14px'
          fontWeight='medium'
          letterSpacing='-0.4px'
          ml='8px'
        >
          Click here to start recording
        </Box>
      </Flex>
    </Tooltip>
  )
}
