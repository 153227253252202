import { useMemo } from 'react'

import { useActivateFilter } from '../hooks/useActivateFilter'
import { useDeleteFilter } from '../hooks/useDeleteFilter'

import { Box, VStack } from '@chakra-ui/react'

import {
  ConfirmRemovalDialog,
  DrawerScrollContainer,
  PageSpinner,
} from '@/components/ui'
import { useSavedIncidentsFiltersQuery } from '@/graphql/generated/hooks'

import { SavedFilterI } from '../types/generic'
import { compareAlphabetically } from '../utils/utils'
import { FilterListItem } from './FilterListItem'
import { NoFilterMessage } from './NoFilterMessage'

interface IncidentsFilterListIProps {
  onFilterUpdate: (filter: SavedFilterI) => void
}

export const IncidentsFilterList = ({
  onFilterUpdate,
}: IncidentsFilterListIProps) => {
  const { data, loading: isLoading } = useSavedIncidentsFiltersQuery({
    fetchPolicy: 'network-only',
  })

  const savedFilters = useMemo(() => {
    return (
      data?.savedFilters?.edges
        ?.map((e) => e.node)
        .sort(compareAlphabetically) || []
    )
  }, [data])

  const {
    deleteFilter,
    filter,
    isDeleteLoading,
    isDeleteModalOpen,
    onConfirmFilterDelete,
    onDeleteModalClose,
  } = useDeleteFilter()

  const {
    onActivateFilter,
    onDectivateFilter,
    isActivateLoading,
    isDeactivateLoading,
  } = useActivateFilter()

  return (
    <DrawerScrollContainer mb='40px' px='30px' py='20px'>
      {isLoading || isActivateLoading || isDeactivateLoading ? (
        <PageSpinner />
      ) : (
        <Box>
          {savedFilters?.length > 0 ? (
            <VStack align='stretch' spacing='14px'>
              {savedFilters?.map((f) => (
                <FilterListItem
                  isActive={f.isActive}
                  key={f.id}
                  label={f.name}
                  onFilterActivate={() => {
                    onActivateFilter(f)
                  }}
                  onFilterDeactivate={() => {
                    onDectivateFilter(f)
                  }}
                  onFilterDelete={() => {
                    onConfirmFilterDelete(f)
                  }}
                  onFilterUpdate={() => {
                    onFilterUpdate(f)
                  }}
                />
              ))}
            </VStack>
          ) : (
            <NoFilterMessage />
          )}
          {isDeleteModalOpen && (
            <ConfirmRemovalDialog
              handleOnClick={deleteFilter}
              isLoading={isDeleteLoading}
              isOpen={isDeleteModalOpen}
              itemTitle={filter.name}
              onClose={onDeleteModalClose}
              title='Delete Filter'
            />
          )}
        </Box>
      )}
    </DrawerScrollContainer>
  )
}
