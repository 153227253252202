import { default as RDatePicker } from 'react-datepicker'

import styled from '@emotion/styled'
import { addMinutes, subMinutes } from 'date-fns'

import { MID_NIGHT, TIME_INTERVAL, getMinTime } from './utils'

const StyledDatePicker = styled(RDatePicker)`
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  color: #000;
  border: 2px solid
    ${(p) => (p.valid ? '#2BD2B4' : p.isInvalid ? '#D01030' : '#9FB8E5')};
  min-height: 48px;
  border-radius: 6px;
  padding-inline-start: 16px;
  padding-inline-end: 16px;

  ::placeholder {
    font-size: 14px;
    color: #2d2e41;
    opacity: 30%;
    font-style: italic;
    font-weight: 500;
  }
`

interface TimePickerIProps {
  [key: string]: unknown
  date?: Date
  minTime?: Date
  maxTime?: Date
  onChange: (time: Date) => void
  time: Date
}

export const TimePicker = ({
  date,
  minTime,
  maxTime,
  time,
  onChange,
  ...rest
}: TimePickerIProps) => {
  const minInterval = addMinutes(minTime, TIME_INTERVAL)
  const maxInterval = subMinutes(maxTime, TIME_INTERVAL)
  return (
    <StyledDatePicker
      {...rest}
      dateFormat='h:mm aa'
      maxTime={maxTime ? maxInterval : MID_NIGHT}
      minTime={minTime ? minInterval : getMinTime(date)}
      onChange={onChange}
      onKeyDown={(event) => event.preventDefault()}
      placeholderText='Select end time'
      popperPlacement='bottom'
      selected={time}
      showTimeSelect
      showTimeSelectOnly
      timeCaption='Time'
      timeIntervals={TIME_INTERVAL}
    />
  )
}
