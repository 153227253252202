export const Locked = ({
  className,
  strokeColor = '#fff',
}: {
  className?: string
  strokeColor?: string
}) => {
  return (
    <svg
      className={className}
      height='48'
      viewBox='0 0 48 48'
      width='48'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <ellipse cx='24' cy='32' id='ellipse-3' rx='2' ry='2' />
      </defs>
      <g>
        <path d='M0 0h48v48H0V0z' fill='none' />
        <path
          d='M14 22h20c2.2076601 0 4 1.7923399 4 4v12c0 2.2076601-1.7923399 4-4 4H14c-2.2076601 0-4-1.7923399-4-4V26c0-2.2076601 1.7923399-4 4-4z'
          fill='none'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
        <g>
          <use fill={strokeColor} xmlnsXlink='#ellipse-3' />
          <use
            fillOpacity='0'
            stroke={strokeColor}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='3'
            xmlnsXlink='#ellipse-3'
          />
        </g>
        <path
          d='M16 22v-8c0-4.418278 3.581722-8 8-8s8 3.581722 8 8v8'
          fill='none'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
      </g>
    </svg>
  )
}
