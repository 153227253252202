import { HiOutlinePencil, HiOutlineTrash } from 'react-icons/hi'

import { Center, Grid } from '@chakra-ui/react'

interface EditRemoveButtonIProps {
  handleOnEdit: () => void
  handleOnDelete?: () => void
  showEdit?: boolean
  showDelete?: boolean
}

export const EditRemoveButton = ({
  handleOnEdit,
  handleOnDelete,
  showEdit = true,
  showDelete = true,
}: EditRemoveButtonIProps) => {
  return (
    <Grid
      gap='10px'
      gridTemplateRows={showDelete ? '1fr 1fr' : '1fr'}
      h='full'
      maxW='100%'
      ml='10px'
    >
      {showEdit && (
        <Center
          _hover={{
            bgColor: '#d2d2d4',
          }}
          bgColor='#E5E8EE'
          boxShadow='0 2px 7px 0 rgba(50,121,199,0.5)'
          cursor='pointer'
          data-testid='EditRemoveButton:edit'
          h='full'
          onClick={handleOnEdit}
          rounded='5px'
          transition='ease-in-out'
          transitionDuration='300ms'
          transitionProperty='background-color'
          w='60px'
        >
          <HiOutlinePencil color='#3279C7' size='24px' />
        </Center>
      )}
      {showDelete && (
        <Center
          _hover={{
            bgColor: '#b40e2a',
          }}
          bgColor='#D01030'
          boxShadow='0 2px 7px 0 rgba(189,25,25,0.5)'
          cursor='pointer'
          data-testid='EditRemoveButton:delete'
          h='full'
          onClick={handleOnDelete}
          rounded='5px'
          transition='ease-in-out'
          transitionDuration='300ms'
          transitionProperty='background-color'
          w='60px'
        >
          <HiOutlineTrash color='#fff' size='24px' />
        </Center>
      )}
    </Grid>
  )
}
