import chroma from 'chroma-js'

export const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled = false, isFocused, isSelected }) => {
    if (!data?.color) {
      return styles
    }
    const color = chroma(data.color)
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    }
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles }),
  singleValue: (styles, { data }) => {
    if (!data?.color) {
      return styles
    }
    return {
      ...styles,
      color: chroma.contrast(data.color, 'white') > 2 ? 'white' : 'black',
      ':before': {
        content: '" "',
        position: 'absolute',
        height: 'calc(100% + 10px)',
        width: 'calc(100% + 10px)',
        zIndex: '-1',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        borderRadius: '5px',
        backgroundColor: data.color,
      },
    }
  },
  multiValue: (styles, { data }) => {
    if (!data?.color) {
      return styles
    }
    const color = chroma(data.color)
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    }
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
    },
  }),
}
