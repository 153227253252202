import { AuthClientTokens } from '@/hooks/useKeyCloak'
import { HYDRATE } from 'next-redux-wrapper'

import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import type {
  AppState,
  /*, AppThunk*/
} from '../store'

export interface TokenStateIProps {
  loggingIn: boolean
  initError: boolean
  token?: string
  idToken?: string
  refreshToken?: string
}

const initialState: TokenStateIProps = {
  loggingIn: false,
  initError: false,
  token: null,
}

export const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setAuthTokens: (state, action: PayloadAction<AuthClientTokens>) => {
      state.token = action?.payload?.token || null
      state.idToken = action?.payload?.idToken || null
      state.refreshToken = action?.payload?.refreshToken || null
    },
    loggingIn: (state) => {
      state.loggingIn = true
    },
    initError: (state) => {
      state.initError = true
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.subject,
      }
    },
  },
})

export const { setAuthTokens, loggingIn, initError } = tokenSlice.actions

export const selectTokens = (state: AppState) => state.token
export const selectHasToken = (state: AppState) => !!state.token.token

// Default export for reducers is a pattern recommended by Redux
// See here: <https://redux.js.org/tutorials/fundamentals/part-3-state-actions-reducers#combinereducers>
// eslint-disable-next-line import/no-default-export
export default tokenSlice.reducer
