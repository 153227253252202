// eslint-disable-next-line no-restricted-imports
import { HYDRATE } from 'next-redux-wrapper'

import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit'

import { DeviceType } from '@/graphql/generated/schemas'

import type { AppState } from '../store'
import { CreatedAtFilter, Filter, MultiFilter } from '../types/filters'
import type {
  DevicePayload,
  EmergencyCallPayload,
  FacilityDrawer,
  FloorTabCounts,
} from './uiSlice.types'

export interface UIStateIProps {
  isSideNavOpen: boolean
  isEditMode: boolean
  hasConfirmedEditMode: boolean
  facility: {
    drawerOpen?: FacilityDrawer
    placedDeviceCount?: number
    incidentsCount?: number
    floorCount?: number
    checkpointCount?: number
    tourPlansCount?: number
  }
  facilities: {
    filters: {
      ids: MultiFilter
      createdAtRange: CreatedAtFilter
      hasIncidents: Filter<boolean>
    }
  }
  devices: {
    filters: {
      facilityIds: MultiFilter
      floorIds: MultiFilter
      createdAtRange: CreatedAtFilter
      hasIncidents: Filter<boolean>
      deviceStatus: MultiFilter
      deviceType: MultiFilter
    }
  }
  incidentRules: {
    pageSize: number
    filters: {
      nameLike: Filter
    }
  }
  outstandingIncidents: {
    filters: {
      facilities: MultiFilter
      deviceType: MultiFilter
      incidentTypes: MultiFilter
      guards: MultiFilter
      operators: MultiFilter
      timeOrder: MultiFilter
    }
    pageSize: number
  }
  notifications: {
    showUnread: boolean
  }
  sops: {
    filters: {
      nameLike: Filter
      facilityId: Filter
      createdAtRange: CreatedAtFilter
      creatorId: Filter
    }
    pageSize: number
  }
  gatewayDevices: {
    pageSize: number
    filters: {
      nameLike: Filter
    }
  }
  submittedTours: {
    pageSize: number
  }
  drawers: {
    incident: {
      id: string
      isOpen: boolean
    }
    device: {
      id: string
      type: DeviceType
      isOpen: boolean
    }
    sop: {
      id: string
      isOpen: boolean
    }
    sopIncident: {
      id: string | null
      isOpen: boolean
      sopNameLike: string
    }
    submittedTour: {
      id: string
      isOpen: boolean
    }
  }
  emergencyCall: {
    isOpen: boolean
    isExpanded: boolean
    isMuted: boolean
    incident: {
      id: string
      displayId: string
      facilityName: string
      emergencyNumber: string
    }
    filters: {
      displayIdLike: Filter
    }
    pageSize: number
  }
  cases: {
    filters: {
      nameOrDisplayIdLike: Filter
      owner: Filter
      status: Filter[]
    }
    pageSize: number
  }
}

const initialState: UIStateIProps = {
  isSideNavOpen: false,
  isEditMode: false,
  hasConfirmedEditMode: false,
  facility: {
    drawerOpen: 'devices',
    placedDeviceCount: null,
    incidentsCount: null,
    floorCount: null,
    checkpointCount: null,
  },
  facilities: {
    filters: {
      ids: null,
      createdAtRange: null,
      hasIncidents: null,
    },
  },
  devices: {
    filters: {
      facilityIds: null,
      floorIds: null,
      createdAtRange: null,
      hasIncidents: null,
      deviceStatus: null,
      deviceType: null,
    },
  },
  incidentRules: {
    filters: {
      nameLike: null,
    },
    pageSize: 25,
  },
  outstandingIncidents: {
    filters: {
      facilities: [],
      deviceType: [],
      incidentTypes: [],
      guards: [],
      operators: [],
      timeOrder: [],
    },
    pageSize: 25,
  },
  notifications: {
    showUnread: false,
  },
  sops: {
    filters: {
      nameLike: null,
      facilityId: null,
      createdAtRange: null,
      creatorId: null,
    },
    pageSize: 25,
  },
  cases: {
    filters: {
      nameOrDisplayIdLike: null,
      owner: null,
      status: null,
    },
    pageSize: 25,
  },
  gatewayDevices: {
    pageSize: 25,
    filters: {
      nameLike: null,
    },
  },
  submittedTours: {
    pageSize: 25,
  },
  drawers: {
    incident: {
      id: null,
      isOpen: false,
    },
    device: {
      id: null,
      type: null,
      isOpen: false,
    },
    sop: {
      id: null,
      isOpen: false,
    },
    sopIncident: {
      id: null,
      isOpen: false,
      sopNameLike: null,
    },
    submittedTour: {
      id: null,
      isOpen: false,
    },
  },
  emergencyCall: {
    isOpen: false,
    isExpanded: true,
    isMuted: false,
    incident: {
      id: null,
      displayId: null,
      facilityName: null,
      emergencyNumber: null,
    },
    filters: {
      displayIdLike: null,
    },
    pageSize: 25,
  },
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    resetUi: (state) => {
      return {
        ...initialState,
        hasConfirmedEditMode: state.hasConfirmedEditMode,
      }
    },
    toggleSideNavOpen: (state) => {
      state.isSideNavOpen = !state.isSideNavOpen
    },
    toggleEditMode: (state) => {
      state.isEditMode = !state.isEditMode
    },
    toggleNotificationsUnread: (state) => {
      state.notifications.showUnread = !state.notifications.showUnread
    },
    enableEditMode: (state) => {
      state.isEditMode = true
      state.hasConfirmedEditMode = true
    },
    openFacilityFloorsDrawer: (state) => {
      state.facility.drawerOpen = 'floors'
    },
    openFacilityIncidentsDrawer: (state) => {
      state.facility.drawerOpen = 'incidents'
    },
    openFacilityDevicesDrawer: (state) => {
      state.facility.drawerOpen = 'devices'
    },
    openFacilityCheckpointsDrawer: (state) => {
      state.facility.drawerOpen = 'checkpoints'
    },
    openFacilityTourPlansDrawer: (state) => {
      state.facility.drawerOpen = 'tour-plans'
    },
    setFacilityTabBarCount: (state, action: PayloadAction<FloorTabCounts>) => {
      state.facility = {
        ...state.facility,
        ...action.payload,
      }
    },
    setFacilityIdsFilter: (state, action: PayloadAction<MultiFilter>) => {
      state.facilities.filters.ids = action.payload
    },
    setFacilityCreatedAtFilter: (
      state,
      action: PayloadAction<CreatedAtFilter>
    ) => {
      state.facilities.filters.createdAtRange = action.payload
    },
    setFacilityHasIncidentsFilter: (
      state,
      action: PayloadAction<Filter<boolean>>
    ) => {
      state.facilities.filters.hasIncidents = action.payload
    },
    setDeviceFacilityIdsFilter: (state, action: PayloadAction<MultiFilter>) => {
      state.devices.filters.facilityIds = action.payload
    },
    setDeviceFloorsIdsFilter: (state, action: PayloadAction<MultiFilter>) => {
      state.devices.filters.floorIds = action.payload
    },
    setDeviceFacilityCreatedAtFilter: (
      state,
      action: PayloadAction<CreatedAtFilter>
    ) => {
      state.devices.filters.createdAtRange = action.payload
    },
    setDevicesHasIncidentsFilter: (
      state,
      action: PayloadAction<Filter<boolean>>
    ) => {
      state.devices.filters.hasIncidents = action.payload
    },
    setDeviceStatusFilter: (state, action: PayloadAction<MultiFilter>) => {
      state.devices.filters.deviceStatus = action.payload
    },
    setDeviceTypeFilter: (state, action: PayloadAction<MultiFilter>) => {
      state.devices.filters.deviceType = action.payload
    },
    setIncident: (state, action: PayloadAction<{ id: string }>) => {
      state.drawers.incident.id = action.payload.id
    },
    setOutstandingIncidentsFacilitiesFilter: (
      state,
      action: PayloadAction<MultiFilter>
    ) => {
      state.outstandingIncidents.filters.facilities = action.payload
    },
    setOutstandingIncidentsIncidentTypesFilter: (
      state,
      action: PayloadAction<MultiFilter>
    ) => {
      state.outstandingIncidents.filters.incidentTypes = action.payload
    },
    setOutstandingIncidentsDeviceTypeFilter: (
      state,
      action: PayloadAction<MultiFilter>
    ) => {
      state.outstandingIncidents.filters.deviceType =
        state.outstandingIncidents.filters.deviceType.length > 0
          ? action.payload.length === 0
            ? []
            : [action.payload[1]]
          : action.payload
    },
    setOutstandingIncidentsGuardsFilter: (
      state,
      action: PayloadAction<MultiFilter>
    ) => {
      state.outstandingIncidents.filters.guards = action.payload
    },
    setOutstandingIncidentsOperatorsFilter: (
      state,
      action: PayloadAction<MultiFilter>
    ) => {
      state.outstandingIncidents.filters.operators = action.payload
    },
    setOutstandingIncidentsTimeOrderFilter: (
      state,
      action: PayloadAction<MultiFilter>
    ) => {
      state.outstandingIncidents.filters.timeOrder =
        state.outstandingIncidents.filters.timeOrder.length > 0
          ? action.payload.length === 0
            ? []
            : [action.payload[1]]
          : action.payload
    },
    removeOutstandingIncidentFacility: (
      state,
      action: PayloadAction<string>
    ) => {
      state.outstandingIncidents.filters.facilities =
        state.outstandingIncidents.filters.facilities.filter(
          (i) => i.value !== action.payload
        )
    },
    removeOutstandingIncidentDeviceType: (
      state,
      action: PayloadAction<string>
    ) => {
      state.outstandingIncidents.filters.deviceType =
        state.outstandingIncidents.filters.deviceType.filter(
          (i) => i.value !== action.payload
        )
    },
    removeOutstandingIncidentIncidentTypes: (
      state,
      action: PayloadAction<string>
    ) => {
      state.outstandingIncidents.filters.incidentTypes =
        state.outstandingIncidents.filters.incidentTypes.filter(
          (i) => i.value !== action.payload
        )
    },
    removeOutstandingIncidentGuard: (state, action: PayloadAction<string>) => {
      state.outstandingIncidents.filters.guards =
        state.outstandingIncidents.filters.guards.filter(
          (i) => i.value !== action.payload
        )
    },
    removeOutstandingIncidentOperator: (
      state,
      action: PayloadAction<string>
    ) => {
      state.outstandingIncidents.filters.operators =
        state.outstandingIncidents.filters.operators.filter(
          (i) => i.value !== action.payload
        )
    },
    removeOutstandingIncidentTimeOrder: (
      state,
      action: PayloadAction<string>
    ) => {
      state.outstandingIncidents.filters.timeOrder =
        state.outstandingIncidents.filters.timeOrder.filter(
          (i) => i.value !== action.payload
        )
    },
    clearOutstandingIncidentFilters: (state) => {
      state.outstandingIncidents.filters =
        initialState.outstandingIncidents.filters
    },
    setOutstandingIncidentsPageSize: (state, action: PayloadAction<number>) => {
      state.outstandingIncidents.pageSize = action.payload
    },
    setSOPNameLikeFilter: (state, action: PayloadAction<Filter>) => {
      state.sops.filters.nameLike = action.payload
    },
    setCaseNameOrDisplayIdLike: (state, action: PayloadAction<Filter>) => {
      state.cases.filters.nameOrDisplayIdLike = action.payload
    },
    setCaseStatusFilter: (state, action: PayloadAction<Filter[]>) => {
      state.cases.filters.status = action.payload
    },
    setCaseOwnerFilter: (state, action: PayloadAction<Filter>) => {
      state.cases.filters.owner = action.payload
    },
    setSOPFacilityIdFilter: (state, action: PayloadAction<Filter>) => {
      state.sops.filters.facilityId = action.payload
    },
    setSOPCreatedAtFilter: (state, action: PayloadAction<CreatedAtFilter>) => {
      state.sops.filters.createdAtRange = action.payload
    },
    setSOPCreatorIdFilter: (state, action: PayloadAction<Filter>) => {
      state.sops.filters.creatorId = action.payload
    },
    setSOPPageSize: (state, action: PayloadAction<number>) => {
      state.sops.pageSize = action.payload
    },
    setCaseListPageSize: (state, action: PayloadAction<number>) => {
      state.cases.pageSize = action.payload
    },
    setSubmittedToursPageSize: (state, action: PayloadAction<number>) => {
      state.submittedTours.pageSize = action.payload
    },
    setGatewayDevicesNameLikeFilter: (state, action: PayloadAction<Filter>) => {
      state.gatewayDevices.filters.nameLike = action.payload
    },
    setGatewayDevicesPageSize: (state, action: PayloadAction<number>) => {
      state.gatewayDevices.pageSize = action.payload
    },
    setDevice: (state, action: PayloadAction<DevicePayload>) => {
      state.drawers.device.id = action.payload.id
      state.drawers.device.type = action.payload.type
    },
    setSOP: (state, action: PayloadAction<{ id: string }>) => {
      state.drawers.sop.id = action.payload.id
    },
    openIncidentDrawer: (state) => {
      state.drawers.incident.isOpen = true
    },
    closeIncidentDrawer: (state) => {
      state.drawers.incident.isOpen = false
    },
    openDeviceDrawer: (state) => {
      state.drawers.device.isOpen = true
    },
    closeDeviceDrawer: (state) => {
      state.drawers.device.isOpen = false
    },
    openSOPDrawer: (state) => {
      state.drawers.sop.isOpen = true
    },
    closeSOPDrawer: (state) => {
      state.drawers.sop.isOpen = false
    },
    openSOPIncidentDrawer: (state) => {
      state.drawers.sopIncident.isOpen = true
    },
    closeSOPIncidentDrawer: (state) => {
      state.drawers.sopIncident.isOpen = false
    },
    toggleSOPIncidentDrawer: (state) => {
      state.drawers.sopIncident.isOpen = !state.drawers.sopIncident.isOpen
    },
    setSOPIncidentId: (state, action: PayloadAction<{ id: string }>) => {
      state.drawers.sopIncident.id = action.payload.id
    },
    openSubmittedTourDrawer: (state) => {
      state.drawers.submittedTour.isOpen = true
    },
    closeSubmittedTourDrawer: (state) => {
      state.drawers.submittedTour.isOpen = false
    },
    setSubmittedTourId: (state, action: PayloadAction<{ id: string }>) => {
      state.drawers.submittedTour.id = action.payload.id
    },
    setSOPIncidentNameLike: (state, action: PayloadAction<string>) => {
      state.drawers.sopIncident.sopNameLike = action.payload
    },
    expandEmergencyCall: (state) => {
      state.emergencyCall.isExpanded = true
    },
    minimizeEmergencyCall: (state) => {
      state.emergencyCall.isExpanded = false
    },
    muteEmergencyCall: (state) => {
      state.emergencyCall.isMuted = true
    },
    unmuteEmergencyCall: (state) => {
      state.emergencyCall.isMuted = false
    },
    toggleMuteEmergencyCall: (state) => {
      state.emergencyCall.isMuted = !state.emergencyCall.isMuted
    },
    openEmergencyCall: (state, action: PayloadAction<EmergencyCallPayload>) => {
      state.emergencyCall.isOpen = true
      state.emergencyCall.isExpanded = true
      state.emergencyCall.isMuted = false
      // Set incident details
      state.emergencyCall.incident.id = action.payload?.incidentId
      state.emergencyCall.incident.displayId = action.payload?.incidentDisplayId
      state.emergencyCall.incident.facilityName = action.payload?.facilityName
      state.emergencyCall.incident.emergencyNumber =
        action.payload?.emergencyNumber
    },
    closeEmergencyCall: (state) => {
      state.emergencyCall.isOpen = false
      state.emergencyCall.incident.id = null
      state.emergencyCall.incident.displayId = null
      state.emergencyCall.incident.facilityName = null
      state.emergencyCall.incident.emergencyNumber = null
    },
    setEmergencyCallDisplayIdLikeFilter: (
      state,
      action: PayloadAction<Filter>
    ) => {
      state.emergencyCall.filters.displayIdLike = action.payload
    },
    setEmergencyCallPageSize: (state, action: PayloadAction<number>) => {
      state.emergencyCall.pageSize = action.payload
    },
    setIncidentRulesPageSize: (state, action: PayloadAction<number>) => {
      state.incidentRules.pageSize = action.payload
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.subject,
      }
    },
  },
})

export const {
  resetUi,
  toggleSideNavOpen,
  toggleNotificationsUnread,
  enableEditMode,
  openFacilityFloorsDrawer,
  openFacilityIncidentsDrawer,
  openFacilityDevicesDrawer,
  openFacilityCheckpointsDrawer,
  openFacilityTourPlansDrawer,
  setFacilityTabBarCount,
  toggleEditMode,
  setFacilityIdsFilter,
  setFacilityCreatedAtFilter,
  setFacilityHasIncidentsFilter,
  setDeviceFacilityIdsFilter,
  setDeviceFloorsIdsFilter,
  setDeviceFacilityCreatedAtFilter,
  setDevicesHasIncidentsFilter,
  setDeviceStatusFilter,
  setDeviceTypeFilter,
  setIncident,
  setOutstandingIncidentsFacilitiesFilter,
  setOutstandingIncidentsIncidentTypesFilter,
  setOutstandingIncidentsDeviceTypeFilter,
  setOutstandingIncidentsGuardsFilter,
  setOutstandingIncidentsOperatorsFilter,
  setOutstandingIncidentsTimeOrderFilter,
  setOutstandingIncidentsPageSize,
  removeOutstandingIncidentFacility,
  removeOutstandingIncidentDeviceType,
  removeOutstandingIncidentIncidentTypes,
  removeOutstandingIncidentGuard,
  removeOutstandingIncidentOperator,
  removeOutstandingIncidentTimeOrder,
  clearOutstandingIncidentFilters,
  setDevice,
  setSOP,
  setSOPNameLikeFilter,
  setSOPFacilityIdFilter,
  setSOPCreatedAtFilter,
  setSOPCreatorIdFilter,
  setSOPPageSize,
  setCaseListPageSize,
  setSubmittedToursPageSize,
  setGatewayDevicesNameLikeFilter,
  setGatewayDevicesPageSize,
  openIncidentDrawer,
  openDeviceDrawer,
  openSOPDrawer,
  closeIncidentDrawer,
  closeDeviceDrawer,
  closeSOPDrawer,
  openSOPIncidentDrawer,
  closeSOPIncidentDrawer,
  toggleSOPIncidentDrawer,
  setSOPIncidentId,
  closeSubmittedTourDrawer,
  openSubmittedTourDrawer,
  setSubmittedTourId,
  setSOPIncidentNameLike,
  expandEmergencyCall,
  minimizeEmergencyCall,
  muteEmergencyCall,
  unmuteEmergencyCall,
  toggleMuteEmergencyCall,
  openEmergencyCall,
  closeEmergencyCall,
  setEmergencyCallDisplayIdLikeFilter,
  setEmergencyCallPageSize,
  setIncidentRulesPageSize,
  setCaseNameOrDisplayIdLike,
  setCaseStatusFilter,
  setCaseOwnerFilter,
} = uiSlice.actions

export const selectUI = (state: AppState) => state.ui

export const selectSideNavOpen = (state: AppState) => state.ui.isSideNavOpen

export const selectEditMode = (state: AppState) => state.ui.isEditMode

export const selectNotificationsUnread = (state: AppState) =>
  state.ui.notifications.showUnread

export const selectConfirmEditMode = (state: AppState) =>
  state.ui.hasConfirmedEditMode

export const selectFacilityFloorsDrawerOpen = (state: AppState) =>
  state.ui.facility.drawerOpen === 'floors'

export const selectFacilityIncidentsDrawerOpen = (state: AppState) =>
  state.ui.facility.drawerOpen === 'incidents'

export const selectCheckpointDrawerOpen = (state: AppState) =>
  state.ui.facility.drawerOpen === 'checkpoints'

export const selectFacilityDrawerOpen = (state: AppState) =>
  state.ui.facility.drawerOpen

export const selectFacilityTabBarCount = (state: AppState) => ({
  placedDeviceCount: state.ui.facility.placedDeviceCount,
  incidentsCount: state.ui.facility.incidentsCount,
  floorCount: state.ui.facility.floorCount,
  checkpointCount: state.ui.facility.checkpointCount,
  tourPlansCount: state.ui.facility.tourPlansCount,
})

export const selectFacilityIdsFilter = (state: AppState) =>
  state.ui.facilities?.filters?.ids

export const selectCreatedAtFilter = (state: AppState) =>
  state.ui.facilities?.filters?.createdAtRange

export const selectFacilityHasIncidentsFilter = (state: AppState) =>
  state.ui.facilities?.filters?.hasIncidents

export const facilityFilterCountSelector = createSelector(
  selectFacilityIdsFilter,
  selectCreatedAtFilter,
  selectFacilityHasIncidentsFilter,
  (idsFilter, createdAtFilter, hasIncidentsFilter) => {
    const ids = idsFilter?.length || 0
    const createdAt = createdAtFilter ? 1 : 0
    const hasIncidents = hasIncidentsFilter ? 1 : 0

    return ids + createdAt + hasIncidents
  }
)

export const selectDeviceFacilityIdsFilter = (state: AppState) =>
  state.ui.devices?.filters?.facilityIds

export const selectDeviceFloorsIdsFilter = (state: AppState) =>
  state.ui.devices?.filters?.floorIds

export const selectDeviceCreatedAtFilter = (state: AppState) =>
  state.ui.devices?.filters?.createdAtRange

export const selectDeviceHasIncidentsFilter = (state: AppState) =>
  state.ui.devices?.filters?.hasIncidents

export const selectDeviceStatusFilter = (state: AppState) =>
  state.ui.devices?.filters?.deviceStatus

export const selectDeviceTypeFilter = (state: AppState) =>
  state.ui.devices?.filters?.deviceType

export const deviceFilterCountSelector = createSelector(
  selectDeviceFacilityIdsFilter,
  selectDeviceFloorsIdsFilter,
  selectDeviceCreatedAtFilter,
  selectDeviceHasIncidentsFilter,
  selectDeviceStatusFilter,
  selectDeviceTypeFilter,
  (
    facilityIdsFilter,
    deviceIdsFilter,
    createdAtFilter,
    hasIncidentsFilter,
    deviceStatusFilter,
    deviceTypeFilter
  ) => {
    const facilityIds = facilityIdsFilter?.length || 0
    const floorIds = deviceIdsFilter?.length || 0
    const createdAt = createdAtFilter ? 1 : 0
    const hasIncidents = hasIncidentsFilter ? 1 : 0
    const deviceStatus = deviceStatusFilter ? 1 : 0
    const deviceType = deviceTypeFilter?.length || 0

    return (
      facilityIds +
      floorIds +
      createdAt +
      hasIncidents +
      deviceStatus +
      deviceType
    )
  }
)

export const selectOutstandingIncidentsFacilitiesFilter = (state: AppState) =>
  state.ui.outstandingIncidents?.filters?.facilities

export const selectOutstandingIncidentsDeviceTypeFilter = (state: AppState) =>
  state.ui.outstandingIncidents?.filters?.deviceType

export const selectOutstandingIncidentsIncidentTypeFilter = (state: AppState) =>
  state.ui.outstandingIncidents?.filters?.incidentTypes

export const selectOutstandingIncidentsGuardsFilter = (state: AppState) =>
  state.ui.outstandingIncidents?.filters?.guards

export const selectOutstandingIncidentsOperatorsFilter = (state: AppState) =>
  state.ui.outstandingIncidents?.filters?.operators

export const selectOutstandingIncidentsTimeOrderFilter = (state: AppState) =>
  state.ui.outstandingIncidents?.filters?.timeOrder

export const selectOutstandingIncidentsPageSize = (state: AppState) =>
  state.ui.outstandingIncidents?.pageSize

export const selectIsIncidentDrawerOpen = (state: AppState) =>
  state.ui.drawers?.incident?.isOpen

export const selectIsDeviceDrawerOpen = (state: AppState) =>
  state.ui.drawers?.device?.isOpen

export const selectIsSOPDrawerOpen = (state: AppState) =>
  state.ui.drawers?.sop?.isOpen

export const selectSubmittedTourOpen = (state: AppState) =>
  state.ui.drawers?.submittedTour?.isOpen

export const selectIncident = (state: AppState) => ({
  id: state.ui.drawers.incident.id,
})
export const selectSubmittedTour = (state: AppState) => ({
  id: state.ui.drawers.submittedTour.id,
})
export const selectDevice = (state: AppState) => ({
  id: state.ui.drawers.device.id,
  type: state.ui.drawers.device.type,
})
export const selectSOP = (state: AppState) => ({
  id: state.ui.drawers.sop.id,
})
export const selectSOPNameLikeFilter = (state: AppState) =>
  state.ui.sops?.filters?.nameLike

export const selectSOPFacilityIdFilter = (state: AppState) =>
  state.ui.sops?.filters?.facilityId

export const selectSOPCreatedAtFilter = (state: AppState) =>
  state.ui.sops?.filters?.createdAtRange

export const selectSOPCreatorIdFilter = (state: AppState) =>
  state.ui.sops?.filters?.creatorId

export const selectSOPPageSize = (state: AppState) => state.ui.sops?.pageSize

export const selectCaseListPageSize = (state: AppState) =>
  state.ui.cases?.pageSize

export const selectSubmittedToursPageSize = (state: AppState) =>
  state.ui.submittedTours?.pageSize

export const selectGatewayDevicesNameLikeFilter = (state: AppState) =>
  state.ui.gatewayDevices?.filters?.nameLike

export const selectGatewayDevicesPageSize = (state: AppState) =>
  state.ui.gatewayDevices?.pageSize

export const sopFilterCountSelector = createSelector(
  selectSOPFacilityIdFilter,
  selectSOPCreatedAtFilter,
  selectSOPCreatorIdFilter,
  (facilityIdsFilter, createdAtFilter, creatorIdFilter) => {
    const facilityId = facilityIdsFilter ? 1 : 0
    const createdAt = createdAtFilter ? 1 : 0
    const creatorId = creatorIdFilter ? 1 : 0

    return facilityId + createdAt + creatorId
  }
)

export const selectSOPInicdentDrawerState = (state: AppState) =>
  state.ui.drawers?.sopIncident

export const selectEmergencyCall = (state: AppState) => state.ui?.emergencyCall

export const selectEmergencyCallIncident = (state: AppState) =>
  state.ui?.emergencyCall.incident

export const selectEmergencyCallDisplayIdLikeFilter = (state: AppState) =>
  state.ui.emergencyCall?.filters?.displayIdLike

export const selectEmergencyCallPageSize = (state: AppState) =>
  state.ui.emergencyCall?.pageSize

export const selectIncidentRulesPageSize = (state: AppState) =>
  state.ui.incidentRules?.pageSize

export const selectCaseNameOrDisplayIdLike = (state: AppState) =>
  state.ui.cases?.filters?.nameOrDisplayIdLike

export const selectCaseStatusFilter = (state: AppState) =>
  state.ui.cases?.filters?.status

export const selectCaseOwnerFilter = (state: AppState) =>
  state.ui.cases?.filters?.owner

export const caseFilterCountSelector = createSelector(
  selectCaseStatusFilter,
  selectCaseOwnerFilter,
  (statusFilter, ownerFilter) => {
    const status = statusFilter?.length ? 1 : 0
    const owner = ownerFilter ? 1 : 0

    return status + owner
  }
)

// Default export for reducers is a pattern recommended by Redux
// See here: <https://redux.js.org/tutorials/fundamentals/part-3-state-actions-reducers#combinereducers>
// eslint-disable-next-line import/no-default-export
export default uiSlice.reducer
