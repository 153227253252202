import { ChangeEvent, useState } from 'react'

import {
  Box,
  FormControl as ChakraFormControl,
  FormControlOptions,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react'
import styled from '@emotion/styled'

import { Textarea } from '../Textarea'

export const StyledFormTextareaControl = styled(ChakraFormControl)`
  .chakra-form__label {
    opacity: ${({ labelOpacity }) => (labelOpacity ? labelOpacity : 0.5)};
    color: ${({ labelColour }) => (labelColour ? labelColour : '#2d2e41')};
    font-weight: ${({ labelFontWeight }) =>
      labelFontWeight ? labelFontWeight : 'bold'};
  }
`

export interface FormTextareaControlIProps extends FormControlOptions {
  /**
   * The label text used to inform users as to what information is
   * requested for a text field.
   */
  label?: string
  /**
   * The label font weight
   */
  labelFontWeight?: string
  /**
   * The label text colour
   */
  labelColour?: string
  /**
   * The label text opacity
   */
  labelOpacity?: number
  /**
   * If `true`, the label text will be bold and the opcaity is full
   */
  isLabelBold?: boolean
  /**
   * The custom `id` to use for the form control. This is passed directly to the form element (e.g, Input).
   * - The form element (e.g Input) gets the `id`
   * - The form label id: `form-label-${id}`
   * - The form error text id: `form-error-text-${id}`
   * - The form helper text id: `form-helper-text-${id}`
   */
  id?: string
  /**
   * The maxium amount of characters used allowed in the input
   */
  maxCharCount?: number
  /**
   * If `true`, the input will display valid state styles with check icon.
   */
  valid?: boolean
  /**
   * The error message to display when `isInvalid` is `true`.
   */
  errorMessage?: string
  /**
   * The width of the input element.
   */
  width?: string
  /**
   * If added, the button will show an icon to the left of the input.
   */
  leftIcon?: React.ElementType
  /**
   * If `true`, the input will display error state styles.
   */
  isInvalid?: boolean
  /**
   * The placeholder value for input field.
   */
  placeholder?: string
  [key: string]: unknown
}

export const FormTextareaControl = ({
  id,
  label,
  labelColour,
  labelOpacity,
  labelFontWeight,
  isLabelBold,
  isInvalid = false,
  maxCharCount,
  valid = false,
  errorMessage,
  width = '100%',
  placeholder,
  textareaRef,
  ...rest
}: FormTextareaControlIProps) => {
  const [charCount, setCharCount] = useState(0)
  const shouldShowMaxCharCount = maxCharCount !== undefined

  const updateCharacterCount = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setCharCount(e.target.value.length)
  }

  return (
    <StyledFormTextareaControl
      data-testid='form-control'
      id={id}
      isInvalid={isInvalid}
      labelColour={labelColour}
      labelFontWeight={labelFontWeight}
      labelOpacity={labelOpacity}
      width={width}
    >
      {label && (
        <FormLabel
          data-testid='form-control-label'
          fontWeight={isLabelBold ? 'bold' : 'normal'}
          htmlFor={id}
          mr='0'
        >
          {label}
        </FormLabel>
      )}
      <Textarea
        isInvalid={isInvalid}
        {...(maxCharCount && { maxLength: maxCharCount })}
        name={id}
        onChange={updateCharacterCount}
        placeholder={placeholder}
        textareaRef={textareaRef}
        valid={valid}
        {...rest}
      />
      <FormErrorMessage data-testid='form-control-error'>
        {errorMessage}
      </FormErrorMessage>
      {shouldShowMaxCharCount && (
        <Box color='#1A202C' fontSize='13px' fontWeight='500' mt='2px'>
          {charCount}/{maxCharCount} characters used
        </Box>
      )}
    </StyledFormTextareaControl>
  )
}
