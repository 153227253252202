import { Center } from '@chakra-ui/react'

export interface VideoErrorIProps {
  errorLabel: string
}

export const VideoError = ({ errorLabel }: VideoErrorIProps) => {
  return (
    <Center
      color='#fff'
      height='100%'
      justifyContent='center'
      left={0}
      pos='absolute'
      textAlign='center'
      top={0}
      width='100%'
    >
      {errorLabel}
    </Center>
  )
}
