import { ReactNode } from 'react'

import { Box, TabList, useStyleConfig, useTab } from '@chakra-ui/react'
import styled from '@emotion/styled'

interface DrawerTabsIProps {
  tabs: { label: string; icon: ReactNode }[]
}

interface TabIProps {
  label: string
  icon: ReactNode
}

const TabButtonStyled = styled(Box)`
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  align-items: center;
  border-top: 1px solid #e2e8f0;
  border-left: 1px solid #e2e8f0;
  padding: 13px;
  padding-right: 16px;
  background-image: ${(p) =>
    p.isActive ? 'linear-gradient(to right, #2E6CDB, #6096F5)' : 'transparent'};

  .tab-btn__label {
    color: ${(p) => (p.isActive ? '#fff' : '#3b4b5d')};
    font-weight: bold;
  }

  .tab-btn__icon {
    color: ${(p) => (p.isActive ? '#fff' : '#3279c7')};

    path {
      stroke: ${(p) => (p.isActive ? '#fff' : '#3279c7')}!important;
    }
  }

  &:first-of-type {
    border-top-left-radius: 5px;
  }
  &:last-of-type {
    border-top-right-radius: 5px;
    border-right: 1px solid #e2e8f0;
  }
`
const TabsStyled = styled(Box)`
  display: flex;
  width: auto;
`

const TabButton = ({ icon, label, ...rest }: TabIProps) => {
  const tabProps = useTab(rest)
  const isSelected = !!tabProps['aria-selected']

  const styles = useStyleConfig('Tab')
  return (
    <TabButtonStyled
      _focus={{
        outline: 'none',
      }}
      isActive={isSelected}
      sx={styles}
      {...tabProps}
    >
      {icon}
      <Box
        className='tab-btn__label'
        fontSize='12px'
        fontWeight='medium'
        letterSpacing='-0.4px'
        lineHeight='15px'
        ml='8px'
      >
        {label}
      </Box>
    </TabButtonStyled>
  )
}

export const DrawerTabs = ({ tabs }: DrawerTabsIProps) => {
  return (
    <TabList border='none' pt='20px' px='24px'>
      <TabsStyled>
        {tabs.map(({ label, icon }) => (
          <TabButton icon={icon} key={label} label={label} />
        ))}
      </TabsStyled>
    </TabList>
  )
}
